export const ResponseExtrasWaybe = {
  map(data) {
    return data.map((e) => mapToExtra(e));
  },
};

function mapToExtra(extra) {
  return {
    idItemConfiguracao: extra.idItemConfiguracao,
    nomeExtra: extra.nome,
    valorUnitario: extra.valorUnitario,
    // estaContratado: extra.estaContratado,
    tipoStatusContrato: extra.tipoStatusContrato,
    tipoItemPlanoWaybe: extra.tipoItemPlanoWaybe,
    quantidadeContratada: extra.quantidadeContratada,
    recorrenciaCancelada: extra.recorrenciaCancelada,
    quantidadeRecorrenciaCancelada: extra.quantidadeRecorrenciaCancelada,
  };
}
