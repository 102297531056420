export const ResponsePlanoParaContratacaoWaychef = {
  mapPlanosReduzido(data) {
    return data.map((p) => {
      return {
        valorMensal: p.valorMensal,
        valorTrimestral: p.valorTrimestral,
        valorSemestral: p.valorSemestral,
        valorAnual: p.valorAnual,
        idPlano: p.idPlano,
        descricao: p.nome,
        tipoRecorrenciaPlano: p.tipoRecorrenciaPlano,
      };
    });
  },
};

export const RequestPlanoParaContratacaoWaychef = {};

function mockDados() {
  return [
    {
      idPlano: 1,
      descricao: "Bronze",
      valorMensal: 10,
      valorTrimestral: 30,
      valorSemestral: 60,
      valorAnual: 120,
    },
    {
      idPlano: 2,
      descricao: "Prata",
      valorMensal: 110,
      valorTrimestral: 130,
      valorSemestral: 160,
      valorAnual: 1120,
    },
    {
      idPlano: 3,
      descricao: "Ouro",
      valorMensal: 210,
      valorTrimestral: 230,
      valorSemestral: 260,
      valorAnual: 2120,
    },
  ];
}
