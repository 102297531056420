import useIsDev from "./useIsDev";

export const disableWarnings = () => {
  if (useIsDev()) {
    return;
  }
  const log = console.error.bind(console);
  console.error = (...args) => {
    if (
      (typeof args[0] === "string" &&
        ((args[0].includes("The pseudo class") &&
          args[0].includes(
            "is potentially unsafe when doing server-side rendering. Try changing it to"
          )) ||
          args[0].includes("Function components cannot be given refs.") ||
          (args[0].includes("Warning: React does not recognize the") &&
            args[0].includes("prop on a DOM element.")))) ||
      args[0].includes("Invalid DOM property") ||
      args[0].includes("Warning: Encountered two children with the same") ||
      args[0].includes(
        "Warning: Received `false` for a non-boolean attribute `aria-hidden`"
      ) ||
      args[0].includes("validateDOMNesting(...)") ||
      args[0].includes("Warning: Each child in a list should have a unique") ||
      args[0].includes("Functions that are interpolated in css")
    ) {
      return;
    }
    log(...args);
  };
};
