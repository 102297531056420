import { BottomSheet } from "react-spring-bottom-sheet";
import Popup from "reactjs-popup";
import styled from "@emotion/styled";

export const Container = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 160px;
  width: 100%;

  @media (max-width: 768px) {
    height: 110px;
  }
`;

export const LinhaIcones = styled.div`
  display: flex;
  padding: 0px 40px;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  width: 100%;
  color: ${(props) => props.theme.cores.brand40neutral100};
  border-bottom: 0.5px solid #cccc;

  @media (max-width: 1100px) {
    padding: 10px 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 768px) {
    border: none;
  }
`;

export const LinhaMenusContainer = styled.nav`
  ${(props) => props.menuFixo && !props.rotaCardapio && `position: fixed`};
  ${(props) =>
    props.menuFixo &&
    !props.rotaCardapio &&
    ` background-color: ${props.theme.cores.surfaceBackground1};`};
  top: 0;
  height: 60px;
  width: 100%;
  padding: 0px 0px 0px 40px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 3px 4px -2px gray;
  transform-origin: top;
  overflow-y: auto;
  z-index: 5;

  @keyframes expandirMenu {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }

  @media (max-width: 768px) {
    display: none !important;
  }
`;

export const LinhaMenusContainerFixo = styled.nav`
  position: fixed;
  background-color: rgba(243, 243, 243, 1);
  top: 0;
  height: 60px;
  width: 100%;
  padding: 0 40px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 3px 4px -2px gray;

  animation: expandirMenu 0.3s linear forwards;

  @keyframes expandirMenu {
    0% {
      opacity: 0;
      transform: rotate(0deg) scaleX(1) scaleY(0);
    }
    100% {
      opacity: 1;
      transform: rotate(0deg) scaleX(1) scaleY(1);
    }
  }

  @media (max-width: 768px) {
    display: none !important;
  }
`;

export const AlterarLojaContainer = styled.div`
  cursor: pointer;
  background-color: ${(props) => props.theme.cores.surface2};
  border-radius: 8px;
  width: 40vw;
  max-width: 440px;
  height: 60px;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:hover {
    opacity: 0.8;
  }

  h5 {
    font-size: 15px;
    font-family: "Comfortaa", cursive;
    font-weight: 600;
  }

  p {
    font-size: 14px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
  }

  @media (max-width: 1100px) {
    height: 60px;
    width: 60vw;
    max-width: 400px;
    padding: 0 10px;
  }

  @media (max-width: 420px) {
    height: 60px;
    width: 70vw;
  }
`;

export const Linha = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const Coluna = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1100px) {
    padding-bottom: 8px;
  }
`;

export const TextoAlterarLoja = styled.span`
  color: ${(props) => props.theme.cores.brand40neutral100};
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
`;

export const TextoPerfil = styled.h5`
  margin-right: 10px;
  font-size: 16px;
`;

export const ContainerMenuItem = styled.div`
  /* min-width: 150px; */
  flex-direction: row;
  //flex: 1;
  //display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px 10px 10px 10px;
  border-radius: 4px;
  ${(props) =>
    props.selecionado && `background-color: rgba(35, 160, 232, 0.15);`}
  ${(props) =>
    props.atualizar && `animation: vibrar 0.3s ease-in-out forwards;`}
  
  display: flex;
  gap: 5px;

  .menu-item-selecionado {
    background-color: red;
  }

  .icone-menu-painel-transicao {
    color: ${(props) => props.theme.cores.textoSimples};
    fill: ${(props) => props.theme.cores.textoSimples};
    ${(props) =>
      props.aberto && `animation: trocarCor 0.5s ease-in-out forwards`};
  }

  .icone-menu-painel-transicao-inverter {
    ${(props) =>
      props.$aberto && `animation: girarIcone 0.5s ease-in-out forwards`};
  }

  @keyframes vibrar {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(10px);
    }
    50% {
      transform: translateX(-10px);
    }
    75% {
      transform: translateX(10px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes girarIcone {
    0% {
      transform: rotate(0deg);
      fill: ${(props) => props.theme.cores.textoSimples};
    }
    100% {
      transform: rotate(180deg);
      fill: ${(props) => props.theme.cores.brand40Default};
    }
  }

  @keyframes trocarCor {
    0% {
      color: ${(props) => props.theme.cores.textoSimples};
      fill: ${(props) => props.theme.cores.textoSimples};
    }
    100% {
      color: ${(props) => props.theme.cores.brand40Default};
      fill: ${(props) => props.theme.cores.brand40Default};
    }
  }
`;

export const TextoMenuItem = styled.span`
  margin: 0 10px;
  font-size: 16px;
  font-weight: 400;
  user-select: none;
  white-space: nowrap;
`;

export const PopupCustomizadoPerfil = styled(Popup)`
  &-content {
    flex-direction: column;
    flex: 1;
    display: flex;
    height: auto;
    min-width: 150px !important;
    padding: 12px 16px;
    animation: delayPopup 0.5s ease-in-out forwards;
    background-color: ${(props) => props.theme.cores.surface2};
  }

  @keyframes delayPopup {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const PopupCustomizado = styled(Popup)`
  &-content {
    width: 230px !important;
    height: auto;
    padding: 12px 16px;
    animation: delayPopup 0.5s ease-in-out forwards;
    background-color: ${(props) => props.theme.cores.surface2};
    max-height: 400px;

    overflow-y: auto;

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) =>
        theme.adaptativo("brand40Default", "brand80")};
      border-radius: 5px;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 8px;
      display: block;
      background-color: ${(props) => props.theme.cores.brand20};
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${(props) => props.theme.cores.actionActive};
      width: 5px;
      border-radius: 5px;
    }
  }

  @keyframes delayPopup {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const MenuItemFilhoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 4px;
  border-bottom: 0.2px solid #ccc3;

  p {
    text-overflow: ellipsis;
    width: 100%;
    padding: 8px 0;
  }

  &:has(p):hover {
    background-color: ${(props) => props.theme.cores.brand10neutral20};
    border-radius: 5px;
  }
`;

export const BotaoTransparente = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;

  &:hover {
    transition: 0.2s;
    opacity: 0.7;
  }
`;

export const StyledBottomSheet = styled(BottomSheet)`
  [data-rsbs-overlay] {
    background: ${(props) => props.theme.cores.surface2};
    height: 70%;
    z-index: 101;
  }
`;

export const CabecalhoModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 42px;
  padding-right: 42px;
  padding-left: 42px;
  margin-bottom: 42px;

  @media (max-width: 768px) {
    p {
      color: transparent;
    }
  }
`;

export const TextoCabecalhoModal = styled.p`
  font-family: "Comfortaa", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 29px;
  letter-spacing: 0.15px;
  color: ${(props) => props.theme.cores.textoModal};
`;

export const ContainerDireita = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 42px;
  padding-left: 42px;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const ContainerInput = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.cores.corInput};
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Input = styled.input`
  border-radius: 4px 4px 0px 0px;
  border: 0;
  font-size: 1rem;
  background-color: ${(props) => props.theme.cores.backgroundInput};
  color: ${(props) => props.theme.cores.corInput};
  padding: 1rem;
  width: 20rem;

  &::placeholder {
    color: ${(props) => props.theme.cores.corInput};
  }

  &:focus {
    outline: 0;
    border: none;

    &::placeholder {
      color: transparent;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    background: transparent;
  }
`;

export const ContainerTabela = styled.div`
  padding-left: 42px;
  padding-right: 42px;
`;

export const TextoVisualizar = styled.p`
  color: #23a1e8;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-decoration: underline;
  cursor: pointer;
`;

export const ImgEmpresaModal = styled.img`
  object-fit: cover;
`;

export const TextoNavegacao = styled.p`
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.25px;
  text-transform: capitalize;
`;

export const NomeEmpresa = styled.p`
  font-family: "Comfortaa", cursive;
  font-size: 13px;
  font-weight: bold;
`;

export const TipoEmpresa = styled.p`
  font-family: "Comfortaa", cursive;
  font-size: 11px;
  font-weight: 300;
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
`;

export const OpcaoItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: unset;
  align-items: center;
  cursor: pointer;
  padding: 5px 5px 5px 7px;
  border-radius: 5px;
  &:hover {
    background-color: rgba(35, 160, 232, 0.15);
  }

  ${(props) =>
    props.efeito &&
    `
  
      &:hover{
          animation: animacaoBotaoAdicionar 0.9s linear reverse infinite;
      }

      @keyframes animacaoBotaoAdicionar {
          0% {
              transform: scale(1);
          }
          50%{
              transform: scale(0.95);
          }
          100% {
              transform: scale(1);
          }
      }
    `};
`;

export const ItemSeparador = styled.div`
  margin-top: 7px;
  margin-bottom: 7px;
  height: 1px;
  background-color: gray;
  width: auto;
  opacity: 0.3;
`;

export const TextoItem = styled.h3`
  font-size: 16px;
  padding: 5px;
  font-weight: 400;
  color: ${(props) => props.theme.cores.textoSimples};
`;
