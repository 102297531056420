import { TextField } from "@mui/material";
import { BottomSheet } from "react-spring-bottom-sheet";
import Popup from "reactjs-popup";
import styled from "@emotion/styled";

export const ContainerFundoBranco = styled.div`
  margin-top: 94px;
  width: 100%;
  background-color: ${(props) => props.theme.cores.surface2};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  margin-bottom: 146px;

  @media (max-width: 920px) {
    margin-bottom: 50px;
    margin-top: 24px;
    border-radius: 16px;
  }
`;

export const CabecalhoMudarPagina = styled.header`
  background-color: ${(props) => props.theme.adaptativo("brand10", "brand70")};
  height: 72px;
  display: flex;
  align-items: center;
  gap: 48px;
  border-radius: 16px 16px 0px 0px;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 29px;
  padding: 16px 24px;
`;

export const PopupCustomizado = styled(Popup)`
  &-content {
    background-color: ${(props) => props.theme.cores.surface2};
    height: 500px;
    width: 70vw;
    border-radius: 16px;
    overflow-y: scroll;
    border: none;
    width: 898px;
  }

  &-overlay {
  }
`;

export const ContainerPopup = styled.div`
  padding-top: 34px;
  padding-left: 21px;
  padding-bottom: 39px;
  padding-right: 24px;
`;

export const CabecalhoModal = styled.div`
  padding-left: 13px;
  margin-bottom: 12px;
`;

export const TextoCabecalhoModalNovoCartao = styled.p`
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 37px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.cores.textoSimples};
`;
export const LinhaCabecalho = styled.hr`
  border: 1px solid ${({ theme }) => theme.cores.corHr};
  width: 100%;
  margin-bottom: 24px;
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
  background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? String(theme.cores.neutral100).replace("1)", "0.2)")
      : "transparent"};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  border: ${({ theme }) =>
    theme.temaDarkAtivo ? "" : "1px solid rgba(73, 69, 79, 1)"};

  z-index: 50;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0px;
  }
`;

export const ContainerBodyModal = styled.div`
  display: flex;
  gap: 110px;
`;

export const ContainerInputsModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 27px;
  width: 50%;

  @media (max-width: 768px) {
    gap: 16px;
    width: 100%;
    margin-bottom: 50px;
  }
`;

export const InputsVencimentoCvv = styled.div`
  display: flex;
  gap: 15px;
`;

export const ContainerCartaoModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 69px;
  width: 40%;
`;

export const CartaoModal = styled.div`
  width: 320px;
  height: 169px;
  background: rgba(33, 36, 38, 1);
  border-radius: 16px;
  padding-top: 20px;
  padding-left: 16px;
  padding-bottom: 4px;
  padding-right: 40px;
`;

export const NomeCartaoModal = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #ffffff;
  margin-bottom: 50px;
`;

export const NumeroCartaoModal = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #ffffff;
  margin-bottom: 12px;
`;

export const BotoesModal = styled.div`
  display: flex;
  gap: 24px;

  @media (max-width: 768px) {
    gap: 16px;
  }
`;

export const BotaoCancelar = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 8px;
  width: 109px;
  height: 48px;
  background: transparent;
  border: 1px solid ${({ theme }) => theme.cores.semanticDanger2};
  cursor: pointer;
  transition: 0.3s;
  border-radius: 8px;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 768px) {
    width: 50%;
  }
`;

export const TextoBotaoCancelar = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.cores.semanticDanger2};

  @media (max-width: 920px) {
    font-size: 16px;
  }
`;

export const BotaoCadastrarCartaoModal = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 8px;
  width: 179px;
  height: 48px;
  border-radius: 8px;
  background: ${({ theme }) =>
    theme.adaptativo("semanticSuccess3", "semanticSuccess2")};
  cursor: pointer;
  transition: 0.3s;
  border: none;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 768px) {
    width: 50%;
  }
`;

export const TextoBotaoCadastrarCartaoModal = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: #ffffff;

  @media (max-width: 920px) {
    font-size: 16px;
  }
`;

export const BottomSheetCustomizada = styled(BottomSheet)`
  --rsbs-bg: ${({ theme }) => theme.cores.surface2};
`;

export const ContainerBottomSheet = styled.div`
  padding-top: 43px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 10px;
`;

export const ContainerCabecalhoBottomSheet = styled.div`
  margin-bottom: 36px;
`;

export const TextoCabecalhoBottomSheet = styled.p`
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 26px;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.cores.textoSimples};
`;
