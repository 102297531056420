import {
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowUp,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import tornarSlider from "../../utils/tornarSlider";
import {
  BotaoAvancarRetroceder,
  BotaoPagina,
  BotoesPagina,
  Cabecalho,
  CabecalhoSVGContainer,
  Celula as CelulaStyle,
  Coluna,
  ContainerMostrarPagina,
  ContainerNumeroPagina,
  ContainerTamanho,
  Controle,
  MostrarPagina,
  Slider,
  Tabela,
  TextoNumeroPagina,
} from "./styles";

import { useTheme } from "@emotion/react";
import useTamanhoTela from "../../utils/useTamanhoTela";
import { CampoDeSelecao } from "../CampoInput";

const verificarDadosParametro = (dadosParametro) => {
  let dados = {
    colunas: [],
    dados: [],
    pagina: 0,
    paginas: 0,
  };

  switch (typeof dadosParametro) {
    case "function":
      dados = dadosParametro();
      break;
    case "object":
      if (dadosParametro.colunas && dadosParametro.dados) {
        dados = dadosParametro;
        break;
      }
      break;
    default:
      
      break;
  }

  return dados;
};

const TabelaOrdenada = ({
  dados,
  controlePersonalizado,
  funcaoCor,
  avancarPagina,
  retrocederPagina,
  tamanhoMinimo,
  style = {},
}) => {
  const tema = useTheme();
  const { largura } = useTamanhoTela();

  const dadosVerificados = verificarDadosParametro(dados);

  const Celula = (props) => {
    if (funcaoCor)
      return (
        <CelulaStyle
          {...props}
          $func={funcaoCor}
          $paddingHorizontal={props.paddingHorizontal}
        />
      );

    return <CelulaStyle {...props} />;
  };

  const sentidos = {
    asc: "asc",
    desc: "desc",
  };

  const [ordenada, setOrdenada] = useState({
    coluna: NaN,
    sentido: sentidos.asc,
  });

  const refTabelaScroll = useRef();

  useEffect(() => {
    tornarSlider(refTabelaScroll.current);
  }, [refTabelaScroll]);

  const CabecalhoInterativo = ({ children, state = null, onClick }) => {
    return (
      <Cabecalho onClick={onClick}>
        <h1>{children}</h1>
        {children === " " ||
        children === "" ||
        children === "Insc.Federal" ||
        children === "  " ? (
          <div> </div>
        ) : (
          <CabecalhoSVGContainer>
            <FontAwesomeIcon
              icon={
                state == null
                  ? faMinus
                  : state === sentidos.asc
                  ? faArrowUp
                  : faArrowDown
              }
              size="xs"
            />
          </CabecalhoSVGContainer>
        )}
      </Cabecalho>
    );
  };

  const checarOrdenada = (i) =>
    ordenada.coluna === i ? ordenada.sentido : null;

  const ordenarColuna = (i) => {
    if (ordenada.coluna === i) {
      setOrdenada({
        ...ordenada,
        sentido:
          ordenada.sentido === sentidos.asc ? sentidos.desc : sentidos.asc,
      });  
      return;
    }
    setOrdenada({ ...ordenada, coluna: i });
  };

  const gerarBotoes = (
    pagina,
    paginas,
    maxBotoes = 5,
    requisitarDados = () => {}
  ) => {
    const botao = (e) => (
      <BotaoPagina
        key={e}
        disabled={e === pagina}
        accent="#000000"
        color={e === pagina ? "#44adee" : "transparent"}
        selected={e === pagina}
        onClick={() => requisitarDados(e)}
      >
        {e + 1}
      </BotaoPagina>
    );

    // Quantos botões serão mostrados
    // No máximo maxBotoes, mas pode ser menos devido a quantidade de páginas
    let quantidadeBotoesMostrados = Math.min(paginas, maxBotoes);

    let metadeMaxBotoes = (maxBotoes - 1) / 2;
    let botaoInicial = 0;
    let ultimoBotao = paginas - 1;

    // Booleano
    let todosBotoesSendoMostrados = paginas === quantidadeBotoesMostrados;

    // Booleano
    let chegandoNosUltimosBotoes = pagina + metadeMaxBotoes > ultimoBotao;

    if (!todosBotoesSendoMostrados) {
      let offset = metadeMaxBotoes;

      if (chegandoNosUltimosBotoes)
        offset = offset + (pagina + metadeMaxBotoes - ultimoBotao);

      botaoInicial = Math.max(0, pagina - offset);
    }

    return Array.from({ length: quantidadeBotoesMostrados }, (_, index) =>
      botao(index + botaoInicial)
    );
  };

  return (
    <Coluna style={{ ...style }}>
      <Slider ref={refTabelaScroll}>
        <ContainerTamanho $tamanhoMinimo={tamanhoMinimo}>
          <Tabela
            $colunas={dadosVerificados.colunas}
            $larguraTela={largura}
            $tamanhoMinimo={tamanhoMinimo}
          >
            {dadosVerificados.colunas.map((coluna, index) => {
              if (!coluna.valor || !coluna.gerador) {
              }

              if (coluna.componenteHeader) {
                const ComponenteHeader = coluna.componenteHeader;
                return (
                  <Cabecalho
                    key={"tabela-ordenada-cabecalho-" + index}
                    $paddingHorizontal={dadosVerificados.paddingHorizontal}
                  >
                    <ComponenteHeader />
                  </Cabecalho>
                );
              }

              if (coluna.gerador)
                return (
                  <Cabecalho
                    key={"tabela-ordenada-cabecalho-" + index}
                    $paddingHorizontal={dadosVerificados.paddingHorizontal}
                  >
                    {coluna.gerador()}
                  </Cabecalho>
                );

              // if (coluna.naoOrdenavel)
              if (true)
                return (
                  <Cabecalho
                    key={"tabela-ordenada-cabecalho-" + index}
                    $paddingHorizontal={dadosVerificados.paddingHorizontal}
                  >
                    {coluna.componenteTitulo ? (
                      coluna.componenteTitulo()
                    ) : (
                      <h1>{coluna.valor}</h1>
                    )}
                  </Cabecalho>
                );
            })}
            {dadosVerificados.dados.map((linha, index) =>
              dadosVerificados.colunas.map((coluna, indexColuna) => {
                if (coluna.gerador)
                  return (
                    <Celula
                      $index={index}
                      key={index}
                      $paddingHorizontal={dadosVerificados.paddingHorizontal}
                    >
                      {coluna.gerador(index)}
                    </Celula>
                  );

                return (
                  <Celula
                    $index={index}
                    $paddingHorizontal={dadosVerificados.paddingHorizontal}
                    key={linha[coluna.valor] + index + indexColuna}
                  >
                    <p style={{ width: "100%" }}>{linha[coluna.valor]}</p>
                  </Celula>
                );
              })
            )}
          </Tabela>
        </ContainerTamanho>
      </Slider>
      <Controle $index={dadosVerificados.dados.length} $alternarEmpresa={true}>
        {controlePersonalizado ? (
          controlePersonalizado()
        ) : (
          <>
            <ContainerMostrarPagina>
              <MostrarPagina>
                <p>
                  ({dadosVerificados.pagina + 1} de {dadosVerificados.paginas})
                </p>
              </MostrarPagina>
              {/* <ContainerNumeroPagina>
                <TextoNumeroPagina>0{dadosTabela.pagina}</TextoNumeroPagina>
                <IconeBotao>
                  <CaretDown
                    size={16}
                    color={tema.cores.corBoxPagina}
                    weight="bold"
                  />
                </IconeBotao>
                <CampoDeSelecao
                  valor={dadosTabela.pagina}
                  style={{ marginTop: 0, marginLeft: 0, width: "100%" }}
                  items={Array.from({ length: dadosTabela.paginas }, (_, i) =>
                    (i + 1).toString().padStart(2, "0")
                  )}
                />
              </ContainerNumeroPagina> */}
            </ContainerMostrarPagina>

            <BotoesPagina>
              {dadosVerificados.pagina === 0 ? (
                <></>
              ) : (
                <BotaoAvancarRetroceder
                  onClick={() =>
                    dadosVerificados?.mudarPagina(dadosVerificados.pagina - 1)
                  }
                >
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    color={tema.cores.actionActive}
                  />
                </BotaoAvancarRetroceder>
              )}
              {gerarBotoes(
                dadosVerificados.pagina,
                dadosVerificados.paginas,
                dadosVerificados.maxBotoes,
                dadosVerificados.mudarPagina
              )}
              {dadosVerificados.pagina === dadosVerificados.paginas - 1 ? (
                <></>
              ) : (
                <BotaoAvancarRetroceder
                  onClick={() =>
                    dadosVerificados?.mudarPagina(dadosVerificados.pagina + 1)
                  }
                >
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    color={tema.cores.actionActive}
                  />
                </BotaoAvancarRetroceder>
              )}
            </BotoesPagina>
          </>
        )}
      </Controle>
    </Coluna>
  );
};

export default TabelaOrdenada;
