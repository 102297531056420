import * as S from "./styles"
import ImgWaybe from "../../assets/images/waybe_logo.png";
import { CampoDeSenha } from "../../components/CampoInput";
import ImgBackgroundLogin from "../../assets/images/login_background.png";
import ImgLogoSifatAzul from "../../assets/images/logoSifatAzul.png";
import ImgLogoSifatBranco from "../../assets/images/logoSifatBranco.png";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/auth";
import { useApi } from "../../contexts/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

export function RecuperarSenha() {
  const theme = useTheme();

  const [hasUpper, setHasUpper] = useState(null)
  const [hasLower, setHasLower] = useState(null)
  const [hasEightLength, setHasEightLenght] = useState(null)
  const [hasNumber, setHasNumber] = useState(null)
  const [passwordIsIqual, setPasswordIsIqual] = useState(false)
  const { dadosAuth } = useAuth();
  const [inputConfirmarSenha, setInputConfirmarSenha] = useState("")
  const [inputNovaSenha, setInputNovaSenha] = useState("")
  const { put } = useApi();
  const [disableButton, setDisableButton] = useState()
  const [senhaFinal, setSenhaFinal] = useState('')
  const [searchParams] = useSearchParams();

  const chave = searchParams.get("chave");
  const nome = searchParams.get("nome");

  const navigate = useNavigate()

  async function enviarNovaSenha() {
    try {
      await put('/usuario/usuario/redefinir-senha', {
        'chave': chave,
        'senha': senhaFinal
      })
      toast.success("Senha alterada com sucesso!")
      navigate("/login")
    } catch (error) {
      toast.error("A senha precisa preencher todos os requisitos para ser redefinida")
      console.error(error)
    }
  }



  function verificarTamanhoTexto(texto) {

    if (!texto.length) {
      return null
    } else if (texto.length >= 8) {
      return true
    } else {
      return false
    }

  }


  function verificarTextoUpper(texto) {
    const upper = /[A-Z]/;

    if (!texto.length) {
      return null
    } else if (upper.test(texto)) {
      return true
    } else {
      return false
    }
  }
  function verificarTextoLower(texto) {
    const lower = /[a-z]/;


    if (!texto.length) {
      return null
    } else if (lower.test(texto)) {
      return true
    } else {
      return false
    }
  }
  function verificarTextoNumero(texto) {
    const number = /\d/;



    if (!texto.length) {
      return null
    } else if (number.test(texto)) {
      return true
    } else {
      return false
    }
  }

  function pegarValorNovaSenha(texto) {

    setHasEightLenght(verificarTamanhoTexto(texto))
    setHasUpper(verificarTextoUpper(texto))
    setHasLower(verificarTextoLower(texto))
    setHasNumber(verificarTextoNumero(texto))

    setInputNovaSenha(texto)
  }


  function pegarValorConfirmarSenha(texto) {

    setInputConfirmarSenha(texto)
  }

  const enviarNoEnter = (event) => {
    const requisitosPreenchidos = verificarDisableButton()

    if ((event.key === "Enter") && !requisitosPreenchidos) {
      enviarNovaSenha()
    }
  };

  function verificarDisableButton() {

    if ((inputConfirmarSenha === inputNovaSenha) && hasEightLength && hasLower && hasNumber && hasUpper) {
      setSenhaFinal(inputNovaSenha)
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    setDisableButton(verificarDisableButton())

  }, [pegarValorNovaSenha, pegarValorConfirmarSenha])

  return (
    <S.Global>
      <S.Background url={ImgBackgroundLogin} />


      <S.ContainerDireita>
        <S.BlocoDireita>
          <S.BlocoFormulario>
            <S.HeaderBlock>
              <img src={ImgWaybe} alt="Logo Sifat" />

              <h1>Olá, <span style={{ textTransform: "capitalize" }} >{nome}</span></h1>
              <p>Defina aqui uma nova senha</p>
            </S.HeaderBlock>

            <S.InputBlock>
              <CampoDeSenha titulo={"Nova senha"} onChange={(e) => pegarValorNovaSenha(e.target.value)} onKeyDown={(e) => enviarNoEnter(e)} />

              <S.RequisitosSenhaContainer>

                {hasLower === null ? <S.StyledIconSemCampo className="material-symbols-rounded">
                  error
                  <p>Deve conter 1 letra minúscula</p>

                </S.StyledIconSemCampo> : hasLower === true ? <S.StyledIconSucesso className="material-symbols-rounded" >
                  check_circle
                  <p>Deve conter 1 letra minúscula</p>

                </S.StyledIconSucesso> : <S.StyledIconError className="material-symbols-rounded">
                  cancel
                  <p>Deve conter 1 letra minúscula</p>

                </S.StyledIconError>}


                {hasEightLength === null ? <S.StyledIconSemCampo className="material-symbols-rounded">
                  error
                  <p>Deve conter 8 caracteres</p>

                </S.StyledIconSemCampo> : hasEightLength === true ? <S.StyledIconSucesso className="material-symbols-rounded" >
                  check_circle
                  <p>Deve conter 8 caracteres</p>

                </S.StyledIconSucesso> : <S.StyledIconError className="material-symbols-rounded">
                  cancel
                  <p>Deve conter 8 caracteres</p>

                </S.StyledIconError>}
                {hasUpper === null ? <S.StyledIconSemCampo className="material-symbols-rounded">
                  error
                  <p>Deve conter 1 letra maiúscula</p>

                </S.StyledIconSemCampo> : hasUpper === true ? <S.StyledIconSucesso className="material-symbols-rounded" >
                  check_circle
                  <p>Deve conter 1 letra maiúscula</p>

                </S.StyledIconSucesso> : <S.StyledIconError className="material-symbols-rounded">
                  cancel
                  <p>Deve conter 1 letra maiúscula</p>

                </S.StyledIconError>}

                {hasNumber === null ? <S.StyledIconSemCampo className="material-symbols-rounded">
                  error
                  <p>Deve conter 1 número</p>

                </S.StyledIconSemCampo> : hasNumber === true ? <S.StyledIconSucesso className="material-symbols-rounded" >
                  check_circle
                  <p>Deve conter 1 número</p>

                </S.StyledIconSucesso> : <S.StyledIconError className="material-symbols-rounded">
                  cancel
                  <p>Deve conter 1 número</p>

                </S.StyledIconError>}







              </S.RequisitosSenhaContainer>
              <CampoDeSenha titulo={"Confirmar nova senha"} onChange={(e) => pegarValorConfirmarSenha(e.target.value)} onKeyDown={enviarNoEnter} />

            </S.InputBlock>
            <S.BotaoBaseAzulPreenchido disabled={disableButton} onClick={enviarNovaSenha}>
              Salvar
            </S.BotaoBaseAzulPreenchido>


          </S.BlocoFormulario>
        </S.BlocoDireita>
        <S.ContainerSifat>


          {theme.temaDarkAtivo ? (
            <S.LogoSifat src={ImgLogoSifatBranco} alt="Logo Sifat" />
          ) : (
            <S.LogoSifat src={ImgLogoSifatAzul} alt="Logo Sifat" />
          )}
        </S.ContainerSifat>
      </S.ContainerDireita>

    </S.Global>
  )
}