export const ResponsePlataformaPlanoPadrao = {
  mapListaPlanoPadraoReduzido(data) {
    return data.map((plano) => mapResponseToPlanoReduzido(plano));
  },
  mapPlanoPadraoComItens(data) {
    return mapResponseToPlanoComItens(data);
  },
};

export const RequestPlataformaPlanoPadrao = {
  mapPlanoPadraoReduzido(data) {
    return data.map((plano) => mapRequestToPlanoReduzido(plano));
  },
  mapPlanoPadraoComItens(data) {
    return mapRequestToPlanoComItens(data);
  },
};

function mapResponseToPlanoReduzido(plano) {
  return {
    ativo: plano.ativo,
    idPlanoPadraoWaymenu: plano.idPlanoPadrao,
    indice: plano.indice,
    nome: plano.nome,
    valorAnual: plano.valorAnual,
    valorMensal: plano.valorMensal,
    valorSemestral: plano.valorSemestral,
    valorTrimestral: plano.valorTrimestral,
    valorBilhetagem: plano.valorBilhetagem,
    custoMensal: plano.custoMensal,
    custoAnual: plano.custoAnual,
    custoSemestral: plano.custoSemestral,
    custoTrimestral: plano.custoTrimestral,
  };
}

function mapResponseToPlanoComItens(plano) {
  return {
    nome: plano.descricaoPlano,
    listaItens: plano.itemPlanoWaymenuDTOList.map((item) =>
      mapResponseToItemPlano(item)
    ),
    numeroUsuarios: plano.numeroUsuarios,
    integradorPodeComercializar: plano.integradorPodeComercializar,
    clientePodeContratar: plano.clientePodeContratar,
    valorAnual: plano.valorAnual,
    valorMensal: plano.valorMensal,
    valorSemestral: plano.valorSemestral,
    valorTrimestral: plano.valorTrimestral,
    valorBilhetagem: plano.valorBilhetagem,
    custoMensal: plano.custoMensal,
    custoAnual: plano.custoAnual,
    custoSemestral: plano.custoSemestral,
    custoTrimestral: plano.custoTrimestral,
  };
}

function mapResponseToItemPlano(item) {
  return {
    descricaoItem: item.descricaoItem,
    descricaoModuloContrato: item.descricaoModuloContrato,
    inclusoNoPlano: item.inclusoNoPlano,
    moduloContrato: item.moduloContrato,
    tipoItemPlanoWaymenu: item.tipoItemPlanoWaymenu,
  };
}

function mapRequestToPlanoComItens(plano) {
  return {
    descricaoPlano: plano.nome,
    itemPlanoWaymenuDTOList: plano.listaItens.map((item) =>
      mapRequestToItemPlano(item)
    ),
    numeroUsuarios: plano.numeroUsuarios,
    integradorPodeComercializar: plano.integradorPodeComercializar,
    clientePodeContratar: plano.clientePodeContratar,
    permiteComercializar: plano.permiteComercializar,
    valorAnual: plano.valorAnual,
    valorMensal: plano.valorMensal,
    valorSemestral: plano.valorSemestral,
    valorTrimestral: plano.valorTrimestral,
    valorBilhetagem: plano.valorBilhetagem,
    custoMensal: plano.custoMensal,
    custoAnual: plano.custoAnual,
    custoSemestral: plano.custoSemestral,
    custoTrimestral: plano.custoTrimestral,
  };
}

function mapRequestToPlanoReduzido(plano) {
  return {
    ativo: plano.ativo,
    idPlanoPadrao: plano.idPlanoPadraoWaymenu,
    indice: plano.indice,
    nome: plano.nome,
    valorAnual: plano.valorAnual,
    valorMensal: plano.valorMensal,
    valorSemestral: plano.valorSemestral,
    valorTrimestral: plano.valorTrimestral,
    valorBilhetagem: plano.valorBilhetagem,
    custoMensal: plano.custoMensal,
    custoAnual: plano.custoAnual,
    custoSemestral: plano.custoSemestral,
    custoTrimestral: plano.custoTrimestral,
  };
}

function mapRequestToItemPlano(item) {
  return {
    descricaoItem: item.descricaoItem,
    descricaoModuloContrato: item.descricaoModuloContrato,
    inclusoNoPlano: item.inclusoNoPlano,
    moduloContrato: item.moduloContrato,
    tipoItemPlanoWaymenu: item.tipoItemPlanoWaymenu,
  };
}

export const ResponsePlanoParaContratacaoWaymenu = {
  mapPlanosReduzido(data) {
    return data.map((p) => {
      return {
        valorMensal: p.valorMensal,
        valorTrimestral: p.valorTrimestral,
        valorSemestral: p.valorSemestral,
        valorAnual: p.valorAnual,
        idPlano: p.idPlano,
        descricao: p.nome,
        tipoRecorrenciaPlano: p.tipoRecorrenciaPlano,
      };
    });
  },
};
