import { AdicionarCartao, ContainerCartoes } from "./styles";
import { Cartao } from "../../../../components/Cartao";
import ModalCadastrarCartao from "../cadastrarCartao/CadastrarCartao";
import { IconeBotao } from "../../../selecaoEmpresas/styles";
import { ReactComponent as IconeMais } from "../../../../assets/icons/icone_mais.svg";
import { useEffect, useState } from "react";
import { ResponsePagamentoCartao } from "../../../../services/contrato/MapperPagamentoCartao";
import { toast } from "react-toastify";
import { useApi } from "../../../../contexts/api";
import useTamanhoTela from "../../../../utils/useTamanhoTela";
import Carregando from "../../../carregando";

export default function ListagemCartao() {
  const { get, put, del } = useApi();

  const [loading, setLoading] = useState(true);
  const [listaCartoes, setListaCartoes] = useState([]);

  useEffect(() => {
    buscarCartoes();
  }, []);

  function buscarCartoes() {
    get("/pagamento/cartao")
      .then((response) => {
        let data = ResponsePagamentoCartao.mapLista(response.data);

        setListaCartoes(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(true);
        setListaCartoes([]);
        console.error(error);
        toast.error("Desculpe! Houve um erro ao buscar os cartões");
      });
  }

  function removerCartao(id, fechar) {
    del("/pagamento/cartao/" + id)
      .then((response) => {
        toast.info("Sucesso! Registro foi excluído com sucesso.");

        const cartoesNovo = listaCartoes.filter((cartao) => cartao.id !== id);

        setListaCartoes(cartoesNovo);
        fechar();
      })
      .catch((error) => {
        console.error(error);
        toast.error("Desculpe! Houve um erro ao deletar o cartão.");
      });
  }

  function definirComoPadrao(idNovoCartaoPadrao) {
    put("/pagamento/cartao/principal/" + idNovoCartaoPadrao)
      .then((response) => {
        toast.info("Sucesso! Cartão padrão foi definido com sucesso.");

        const cartoesNovo = listaCartoes.map((cartao) =>
          mapCartoesParaPadrao(cartao, idNovoCartaoPadrao)
        );

        setListaCartoes(cartoesNovo);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Desculpe! Houve um erro ao definir o cartão como padrão.");
      });
  }

  function mapCartoesParaPadrao(cartao, idCartaoPadrao) {
    if (cartao.id === idCartaoPadrao) {
      return { ...cartao, cartaoPadrao: true };
    }

    return { ...cartao, cartaoPadrao: false };
  }

  return loading ? (
    <Carregando adaptavel />
  ) : (
    <ContainerCartoes>
      {listaCartoes.map((informacoes) => (
        <Cartao
          key={informacoes.id}
          id={informacoes.id}
          nome={informacoes.nomeDonoCartao}
          numero={informacoes.finalCartao}
          cartaoPadrao={informacoes.cartaoPadrao}
          onRemove={(fechar) => removerCartao(informacoes.id, fechar)}
          onClickDefinirComoPadrao={() => definirComoPadrao(informacoes.id)}
        />
      ))}
      {listaCartoes.length < 2 && (
        <AdicionarCartao>
          <ModalCadastrarCartao
            componenteTrigger={
              <IconeBotao>
                <IconeMais />
              </IconeBotao>
            }
            onCadastradoComSucesso={() => buscarCartoes()}
          />
        </AdicionarCartao>
      )}
    </ContainerCartoes>
  );
}
