import { AccordionDetails } from "@mui/material";
import Popup from "reactjs-popup";
import styled from "@emotion/styled";

export const CardPlano = styled.div`
  width: 416px;
  padding-top: 24px;
  padding-bottom: 45px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 16px;
  border: 1px solid
    ${({ theme }) => theme.adaptativo("neutral90", "transparent")};
  background: ${({ theme }) => theme.cores.surface3};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CabecalhoCardPlano = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
`;

export const TituloCard = styled.p`
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 37px;
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.cores.textoSimples};
`;

export const SubtituloCard = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.cores.textoSimples};
`;

export const LinhaCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;

  div {
    display: flex;
    flex-direction: row;
  }

  .MuiRadio-root {
    @media (max-width: 768px) {
      padding-left: 4px;
    }
  }
`;

export const TituloLinhaCard = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.cores.textoSimples};
`;

export const ValorLinhaCard = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.cores.textoSimples};
`;

export const Hr = styled.hr`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.cores.corHr};
  margin-bottom: 18px;
`;

export const ContainerBotoes = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;

  @media (max-width: 375px) {
    gap: 4px;
  }
`;

export const BotaoDetalhesDoPlano = styled.button`
  background: transparent;
  width: 170px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.cores.actionActive};
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  @media (max-width: 425px) {
    width: 35vw;
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const TextoBotaoDetalhesDoPlano = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.cores.actionActive};

  @media (max-width: 450px) {
    font-size: 14px;
  }

  @media (max-width: 370px) {
    font-size: 12px;
  }
`;

export const BotaoComprarAgora = styled.button`
  background: ${({ theme }) =>
    theme.adaptativo("semanticSuccess3", "semanticSuccess2")};
  width: 150px;
  height: 32px;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  @media (max-width: 425px) {
    width: 35vw;
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const TextoBotaoComprarAgora = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.adaptativo("textAction", "semanticSuccess3")};

  @media (max-width: 450px) {
    font-size: 14px;
  }

  @media (max-width: 370px) {
    font-size: 12px;
  }
`;

export const PopupCustomizado = styled(Popup)`
  &-content {
    background-color: ${(props) => props.theme.cores.surface2};
    height: 670px;
    width: 70vw;
    border-radius: 16px;
    border: none;
    width: 865px;
    overflow-y: scroll;
  }

  &-overlay {
    pointer-events: none;
    overflow-y: unset;
  }
`;

export const ContainerPopup = styled.div`
  /* padding: 24px; */
  overflow-y: scroll;
`;

export const CabecalhoPopup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
`;

export const TituloPopup = styled.p`
  font-size: 33px;
  font-family: "Comfortaa", sans-serif;
  font-weight: 700;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.adaptativo("neutral10", "neutral100")};

  @media (max-width: 768px) {
    font-size: 23px;
    font-weight: 500;
  }
`;

export const ContainerAccordion = styled.div`
  height: 800px;
  margin-bottom: 32px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    height: 800px;
    width: 813px;
  }
`;

export const ContainerOverflow = styled.div`
  height: 416px;
  margin-bottom: 40px;
  overflow-y: auto;

  @media (max-width: 768px) {
    overflow: auto;
  }
`;

export const ContainerBotoesPopup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const BotaoCancelar = styled.button`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.cores.semanticDanger2};
  cursor: pointer;
  transition: 0.3s;
  width: 190px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.7;
  }
`;

export const TextoBotaoCancelar = styled.p`
  font-size: 16px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.cores.semanticDanger2};
`;

export const BotaoFazerPagamento = styled.button`
  background: ${({ theme }) => theme.cores.actionActive};
  border: none;
  cursor: pointer;
  transition: 0.3s;
  width: 190px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.7;
  }
`;

export const TextoBotaoFazerPagamento = styled.p`
  font-size: 16px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.cores.textAction};
`;

export const CabecalhoAccordion = styled.div`
  width: 100%;
  min-height: 48px;
  background: ${({ theme }) => theme.adaptativo("brand10", "brand70")};
  display: flex;
  align-items: center;
`;

export const TextoCabecalhoAccordion = styled.p`
  flex: 1;
  font-size: 14px;
  font-family: "Comfortaa", sans-serif;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.adaptativo("neutral10", "neutral100")};
`;

export const TextoCabecalhoAccordionDescricao = styled.p`
  width: 50%;
  padding-left: 16px;
  font-size: 14px;
  font-family: "Comfortaa", sans-serif;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.adaptativo("neutral10", "neutral100")};
  display: flex;
  justify-content: center;
`;

export const ContainerLinhaAccordion = styled.div`
  display: flex;
`;

export const ContainerEsquerdaAccordion = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-right: 16px;
`;

export const ContainerItemAccordion = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContainerDireitaAccordion = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-right: 16px;
`;

export const LinhaClara = styled(AccordionDetails)`
  background: ${({ theme }) => theme.cores.surface2};
  display: flex;
  align-items: center;
  height: 69px;
  justify-content: space-between;
`;

export const LinhaEscura = styled(AccordionDetails)`
  background: ${({ theme }) => theme.adaptativo("surface3", "neutral10")};
  display: flex;
  align-items: center;
  height: 69px;
  justify-content: space-between;
`;

export const ContainerBottomSheet = styled.div`
  padding: 24px;
`;
