import { useState } from "react";
import {
  RequestPagamentoCartao,
  ResponsePagamentoCartao,
} from "../../../../services/contrato/MapperPagamentoCartao";
import { toast } from "react-toastify";
import {
  BotaoCadastrarCartaoModal,
  BotaoCancelar,
  BotoesModal,
  BottomSheetCustomizada,
  CabecalhoModal,
  CartaoModal,
  ContainerBodyModal,
  ContainerBottomSheet,
  ContainerCabecalhoBottomSheet,
  ContainerCartaoModal,
  ContainerInputsModal,
  ContainerPopup,
  InputsVencimentoCvv,
  LinhaCabecalho,
  NomeCartaoModal,
  NumeroCartaoModal,
  PopupCustomizado,
  StyledTextField,
  TextoBotaoCadastrarCartaoModal,
  TextoBotaoCancelar,
  TextoCabecalhoBottomSheet,
  TextoCabecalhoModalNovoCartao,
} from "./styles";
import { CampoDeTexto } from "../../../../components/CampoInput";
import { formatarCPF } from "../../../../utils/formatarCpf";
import useTamanhoTela from "../../../../utils/useTamanhoTela";
import { useApi } from "../../../../contexts/api";

export default function ModalCadastrarCartao({
  componenteTrigger,
  onCadastradoComSucesso,
}) {
  const { post } = useApi();
  const { largura } = useTamanhoTela();

  const [nomeCartao, setNomeCartao] = useState("");
  const [numeroCartao, setNumeroCartao] = useState("");
  const [validade, setValidade] = useState("");
  const [codigoSeguranca, setCodigoSeguranca] = useState("");
  const [cpf, setCpf] = useState("");

  const [erros, setErros] = useState({});

  function handleChangeNomeCartao(e) {
    setNomeCartao(e.target.value);
  }

  function handleChangeVencimento(e) {
    let novoValor = e.target.value;

    novoValor = novoValor.replace(/\D/g, "");
    novoValor = novoValor.slice(0, 6);

    setValidade(novoValor);
  }

  function getVencimento() {
    let novaValidade = validade;

    if (novaValidade.length > 2) {
      const parte1 = novaValidade.slice(0, 2);
      const parte2 = novaValidade.slice(2);

      return `${parte1}/${parte2}`;
    } else {
      return novaValidade;
    }
  }

  function handleChangeNumeroCartao(e) {
    let novoNumeroCartao = e.target.value;

    novoNumeroCartao = novoNumeroCartao.replace(/\D/g, "");
    novoNumeroCartao = novoNumeroCartao.slice(0, 16);

    setNumeroCartao(novoNumeroCartao);
  }

  function handleChangeCPF(e) {
    let novoNumeroCpf = e.target.value;

    novoNumeroCpf = novoNumeroCpf.replace(/\D/g, "");
    novoNumeroCpf = novoNumeroCpf.slice(0, 11);

    setCpf(novoNumeroCpf);
  }

  function handleChangeCodigoSeguranca(e) {
    let novoCodigoSeguranca = e.target.value;

    novoCodigoSeguranca = novoCodigoSeguranca.replace(/\D/g, "");
    novoCodigoSeguranca = novoCodigoSeguranca.slice(0, 3);

    setCodigoSeguranca(novoCodigoSeguranca);
  }

  function cadastrarCartao(close) {
    let cartao = {
      numeroCartao: numeroCartao,
      nomeDonoCartao: nomeCartao,
      validade: validade,
      codigoSeguranca: codigoSeguranca,
      cpf: cpf,
    };

    const novoCartao = RequestPagamentoCartao.mapCartao(cartao);

    post("/pagamento/cartao", novoCartao)
      .then((response) => {
        if (response.status === 200) {
          close();
          onCadastradoComSucesso();
          toast.info("Sucesso! Registro foi inserido com sucesso.");
        } else if (response.data.message) {
          toast.warn(response.data.message);
        } else {
          let json = response.data;
          let erros = {};

          erros = incrementarErro(erros, json, "numeroCartao");
          erros = incrementarErro(erros, json, "codigoSeguranca");
          erros = incrementarErro(erros, json, "cpf");
          erros = incrementarErro(erros, json, "nomeDonoCartao");
          erros = incrementarErro(erros, json, "validade");

          console.error(erros);
          setErros(erros);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Desculpe! Houve um erro ao inserir o cartão.");
      });
  }

  function incrementarErro(listaErros, json, campo) {
    let mensagem = json[campo];

    if (mensagem) {
      return { ...listaErros, [campo]: mensagem };
    }

    return json;
  }

  return (
    <PopupCustomizado trigger={componenteTrigger} modal nested>
      {(close) => (
        <ContainerPopup>
          <CabecalhoModal>
            <TextoCabecalhoModalNovoCartao>
              Novo Cartão
            </TextoCabecalhoModalNovoCartao>
          </CabecalhoModal>
          <LinhaCabecalho />
          <ContainerBodyModal>
            <ContainerInputsModal>
              <CampoDeTexto
                titulo="Número do cartão"
                InputProps={{ type: "text" }}
                variant="standard"
                value={numeroCartao}
                onChange={handleChangeNumeroCartao}
                required={true}
                error={erros.numeroCartao}
                helperText={erros?.numeroCartao}
              />
              <CampoDeTexto
                titulo="CPF"
                variant="standard"
                InputProps={{ type: "text" }}
                onChange={handleChangeCPF}
                value={formatarCPF(cpf)}
                required={true}
                error={erros.cpf}
                helperText={erros?.cpf}
              />
              <CampoDeTexto
                titulo="Nome no cartão"
                variant="standard"
                InputProps={{ type: "text" }}
                onChange={handleChangeNomeCartao}
                required={true}
                error={erros.nomeDonoCartao}
                helperText={erros?.nomeDonoCartao}
              />

              <InputsVencimentoCvv>
                <CampoDeTexto
                  titulo="Vencimento do cartão"
                  style={{ width: "50%" }}
                  variant="standard"
                  placeholder="EX: 07/2025"
                  onChange={handleChangeVencimento}
                  value={getVencimento()}
                  required={true}
                  error={erros.validade}
                  helperText={erros?.validade}
                />

                <CampoDeTexto
                  titulo="CVV"
                  style={{ width: "50%" }}
                  variant="standard"
                  value={codigoSeguranca}
                  onChange={handleChangeCodigoSeguranca}
                  required={true}
                  error={erros.codigoSeguranca}
                  helperText={erros?.codigoSeguranca}
                />
              </InputsVencimentoCvv>
            </ContainerInputsModal>
            <ContainerCartaoModal>
              <CartaoModal>
                <NomeCartaoModal>{nomeCartao}</NomeCartaoModal>
                <NumeroCartaoModal>{numeroCartao}</NumeroCartaoModal>
              </CartaoModal>
              <BotoesModal>
                <BotaoCancelar onClick={close}>
                  <TextoBotaoCancelar>Cancelar</TextoBotaoCancelar>
                </BotaoCancelar>
                <BotaoCadastrarCartaoModal
                  onClick={() => cadastrarCartao(close)}
                >
                  <TextoBotaoCadastrarCartaoModal>
                    Cadastrar Cartão
                  </TextoBotaoCadastrarCartaoModal>
                </BotaoCadastrarCartaoModal>
              </BotoesModal>
            </ContainerCartaoModal>
          </ContainerBodyModal>
        </ContainerPopup>
      )}
    </PopupCustomizado>
  );
}
