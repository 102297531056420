import { Tab, Tabs } from "@mui/material";
import styled from "@emotion/styled";
export const Coluna = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const ContainerTabs = styled(Tabs)`
  background-color: ${(props) => props.theme.adaptativo("brand10", "brand70")};
  width: 100%;
  height: 72px;
  border-radius: 16px 16px 0px 0px;
  display: flex;
  align-items: center;
  padding: 0px 20px;

  .MuiButtonBase-root {
    border-radius: 10px;
  }

  .Mui-selected {
    color: ${(props) => props.theme.cores.neutral100}!important;
    z-index: 1;
  }

  .MuiTabs-indicator {
    background-color: ${(props) => props.theme.cores.actionActive};
    height: 40px;
    margin-bottom: 5px;
    border-radius: 16px;
  }

  .MuiTabs-scrollButtons {
    color: ${({ theme }) => theme.cores.actionActive};
  }
`;

export const TabItem = styled(Tab)`
  color: ${(props) => props.theme.adaptativo("actionActive", "neutral100")};
  /* margin-right: ${({ marginTabs }) => (marginTabs ? "32px" : "inherit")}; */
`;
