import React, { useState, useEffect, Fragment, useRef } from "react";
import {
  PopupCustomizado,
  BottomSheetCustomizado,
  OverlayManual,
} from "./style";
import useTamanhoTela from "../../utils/useTamanhoTela";

export const GerarModalResponsivo = ({
  children,
  trigger,
  width,
  maxWidth,
  blocking = true,
  larguraQuebra = 1100,
  tabFocus = false,
  tabIndex = 0,
  ativo = false,
  style,
  onFechar = () => { },
  overlayManual,
  scrollLocking = false
}) => {
  const LARGURA_QUEBRA = larguraQuebra;

  const keyDownRef = useRef(null);

  const { largura, altura } = useTamanhoTela();
  let modo = largura < LARGURA_QUEBRA ? "bottom-sheet" : "modal";

  const [aberto, setAberto] = useState(ativo);

  useEffect(() => {
    // alert("setando ativo");
    setAberto(ativo);
  }, [ativo]);

  useEffect(() => {
    if (modo !== "bottom-sheet") setAberto(false);
  }, [modo]);

  useEffect(() => {
    if (keyDownRef.current && tabFocus) {
      keyDownRef.current.addEventListener("keydown", teclaPressionada);
    }

    return () => {
      if (keyDownRef.current && tabFocus) {
        keyDownRef.current.removeEventListener("keydown", teclaPressionada);
      }
    };
  }, [keyDownRef]);

  function teclaPressionada(e) {
    if (e.key === "Enter") {
      setAberto(true);
    }
  }

  return modo === "bottom-sheet" ? (
    <div tabIndex={tabFocus ? tabIndex : null} ref={keyDownRef}>
      {overlayManual && aberto && (
        <OverlayManual
          onClick={() => {
            setAberto(false);
            onFechar();
          }}
        />
      )}
      <div
        onClick={() => {
          setAberto(true);
        }}
      >
        {trigger}
      </div>
      <BottomSheetCustomizado
        open={aberto}
        onDismiss={() => {
          setAberto(false);
          onFechar();
        }}
        blocking={blocking}
        scrollLocking={scrollLocking}
      >
        {children(() => {
          setAberto(false);
          onFechar();
        })}
      </BottomSheetCustomizado>
    </div>
  ) : (
    <div tabIndex={tabFocus ? tabIndex : null} ref={keyDownRef}>
      <PopupCustomizado
        $width={width}
        $maxWidth={maxWidth}
        trigger={trigger}
        modal
        open={ativo || aberto ? ativo || aberto : null}
        onClose={() => {
          setAberto(false);
          onFechar();
        }}
        nested
      >
        {children}
      </PopupCustomizado>
    </div>
  );
};

export default GerarModalResponsivo;
