import styled from "@emotion/styled";
import { CaretRight } from "phosphor-react";

export const ContainerCaminhoAtalhos = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Caminho = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  width: 100%;
`;

export const TextoCaminho = styled.div`
  font-size: 0.9375rem;
  font-family: "Source Sans Pro";
  letter-spacing: 0.25px;
  color: ${(props) => props.theme.cores.textoCaminho};
`;

export const IconeCaminho = styled(CaretRight)`
  color: ${(props) => props.theme.cores.textoCaminho};
`;

export const TextoVerAtalhos = styled.div`
  cursor: pointer;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.25px;
  width: 96px;
  color: ${({ theme }) => theme.cores.surfaceActionActivePrimary};

  &:hover {
    opacity: 0.7;
    transition: 0.2s;
  }

  &:disabled {
    opacity: 0.7;
    cursor: auto;
  }
`;
