import { ContratoCustomizado } from "../../../../components/Contrato/Customizado";
import { ContratoPadrao } from "../../../../components/Contrato/Padrao";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import {
  Container,
  ContainerCardsPlanos,
  ContainerFundoBranco,
  ContainerPlanosCustomizados,
  ContainerSemContratos,
  TextoContratosCustomizados,
  Hr,
} from "./styles";
import { Fragment, useEffect, useState } from "react";
import { ResponsePlanoParaContratacaoWaychef } from "../../../../services/contrato/waychef/MapperPlanoParaContratacaoWaychef";
import { ResponseAplicacoesContratadas } from "../../../../services/contrato/waybe/MapperAplicacoesContratadas";
import { toast } from "react-toastify";
import { useApi } from "../../../../contexts/api";
import { ResponsePlanoParaContratacaoWaymenu } from "../../../../services/contrato/waymenu/MapperPlanoPadraoWaymenu";
import Carregando from "../../../carregando";
import { BotaoAzulVazado } from "../../../../components/Botao";

export default function PlanosCompra({
  irParaPagamento,
  modoReduzido = false,
  voltar = () => { },
  aplicacaoSelecionada,
  comparacao = false,
  ocultarVoltar = false,
  tipoContrato = 'consumidor',
  idEmpresa = null
}) {
  const { get } = useApi();

  const [planosDisponiveis, setPlanosDisponiveis] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tipoPlano, setTipoPlano] = useState("");
  const [comprarBloqueado, setComprarBloqueado] = useState(false);

  useEffect(() => {
    if (aplicacaoSelecionada === "WAYMENU") {
      buscarDadosWaymenu();
    } else if (aplicacaoSelecionada === "WAYCHEF") {
      buscarDadosWaychef();
    }
  }, []);

  function buscarDadosWaymenu() {
    get(
      `/contrato/${tipoContrato}/waymenu/plano-padrao/disponivel-para-contratar${idEmpresa != null ? `/${idEmpresa}` : ''}`
    )
      .then((response) => {
        if (response.status === 404) {
          setPlanosDisponiveis(null);
          toast.error(response.data.message);
          setLoading(false);
          return;
        }
        let planosDisponiveis =
          ResponsePlanoParaContratacaoWaychef.mapPlanosReduzido(response.data);
        setPlanosDisponiveis(planosDisponiveis);
        setTipoPlano("WAYMENU");
        setLoading(false);
      })
      .catch((error) => {
        setPlanosDisponiveis(null);
        setLoading(false);
        console.error(error);
        toast.error("Desculpe! Houve um erro ao obter os planos");
      });
  }

  function buscarDadosWaychef() {
    get(
      `/contrato/${tipoContrato}/waychef/plano-padrao/disponivel-para-contratar${idEmpresa != null ? `/${idEmpresa}` : ''}`
    )
      .then((response) => {
        if (response.status === 404) {
          setPlanosDisponiveis(null);
          toast.error(response.data.message);
          setLoading(false);
          return;
        }

        let planosDisponiveis =
          ResponsePlanoParaContratacaoWaymenu.mapPlanosReduzido(response.data);
        setPlanosDisponiveis(planosDisponiveis);
        setTipoPlano("WAYCHEF");
        setLoading(false);
      })
      .catch((error) => {
        setPlanosDisponiveis(null);
        console.error(error);
        setLoading(false);
        toast.error("Desculpe! Houve um erro ao obter os planos");
      });
  }
  return modoReduzido ? (
    loading ? (
      <Carregando />
    ) : planosDisponiveis ? (
      <>
        {!ocultarVoltar && (
          <Fragment>
            <BotaoAzulVazado
              onClick={voltar}
              texto={"Voltar"}
              tamanho={"MD"}
              style={{ width: "min-content", marginTop: "12px" }}
            />
            <Hr />
          </Fragment>
        )}
        <ContainerCardsPlanos>
          {planosDisponiveis &&
            planosDisponiveis.map((plano) => (
              <ContratoPadrao
                comparacao={comparacao}
                key={"chavePlanoDisponivel" + plano.idPlano}
                idPlanoPadrao={plano.idPlano}
                nome={plano.descricao}
                valorMensal={plano.valorMensal}
                valorTrimestral={plano.valorTrimestral}
                valorSemestral={plano.valorSemestral}
                valorAnual={plano.valorAnual}
                irParaPagamento={irParaPagamento}
                tipoPlano={tipoPlano}
                voltarParaTelaAnterior={voltar}
                plano={plano}
                comprarBloqueado={comprarBloqueado}
                setComprarBloqueado={setComprarBloqueado}
                tipoContrato={tipoContrato}
                idEmpresa={idEmpresa}
              />
            ))}
        </ContainerCardsPlanos>
      </>
    ) : (
      <ContainerSemContratos>
        <h1>Desculpe, nenhum contrato encontrado.</h1>
      </ContainerSemContratos>
    )
  ) : loading ? (
    <Carregando />
  ) : planosDisponiveis ? (
    <>
      <Header />
      <Container>
        <ContainerFundoBranco>
          <ContainerCardsPlanos>
            {planosDisponiveis &&
              planosDisponiveis.map((plano) => (
                <ContratoPadrao
                  comparacao={comparacao}
                  key={"chavePlanoDisponivel" + plano.idPlano}
                  idPlanoPadrao={plano.idPlano}
                  nome={plano.descricao}
                  valorMensal={plano.valorMensal}
                  valorTrimestral={plano.valorTrimestral}
                  valorSemestral={plano.valorSemestral}
                  valorAnual={plano.valorAnual}
                  irParaPagamento={irParaPagamento}
                  tipoPlano={tipoPlano}
                  voltarParaTelaAnterior={voltar}
                  plano={plano}
                  comprarBloqueado={comprarBloqueado}
                  setComprarBloqueado={setComprarBloqueado}
                  tipoContrato={tipoContrato}
                  idEmpresa={idEmpresa}
                />
              ))}
          </ContainerCardsPlanos>
        </ContainerFundoBranco>
        <Footer caminho={["Dados da Empresa", "Minha Empresa"]} icon="" />
      </Container>
    </>
  ) : (
    <ContainerSemContratos>
      <h1>Desculpe, nenhum contrato encontrado.</h1>
    </ContainerSemContratos>
  );
}
