import styled from "@emotion/styled";
import { ReactComponent as IconeCheckboxDesmarcado } from "../../assets/icons/checkbox_desmarcado.svg";
import { ReactComponent as IconeCheckboxMarcado } from "../../assets/icons/checkbox_marcado.svg";

export const Linha = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  user-select: none;
  pointer-events: ${({ desabilitado }) => (desabilitado ? "none" : "initial")};
  cursor: ${({ desabilitado }) => (desabilitado ? "not-allowed" : "pointer")};
`;

export const CheckBoxDesmarcado = styled(IconeCheckboxDesmarcado)`
  fill: ${({ theme }) => theme.adaptativo("neutral40", "neutral90")};
  opacity: ${({ desabilitado }) => (desabilitado ? 0.4 : 1)};

  &:hover {
    fill: ${({ theme, cor }) => (cor ? cor : theme.cores.actionActive)};
  }
  cursor: ${({ desabilitado }) => (desabilitado ? "not-allowed" : "pointer")};
`;
export const CheckBoxMarcado = styled(IconeCheckboxMarcado)`
  fill: ${({ theme, cor }) => (cor ? cor : theme.cores.actionActive)};
  opacity: ${({ desabilitado }) => (desabilitado ? 0.4 : 1)};
`;

export const LinhaCheckboxRef = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  user-select: none;
  pointer-events: ${({ desabilitado }) => (desabilitado ? "none" : "initial")};
  cursor: ${({ desabilitado }) => (desabilitado ? "not-allowed" : "pointer")};

  > svg:first-child {
    display: none;
  }

  &.checked {
    > svg:first-child {
      fill: ${(props) => props.theme.cores.actionActive}!important;
      display: block;
    }

    > svg:last-child {
      display: none !important;
    }
  }
`;

export const CheckBoxRefItem = styled.div`
  content: "teste";
`;
