import styled from "@emotion/styled";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.cores.surfaceBackground1};
  margin: 0px 40px 80px 40px;

  @media (max-width: 768px) {
    margin: 0px 20px 80px 20px;
  }
`;

export const ContainerFundoBranco = styled.div`
  margin-top: 63px;
  width: 100%;
  background-color: ${(props) => props.theme.cores.surface2};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  margin-bottom: 146px;

  @media (max-width: 768px) {
    margin-bottom: 50px;
    margin-top: 24px;
  }
`;

export const ContainerBotaoNovo = styled.div`
  align-self: flex-end;
  margin-bottom: 42px;
  margin-top: 32px;
`;

export const BotaoNovo = styled.button`
  background: ${({ theme }) => theme.cores.actionActive};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 101px;
  height: 40px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
    background: ${(props) => props.theme.cores.actionHover};
  }
`;

export const TextoBotaoNovo = styled.p`
  color: ${({ theme }) => theme.cores.textAction};
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
`;

export const BotaoPesquisarCampos = styled.button`
  background: transparent;
  box-sizing: border-box;
  width: 170px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.cores.actionActive};
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  gap: 8px;
  margin-bottom: 18px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 8px;

  &:hover {
    opacity: 0.7;

    background: ${(props) => props.theme.cores.actionHover2};
  }
`;

export const TextoBotaoPesquisarCampos = styled.p`
  color: ${({ theme }) => theme.cores.actionActive};
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
`;
