import React, { useState, useEffect, useContext, Suspense } from "react";
import {
  Coluna,
  Container,
  Linha,
  Background,
  ContainerFormulario,
  ImagemWaybe,
  TextoAviso,
  TextoTitulo,
  ContainerInputs,
  TextoEsqueciSenha,
  ContainerSifat,
  LogoSifat,
  ContainerExpansivo,
  ContainerModal,
  LinhaBotoes,
  BottomSheetCustomizada,
} from "./styles";
import ImgBackgroundLogin from "../../assets/images/login_background.png";
import ImgWaybe from "../../assets/images/waybe_logo.png";
import { CampoDeTexto, CampoDeSenha } from "../../components/CampoInput";
import { CheckBox } from "../../components/Checkbox";
import { useTheme } from "@emotion/react";
import {
  BotaoAzulPreenchido,
  BotaoVermelhoSemFundo,
} from "../../components/Botao/";
import ImgLogoSifatAzul from "../../assets/images/logoSifatAzul.png";
import ImgLogoSifatBranco from "../../assets/images/logoSifatBranco.png";
import Popup from "reactjs-popup";
import useTamanhoTela from "../../utils/useTamanhoTela";
import { useAuth } from "../../contexts/auth";
import { useApi, baseURLApp } from "../../contexts/api";
import "react-spring-bottom-sheet/dist/style.css";
import { toast } from "react-toastify";
import { useMenu } from "../../contexts/menu";
import { useLoader } from "../../contexts/loader";
import { useLocation } from "react-router-dom";


const ModalRecuperarSenha = React.lazy(() => import("../../components/ModalRecuperarSenha"))
const ModalAtualizarSenha = React.lazy(() => import("../../components/ModalAtualizarSenha"))

function PaginaLogin() {
  /////////////////////////////////////////////// Dados Mockados ////////////////////////////////////////////

  ////////////////////////////////////// Variáveis, estados e Funções ///////////////////////////////////////
  const [email, setEmail] = useState(null);
  const [erroEmail, setErroEmail] = useState(false);
  const [senha, setSenha] = useState(null);
  const [erroSenha, setErroSenha] = useState(false);
  const [lembrarEmail, setLembrarEmail] = useState(false);
  const [mostrarAvisoLogin, setMostrarAvisoLogin] = useState(false);
  const [recuperarSenha, setRecuperarSenha] = useState(false);
  const [atualizarSenha, setAtualizarSenha] = useState(false)

  const { realizarLogin, setLogado, setLoading } = useAuth();

  const { largura } = useTamanhoTela();
  const { buscarDashboards } = useMenu();
  const { post, put } = useApi();
  const { abrirCarregamento, fecharCarregamento } = useLoader();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);



  useEffect(() => {
    let emailLembrado;
    emailLembrado = localStorage.getItem("lembrar_email");

    if (emailLembrado) {
      setEmail(emailLembrado);
      setLembrarEmail(true);
    } else {
      setLembrarEmail(false);
    }

    //Verificar se está atualizando senha
    if (queryParams.get('recuperacao') != undefined) {
      setAtualizarSenha(true)
    }
  }, []);

  function logar(forcarLogin) {
    if (!email || email == "" || email == undefined) {
      setErroEmail(true);
      toast.error("Por favor, informe um email!");
      return;
    } else {
      setErroEmail(false);
    }

    if (!senha || senha == "" || senha == undefined) {
      setErroSenha(true);
      toast.error("Por favor, informe uma senha!");
      return;
    } else {
      setErroSenha(false);
    }

    const loginPromise = realizarLogin(email, senha, forcarLogin)
      .then(async (token) => {
        if (lembrarEmail) {
          localStorage.setItem("lembrar_email", email);
        } else {
          localStorage.removeItem("lembrar_email");
        }

        //Buscar primeira carga de dashboards
        await buscarDashboards(token)
          .then(() => {
            setLoading(false);
            setLogado(true);
            window.location.href = `${baseURLApp}`
          })
          .catch((erro) => {
            tratativaErros(erro);
            throw erro;
          });
      })
      .catch((erro) => {
        tratativaErros(erro);
        throw erro;
      });

    toast.promise(loginPromise, {
      pending: "Realizando Login...",
      success: "Login realizado com sucesso!",
    });
  }

  const enviarNoEnter = (event) => {
    if (event.key === "Enter") {
      logar();
    }
  };

  const tratativaErros = (error) => {
    if (error.response?.data) {
      let mensagem = error.response?.data.message;
      if (mensagem) {
        switch (mensagem) {
          case "Sessão do Usuário está ocupada":
            toast.error(mensagem);
            setMostrarAvisoLogin(true);
            break;
          case "Usuário não tem permissão para realizar o login":
            toast.error(mensagem);
            setErroEmail(true);
            break;
          case "Credenciais inválidas. Ao se comunicar com a api API-USUARIO":
            toast.error("Senha inválida.");
            setErroSenha(true);
            break;
          default:
            toast.error(mensagem);
            break;
        }
      } else {
        toast.error("Erro ao realizar login.");
      }
    }
    if (error.message == "Network Error") {
      toast.error("Erro ao se conectar ao servidor.");
    }
  };

  //////////////////////////////////////////// Layout da Página /////////////////////////////////////////////

  function CorpoPagina() {
    const theme = useTheme();
    return (
      <Container>
        <Background url={ImgBackgroundLogin} />
        <ContainerFormulario>
          <ImagemWaybe url={ImgWaybe} />
          <TextoTitulo>Bem vindo ao Waybe ERP!</TextoTitulo>
          <TextoAviso>
            Por favor, insira seus dados para efetuar o login
          </TextoAviso>
          <ContainerInputs>
            <CampoDeTexto
              titulo={"Login"}
              defaultValue={""}
              error={erroEmail}
              value={email}
              onChange={(event) => setEmail(event.target.value.trim())}
              onKeyDown={enviarNoEnter}
            />
            <CampoDeSenha
              titulo={"Senha"}
              defaultValue={""}
              error={erroSenha}
              value={senha}
              onChange={(event) => setSenha(event.target.value.trim())}
              onKeyDown={enviarNoEnter}
            />
            <Linha>
              <CheckBox
                cor={theme.cores.textoSimples}
                valor={lembrarEmail}
                onClick={() => setLembrarEmail(!lembrarEmail)}
                styleCheckbox={{ transform: "scale(0.8)" }}
                texto={"Lembrar usuário"}
              />
            </Linha>
          </ContainerInputs>
          <BotaoAzulPreenchido
            className="botao-entrar"
            texto={"Entrar"}
            tamanho={"LG"}
            style={{ color: theme.cores.neutral100 }}
            onClick={() => logar()}
            cor={theme.cores.actionActive}
          />
          <TextoEsqueciSenha onClick={() => verificarEmailValido()}>Esqueci minha senha</TextoEsqueciSenha>
          <ContainerExpansivo />
          <ContainerSifat>
            {theme.temaDarkAtivo ? (
              <LogoSifat src={ImgLogoSifatBranco} alt="Logo Sifat" />
            ) : (
              <LogoSifat src={ImgLogoSifatAzul} alt="Logo Sifat" />
            )}
          </ContainerSifat>
        </ContainerFormulario>
        {recuperarSenha && <Suspense>
          <ModalRecuperarSenha fechar={() => setRecuperarSenha(false)} solicitarCodigoRecuperacaoSenha={solicitarCodigoRecuperacaoSenha} />
        </Suspense>}

        {atualizarSenha && <Suspense>
          <ModalAtualizarSenha fechar={() => setAtualizarSenha(false)} atualizarSenhaUsuarioERP={atualizarSenhaUsuarioERP} />
        </Suspense>}
        <SessaoOcupada />
      </Container>
    );
  }

  /////////////////////////////////////////////// COMPONENTES  //////////////////////////////////////////////

  const SessaoOcupada = () => {
    const theme = useTheme();
    function fechar() {
      setMostrarAvisoLogin(false);
    }
    return largura > 768 ? (
      <Popup
        modal
        open={mostrarAvisoLogin}
        onClose={fechar}
        contentStyle={{
          width: "500px",
          height: "250px",
          borderRadius: "20px",
          padding: 0,
          border: "none",
        }}
      >
        {(close) => (
          <ContainerModal>
            <h3>Sessão ocupada</h3>
            <p style={{ fontSize: 19 }}>
              Já existe uma sessão ativa para esse usuário, deseja encerrar a
              sessão?
            </p>
            <LinhaBotoes>
              <BotaoVermelhoSemFundo
                texto={"Cancelar"}
                tamanho={"MD"}
                onClick={close}
                style={{ marginRight: 16 }}
              />
              <BotaoAzulPreenchido
                style={{ width: 141 }}
                texto={"Encerrar sessão"}
                tamanho={"MD"}
                onClick={() => logar(true)}
                cor={theme.cores.actionActive}
              />
            </LinhaBotoes>
          </ContainerModal>
        )}
      </Popup>
    ) : (
      <>
        <BottomSheetCustomizada
          open={mostrarAvisoLogin}
          onDismiss={() => fechar()}
          snapPoints={({ minHeight }) => minHeight}
          blocking={false}
        >
          <ContainerModal>
            <h3>Sessão ocupada</h3>
            <p style={{ fontSize: 19 }}>
              Já existe uma sessão ativa para esse usuário, deseja encerrar a
              sessão?
            </p>
            <LinhaBotoes>
              <BotaoVermelhoSemFundo
                texto={"Cancelar"}
                tamanho={"MD"}
                onClick={fechar}
                style={{ marginRight: 16 }}
              />
              <BotaoAzulPreenchido
                style={{ width: 141 }}
                texto={"Encerrar Sessão"}
                tamanho={"MD"}
                onClick={() => logar(true)}
                cor={theme.cores.actionActive}
              />
            </LinhaBotoes>
          </ContainerModal>
        </BottomSheetCustomizada>
      </>
    );
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////

  const verificarEmailValido = () => {
    //Verificar se o email digitado no input é um email valido, caso for já processar a chamada
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (regex.test(email)) {
      solicitarCodigoRecuperacaoSenha(email)
    } else {
      setRecuperarSenha(true)
    }

  }

  const solicitarCodigoRecuperacaoSenha = (emailUsuario) => {
    setRecuperarSenha(false)
    abrirCarregamento();
    post(`email/usuario-email/esqueci-minha-senha?email=${emailUsuario}`).then((res) => {
      toast.success(`Foi enviado um link de recuperação de senha para o e-mail ${emailUsuario}.`);
    }).catch((erro) => {
      toast.error("Não foi possível solicitar recuperação.");
    }).finally(() => {
      fecharCarregamento();
    })
  }

  const atualizarSenhaUsuarioERP = (data) => {
    setAtualizarSenha(false)
    abrirCarregamento();
    put(`usuario-sessao/client/usuario/redefinir-senha/${queryParams.get('recuperacao')}?clientKey=5202BFAFF162A71345CC0F3DDE1940A4`, { ...data }).then((res) => {
      toast.success("Senha atualizada com sucesso!");
    }).catch((erro) => {
      toast.error("Não foi possível criar uma nova senha.");
    }).finally(() => {
      fecharCarregamento();
      setTimeout(() => {
        loginAutomatico(data.senha)
      }, 500);
    })
  }

  const loginAutomatico = (senha) => {
    try {
      const emailRecebido = queryParams.get('email');
      if (emailRecebido != null && emailRecebido != undefined) {

        setTimeout(() => {
          setEmail(emailRecebido)
          setSenha(senha)
          setTimeout(() => {
            try {
              const botao = document.getElementsByClassName('botao-entrar');
              botao[0].click()
            } catch (error) { }
          }, 500);
        }, 500);


      }
    } catch (error) {

    }
  }


  return CorpoPagina();
}

export default PaginaLogin;
