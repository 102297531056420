export default function removerCaracteresEspeciais(str) {
    try {
        str = str.replace('/[áàãâä]/ui', 'a');
        str = str.replace('/[éèêë]/ui', 'e');
        str = str.replace('/[íìîï]/ui', 'i');
        str = str.replace('/[óòõôö]/ui', 'o');
        str = str.replace('/[úùûü]/ui', 'u');
        str = str.replace('/[ç]/ui', 'c');
        str = str.replace('/[^a-z0-9]/i', '_');
        str = str.replace('/_+/', '_');
        return str;
    } catch (error) {
        return str;
    }

}
