import React, { Fragment, useState } from "react";

import {
  Caminho,
  TextoCaminho,
  IconeCaminho,
  ContainerCaminhoAtalhos,
  TextoVerAtalhos,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { BottomSheetAtalhos } from "../BottomSheetAtalhos";

const Breadcrumb = ({
  caminho = [
    { titulo: "exemplo" },
    { titulo: "exemplo 2", url: "/fidelidade/cashback" },
  ],
  atalhos = [],
}) => {
  const [bottomSheetAberto, setBottomSheetAberto] = useState(false);

  const navigate = useNavigate();

  const paginas = () => {
    return caminho.map((item, index) => {
      return (
        <Fragment key={item.titulo + "Breadcrumb" + index}>
          {item.url != undefined ? (
            <TextoCaminho
              style={{ cursor: "pointer" }}
              onClick={() => navigate(item.url)}
            >
              {item.titulo}
            </TextoCaminho>
          ) : (
            <TextoCaminho>{item.titulo}</TextoCaminho>
          )}
          {caminho.length - 1 > index && <IconeCaminho />}
        </Fragment>
      );
    });
  };

  return (
    <ContainerCaminhoAtalhos>
      <Caminho>{paginas()}</Caminho>

      {/* {atalhos.length > 0 && (
                <>
                    <TextoVerAtalhos onClick={() => setBottomSheetAberto(true)}>Ver Atalhos</TextoVerAtalhos>
                    <BottomSheetAtalhos
                        fecharBottomSheet={() => setBottomSheetAberto(false)}
                        bottomSheetAberto={bottomSheetAberto}
                        atalhos={atalhos}
                    />
                </>
            )} */}
    </ContainerCaminhoAtalhos>
  );
};

export default Breadcrumb;
