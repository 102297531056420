import { createContext, useEffect, useState, useContext } from "react";
import { useApi, baseURL, versaoClienteDTO } from "./api";
import axios from "axios";
import { toast } from "react-toastify";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const { definirToken, token } = useApi();
  //// Estados

  const [dadosAuth, setDadosAuth] = useState(null);
  const [loading, setLoading] = useState(true);
  const [logado, setLogado] = useState(false);
  const [pagamentoNecessario, setPagamentoNecessario] = useState(false);
  const [usuarioMaster, setUsuarioMaster] = useState(false);
  const [homologacao, setHomologacao] = useState();
  const { get } = useApi()

  useEffect(() => {
    retomarSessao();
  }, []);

  useEffect(() => {
    if (dadosAuth && dadosAuth.tipoLogin === "PAGAMENTO_NECESSARIO") {
      setPagamentoNecessario(true);
      toast.error("Seu plano expirou! Renove agora para continuar.", {
        position: "top-right",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        closeButton: true,
      });
    } else {
      setPagamentoNecessario(false);
    }
  }, [dadosAuth]);

  useEffect(() => {
    try {
      if (
        dadosAuth &&
        dadosAuth.usuarioLogadoDTO.usuarioPerfilEmpresaDTO.tipoPerfilUsuario ===
        "MASTER"
      ) {
        setUsuarioMaster(true);
      } else {
        setUsuarioMaster(false);
      }
    } catch (error) {
      setUsuarioMaster(false);
    }
  }, [dadosAuth]);

  useEffect(() => {
    checarHomologacao();
  }, [dadosAuth]);
  //// Funções

  function checarHomologacao() {
    if (dadosAuth) {
      let api = axios.create({
        baseURL: baseURL,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Authorization": `Bearer ${dadosAuth.tokenDTO.token}`,
        },
      });

      api
        .get(`/waybe/configuracao/1`)
        .then((response) => {
          if (response.data?.homologacao) {
            setHomologacao(true);
          } else {
            setHomologacao(false);
          }
        })
        .catch((error) => {
          toast.error("Erro ao obter configurações Waybe.");
          console.error(error);
        });
    }
  }

  function retomarSessao() {
    setLoading(true);

    //checando Token URL
    const urlParams = new URLSearchParams(window.location.search);
    const tokenURL = urlParams.get("sessao");

    if (tokenURL) {
      setDadosAuth(null);
      definirToken(tokenURL);
      setLogado(true);
      setLoading(false);
      return;
    }

    //checando Token LocalStorage
    let stringObject;
    stringObject = localStorage.getItem("_da");

    if (stringObject) {
      try {
        let object = JSON.parse(stringObject);
        setDadosAuth(object);
        definirToken(object.tokenDTO.token);
        setLogado(true);
        setLoading(false);
      } catch (error) {
        setDadosAuth(null);
        setLogado(false);
        setLoading(false);
      }
    } else {
      setDadosAuth(null);
      setLogado(false);
      setLoading(false);
    }
    setLoading(false);
  }

  function realizarLogin(email, senha, forcarLogin = false) {
    // setLoading(true);
    return new Promise((resolve, reject) => {
      let api = axios.create({
        baseURL: baseURL,
      });

      api
        .post("/usuario-sessao/client/usuario/erp", {
          username: email,
          password: senha,
          forcarLogin: forcarLogin,
          clientKey: "5202BFAFF162A71345CC0F3DDE1940A4",
          tipoClient: "ERP",
          versaoClientDTO: versaoClienteDTO,
        })
        .then(async (dadosLogin) => {
          setDadosAuth(dadosLogin.data);

          localStorage.setItem("_da", JSON.stringify(dadosLogin.data));
          localStorage.setItem("sessao", JSON.stringify(dadosLogin.data));
          definirToken(dadosLogin.data.tokenDTO.token);
          // setLoading(false);
          // setLogado(true);
          resolve(dadosLogin.data.tokenDTO.token);
        })
        .catch((error) => {
          // setLoading(false);
          setLogado(false);
          reject(error);
        });
    });
  }


  function realizarLogoff() {
    localStorage.removeItem("_da");
    localStorage.removeItem("lista_dashboards");
    localStorage.removeItem("sessao");
    setDadosAuth(null);
    setLogado(false);
  }

  function trocarEmpresa(empresa) {
    return new Promise((resolve, reject) => {
      let api = axios.create({
        baseURL: baseURL,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Authorization": `Bearer ${dadosAuth.tokenDTO.token}`,
        },
      });

      api
        .post(
          `/usuario-sessao/client/usuario/erp/trocar-empresa/${empresa.idEmpresa}`
        )
        .then((dadosLogin) => {
          setDadosAuth(dadosLogin.data);
          localStorage.setItem("_da", JSON.stringify(dadosLogin.data));
          localStorage.setItem("sessao", JSON.stringify(dadosLogin.data));
          definirToken(dadosLogin.data.tokenDTO.token);
          resolve(dadosLogin.data);
        })
        .catch((error) => {
          setLoading(false);
          setLogado(false);
          reject(error);
        });
    });
  }

  function getDadosEmpresa() {
    return new Promise((resolve, reject) => {
      let api = axios.create({
        baseURL: baseURL,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Authorization": `Bearer ${token}`,
        },
      });

      api
        .get(`configuracoes-lojas/empresa/` + dadosAuth.empresaDTO.id)
        .then((dadosEmpresa) => {
          resolve(dadosEmpresa.data);
        })
        .catch((error) => {
          toast.error("Erro ao obter dados da empresa");
          reject(error);
        });
    });
  }


  return (
    <AuthContext.Provider
      value={{
        dadosAuth,
        loading,
        logado,
        pagamentoNecessario,
        setLoading,
        setLogado,
        setPagamentoNecessario,
        realizarLogin,
        trocarEmpresa,
        usuarioMaster,
        homologacao,
        getDadosEmpresa,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
