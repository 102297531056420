import Popup from "reactjs-popup";
import styled from "@emotion/styled";

export const CardPlano = styled.div`
  width: 416px;
  padding-top: 24px;
  padding-bottom: 32px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 16px;
  border: 1px solid
    ${({ theme }) => theme.adaptativo("neutral90", "transparent")};
  background: ${({ theme }) => theme.cores.actionDisable};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CabecalhoCardPlano = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const TituloCard = styled.p`
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 37px;
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.cores.textActionDisable};
`;

export const SubtituloCard = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.cores.textActionDisable};
`;

export const LinhaCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
`;

export const TituloLinhaCard = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.cores.textActionDisable};
`;

export const ValorLinhaCard = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.cores.textActionDisable};
`;

export const Hr = styled.hr`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.cores.corHr};
  margin-bottom: 32px;
`;

export const ContainerBotoes = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const BotaoComprarAgora = styled.button`
  background: transparent;
  width: 150px;
  height: 32px;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid
    ${({ theme }) => theme.adaptativo("transparent", "textActionDisable")};

  &:hover {
    opacity: 0.7;
  }
`;

export const TextoBotaoComprarAgora = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.cores.textActionDisable};

  @media (max-width: 450px) {
    font-size: 14px;
  }

  @media (max-width: 370px) {
    font-size: 12px;
  }
`;

export const BotaoCancelarAssinatura = styled.button`
  background: ${({ theme }) => theme.cores.semanticDanger2};
  width: 160px;
  height: 32px;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: none;

  &:hover {
    opacity: 0.7;
  }
`;

export const TextoBotaoCancelarAssinatura = styled.p`
  font-size: 16px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.cores.semanticDanger3};
`;

export const PopupCustomizadoMin = styled(Popup)`
  &-content {
    background-color: ${(props) => props.theme.cores.surface2};
    height: 360px;
    width: 70vw;
    border-radius: 16px;
    overflow-y: scroll;
    border: none;
    width: 500px;
  }

  &-overlay {
  }
`;

export const ContainerPopup = styled.div`
  padding-top: 34px;
  padding-left: 24px;
  padding-bottom: 39px;
  padding-right: 24px;
`;

export const CabecalhoModalCancelarAssinaturaModal = styled.div`
  padding-left: 10px;
  box-sizing: border-box;
  margin-bottom: 14px;
`;

export const TextoCabecalhoModalCancelarAssinaturaModal = styled.p`
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 37px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.cores.textoSimples};
`;

export const LinhaCabecalhoModalCancelarAssinaturaModal = styled.hr`
  border: 1px solid ${({ theme }) => theme.cores.corHr};
  width: 100%;
  margin-bottom: 11px;
`;

export const TextoSubtituloModal = styled.div`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  margin-bottom: 21px;
  color: ${({ theme }) => theme.cores.textoSimples};
`;

export const FooterModal = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const BotaoDesistir = styled.button`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 8px;
  width: 99px;
  height: 48px;
  border: 1px solid ${({ theme }) => theme.cores.semanticDanger2};
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
  background: transparent;

  &:hover {
    opacity: 0.7;
  }
`;

export const TextoBotaoDesistir = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.cores.semanticDanger2};
  width: 67px;
  height: 32px;
`;

export const BotaoCancelarAssinaturaModal = styled.button`
  width: 214px;
  height: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 8px;
  background: ${({ theme }) =>
    theme.adaptativo("semanticSuccess3", "semanticSuccess2")};
  cursor: pointer;
  transition: 0.3s;
  border: none;

  &:hover {
    opacity: 0.7;
  }
`;
export const TextoBotaoCancelarAssinaturaModal = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.adaptativo("neutral100", "semanticSuccess3")};
  width: 182px;
  height: 32px;
`;

export const ContainerBottomSheet = styled.div`
  padding-top: 34px;
  padding-left: 24px;
  padding-bottom: 49px;
  padding-right: 18px;
`;
