import { Document, Font, Image, PDFViewer, Page, Text, View } from "@react-pdf/renderer";
import styles from "./stylesPdf";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import RobotoBold from "../../../../../assets/fonts/Roboto/Roboto-Bold.ttf";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../../contexts/auth";
import Comfortaa from '../../../../../assets/fonts/Comfortaa/Comfortaa_Regular.ttf'
import ComfortaaBold from '../../../../../assets/fonts/Comfortaa/Comfortaa_Bold.ttf'
import { useApi } from "../../../../../contexts/api";
import { useLoader } from "../../../../../contexts/loader";
import Logo from '../../../../../assets/images/logo-pdf-relatorio.png'
import formatarReal from "../../../../../utils/formatarReal";
import { toast } from "react-toastify";
import { indexOf } from "lodash";
import { BotaoAlterarPlano } from "../../../../gerenciamentoAssinaturaPlanos/detalhesPlano/plano/styles";

export default function RelatorioPdfPisCofins() {
    const [dadosFiltro, setDadosFiltro] = useState([]);
    const { abrirCarregamento, fecharCarregamento } = useLoader();
    const [searchParams] = useSearchParams();
    const { dadosAuth } = useAuth();
    const { post, get } = useApi();
    const [dadosRequest, setDadosRequest] = useState([])
    const [filtros, setFiltros] = useState()


    Font.register({
        family: "RobotoBold",
        src: RobotoBold,
    });

    Font.register({
        family: "ComfortaaBold",
        src: ComfortaaBold
    })

    Font.register({
        family: "Comfortaa",
        src: Comfortaa
    })


    Font.registerHyphenationCallback((word) => [word]);

    dayjs.locale("pt-br");


    const dataCompletaEscrita = () => {
        const data = new Date();

        const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            timeZoneName: "short",
        };

        const formatter = new Intl.DateTimeFormat("pt-BR", options);
        return formatter.format(data);
    };

    const [descricaoPis, setDescricaoPis] = useState()
    const [descricaoCofins, setDescricaoCofins] = useState()
    const pegarDadosStorage = async () => {
        try {
            const dados = JSON.parse(localStorage.getItem("relatorioPISCOFINS"))
            setFiltros(dados.filtros)
            gerarRelatorio(dados.filtros)
            setDescricaoPis(dados.descricaoPis)
            setDescricaoCofins(dados.descricaoCofins)
            localStorage.removeItem("relatorioPISCOFINS")
        } catch (e) {
            console.error(e)
            window.location.href = "/analise-relatorios/cadastrais/tributacao/piscofins"
        }
    }
    async function gerarRelatorio(dados) {
        abrirCarregamento()
        try {
            const response = await post(`/relatorios/cadastrais/tributacao/pis-cofins?size=9999`,
                dados)
            setDadosRequest(response.data.content)
        } catch (e) {
            console.error(e)
            toast.error("Erro ao gerar relatório, tente novamente!")
        } finally {
fecharCarregamento()
        }
    }

    function dividirItensPorPagina(array) {
        const newArr = [];
        let contadorItensArray = 0;

        for (let i = 0; i < array.length; i += 20) {

            if (i == 0) {
                const primeiroArray = array.slice(0, 14)
                newArr.push(primeiroArray)
                contadorItensArray = 14
            } else {
                const outrosArrays = array.slice(contadorItensArray, contadorItensArray + 20)

                contadorItensArray = contadorItensArray + 20

                newArr.push(outrosArrays)
            }

        }
        return newArr
    }


    useEffect(() => {
        pegarDadosStorage()
    }, [])

    return (
        <>
            <PDFViewer style={{ width: "100vw", height: "100vh" }}>
                <Document title={`Relatório PIS/COFINS ${dayjs().format('DD/MM/YYYY HH:mm:ss')}`}>
                    {dadosRequest && dividirItensPorPagina(dadosRequest).map((item, index) => (


                        <Page size="A4" style={styles.page}>
                            <View style={styles.coluna}>
                                {index === 0 &&
                                    (<>
                                        <View style={styles.header}>
                                            <Text style={styles.headerTitulo}>
                                                {dadosAuth.empresaDTO.apelido}
                                            </Text>
                                            <Text style={styles.headerRelatorio}>Relatório PIS/COFINS</Text>
                                        </View>
                                        <View style={styles.headerBottom} />
                                        <View style={{ ...styles.sectionFiltros, minHeight: 100 }}>

                                            <Text style={styles.filtrosTitulo}>Filtros aplicados:</Text>
                                            <br />

                                            {filtros.tipoOperacao && <Text style={styles.filtros} >Tipo de operação: {filtros.tipoOperacao}</Text>}

                                            {filtros.cstCofinsList.length && (<Text style={styles.filtros} >CST COFINS: {descricaoCofins.map((item) => {
                                                return item
                                            }).join(", ")}</Text>)}
                                            {filtros.cstPisList.length && (<Text style={styles.filtros} >CST PIS: {descricaoPis.map((item) => {
                                                return item
                                            }).join(", ")}</Text>)}

                                        </View >
                                    </>)}

                                <View style={{ ...styles.section, display: "flex", flexDirection: 'row', width: '100%' }}>
                                    <View style={{ ...styles.celulaTabelaTitulo, width: "39.9%" }}>
                                        <Text style={styles.celulaTabelaTextoTitulo}>NCM</Text>
                                    </View>
                                    <View style={{ ...styles.celulaTabelaTitulo, width: "10%" }}>
                                        <Text style={styles.celulaTabelaTextoTitulo}>Tipo de Operação</Text>
                                    </View>
                                    <View style={{ ...styles.celulaTabelaTituloMenor, width: "24.6%" }}>
                                        <Text style={styles.celulaTabelaTextoTitulo}>PIS</Text>
                                        <View style={styles.celulaTabelaFlex} >

                                            <View style={{borderRight: "0.8px groove #888", width: "33%" }}>

                                                <Text style={{ ...styles.celulaTabelaTextoTituloSubTitulo }}>CST</Text>
                                            </View>
                                            <View style={{borderRight: "0.8px groove #888", width: "33%" }}>

                                                <Text style={{ ...styles.celulaTabelaTextoTituloSubTitulo }}>Alíquota</Text>
                                            </View>
                                            <View style={{ width: "33%" }}>

                                                <Text style={{ ...styles.celulaTabelaTextoTituloSubTitulo }}>Base de cálculo</Text>
                                            </View>
                                        </View>
                                    </View>

                                    <View style={{ ...styles.celulaTabelaTituloMenor, width: "24.7%" }}>
                                        <Text style={styles.celulaTabelaTextoTitulo}>COFINS</Text>
                                        <View style={styles.celulaTabelaFlex} >

                                            <View style={{borderRight: "0.8px groove #888", width: "33%" }}>

                                                <Text style={{ ...styles.celulaTabelaTextoTituloSubTitulo }}>CST</Text>
                                            </View>
                                            <View style={{borderRight: "0.8px groove #888", width: "33%" }}>

                                                <Text style={{ ...styles.celulaTabelaTextoTituloSubTitulo }}>Alíquota</Text>
                                            </View>
                                            <View style={{ width: "33%" }}>

                                                <Text style={{ ...styles.celulaTabelaTextoTituloSubTitulo }}>Base de cálculo</Text>
                                            </View>
                                        </View>
                                    </View>

                                </View>

                                {item && item.map((linha, i) => (
                                    <>
                                        <View style={{ ...styles.section2, display: "flex", flexDirection: 'row', backgroundColor: i % 2 ? "#d9d5d4" : "transparent" }}>

                                            <View style={{ ...styles.celulaTabelaTitulo2, width: "40%" }}>
                                                <Text style={styles.celulaTabelaTexto}>{linha.codigoNCM}</Text>
                                            </View>
                                            <View style={{ ...styles.celulaTabelaTitulo2, width: "10%" }}>
                                                <Text style={styles.celulaTabelaTexto}>{linha.tipoOperacao}</Text>
                                            </View>
                                            <View style={{ ...styles.celulaTabelaTitulo2, width: "8.33%" }}>
                                                <Text style={styles.celulaTabelaTexto}>{linha.codigoCstPis}</Text>
                                            </View>
                                            <View style={{ ...styles.celulaTabelaTitulo2, width: "8.33%" }}>
                                                <Text style={styles.celulaTabelaTexto}>{linha.aliquotaPis.toFixed(2)}</Text>
                                            </View>
                                            <View style={{ ...styles.celulaTabelaTitulo2, width: "8.33%" }}>
                                                <Text style={styles.celulaTabelaTexto}>{linha.baseCalculoPis.toFixed(2)}</Text>
                                            </View>

                                            <View style={{ ...styles.celulaTabelaTitulo2, width: "8.33%" }}>
                                                <Text style={styles.celulaTabelaTexto}>{linha.codigoCstCofins}</Text>
                                            </View>
                                            <View style={{ ...styles.celulaTabelaTitulo2, width: "8.33%" }}>
                                                <Text style={{ ...styles.celulaTabelaTexto }}>{linha.aliquotaCofins.toFixed(2)}</Text>
                                            </View>
                                            <View style={{ ...styles.celulaTabelaTitulo2, width: "8.33%" }}>
                                                <Text style={styles.celulaTabelaTexto}>{linha.baseCalculoCofins.toFixed(2)}</Text>
                                            </View>


                                        </View>
                                    </>
                                ))}
                                <View style={styles.footer}>
                                    <View style={styles.footerLinha}>
                                        <Text style={styles.footerTitulo}>ERP Waybe</Text>
                                        <Image source={Logo} style={styles.logo} />
                                    </View>
                                    <View style={styles.footerLinha}>
                                        <Text style={styles.footerTexto}>
                                            Nome do usuário: <Text style={styles.footerUsuario}>{dadosAuth.usuarioLogadoDTO.nome}</Text>
                                        </Text>
                                    </View>
                                    <View style={styles.footerLinha} >
                                        <Text style={styles.footerTexto} >
                                            {dataCompletaEscrita()}
                                        </Text>
                                        <Text
                                            render={({ pageNumber, totalPages }) =>
                                                `Página ${pageNumber} de ${totalPages}`
                                            }
                                            style={styles.footerTexto}

                                        />
                                    </View>
                                </View>
                            </View>
                        </Page>




                    ))}
                </Document>
            </PDFViewer>
        </>
    )
}