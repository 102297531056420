import { Chip } from "@mui/material/";
import styled from "@emotion/styled";

export const ChipCustomizado = styled(Chip)`
  color: ${({ theme }) => theme.cores.actionActive};
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 12px;
  height: fit-content;
  border-width: 1.4px;
  height: 28px;

  .MuiChip-label {
    color: ${({ theme }) => theme.cores.actionActive};
    padding: 0px 10px;
    margin-bottom: 0px;
  }
`;
