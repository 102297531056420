import {
  BotaoCancelarRecorrencia,
  BotaoDesistir,
  BotaoFazerPagamento,
  BoxCountModal,
  CabecalhoModalCancelarRecorrencia,
  ContainerAccordions1,
  ContainerAccordionsDireita,
  ContainerAccordionsEsquerda,
  ContainerInputBoxCountModal,
  ContainerRemover,
  ContainerTextoValorTotalPlano,
  ContainerTituloValorTotalPlano,
  ContainerValorTotalPlano,
  FooterModal,
  LinhaCabecalhoModalCancelarRecorrencia,
  LinhaClaraExtras,
  LinhaEscuraExtras,
  NumberInputCustomizadoModal,
  SubtituloValorPlano,
  TextoAdicionar,
  TextoBotaoCancelarRecorrencia,
  TextoBotaoDesistir,
  TextoBotaoFazerPagamento,
  TextoBoxCountModal,
  TextoCabecalhoModalCancelarRecorrencia,
  TextoCancelarRecorrencia,
  TextoReativarRecorrencia,
  TextoRemover,
  TextoSubtituloModal,
  TituloLinhaExtras,
  TituloValorTotalPlano,
  ValorTotalPlano,
} from "./styles";

import { PopupCustomizadoMin } from "../../../../components/Contrato/Ativo/styles";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  StyledAccordion,
  TextoLinha,
  TypographyTitulo,
} from "../../../contratoCustomizado/cadastro/styles";
import { AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconeBotao } from "../../../selecaoEmpresas/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContainerPopup } from "../../../../components/Contrato/Padrao/styles";
import { useEffect, useState } from "react";
import {
  agruparItensPorModulo,
  gerarItemExpansao,
} from "./componentes/ModularizadorItens";
import { ResponseExtrasWaybe } from "../../../../services/contrato/waybe/MapperExtrasWaybe";
import { useTheme } from "@emotion/react";
import useTamanhoTela from "../../../../utils/useTamanhoTela";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../../../contexts/api";
import formatarReal from "../../../../utils/formatarReal";
import { toast } from "react-toastify";
import ModalDetalharPlano from "../../comparacaoPlanos";
import {
  BotaoAzulVazado,
  BotaoVerdePreenchido,
  BotaoVermelhoPreenchido,
  BotaoVermelhoSemFundo,
  BotaoVermelhoVazado,
} from "../../../../components/Botao";
import { ItensRealizarPagamentoFactory } from "../../../realizarPagamento/itensRealizarPagamentoFactory";
import { ResponseCalculoExtras } from "../../../../services/contrato/calculo/MapperCalculoExtras";
import { getUrlEmpresa } from "../../../../enums/TipoEmpresa";
import {
  CampoDeInputNumericoComSetas,
  CampoDeInputNumericoComSetasZero,
} from "../../../../components/CampoInput";
import GerarModalResponsivo from "../../../../components/GerarModalResponsivo";
import Carregando from "../../../carregando";
import { ModalAdicionarQuantidade } from "./componentes/ModalAdicionarQuantidade";

export function ItensWaybe({ aplicacao, irParaPagamento, irParaPlano }) {
  const ITEM_NAO_CONTRATADO = "ITEM_NAO_CONTRATADO";
  const ITEM_CONTRATADO = "ITEM_CONTRATADO";
  const ITEM_RECORRENCIA_CANCELADA = "ITEM_RECORRENCIA_CANCELADA";
  const SESSAO_EXTRA = "SESSAO_EXTRA";
  const SITEF_PDV = "SITEF_PDV";

  const { largura } = useTamanhoTela();
  const tema = useTheme();
  const navigate = useNavigate();
  const { get, put, del } = useApi();

  const [listaExtras, setListaExtras] = useState([]);
  const [loading, setLoading] = useState(true);
  const [desabilitado, setDesabilitado] = useState(false);

  useEffect(() => {
    buscarExtras();
  }, []);

  function mapExtrasComCamposTela(extra) {
    let status;
    if (extra.tipoStatusContrato === "RECORRENCIA_CANCELADA") {
      status = ITEM_RECORRENCIA_CANCELADA;
    } else if (extra.tipoStatusContrato === "ATIVO") {
      status = ITEM_CONTRATADO;
    } else {
      status = ITEM_NAO_CONTRATADO;
    }

    return {
      ...extra,
      camposTela: {
        adicionadoAoCarrinho: 0,
        status: status,
      },
    };
  }

  function buscarExtras() {
    setLoading(true);
    if (aplicacao.possuiExtras) {
      get("/contrato/consumidor/waybe/empresa-contrato/item/extra")
        .then((response) => {
          if (response.status !== 200) {
            setListaExtras([]);

            if (response.data?.message?.includes("Contratos gratuitos")) {
              toast.error("Seu plano não permite contratação de Extras");
            } else {
              toast.error(
                response.data.message
                  ? response.data.message
                  : "Desculpe! Houve um erro ao obter os Extras"
              );
            }
            throw new Error();
          }
          let data = ResponseExtrasWaybe.map(response.data);
          data = data.map((e) => mapExtrasComCamposTela(e));
          setListaExtras(data);
          setLoading(false);
        })
        .catch((error) => {
          setListaExtras([]);
          console.error(error);
          setLoading(false);
        });
    } else {
      setListaExtras(null);
    }
  }

  function ItemExtra({ item, index }) {
    if (index % 2 === 0) {
      return <ItemExtraLinhaClara item={item} />;
    } else {
      return <ItemExtraLinhaEscura item={item} />;
    }
  }

  function ItemExtraLinhaClara({ item }) {
    return (
      <LinhaClaraExtras>
        <TituloLinhaExtras>{item.nomeExtra}</TituloLinhaExtras>
        <TextoLinha style={{ marginRight: largura < 768 ? 45 : 0 }}>
          {item.valorUnitario > 0
            ? formatarReal(item.valorUnitario)
            : "Gratuito"}
        </TextoLinha>

        {<BotaoAcaoExtra item={item} />}
      </LinhaClaraExtras>
    );
  }

  function ItemExtraLinhaEscura({ item }) {
    return (
      <LinhaEscuraExtras>
        <TituloLinhaExtras>{item.nomeExtra}</TituloLinhaExtras>
        <TextoLinha style={{ marginRight: largura < 768 ? 45 : 0 }}>
          {item.valorUnitario > 0
            ? formatarReal(item.valorUnitario)
            : "Gratuito"}
        </TextoLinha>
        {<BotaoAcaoExtra item={item} />}
      </LinhaEscuraExtras>
    );
  }

  function BotaoAcaoExtra({ item }) {
    if (
      !item.estaContratado &&
      item.camposTela.adicionadoAoCarrinho > 0 &&
      !item.camposTela.tipoItemPlanoWaybe == SITEF_PDV
    ) {
      return <BotaoRemoverExtra item={item} />;
    }

    switch (item.camposTela.status) {
      case ITEM_CONTRATADO:
        if (item.tipoItemPlanoWaybe == SESSAO_EXTRA) {
          return <PopupCancelarRecorrencia item={item} />;
        } else if (item.tipoItemPlanoWaybe == SITEF_PDV) {
          return <AlterarQuantidade item={item} />;
        } else {
          return <BotaoCancelarRecorrencia item={item} />;
        }
      case ITEM_NAO_CONTRATADO:
        if (item.tipoItemPlanoWaybe == SITEF_PDV) {
          return <AlterarQuantidade item={item} />;
        } else {
          return <BotaoAdicionarExtra item={item} />;
        }
      case ITEM_RECORRENCIA_CANCELADA:
        if (item.tipoItemPlanoWaybe == SITEF_PDV) {
          return <AlterarQuantidade item={item} />;
        } else {
          return <BotaoReativarRecorrenciaExtra item={item} />;
        }
    }
  }

  function BotaoAdicionarExtra({ item }) {
    return (
      <TextoAdicionar
        onClick={() => adicionarExtra(item.idItemConfiguracao)}
        style={{ width: 180 }}
      >
        Adicionar
      </TextoAdicionar>
    );
  }

  function adicionarExtra(idExtra, quantidade = null) {
    let novaListaExtras = listaExtras.map((e) => {
      if (idExtra === e.idItemConfiguracao) {
        if (quantidade) {
          e.camposTela.adicionadoAoCarrinho = quantidade;
        } else {
          e.camposTela.adicionadoAoCarrinho = 1;
        }
      }

      return e;
    });

    setListaExtras(novaListaExtras);
  }

  function BotaoRemoverExtra({ item }) {
    return (
      <ContainerRemover
        onClick={() => removerExtra(item.idItemConfiguracao)}
        style={{ width: 180 }}
      >
        <TextoRemover>Remover</TextoRemover>
      </ContainerRemover>
    );
  }

  function removerExtra(idExtra) {
    let novaListaExtras = listaExtras.map((e) => {
      if (idExtra === e.idItemConfiguracao) {
        e.camposTela.adicionadoAoCarrinho = 0;
      }

      return e;
    });

    setListaExtras(novaListaExtras);
  }

  function zerarExtras() {
    let novaListaExtras = listaExtras.map((e) => {
      e.camposTela.adicionadoAoCarrinho = 0;

      return e;
    });

    setListaExtras(novaListaExtras);
  }

  function BotaoReativarRecorrenciaExtra({ item }) {
    return (
      <TextoReativarRecorrencia
        style={{ display: largura > 768 ? "flex" : "none", width: 180 }}
      >
        Recorrência Cancelada
      </TextoReativarRecorrencia>
    );
  }

  function PopupCancelarRecorrencia({ item }) {
    const [quantidadeCancelar, setQuantidadeCancelar] = useState(0);

    function contratar() {
      del(
        `/contrato/consumidor/waybe/empresa-contrato/item/extra/${item.idItemConfiguracao}/${quantidadeCancelar}`
      )
        .then((response) => {
          if (response.status === 200) {
            toast.success("Recorrência cancelada com sucesso!");
            buscarExtras();
          } else {
            throw new Error(response);
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error("Desculpe! Houve um erro ao cancelar a recorrência");
          buscarExtras();
        });
    }

    return (
      <GerarModalResponsivo
        blocking={true}
        trigger={
          <TextoCancelarRecorrencia
            style={{
              width: 180,
              cursor: "pointer",
            }}
          >
            Cancelar recorrência
          </TextoCancelarRecorrencia>
        }
        modal
        nested
      >
        {(close) => (
          <ContainerPopup>
            <CabecalhoModalCancelarRecorrencia>
              <TextoCabecalhoModalCancelarRecorrencia>
                Cancelar recorrência das sessões extras
              </TextoCabecalhoModalCancelarRecorrencia>
            </CabecalhoModalCancelarRecorrencia>
            <ContainerInputBoxCountModal>
              <CampoDeInputNumericoComSetas
                variant="standard"
                label="Quantidade"
                value={parseInt(quantidadeCancelar)}
                style={{ width: "100%" }}
                onChange={setQuantidadeCancelar}
              />
            </ContainerInputBoxCountModal>
            <FooterModal>
              <BotaoVermelhoVazado onClick={close} texto={"Cancelar"} />
              <BotaoVermelhoPreenchido
                texto={"Confirmar"}
                onClick={contratar}
              />
            </FooterModal>
          </ContainerPopup>
        )}
      </GerarModalResponsivo>
    );
  }

  function BotaoCancelarRecorrencia({ item }) {
    function cancelar() {
      del(
        `/contrato/consumidor/waybe/empresa-contrato/item/extra/${item.idItemConfiguracao}/1`
      )
        .then((response) => {
          if (response.status === 200) {
            toast.success("Recorrência cancelada com sucesso!");
            buscarExtras();
          } else {
            throw new Error(response);
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error("Desculpe! Houve um erro ao cancelar a recorrência");
          buscarExtras();
        });
    }

    return (
      <TextoCancelarRecorrencia
        onClick={cancelar}
        style={{
          width: 180,
          cursor: "pointer",
        }}
      >
        Cancelar recorrência
      </TextoCancelarRecorrencia>
    );
  }

  function AlterarQuantidade({ item }) {
    const [quantidade, setQuantidade] = useState(null);

    useEffect(() => {
      setQuantidade(
        item?.quantidadeContratada -
          item?.quantidadeRecorrenciaCancelada +
          item?.camposTela?.adicionadoAoCarrinho
      );
    }, []);

    return (
      <div
        style={
          largura > 400
            ? { width: "100%", maxWidth: "180px", position: "relative" }
            : { width: "100%" }
        }
      >
        <div style={{ width: "180px", position: "absolute", left: "-15vw" }}>
          Qtd: {quantidade}
        </div>
        <ModalAdicionarQuantidade
          item={item}
          adicionarExtra={adicionarExtra}
          buscarExtras={buscarExtras}
          tipoItem={`waybe`}
        />
      </div>
    );
  }

  function PainelAlteracaoExtras({ extrasDisponiveis }) {
    const { post } = useApi();

    const [possuiExtrasAdicionados, setPossuiExtrasAdicionados] = useState(0);
    const [calculoExtrasIncrementados, setCalculoExtrasIncrementados] =
      useState(null);

    useEffect(() => {
      verificarPossuiExtrasAdicionados();
      calcularTotalExtrasIncrementados();
    }, []);

    function calcularTotalExtrasIncrementados() {
      let listaExtrasAdicionados = [];
      for (let extra of extrasDisponiveis) {
        if (extra.camposTela.adicionadoAoCarrinho > 0) {
          listaExtrasAdicionados.push({
            idItemConfiguracao: extra.idItemConfiguracao,
            quantidadeExtra: extra.camposTela.adicionadoAoCarrinho,
          });
        }
      }

      if (listaExtrasAdicionados.length > 0) {
        post("/contrato/consumidor/waybe/empresa-contrato/item/extra/calculo", {
          listaExtras: listaExtrasAdicionados,
        })
          .then((response) => {
            if (response.status !== 200) {
              setDesabilitado(true);
              zerarExtras();
              toast.error(
                response.data.message
                  ? response.data.message
                  : "Desculpe! Houve um erro ao calcular o custo dos extras"
              );
              throw new Error(
                response.data.message
                  ? response.data.message
                  : "Desculpe! Houve um erro ao calcular o custo dos extras"
              );
              return;
            }

            let data = ResponseCalculoExtras.map(response.data);
            setCalculoExtrasIncrementados(data);
          })
          .catch((error) => {
            setCalculoExtrasIncrementados(null);
            console.error(error);
          });
      }
    }

    function verificarPossuiExtrasAdicionados() {
      for (let extra of extrasDisponiveis) {
        if (extra.camposTela.adicionadoAoCarrinho > 0) {
          setPossuiExtrasAdicionados(true);
          return;
        }
      }

      setPossuiExtrasAdicionados(false);
    }

    function valorFormatado() {
      return formatarReal(calculoExtrasIncrementados?.total);
    }

    function onClickFazerPagamento() {
      let listaItemExtrasAtual = [];
      for (let extra of listaExtras) {
        if (extra.camposTela.status === ITEM_CONTRATADO) {
          listaItemExtrasAtual.push(
            ItensRealizarPagamentoFactory.criarSubItemDescricao(
              extra.nomeExtra +
                `${
                  extra?.quantidadeContratada > 1
                    ? ` (${extra?.quantidadeContratada})`
                    : ""
                }`,
              null
            )
          );
        }
      }

      let itemDescricaoExtrasAtual =
        ItensRealizarPagamentoFactory.criarItemDescricao(
          "Extras do plano atual",
          listaItemExtrasAtual
        );

      let listaItemExtrasContratando = [];
      for (let extra of calculoExtrasIncrementados.listaItens) {
        let itemSelecionado = extrasDisponiveis.find(
          (e) => e.idItemConfiguracao === extra.idItemConfiguracao
        );

        listaItemExtrasContratando.push(
          ItensRealizarPagamentoFactory.criarSubItemDescricao(
            extra.descricao +
              `${
                itemSelecionado?.camposTela?.adicionadoAoCarrinho > 1
                  ? ` (${itemSelecionado?.camposTela?.adicionadoAoCarrinho})`
                  : ""
              }`,
            extra.valor
          )
        );
      }

      let itemDescricaoExtrasContratando =
        ItensRealizarPagamentoFactory.criarItemDescricao(
          "Extras contratados",
          listaItemExtrasContratando
        );

      let listaTotal = [];
      listaTotal.push(
        ItensRealizarPagamentoFactory.criarItemTotal(
          "Total extras",
          calculoExtrasIncrementados.total
        )
      );

      let listaDetalhes = [];
      listaDetalhes.push(itemDescricaoExtrasAtual);
      listaDetalhes.push(itemDescricaoExtrasContratando);

      const extrasPagamento = () => {
        let corpo = [];

        for (let extra of extrasDisponiveis) {
          if (extra.camposTela.adicionadoAoCarrinho > 0) {
            corpo.push({
              idItemConfiguracao: extra.idItemConfiguracao,
              quantidadeExtra: extra.camposTela.adicionadoAoCarrinho,
            });
          }
        }

        return post("/contrato/consumidor/waybe/empresa-contrato/item/extra", {
          listaExtras: corpo,
        })
          .then((response) => {
            if (response.status === 200) {
              toast.success("Extra contratado com sucesso!");
              irParaPlano();
            } else {
              toast.error(
                response.data?.message
                  ? response.data?.message
                  : "Erro ao contratar!"
              );
              throw new Error(response);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      };

      let dadosPagamento = {
        titulo: "Adição de extras",
        avisoAntesPagamento:
          "Valor foi calculado referente aos dias restantes até o vencimento do contrato.",
        listaTotal: listaTotal,
        listaDetalhes: listaDetalhes,
        realizarPagamentoFuncao: extrasPagamento,
      };

      irParaPagamento(dadosPagamento);
    }

    const styleDesabilitado = desabilitado
      ? {
          pointerEvent: "none",
          backgroundColor: "transparent",
          color: tema.cores.textActionDisable,
          border: `1px solid ${tema.cores.actionDisable}`,
          cursor: "auto",
        }
      : {};

    return (
      <div>
        {possuiExtrasAdicionados && (
          <ContainerValorTotalPlano>
            <ContainerTextoValorTotalPlano>
              <ContainerTituloValorTotalPlano>
                <TituloValorTotalPlano>
                  Valor total dos extras
                </TituloValorTotalPlano>
                <ValorTotalPlano>{valorFormatado()}</ValorTotalPlano>
              </ContainerTituloValorTotalPlano>
              <SubtituloValorPlano>
                Você adicionou pacotes extras ao seu plano, será cobrado o valor
                de {valorFormatado()}
                <br />
                referente aos dias restantes de contrato.
              </SubtituloValorPlano>
            </ContainerTextoValorTotalPlano>
            <BotaoAzulVazado
              onClick={onClickFazerPagamento}
              style={{ ...styleDesabilitado }}
              texto="Fazer pagamento"
            />
          </ContainerValorTotalPlano>
        )}
      </div>
    );
  }

  return (
    <div>
      {/*listaExtras*/}
      <StyledAccordion defaultExpanded={true} style={{ marginBottom: 32 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <TypographyTitulo>Extras</TypographyTitulo>
        </AccordionSummary>

        {loading ? (
          <LinhaClaraExtras
            style={{
              width: "100%",
              height: "fit-content",
              justifyContent: "center",
              padding: 0,
              paddingRight: 0,
            }}
          >
            <Carregando />
          </LinhaClaraExtras>
        ) : (
          listaExtras.map((e, index) => (
            <ItemExtra
              item={e}
              index={index}
              key={e.nomeExtra + "ItemExtra" + index}
            />
          ))
        )}
      </StyledAccordion>

      <PainelAlteracaoExtras extrasDisponiveis={listaExtras} />
    </div>
  );
}
