import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/pt-br";
import React from "react";
import { TemaProvider } from "./contexts/theme";
import RoutesApp from "./routes";
import { GlobalStyle } from "./styles/global";
import { AuthProvider } from "./contexts/auth";
import { ToastContainer } from "react-toastify";
import { ToastifyIcon } from "./styles/toastify";
import { ApiProvider } from "./contexts/api";
import { LoaderProvider } from "./contexts/loader";
import * as updateLocale from "dayjs/plugin/updateLocale";
import "react-toastify/dist/ReactToastify.css";
import { NavegacaoProvider } from "./contexts/navegacao";
import { disableWarnings } from "./utils/desabilitarWarnings";
dayjs.extend(updateLocale);

const App = () => {


  window.sessao = function () {
    try {
    } catch (error) {
      console.error(error)
    }
  }

  dayjs.updateLocale("pt-br", {
    weekdaysMin: "D_S_T_Q_Q_S_S".split("_"),
  });

  disableWarnings();

  return (
    <TemaProvider>
      <ApiProvider>
        <AuthProvider>
          <LoaderProvider>
            <NavegacaoProvider>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="pt-br"
              >
                <RoutesApp />
                <GlobalStyle />
                <ToastContainer
                  theme="colored"
                  icon={<ToastifyIcon />}
                  // limit={2}
                  autoClose={2500}
                />
              </LocalizationProvider>
            </NavegacaoProvider>
          </LoaderProvider>
        </AuthProvider>
      </ApiProvider>
    </TemaProvider>
  );
};

export default App;
