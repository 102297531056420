import styled from "@emotion/styled";
import { Accordion, AccordionDetails, Typography } from "@mui/material";

export const LinhaComparacao = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const ContainerDados = styled.div`
  height: 800px;
  margin-bottom: 32px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;

  @media (max-width: 530px) {
    width: 170vw;
  }

  @media (min-width: 530px) and (max-width: 768px) {
    height: fit-content;
    width: 100%;
    padding-bottom: 40px;
  }
`;

export const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  margin: 0;
  margin-bottom: 0;
  background-color: ${(props) => props.theme.adaptativo("brand10", "brand70")};

  overflow: scroll;

  svg {
    color: ${(props) => props.theme.textoContrato};
  }

  &.MuiAccordion-root {
    margin: 0;
  }

  &.MuiAccordionSummary-content {
    margin: 10px 0 !important;
  }

  #panel1d-content {
    /* min-width: 600px; */
  }

  #panel1d-header {
    min-height: 0;
    height: 50px;
  }

  .MuiCollapse-root {
    overflow-x: scroll;
  }

  thead {
    display: none;
  }

  tr:nth-child(odd) {
    background-color: ${(props) =>
      props.theme.adaptativo("neutral100", "surface2")} !important;
  }

  tr {
    height: 90px;
  }

  .container-tabela {
    height: unset !important;
  }
`;

export const TypographyTitulo = styled(Typography)`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.5px;
  @media (max-width: 768px) {
    width: 100%;
    font-family: "Comfortaa";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
  }
`;

export const ContainerCarregamento = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LinhaItem = styled(AccordionDetails)`
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  align-items: center;
  height: 69px;
  padding: 16px;
`;

export const CabecalhoAccordion = styled.div`
  width: 100%;
  min-height: 48px;
  background: ${({ theme }) => theme.adaptativo("brand10", "brand70")};

  padding: 16px;
  align-items: center;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
`;

export const TextoCabecalhoAccordionDescricao = styled.p`
  width: 100%;
  font-size: 14px;
  font-family: "Comfortaa", sans-serif;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.adaptativo("neutral10", "neutral100")};
  display: flex;
  justify-content: center;
`;
