import {
  BotaoFazerPagamento,
  Hr,
  TextoFazerPagamento,
} from "../../realizarPagamento/styles";
import {
  BotaoCadastrarCartaoModal,
  BotaoCancelar,
  BotoesModal,
  TextoBotaoCadastrarCartaoModal,
  TextoBotaoCancelar,
  ContainerCartoes,
  ContainerPopupCartao,
  PopupCustomizadoCartao,
  TextoCartaoCadastrado,
  TextoFinalizarCompra,
  ContainerAviso,
  TextoAviso,
} from "./styles";
import { useState } from "react";
import ListagemCartao from "../cartoes/listagemCartao";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@emotion/react";
import GerarModalResponsivo from "../../../components/GerarModalResponsivo";
import {
  BotaoVerdePreenchido,
  BotaoVermelhoVazado,
} from "../../../components/Botao";

export default function ModalPagamento({
  onFechar,
  componenteTrigger,
  textoBotaoVerde = "Finalizar compra",
  textoHeaderModal = "Finalizar compra",
  textoAviso = "",
  realizarPagamentoFuncao,
}) {
  const theme = useTheme();

  function pagar(close) {
    realizarPagamentoFuncao();
    close();
  }

  return (
    <GerarModalResponsivo
      trigger={componenteTrigger}
      onFechar={onFechar}
      children={(close) => (
        <ContainerPopupCartao>
          <TextoFinalizarCompra>{textoHeaderModal}</TextoFinalizarCompra>
          <Hr />
          <TextoCartaoCadastrado>Cartão Cadastrado</TextoCartaoCadastrado>
          <ContainerCartoes>
            <ListagemCartao />
          </ContainerCartoes>
          {textoAviso && (
            <ContainerAviso>
              <FontAwesomeIcon
                icon={faWarning}
                style={{
                  width: 20,
                  height: 20,
                  color: theme.cores.semanticAlert3,
                }}
              />
              <TextoAviso>{textoAviso}</TextoAviso>
            </ContainerAviso>
          )}
          <BotoesModal>
            <BotaoVermelhoVazado texto={"Cancelar"} onClick={close} />
            <BotaoVerdePreenchido
              texto={textoBotaoVerde}
              onClick={() => {
                pagar(close);
              }}
            />
          </BotoesModal>
        </ContainerPopupCartao>
      )}
    />
  );
}
