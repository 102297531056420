import { FormControl, MenuItem, Select } from "@mui/material";
import { Container } from "../../../produtos/promocoes/styles";
import { IconeBotao } from "../../../selecaoEmpresas/styles";
import {
  CabecalhoMudarPagina,
  ContainerFundoBranco,
  ItemPreenchido,
  TextoCabecalhoMudarPagina,
} from "../styles";
import {
  CabecalhoTituloPlano,
  ContainerDetalhesDoPlano,
  ContainerSubtituloValor,
  ContainerTipoAplicacao,
  ContainerTituloPlano,
  SubtituloValor,
  SubtituloVencimento,
  TituloPlano,
  Valor,
} from "./styles";
import useTamanhoTela from "../../../../utils/useTamanhoTela";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../../../contexts/api";
import { toast } from "react-toastify";
import { ResponseContratoWaychef } from "../../../../services/contrato/waychef/MapperContratoWaychef";
import {
  BotaoAzulPreenchido,
  BotaoVermelhoPreenchido,
  BotaoVermelhoVazado,
} from "../../../../components/Botao";
import { ResponseAplicacoesContratadas } from "../../../../services/contrato/waybe/MapperAplicacoesContratadas";
import { ItensWaychef } from "./ItensWaychef";
import { ItensWaymenu } from "./ItensWaymenu";
import { ResponseContratoWaymenu } from "../../../../services/contrato/waymenu/MapperContratoWaymenu";
import { GerarModalConfirmacao } from "../../../../components/PopUpConfirmacao";
import { CampoDeTexto } from "../../../../components/CampoInput";
import formatarReal from "../../../../utils/formatarReal";
import { IndicadorStatus } from "../../../../components/IndicadorStatus";
import { formatarDataEHorarioParaPadraoBrasileiro } from "../../../../utils/formatarDataPadraoBrasileiro";
import RealizarPagamento from "../../../realizarPagamento";
import PlanosCompra from "../../listagemPlanos/planosCompra";
import Carregando from "../../../carregando";
import { ResponseInformacoesGerais } from "../../../../services/contrato/waybe/MapperInformacoesGerais";

var aplicacaoSelecionadaGlobal;
const PAGINA_PAGAMENTO = "PAGINA_PAGAMENTO";
const PAGINA_PLANOS_DISPONIVEIS = "PAGINA_PLANOS_DISPONIVEIS";
const PAGINA_PLANOS_DISPONIVEIS_COMPRA = "PAGINA_PLANOS_DISPONIVEIS_COMPRA";
const PAGINA_MEU_PLANO = "PAGINA_MEU_PLANO";
const PAGINA_PAGAMENTO_COMPRA = "PAGINA_PAGAMENTO_COMPRA";

export function Plano({ tipoAplicacaoDefault }) {
  const tema = useTheme();
  const navigate = useNavigate();
  const { get, put, del, post } = useApi();

  const [paginaAtiva, setPaginaAtiva] = useState(PAGINA_MEU_PLANO);
  const [dadosPagamento, setDadosPagamento] = useState({});
  function irParaPagamento(dadosPagamento) {
    setPaginaAtiva(PAGINA_PAGAMENTO);
    setDadosPagamento(dadosPagamento);
  }

  function irParaPagamentoCompra(dadosPagamento) {
    setPaginaAtiva(PAGINA_PAGAMENTO_COMPRA);
    setDadosPagamento(dadosPagamento);
  }

  function irParaPlano() {
    setPaginaAtiva(PAGINA_MEU_PLANO);
  }
  function irParaTrocaPlano() {
    setPaginaAtiva(PAGINA_PLANOS_DISPONIVEIS);
  }

  function irParaContratacaoNovoPlano() {
    setPaginaAtiva(PAGINA_PLANOS_DISPONIVEIS_COMPRA);
  }

  function PaginaPagamento({ dadosPagamento }) {
    return (
      <RealizarPagamento
        titulo={dadosPagamento.titulo}
        listaTotal={dadosPagamento.listaTotal}
        listaDetalhes={dadosPagamento.listaDetalhes}
        avisoAntesPagamento={dadosPagamento.avisoAntesPagamento}
        realizarPagamentoFuncao={dadosPagamento.realizarPagamentoFuncao}
        valorTotal={dadosPagamento.valorTotal}
        avisoSuperiorEsquerda={dadosPagamento.avisoSuperiorEsquerda}
        voltarParaTelaAnterior={irParaTrocaPlano}
      />
    );
  }

  function PaginaPagamentoCompra({ dadosPagamento }) {
    return (
      <RealizarPagamento
        titulo={dadosPagamento.titulo}
        listaTotal={dadosPagamento.listaTotal}
        listaDetalhes={dadosPagamento.listaDetalhes}
        avisoAntesPagamento={dadosPagamento.avisoAntesPagamento}
        realizarPagamentoFuncao={dadosPagamento.realizarPagamentoFuncao}
        voltarParaTelaAnterior={irParaContratacaoNovoPlano}
      />
    );
  }

  function PaginaMeuPlano() {
    const [aplicacoesContratadas, setAplicacoesContratadas] = useState([]);
    const [aplicacaoSelecionada, setAplicacaoSelecionada] = useState(null);
    const [contrato, setContrato] = useState(null);
    const [loading, setLoading] = useState(true);

    const [informacoes, setInformacoes] = useState(null);

    function getInformacoesGerais() {
      get("/contrato/consumidor/waybe/empresa-contrato/informacoes-gerais")
        .then((response) => {
          if (response.status === 200) {
            let data = ResponseInformacoesGerais.map(response.data);
            setInformacoes(data);
          } else {
            throw new Error("Erro ao obter o contrato");
          }
        })
        .catch((error) => {
          setInformacoes([]);
          console.error(error);
          navigate("/");
          toast.error(
            "Desculpe! Houve um erro ao obter as informações gerais do contrato"
          );
        });
    }

    useEffect(() => {
      buscarContratacoes();
      getInformacoesGerais();
    }, []);

    useEffect(() => {
      aplicacaoSelecionadaGlobal = aplicacaoSelecionada;
    }, [aplicacaoSelecionada]);

    function buscarContratacoes() {
      setLoading(true);
      get("/contrato/consumidor/waybe/empresa-contrato/aplicacoes")
        .then((response) => {
          let aplicacoes = ResponseAplicacoesContratadas.map(response.data);
          setAplicacoesContratadas(aplicacoes);
          if (tipoAplicacaoDefault) {
            let aplicacao = aplicacoes.find(
              (a) => a.tipoAplicacao === tipoAplicacaoDefault
            );
            if (aplicacao) {
              setAplicacao(aplicacao);
              return;
            }
          }
          setAplicacao(aplicacoes[0]);
        })
        .catch((error) => {
          setContrato(null);
          setLoading(false);
          console.error(error);
          toast.error("Desculpe! Houve um erro ao obter os contratos");
        });
    }

    function buscarContrato(aplicacao) {
      setLoading(true);
      get(
        "/contrato/consumidor/" +
        aplicacao.uri +
        "/empresa-contrato/vigente/infomacoes-detalhadas"
      )
        .then(async (response) => {
          let data = mapResponse(aplicacao, response.data);
          setContrato(data);
          setLoading(false);
        })
        .catch((error) => {
          setContrato(null);
          setLoading(false);
          console.error(error);
          toast.error("Desculpe! Houve um erro ao obter os contratos");
        });
    }

    function mapResponse(aplicacao, data) {
      switch (aplicacao.tipoAplicacao) {
        case "WAYCHEF":
          return ResponseContratoWaychef.mapContratoComItens(data);
        case "WAYMENU":
          return ResponseContratoWaymenu.mapContratoComItens(data);
        default:
          return [];
      }
    }

    function setTipoAplicacao(tipoAplicacao) {
      let aplicacao = aplicacoesContratadas.find(
        (a) => a.tipoAplicacao === tipoAplicacao
      );

      setAplicacao(aplicacao);
    }

    function setAplicacao(aplicacao) {
      if (aplicacao) {
        buscarContrato(aplicacao);
        setAplicacaoSelecionada(aplicacao);
        aplicacaoSelecionadaGlobal = aplicacao;
      } else {
        setAplicacaoSelecionada(null);
        aplicacaoSelecionadaGlobal = null;
        setContrato(null);
        setLoading(false);
      }
    }

    function ChipStatus({ status }) {
      switch (status) {
        case "ATIVO":
          return (
            <IndicadorStatus
              texto="Ativo"
              cor="verde"
              tyle={{ width: "auto" }}
            />
          );
        case "RECORRENCIA_CANCELADA":
          return (
            <IndicadorStatus
              texto="Cancelado"
              cor="vermelho"
              style={{ width: "auto" }}
            />
          );

        default:
          return (
            <IndicadorStatus
              texto="Não definido"
              cor="vermelho"
              style={{ width: "auto" }}
            />
          );
      }
    }

    function DetalhesDoPlano({ aplicacao }) {
      function itensDaAplicacao() {
        switch (aplicacao.tipoAplicacao) {
          case "WAYCHEF":
            return <ItensWaychef contrato={contrato} aplicacao={aplicacao} />;
          case "WAYMENU":
            return <ItensWaymenu contrato={contrato} aplicacao={aplicacao} />;
          default:
            return <h3>{aplicacao.nomeAplicacao}</h3>;
        }
      }

      return loading ? (
        <Carregando />
      ) : (
        <div>
          <ContainerTituloPlano style={{ margin: "40px 0 40px 16px" }}>
            <TituloPlano>{contrato.descricao}</TituloPlano>

            {informacoes !== null &&
              informacoes.tipoFaturamentoPlataforma != "BOLETO_BANCARIO" ? (
              <ContainerSubtituloValor>
                <SubtituloValor>Valor total: </SubtituloValor>
                <Valor>{formatarReal(contrato.valorTotal)}</Valor>
              </ContainerSubtituloValor>
            ) : (
              <></>
            )}

            <div style={{ display: "flex", gap: 8 }}>
              <SubtituloVencimento>Status: </SubtituloVencimento>
              <ChipStatus status={contrato.statusContrato} />
            </div>

            {contrato.dataCancelamento && (
              <ContainerSubtituloValor>
                <SubtituloValor>Data do cancelamento: </SubtituloValor>
                <Valor>
                  {formatarDataEHorarioParaPadraoBrasileiro(
                    contrato.dataCancelamento
                  )}
                </Valor>
              </ContainerSubtituloValor>
            )}
          </ContainerTituloPlano>
          <ContainerDetalhesDoPlano>
            {itensDaAplicacao()}
          </ContainerDetalhesDoPlano>
        </div>
      );
    }

    function SelecaoTipoAplicacao({ aplicacao, listaAplicacoes }) {
      return (
        <FormControl fullWidth sx={{ m: 1, width: 150 }}>
          <Select
            value={aplicacao?.tipoAplicacao}
            onChange={(e) => setTipoAplicacao(e.target.value)}
            style={{
              borderRadius: 8,
              backgroundColor: tema.cores.actionActive,
              color: "white",
              height: 32,
              fontWeight: 600,
            }}
          >
            {/* <MenuItem value={null}>Nenhum</MenuItem> */}
            {listaAplicacoes.map((a) => (
              <MenuItem
                key={"chaveSelecao" + a.nomeAplicacao}
                value={a.tipoAplicacao}
              >
                {a.nomeAplicacao}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    function BotaoModalCancelarRecorrencias({ aplicacao }) {
      const [motivoCancelamento, setMotivoCancelamento] = useState("");

      function cancelarContratos(fecharModal) {
        post(
          "/contrato/consumidor/" +
          aplicacao.uri +
          "/empresa-contrato/vigente/cancelar-recorrencia",
          { motivoCancelamento }
        )
          .then((response) => {
            if (response.status === 200) {
              toast.info("Sucesso! seu contrato foi cancelado");
              buscarContratacoes();
            } else {
              toast.error("Desculpe! Houve um erro ao cancelar o seu contrato");
              buscarContratacoes();
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error("Desculpe! Houve um erro ao cancelar o seu contrato");
          })
          .finally(() => {
            fecharModal();
          });
      }

      return (
        contrato?.statusContrato !== "RECORRENCIA_CANCELADA" && (
          <GerarModalConfirmacao
            onClick={(b) => cancelarContratos(b)}
            trigger={
              <BotaoVermelhoVazado texto="Cancelar Contrato" tamanho="MD" />
            }
            titulo="Tem certeza que deseja cancelar o contrato?"
            subTitulo="Ao cancelar os seus contratos, eles ainda serão válidos até o vencimento, porém não serão mais renovados."
            observacao="OBS: Não será possível reverter essa ação"
            botaoCancelar={(fechar) => (
              <BotaoVermelhoVazado
                texto="Desistir"
                tamanho="SM"
                onClick={fechar}
              />
            )}
            botaoConfirmar={(fechar) => (
              <BotaoVermelhoPreenchido
                texto="Cancelar Contrato"
                tamanho="SM"
                onClick={() => cancelarContratos(fechar)}
              />
            )}
            campoInput={
              <CampoDeTexto
                titulo="Motivo do cancelamento"
                value={motivoCancelamento}
                onChange={(e) => setMotivoCancelamento(e.target.value)}
              />
            }
          />
        )
      );
    }

    return loading ? (
      <Carregando />
    ) : (
      <>
        <CabecalhoTituloPlano>
          <ContainerTipoAplicacao>
            <SelecaoTipoAplicacao
              aplicacao={aplicacaoSelecionada}
              listaAplicacoes={aplicacoesContratadas}
            />
          </ContainerTipoAplicacao>

          {contrato && (
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <BotaoModalCancelarRecorrencias
                aplicacao={aplicacaoSelecionada}
              />

              {contrato?.tipoRenovacaoContrato == "NOVO_PLANO" ? (
                <BotaoAzulPreenchido
                  style={{ marginRight: 12, marginLeft: 12 }}
                  tamanho="MD"
                  texto="Contratar"
                  onClick={irParaContratacaoNovoPlano}
                />
              ) : (
                <BotaoAzulPreenchido
                  style={{ marginRight: 12, marginLeft: 12 }}
                  tamanho="MD"
                  texto="Alterar Plano"
                  onClick={irParaTrocaPlano}
                />
              )}
            </div>
          )}
        </CabecalhoTituloPlano>

        {contrato && aplicacaoSelecionada && (
          <DetalhesDoPlano aplicacao={aplicacaoSelecionada} />
        )}
      </>
    );
  }

  function PlanosDisponiveis() {
    return (
      <PlanosCompra
        comparacao={true}
        voltar={irParaPlano}
        irParaPagamento={irParaPagamento}
        modoReduzido={true}
        aplicacaoSelecionada={aplicacaoSelecionadaGlobal.tipoAplicacao}
      />
    );
  }

  function PlanosDisponiveisCompra() {
    return (
      <PlanosCompra
        comparacao={false}
        voltar={irParaPlano}
        irParaPagamento={irParaPagamentoCompra}
        modoReduzido={true}
        aplicacaoSelecionada={aplicacaoSelecionadaGlobal.tipoAplicacao}
      />
    );
  }

  function getPaginaAtiva() {
    switch (paginaAtiva) {
      case PAGINA_PAGAMENTO:
        return <PaginaPagamento dadosPagamento={dadosPagamento} />;
      case PAGINA_PAGAMENTO_COMPRA:
        return <PaginaPagamentoCompra dadosPagamento={dadosPagamento} />;
      case PAGINA_MEU_PLANO:
        return <PaginaMeuPlano />;
      case PAGINA_PLANOS_DISPONIVEIS:
        return <PlanosDisponiveis />;
      case PAGINA_PLANOS_DISPONIVEIS_COMPRA:
        return <PlanosDisponiveisCompra />;
    }
  }

  return getPaginaAtiva();
}
