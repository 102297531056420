import styled from "@emotion/styled";

export const CardPlano = styled.div`
    width: 416px;
    padding-top: 24px;
    padding-bottom: 25px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.adaptativo("neutral90", "transparent")};
    background: ${({ theme }) => theme.cores.surface3};

    @media (max-width: 768px) {
        width: 100%;
    }
`

export const CabecalhoCardPlano = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
`

export const TituloCard = styled.p`
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 37px;
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    color: ${({ theme }) => theme.cores.textoSimples};
`

export const SubtituloCard = styled.p`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.cores.textoSimples};
`

export const LinhaCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
`

export const TituloLinhaCard = styled.p`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.cores.textoSimples};
`

export const ValorLinhaCard = styled.p`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.cores.textoSimples};
`

export const Hr = styled.hr`
    width: 100%;
    border: 1px solid ${({ theme }) => theme.cores.corHr};
    margin-bottom: 32px;
`

export const ContainerBotoes = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 768px) {
        justify-content: center;
    }
`

export const BotaoComprarAgora = styled.button`
    background: ${({ theme }) => theme.adaptativo("semanticSuccess3", "semanticSuccess2")};
    width: 150px;
    height: 32px;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    &:hover {
        opacity: 0.7
    }
`

export const TextoBotaoComprarAgora = styled.p`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: ${({ theme }) => theme.adaptativo("textAction", "semanticSuccess3")};

    @media (max-width: 450px) {
        font-size: 14px;
    }

    @media (max-width: 370px) {
        font-size: 12px;
    }
`