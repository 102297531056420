import styled from "@emotion/styled";

export const Global = styled.div`
width: 100%;
height: 100vh;

display: flex;

 @media (max-width: 800px) {
  display: block;
height: auto
}
`

export const BlocoEsquerda = styled.div``


export const Background = styled.div`
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 70%;
  @media (max-width: 1100px) {
    width: 60%;
  }

  @media (max-width: 800px) {
   display: none;
  }
`;
export const BlocoDireita = styled.div`

 display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
  background-color: ${({ theme }) => theme.cores.surfaceBackground1};
padding: 35px 108px 0px;
height: 90%;


  @media (max-width: 800px) {
    width: 100%;
    height: 100%;

padding: 35px 24px 0px;

  }
`


export const BlocoFormulario = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`

export const HeaderBlock = styled.div`
display: flex;
align-items: center;
flex-direction: column;


> img {
max-width: 308px;
height: 66px;
margin-bottom: 32px;
}

> h1 {
  font-family: "Comfortaa", sans-serif;
  font-size: 23px;
  font-weight: 500;
  color: ${({ theme }) => theme.adaptativo("neutral40", "neutral100")};

  margin-bottom: 4px;
}

> p {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  color: ${({ theme }) => theme.adaptativo("neutral10", "neutralBackground99")};
  margin-bottom: 42px;
}
`

export const InputBlock = styled.div`
display: flex;
flex-direction: column;
gap: 16px;
`

export const RequisitosSenhaContainer = styled.div`
display: flex;
flex-direction: column;
gap: 8px;
`

export const ContainerDireita = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;

@media (max-width: 800px) {
  height: 100vh;
}
`


const baseIcon = styled.span`
display: flex;
align-items: center;
gap: 8px;
font-size: 20px;

> p {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
}

`


export const StyledIconSucesso = styled(baseIcon)`

color: ${({ theme }) => theme.cores.surfaceActionActiveSuccess};

> p {
  color: ${({ theme }) => theme.cores.surfaceActionActiveSuccess};
}
`
export const StyledIconSemCampo = styled(baseIcon)`

color: ${({ theme }) => theme.adaptativo("corInput", "neutral100")};

> p {
  color: ${({ theme }) => theme.adaptativo("corInput", "neutral100")};
}
`
export const StyledIconError = styled(baseIcon)`

color: ${({ theme }) => theme.cores.cancelIcon};

> p {
  color: ${({ theme }) => theme.cores.semanticDanger2};
}
`

export const ButtonSalvar = styled.button`
width: 206px;
border-radius: 8px;
border: none;
outline: none;

background-color: ${({ theme }) => theme.cores.actionDisable};

font-family: "Source Sans Pro", sans-serif;
font-size: 16px;
font-weight: 600;


cursor: pointer;
margin-top: 42px;
`

export const ContainerSifat = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 5px;

  background-color: ${({ theme }) =>
    theme.adaptativo("surfaceBackground1", "surface2")};
margin-top: 10px;

`;

export const LogoSifat = styled.img`
  width: 10vw;

  @media (max-width: 800px) {
    width: 130px;
  }
`;



const Botao = styled.button`
  cursor: pointer;
  border: none;
  flex-direction: row;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  font-weight: 700;

  width: 206px;
  margin-top: 42px;
  padding: 8px 24px;
`;

export const BotaoBaseAzulPreenchido = styled(Botao)`
  color: ${(props) => props.theme.cores.textActionPrimary};
  fill: ${(props) => props.theme.cores.textActionPrimary};
  background-color: ${(props) => props.theme.cores.surfaceActionActivePrimary};

  &:hover{
    background-color: ${(props) => props.theme.cores.surfaceActionHoverPrimary};
    color: ${(props) => props.theme.cores.textActionPrimaryAlernative};
    fill: ${(props) => props.theme.cores.textActionPrimaryAlernative};
  }

  &:active {
    background-color: ${(props) => props.theme.cores.surfaceActionPressedPrimary};
    color: ${(props) => props.theme.cores.textActionPrimary};
    fill: ${(props) => props.theme.cores.textActionPrimary};
  }

  &:disabled {

  background-color: ${(props) => props.theme.cores.actionDisable};

    cursor: not-allowed;

  }

  &:disabled:hover {
  color: ${(props) => props.theme.cores.textActionPrimary};
    
  }

    font-size: 16px;
    font-weight: 600;
`;