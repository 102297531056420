import { FormControl, MenuItem, Select } from "@mui/material";
import { Container } from "../../../produtos/promocoes/styles";
import { IconeBotao } from "../../../selecaoEmpresas/styles";
import {
  CabecalhoMudarPagina,
  ContainerFundoBranco,
  ItemPreenchido,
  TextoCabecalhoMudarPagina,
} from "../styles";
import {
  CabecalhoTituloPlano,
  ContainerSubtituloValor,
  ContainerTituloPlano,
  SubtituloValor,
  SubtituloVencimento,
  TituloPlano,
  Valor,
} from "./styles";
import useTamanhoTela from "../../../../utils/useTamanhoTela";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../../../contexts/api";
import { toast } from "react-toastify";
import { ResponseContratoWaychef } from "../../../../services/contrato/waychef/MapperContratoWaychef";
import {
  BotaoAzulPreenchido,
  BotaoVermelhoPreenchido,
  BotaoVermelhoVazado,
} from "../../../../components/Botao";
import { ResponseAplicacoesContratadas } from "../../../../services/contrato/waybe/MapperAplicacoesContratadas";
import { ItensWaychef } from "./ItensWaychef";
import { ResponseContratoWaymenu } from "../../../../services/contrato/waymenu/MapperContratoWaymenu";
import { GerarModalConfirmacao } from "../../../../components/PopUpConfirmacao";
import { CampoDeTexto } from "../../../../components/CampoInput";
import formatarReal from "../../../../utils/formatarReal";
import { IndicadorStatus } from "../../../../components/IndicadorStatus";
import { formatarDataEHorarioParaPadraoBrasileiro } from "../../../../utils/formatarDataPadraoBrasileiro";
import RealizarPagamento from "../../../realizarPagamento";
import { ItensWaybe } from "./ItensWaybe";
import Carregando from "../../../carregando";

export function Extras({ tipoAplicacaoDefault }) {
  const PAGINA_PAGAMENTO = "PAGINA_PAGAMENTO";
  const PAGINA_PLANOS_DISPONIVEIS = "PAGINA_PLANOS_DISPONIVEIS";
  const PAGINA_MEU_PLANO = "PAGINA_MEU_PLANO";

  const APLICACAO_WAYBE = {
    tipoAplicacao: "WAYBE",
    nomeAplicacao: "Waybe",
    possuiExtras: true,
    uri: "waybe",
  };

  const { largura } = useTamanhoTela();
  const tema = useTheme();
  const navigate = useNavigate();
  const { get, put, del } = useApi();

  const [paginaAtiva, setPaginaAtiva] = useState(PAGINA_MEU_PLANO);

  const [dadosPagamento, setDadosPagamento] = useState({});

  function irParaPagamento(dadosPagamento) {
    setPaginaAtiva(PAGINA_PAGAMENTO);
    setDadosPagamento(dadosPagamento);
  }
  function irParaPlano() {
    setPaginaAtiva(PAGINA_MEU_PLANO);
  }
  function PaginaPagamento({ dadosPagamento }) {
    return (
      <RealizarPagamento
        titulo={dadosPagamento.titulo}
        listaTotal={dadosPagamento.listaTotal}
        listaDetalhes={dadosPagamento.listaDetalhes}
        avisoAntesPagamento={dadosPagamento.avisoAntesPagamento}
        realizarPagamentoFuncao={dadosPagamento.realizarPagamentoFuncao}
        voltarParaTelaAnterior={irParaPlano}
      />
    );
  }

  function PaginaMeuPlano() {
    const [aplicacoesContratadas, setAplicacoesContratadas] = useState([
      {
        tipoAplicacao: "WAYCHEF",
        nomeAplicacao: "Waychef",
        possuiExtras: true,
        uri: "waychef",
      },
      {
        tipoAplicacao: "WAYBE",
        nomeAplicacao: "Waybe",
        possuiExtras: true,
        uri: "waychef",
      },
    ]);
    const [aplicacaoSelecionada, setAplicacaoSelecionada] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      buscarContratacoes();
    }, []);

    function buscarContratacoes() {
      get("/contrato/consumidor/waybe/empresa-contrato/aplicacoes")
        .then((response) => {
          let aplicacoes = ResponseAplicacoesContratadas.map(
            response.data?.filter((a) => a.possuiExtras)
          );

          aplicacoes.unshift(APLICACAO_WAYBE);
          setAplicacoesContratadas(aplicacoes);
          if (tipoAplicacaoDefault) {
            let aplicacao = aplicacoes.find(
              (a) => a.tipoAplicacao === tipoAplicacaoDefault
            );

            if (aplicacao) {
              setAplicacao(aplicacao);
              setLoading(false);
              return;
            }
          }

          setAplicacao(aplicacoes[0]);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
          toast.error("Desculpe! Houve um erro ao obter os contratos");
        });
    }

    function mapResponse(aplicacao, data) {
      switch (aplicacao.tipoAplicacao) {
        case "WAYCHEF":
          return ResponseContratoWaychef.mapContratoComItens(data);
        case "WAYBE":
          return ResponseContratoWaychef.mapContratoComItens(data);
        default:
          return [];
      }
    }

    function setTipoAplicacao(tipoAplicacao) {
      let aplicacao = aplicacoesContratadas.find(
        (a) => a.tipoAplicacao === tipoAplicacao
      );

      setAplicacao(aplicacao);
      setLoading(false);
    }

    function setAplicacao(aplicacao) {
      if (aplicacao) {
        // buscarContrato(aplicacao);
        setAplicacaoSelecionada(aplicacao);
        setLoading(false);
      } else {
        setAplicacaoSelecionada(null);
        setLoading(false);
      }
    }

    function ChipStatus({ status }) {
      switch (status) {
        case "ATIVO":
          return (
            <IndicadorStatus
              texto="Ativo"
              cor="verde"
              tyle={{ width: "auto" }}
            />
          );
        case "RECORRENCIA_CANCELADA":
          return (
            <IndicadorStatus
              texto="Cancelado"
              cor="vermelho"
              style={{ width: "auto" }}
            />
          );

        default:
          return (
            <IndicadorStatus
              texto="Não definido"
              cor="vermelho"
              style={{ width: "auto" }}
            />
          );
      }
    }

    function Extras({ aplicacao }) {
      function itensDaAplicacao() {
        switch (aplicacao.tipoAplicacao) {
          case "Geral":
            return (
              <>
                <ItensWaychef
                  aplicacao={aplicacao}
                  irParaPagamento={irParaPagamento}
                  irParaPlano={irParaPlano}
                />
                <ItensWaybe
                  aplicacao={aplicacao}
                  irParaPagamento={irParaPagamento}
                  irParaPlano={irParaPlano}
                />
              </>
            );
          case "WAYCHEF":
            return (
              <ItensWaychef
                aplicacao={aplicacao}
                irParaPagamento={irParaPagamento}
                irParaPlano={irParaPlano}
              />
            );
          case "WAYBE":
            return (
              <ItensWaybe
                aplicacao={aplicacao}
                irParaPagamento={irParaPagamento}
                irParaPlano={irParaPlano}
              />
            );
          default:
            return <h3>{aplicacao.nomeAplicacao}</h3>;
        }
      }

      return (
        <div>
          {/* <ContainerTituloPlano style={{ margin: "40px 0 40px 16px" }}>
            {contrato && contrato?.dataCancelamento && (
              <ContainerSubtituloValor>
                <SubtituloValor>Data do cancelamento: </SubtituloValor>
                <Valor>
                  {formatarDataEHorarioParaPadraoBrasileiro(
                    contrato.dataCancelamento
                  )}
                </Valor>
              </ContainerSubtituloValor>
            )}
          </ContainerTituloPlano> */}

          {itensDaAplicacao()}
        </div>
      );
    }

    function SelecaoTipoAplicacao({ aplicacao, listaAplicacoes }) {
      return (
        <FormControl fullWidth sx={{ m: 1, width: 133 }}>
          <Select
            value={aplicacao?.tipoAplicacao ? aplicacao.tipoAplicacao : ""}
            onChange={(e) => {
              setTipoAplicacao(e.target.value);
            }}
            style={{
              borderRadius: 8,
              backgroundColor: tema.cores.actionActive,
              color: "white",
              height: 32,
              fontWeight: 600,
            }}
          >
            {listaAplicacoes.map((a, index) => (
              <MenuItem
                value={a.tipoAplicacao}
                key={a.nomeAplicacao + "tipoAplicacao" + index}
              >
                {a.nomeAplicacao}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    return (
      <>
        <CabecalhoTituloPlano>
          <div style={{ marginLeft: 16 }}>
            <SelecaoTipoAplicacao
              aplicacao={aplicacaoSelecionada}
              listaAplicacoes={aplicacoesContratadas}
            />
          </div>
        </CabecalhoTituloPlano>

        {loading ? (
          <Carregando />
        ) : (
          aplicacaoSelecionada && <Extras aplicacao={aplicacaoSelecionada} />
        )}
      </>
    );
  }

  function getPaginaAtiva() {
    switch (paginaAtiva) {
      case PAGINA_PAGAMENTO:
        return <PaginaPagamento dadosPagamento={dadosPagamento} />;
      case PAGINA_MEU_PLANO:
        return <PaginaMeuPlano />;
    }
  }

  return getPaginaAtiva();
}
