import styled from "@emotion/styled";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.cores.surfaceBackground1};
  margin: 0px 40px 80px 40px;

  @media (max-width: 768px) {
    margin: 0px 20px 80px 20px;
  }
`;

export const ContainerFundoBranco = styled.div`
  margin-top: 94px;
  width: 100%;
  background-color: ${(props) => props.theme.cores.surface2};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding-top: 26px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 72px;

  @media (max-width: 768px) {
    margin-bottom: 50px;
    margin-top: 24px;
  }
`;

export const ContainerCardsPlanos = styled.div`
  display: flex;
  justify-content: center;
  gap: 32px;
  flex-wrap: wrap;
  margin-bottom: 48px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: unset;
  }
`;

export const TextoContratosCustomizados = styled.p`
  font-size: 33px;
  font-family: "Comfortaa", sans-serif;
  font-weight: 700;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.adaptativo("neutral40", "neutral100")};
  margin-bottom: 24px;
  text-align: center;
`;

export const ContainerPlanosCustomizados = styled.div`
  display: flex;
  justify-content: center;
  gap: 32px;
  flex-wrap: wrap;
  margin-bottom: 48px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerSemContratos = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Hr = styled.hr`
  width: 100%;
  border: 1px solid ${(props) => props.theme.cores.corHr};
  margin-top: 24px;
  margin-bottom: 24px;
`;
