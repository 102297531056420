import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "../../../produtos/promocoes/styles";
import { IconeBotao } from "../../../selecaoEmpresas/styles";
import {
  CabecalhoMudarPagina,
  ContainerFundoBranco,
  ItemPreenchido,
  TextoCabecalhoMudarPagina,
} from "../styles";
import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faArrowDown,
  faEye,
  faFileLines,
  faWarning,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import useTamanhoTela from "../../../../utils/useTamanhoTela";
import { useTheme } from "@emotion/react";
import {
  AlinhamentoTexto,
  AvisoPagamento,
  AvisoPagamentoBilhetagem,
  Bilhetagem,
  BotaoLimparFiltros,
  BotaoPagar,
  BotaoPesquisar,
  CabecalhoContainerContratosFooter,
  CabecalhoContainerDescricaoPagamento,
  CabecalhoContainerPagamento,
  CabecalhoTituloContratosFooter,
  CardContainerEspecial,
  CardPedencias,
  Coluna,
  ColunaInformacoesPagamento,
  ColunaInformacoesPagamentoMobile,
  ContainerBodyContratosFooter,
  ContainerBoleto,
  ContainerBotoes,
  ContainerCardPendencias,
  ContainerCardsMobile,
  ContainerContratosFooter,
  ContainerDescricaoFooter,
  ContainerDescricaoFooterMobile,
  ContainerDescricaoPagamento,
  ColunaCardFaturas,
  ContainerInputs,
  ContainerNumeroPagamento,
  ContainerPagamento,
  ContainerValorFooter,
  ContratoItemPreenchido,
  DataFooter,
  DescricaoColunaInformacoesPagamento,
  LinhaInternaCardPendencias,
  FooterPagamento,
  Hr,
  IconeETextoAvisoPagamento,
  IconeETextoBoleto,
  InformacoesPagamento,
  NumeroPagamento,
  TextoAlterarCartao,
  TextoAvisoPagamento,
  TextoBoleto,
  TextoBotaoLimparFiltros,
  TextoBotaoPesquisar,
  TextoContainerContratosFooter,
  TextoCopiarCodigo,
  TextoDescricaoFooter,
  TextoFaturas,
  TextoPendencias,
  TextoValorFooter,
  TituloCardPendencias,
  TituloColunaInformacoesPagamento,
  TituloDescricaoFooter,
  TituloNumeroPagamento,
  TituloValorFooter,
  ValorFooter,
  ColunaCardPendencias,
  ContainerCardFaturas,
  CardContainer,
  ContainerPaginacao,
  ContainerPaginaAtualTextfield,
  StyledCampoDeSelecao,
  ContainerPaginas,
  IconeBotaoPage,
  IconsFirstAndLastPage,
  BotaoPagina,
  ContainerDetalhes,
  Linha,
  ContainerModalDetalhes,
  CampoModalDetalhes,
  LinhaInternaModalDetalhes,
  TextoNenhumItemEncontrado,
  ContainerNenhumaFaturaEncontrada,
} from "./styles";
import { CampoDeData, CampoDeSelecao } from "../../../../components/CampoInput";
import { IndicadorStatus } from "../../../../components/IndicadorStatus";
import { useEffect, useState } from "react";
import { useApi } from "../../../../contexts/api";
import formatarReal from "../../../../utils/formatarReal";
import { Card } from "@mui/material";
import { toast } from "react-toastify";
import Carregando from "../../../carregando";
import {
  BotaoAzulPreenchido,
  BotaoAzulSemFundo,
  BotaoAzulVazado,
  BotaoVermelhoVazado,
} from "../../../../components/Botao";
import dayjs from "dayjs";
import GerarModalResponsivo from "../../../../components/GerarModalResponsivo";
import { Close, Download, WarningAmber } from "@mui/icons-material";
import RealizarPagamento from "../../../realizarPagamento";
import { set } from "lodash";
import { useAuth } from "../../../../contexts/auth";

const listaStatus = [
  { "titulo": "Pago", "valor": "PAGO" },
  { "titulo": "Pendente", "valor": "PENDENTE" },
  { "titulo": "Cancelado", "valor": "CANCELADO" },
];
export function Fatura({ mostrarPagina }) {
  const { largura } = useTamanhoTela();
  const theme = useTheme();
  const { get, post, realizarLogoff } = useApi();
  const { pagamentoNecessario } = useAuth();

  const [loading, setLoading] = useState(true);
  const [loadingOrdens, setLoadingOrdens] = useState(true);
  const [lodingPendentes, setLodingPendentes] = useState(true);

  const [dataInicial, setDataInicial] = useState(null);
  const [dataFinal, setDataFinal] = useState(null);
  const [status, setStatus] = useState(null);

  const [pagina, setPagina] = useState(0);
  const [itemsPorPagina, setItemsPorPagina] = useState(6);
  const [totalItems, setTotalItems] = useState(6);
  const [totalPaginas, setTotalPaginas] = useState(1);

  const [faturas, setFaturas] = useState([]);
  const [faturasPendentes, setFaturasPendentes] = useState([]);
  const [valorNotaPendente, setValorNotaPendente] = useState(null);

  const [ordemSelecionada, setOrdemSelecionada] = useState(null);
  const [dadosOrdemSelecionada, setDadosOrdemSelecionada] = useState({});
  const [loadingOrdem, setLoadingOrdem] = useState(true);

  const [realizandoPagamento, setRealizandoPagamento] = useState(false);
  const [dadosPagamento, setDadosPagamento] = useState(null);

  function updateDados() {
    setLoading(true);
    pesquisar();
    pesquisarOrdemsPendentes();
  }
  useEffect(() => {
    updateDados();
  }, [pagina, itemsPorPagina]);

  useEffect(() => {
    //Carregar dados da Ordem de Pagamento Selecionada
    setLoadingOrdem(true);
    if (ordemSelecionada && ordemSelecionada !== null && ordemSelecionada.id) {
      get(`pagamento/pagamento-transacao/${ordemSelecionada.id}`)
        .then((res) => {
          setDadosOrdemSelecionada(res.data);
          setLoadingOrdem(false);
        })
        .catch((err) => {
          console.error(err);
          toast.error("Erro ao buscar as informações da Ordem de Pagamento");
          setLoadingOrdem(false);
          setOrdemSelecionada(null);
        });
    }
  }, [ordemSelecionada]);

  async function pesquisarOrdemsPendentes() {
    await post("/pagamento/ordem-pagamento/filtrar-ordem-pagamentos", {
      "tipoStatusPagamento": "PENDENTE",
      "dataInicial": null,
      "dataFinal": null,
      "pagina": 1,
      "tamanhoPagina": 100,
    })
      .then((res) => {
        //Checar se não há pendencias enquanto travado no Pagamento Necessário.
        if (pagamentoNecessario && res.data.content.length === 0) {
          realizarLogoff();
        }

        setFaturasPendentes(res.data.content);
        setLodingPendentes(false);
      })
      .catch((err) => {
        console.error(err);
        toast.error("Erro ao buscar as Notas pendentes");
        setLodingPendentes(false);
      });
  }

  async function pesquisar() {
    setOrdemSelecionada(null);
    setLoadingOrdens(true);
    let notasBilhetagem = {};
    let valorNotaPendente = null;

    await get("contrato/bilhetagem/buscar-todos")
      .then((res) => {
        if (res.status === 400) {
          return;
        }
        res.data.forEach((nota) => {
          if (nota.idOrdemPagamento !== null) {
            notasBilhetagem[nota.idOrdemPagamento] = nota;
          }
        });
        valorNotaPendente = res.data.find(
          (nota) => nota.idOrdemPagamento === null
        );
      })
      .catch((err) => {
        console.error(err);
        toast.error("Erro ao buscar informações de bilhetagem");
        setLoading(false);
      });

    await post("/pagamento/ordem-pagamento/filtrar-ordem-pagamentos", {
      "tipoStatusPagamento": status ? status : null,
      "dataInicial": dataInicial,
      "dataFinal": dataFinal,
      "pagina": pagina + 1,
      "tamanhoPagina": itemsPorPagina,
    })
      .then((res) => {
        setTotalPaginas(res.data.totalPages);
        setTotalItems(res.data.totalElements);

        let faturas = [];
        res.data.content.forEach((fatura) => {
          let faturaObj;
          if (notasBilhetagem[fatura.id]) {
            faturaObj = { ...notasBilhetagem[fatura.id], ...fatura };
          } else {
            faturaObj = { ...fatura };
          }
          faturas.push(faturaObj);
        });

        setFaturas(faturas);
        setValorNotaPendente(valorNotaPendente ? valorNotaPendente : null);
      })
      .catch((err) => {
        console.error(err);
        toast.error("Erro ao buscar informações de bilhetagem");
        setLoading(false);
      });
    setLoadingOrdens(false);
    setLoading(false);
  }

  function fazerPagamentoPendencias() {
    let listaTotal = [];
    let listaDetalhes = [];

    faturasPendentes.forEach((fatura) => {
      listaTotal.push({
        descricao: fatura.tipoOrdemPagamento,
        valor: fatura.valor,
      });
    });

    if (valorNotaPendente && valorNotaPendente?.valorBilhetagem) {
      listaTotal.push({
        descricao: "Bilhetagem",
        valor: valorNotaPendente?.valorBilhetagem,
      });
    }

    function pagarTodas() {
      return post("/pagamento/ordem-pagamento/pagar/ordens-pagamento")
        .then((response) => {
          if (response.status === 200) {
            toast.success("Pagamento feito com sucesso!");

            if (pagamentoNecessario) {
              realizarLogoff();
            } else {
              voltarParaTelaAnterior();
            }
          } else {
            toast.error(
              response.data?.message
                ? response.data.message
                : "Erro ao realizar o pagamento!"
            );
            throw new Error(response.data);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    let dadosPagamento = {
      titulo: "Pagamento de fatura",
      listaTotal: listaTotal,
      listaDetalhes: listaDetalhes,
      realizarPagamentoFuncao: pagarTodas,
    };

    setDadosPagamento(dadosPagamento);
    setRealizandoPagamento(true);
  }

  // function fazerPagamentoFaturas(ordem, dadosOrdem) {
  //   let listaTotal = [
  //     {
  //       descricao: ordem.tipoOrdemPagamento,
  //       valor: ordem.valor,
  //     },
  //   ];

  //   function pagarUnicaFatura() {
  //     return post("/pagamento/ordem-pagamento/pagar/ordens-pagamento")
  //       .then((response) => {
  //         if (response.status === 200) {
  //           toast.success("Pagamento feito com sucesso!");
  //           pesquisarOrdemsPendentes();
  //           voltarParaTelaAnterior();
  //         } else {
  //           toast.error(
  //             response.data?.message
  //               ? response.data.message
  //               : "Erro ao realizar o pagamento!"
  //           );
  //           throw new Error(response.data);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //   }

  //   let dadosPagamento = {
  //     titulo: "Pagamento de Fatura",
  //     listaTotal: listaTotal,
  //     realizarPagamentoFuncao: pagarUnicaFatura,
  //   };

  //   setDadosPagamento(dadosPagamento);
  //   setRealizandoPagamento(true);
  // }

  function CardFatura({ nota }) {
    function getStatus() {
      switch (nota.tipoStatusPagamento.toLowerCase()) {
        case "pago":
          return (
            <IndicadorStatus
              cor="verde"
              texto="Pago"
              style={{ height: 24, width: "min-content" }}
            />
          );

        case "pendente":
          return (
            <IndicadorStatus
              cor="amarelo"
              texto="Pendente"
              style={{ height: 24, width: "min-content" }}
            />
          );
        case "cancelado":
          return (
            <IndicadorStatus
              cor="vermelho"
              texto="Cancelado"
              style={{
                height: 24,

                width: "min-content",
                background: theme.adaptativo(
                  "semanticDanger3",
                  "semanticDanger2"
                ),
                color: theme.adaptativo("semanticDanger2", "semanticDanger3"),
              }}
            />
          );
      }
    }

    return (
      <CardContainer>
        <CabecalhoTituloContratosFooter>
          <TituloCardPendencias style={{ marginBottom: 0, fontSize: 17 }}>
            {nota.tipoOrdemPagamento}
          </TituloCardPendencias>
          {getStatus()}
        </CabecalhoTituloContratosFooter>
        <DataFooter>
          {dayjs(nota.dataVencimento).format("DD/MM/YYYY")}
        </DataFooter>
        <Coluna>
          <LinhaInternaCardPendencias>
            <AlinhamentoTexto>
              {nota.quantidadeNotas && (
                <Bilhetagem>
                  Quantidade:
                  <strong>{nota.quantidadeNotas}</strong>
                </Bilhetagem>
              )}
              {nota.valorBilhetagem && (
                <Bilhetagem>
                  Bilhetagem:
                  <strong>{formatarReal(nota.valorBilhetagem)}</strong>
                </Bilhetagem>
              )}
              <ValorFooter>
                Valor: <strong>{formatarReal(nota.valor)}</strong>
              </ValorFooter>
            </AlinhamentoTexto>
          </LinhaInternaCardPendencias>
          <LinhaInternaCardPendencias style={{ justifyContent: "flex-end" }}>
            <BotaoAzulSemFundo
              texto={"Ver detalhes"}
              onClick={() => setOrdemSelecionada(nota)}
            />
          </LinhaInternaCardPendencias>
        </Coluna>
      </CardContainer>
    );
  }

  function CardPendencia({ nota }) {
    function getStatus() {
      switch (nota.tipoStatusPagamento.toLowerCase()) {
        case "pago":
          return (
            <IndicadorStatus
              cor="verde"
              texto="Pago"
              style={{ height: 24, width: "min-content" }}
            />
          );

        case "pendente":
          return (
            <IndicadorStatus
              cor="amarelo"
              texto="Pendente"
              style={{ height: 24, width: "min-content" }}
            />
          );
        case "cancelado":
          return (
            <IndicadorStatus
              cor="vermelho"
              texto="Cancelado"
              style={{
                height: 24,

                width: "min-content",
                background: theme.adaptativo(
                  "semanticDanger3",
                  "semanticDanger2"
                ),
                color: theme.adaptativo("semanticDanger2", "semanticDanger3"),
              }}
            />
          );
      }
    }

    return (
      <CardContainer>
        <CabecalhoTituloContratosFooter>
          <TituloCardPendencias style={{ marginBottom: 0, fontSize: 17 }}>
            {nota.tipoOrdemPagamento}
          </TituloCardPendencias>
          {getStatus()}
        </CabecalhoTituloContratosFooter>
        <Coluna style={{ gap: 32 }}>
          <DataFooter>
            {dayjs(nota.dataVencimento).format("DD/MM/YYYY")}
          </DataFooter>
          <LinhaInternaCardPendencias>
            <ValorFooter>
              Valor: <strong>{formatarReal(nota.valor)}</strong>
            </ValorFooter>
          </LinhaInternaCardPendencias>
          <LinhaInternaCardPendencias style={{ justifyContent: "flex-end" }}>
            <BotaoAzulSemFundo
              texto={"Ver detalhes"}
              onClick={() => setOrdemSelecionada(nota)}
            />
            {/* <BotaoAzulVazado texto={"Copiar código de barras"} /> */}
          </LinhaInternaCardPendencias>
        </Coluna>
      </CardContainer>
    );
  }

  function Paginacao() {
    const gerarBotoes = (pagina, paginas, maxBotoes = 5) => {
      const botao = (e) => (
        <BotaoPagina
          key={e}
          disabled={e === pagina}
          accent="#000000"
          color={e === pagina ? "#CBE6FF" : "transparent"}
          style={{
            color:
              e === pagina
                ? theme.cores.textoInverso
                : theme.cores.textoSimples,
            backgroundColor:
              e === pagina ? theme.cores.actionPressed : "transparent",
          }}
          selected={e === pagina}
          onClick={() => {
            setPagina(e);
          }}
        >
          {e + 1}
        </BotaoPagina>
      );

      // Quantos botões serão mostrados
      // No máximo maxBotoes, mas pode ser menos devido a quantidade de páginas
      maxBotoes = largura > 767 ? 5 : 3;
      let quantidadeBotoesMostrados = Math.min(paginas, maxBotoes);
      let metadeMaxBotoes = (maxBotoes - 1) / 2;
      let botaoInicial = 0;
      let ultimoBotao = paginas - 1;

      // Booleano
      let todosBotoesSendoMostrados = paginas === quantidadeBotoesMostrados;

      // Booleano
      let chegandoNosUltimosBotoes = pagina + metadeMaxBotoes > ultimoBotao;

      if (!todosBotoesSendoMostrados) {
        let offset = metadeMaxBotoes;

        if (chegandoNosUltimosBotoes)
          offset = offset + (pagina + metadeMaxBotoes - ultimoBotao);

        botaoInicial = Math.max(0, pagina - offset);
      }

      return Array.from({ length: quantidadeBotoesMostrados }, (_, index) =>
        botao(index + botaoInicial)
      );
    };

    return (
      <ContainerPaginacao>
        {largura <= 768 || (
          <ContainerPaginaAtualTextfield>
            <span>Itens por página</span>
            <StyledCampoDeSelecao
              campoVazio={false}
              disabled={totalItems <= 1 ? true : false}
              valor={String(itemsPorPagina ? itemsPorPagina : 1)}
              items={Array.from(
                {
                  length: 10,
                },
                (_, index) => {
                  return (index + 1).toString();
                }
              )}
              style={{
                width: 97,
                margin: 0,
                fontSize: 15,
                textAlign: "center",
                height: "33px",
              }}
              sx={{
                "& .MuiInputBase-root": {
                  padding: "0 5px",
                  margin: 0,
                  marginTop: "20px",
                  height: "33px",
                },
                ".MuiInputBase-input": {
                  fontSize: "15px",
                },
                "& .MuiFormControl-root": {
                  height: "33px",
                },
                "& .MuiSelect-select": {
                  paddingBottom: "10px",
                },
                "& .css-17idfle-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus":
                  {
                    backgroundColor: "transparent",
                  },
              }}
              onChange={(e) => setItemsPorPagina(e.target.value)}
            />
          </ContainerPaginaAtualTextfield>
        )}

        <ContainerPaginas>
          <IconeBotaoPage disabled={pagina === 0}>
            <IconsFirstAndLastPage
              className="material-symbols-rounded"
              onClick={() => {
                if (pagina !== 0) {
                  setPagina(0);
                }
              }}
            >
              first_page
            </IconsFirstAndLastPage>
          </IconeBotaoPage>
          <IconeBotaoPage disabled={pagina === 0}>
            <FontAwesomeIcon
              icon={faAngleLeft}
              color={theme.cores.actionActive}
              style={{ width: 24, height: 24 }}
              onClick={() => {
                if (pagina !== 0) {
                  setPagina(pagina - 1);
                }
              }}
            />
          </IconeBotaoPage>
          {gerarBotoes(
            pagina,
            totalPaginas,
            5
            // dadosTabela.mudarPagina
          )}
          <IconeBotaoPage disabled={pagina === totalPaginas - 1}>
            <FontAwesomeIcon
              icon={faAngleRight}
              color={theme.cores.actionActive}
              style={{ width: 24, height: 24 }}
              onClick={() => {
                if (pagina !== totalPaginas) {
                  setPagina(pagina + 1);
                }
              }}
            />
          </IconeBotaoPage>
          <IconeBotaoPage disabled={pagina === totalPaginas - 1}>
            <IconsFirstAndLastPage
              className="material-symbols-rounded"
              onClick={() => {
                if (pagina !== totalPaginas - 1) {
                  setPagina(totalPaginas - 1);
                }
              }}
            >
              last_page
            </IconsFirstAndLastPage>
          </IconeBotaoPage>
        </ContainerPaginas>
      </ContainerPaginacao>
    );
  }

  function voltarParaTelaAnterior() {
    updateDados();
    setRealizandoPagamento(false);
    setDadosPagamento(null);
    setOrdemSelecionada(null);
    setPagina(0);
  }
  function TelaPagamento() {
    return (
      <RealizarPagamento
        titulo={dadosPagamento.titulo}
        listaTotal={dadosPagamento.listaTotal}
        listaDetalhes={dadosPagamento.listaDetalhes}
        avisoAntesPagamento={dadosPagamento.avisoAntesPagamento}
        avisoSuperiorEsquerda={dadosPagamento.avisoSuperiorEsquerda}
        realizarPagamentoFuncao={dadosPagamento.realizarPagamentoFuncao}
        voltarParaTelaAnterior={() => voltarParaTelaAnterior()}
      />
    );
  }

  return loading ? (
    <Carregando />
  ) : realizandoPagamento ? (
    <TelaPagamento />
  ) : (
    <>
      <ContainerInputs style={{ marginRight: largura > 1013 ? 150 : 0 }}>
        <CampoDeData
          titulo="Selecione um período (a partir de)"
          value={dataInicial}
          onChange={setDataInicial}
          mostrarHora={false}
        />
        <CampoDeData
          titulo="Selecione um período (até)"
          value={dataFinal}
          onChange={setDataFinal}
          mostrarHora={false}
        />
        <CampoDeSelecao
          titulo="Status da transação"
          items={listaStatus}
          onChange={(e) => setStatus(e.target.value)}
          valor={status}
          campoVazio={false}
          referenciaNome={"titulo"}
          referenciaValor={"valor"}
        />
      </ContainerInputs>
      <ContainerBotoes>
        <BotaoVermelhoVazado
          texto={"Limpar Filtros"}
          onClick={() => {
            setDataInicial(null);
            setDataFinal(null);
            setStatus("");
          }}
        />
        <BotaoAzulVazado texto={"Pesquisar"} onClick={pesquisar} />
      </ContainerBotoes>
      {faturasPendentes && faturasPendentes.length > 0 && (
        <Linha style={{ gap: 4 }}>
          <TextoPendencias>Pendências</TextoPendencias>
          <Hr />
        </Linha>
      )}

      {faturasPendentes && faturasPendentes.length > 0 && (
        <ColunaCardPendencias>
          {valorNotaPendente && (
            <AvisoPagamentoBilhetagem>
              <IconeETextoAvisoPagamento>
                <FontAwesomeIcon
                  icon={faWarning}
                  style={{ color: theme.cores.semanticAlert3 }}
                />
                <TextoAvisoPagamento>
                  O Valor da taxa sobre os pedidos pendentes de pagamento é de{" "}
                  {formatarReal(valorNotaPendente?.valorBilhetagem)}. Esse valor
                  será cobrado junto em sua renovação.
                </TextoAvisoPagamento>
              </IconeETextoAvisoPagamento>
            </AvisoPagamentoBilhetagem>
          )}
          <Linha style={{ justifyContent: "flex-end", marginBottom: 24 }}>
            <BotaoAzulPreenchido
              texto={"Fazer o pagamento"}
              onClick={() => fazerPagamentoPendencias()}
            />
          </Linha>
          <ContainerCardPendencias>
            {lodingPendentes && <Carregando />}
            {faturasPendentes &&
              faturasPendentes.map((nota, index) => {
                return <CardPendencia nota={nota} key={index + "nota"} />;
              })}
          </ContainerCardPendencias>
        </ColunaCardPendencias>
      )}
      <Linha style={{ gap: 4 }}>
        <TextoFaturas>Faturas</TextoFaturas>
        <Hr />
      </Linha>
      {loadingOrdens && <Carregando />}
      {!loadingOrdens && (
        <ColunaCardFaturas>
          <ContainerCardFaturas>
            {faturas &&
              faturas.map((nota, index) => {
                return <CardFatura nota={nota} key={index + "nota"} />;
              })}
          </ContainerCardFaturas>
        </ColunaCardFaturas>
      )}
      {faturas.length > 0 && <Paginacao />}
      {faturas.length === 0 && (
        <ContainerNenhumaFaturaEncontrada>
          <TextoNenhumItemEncontrado>
            Nenhuma fatura encontrada
          </TextoNenhumItemEncontrado>
        </ContainerNenhumaFaturaEncontrada>
      )}
      <GerarModalResponsivo
        ativo={ordemSelecionada ? true : false}
        onFechar={() => {
          setOrdemSelecionada(null);
        }}
        children={(close) => (
          <DadosOrdemPagamento
            ordemSelecionada={ordemSelecionada}
            loadingOrdem={loadingOrdem}
            dadosOrdemSelecionada={dadosOrdemSelecionada}
            setOrdemSelecionada={setOrdemSelecionada}
            close={close}
            // fazerPagamentoFaturas={fazerPagamentoFaturas}
          />
        )}
      />
    </>
  );
}

function DadosOrdemPagamento({
  ordemSelecionada,
  loadingOrdem,
  dadosOrdemSelecionada,
  setOrdemSelecionada,
  close,
}) {
  const theme = useTheme();
  const { largura } = useTamanhoTela();

  let formaReduzida = false;
  if (!dadosOrdemSelecionada || dadosOrdemSelecionada?.code == "NOT_FOUND") {
    formaReduzida = true;
  }

  function checkVisibilidade(propriedade) {
    if (largura < 800 && formaReduzida) {
      if (propriedade) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  // function irParaPagamentoFatura() {
  //   fazerPagamentoFaturas(ordemSelecionada, dadosOrdemSelecionada);
  // }

  return loadingOrdem ? (
    <ContainerModalDetalhes>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Carregando />
      </div>
    </ContainerModalDetalhes>
  ) : (
    ordemSelecionada && (
      <ContainerModalDetalhes>
        <Coluna style={{ gap: 32 }}>
          <Linha>
            <h2>{ordemSelecionada.tipoOrdemPagamento}</h2>
            <Close
              style={{
                color: theme.cores.semanticDanger2,
                cursor: "pointer",
              }}
              onClick={() => {
                close();
                setOrdemSelecionada(null);
              }}
            />
          </Linha>

          <Linha>
            <ValorFooter>
              <h5>Número</h5>
              <strong>{ordemSelecionada.id}</strong>
            </ValorFooter>
          </Linha>

          <LinhaInternaModalDetalhes
            $formaReduzida={formaReduzida}
            style={{ gap: 32, justifyContent: "space-between" }}
          >
            <CampoModalDetalhes>
              <h5>NFSE</h5>
              <IconeBotao>
                <Download
                  style={{
                    width: 24,
                    height: 24,
                    color: theme.adaptativo("brand60", "actionActive"),
                  }}
                />
              </IconeBotao>
            </CampoModalDetalhes>

            <CampoModalDetalhes
              visivel={checkVisibilidade(ordemSelecionada.dataVencimento)}
            >
              {ordemSelecionada.dataVencimento && (
                <>
                  <h5>Data</h5>
                  {dayjs(ordemSelecionada.dataVencimento).format("DD/MM/YYYY")}
                </>
              )}
            </CampoModalDetalhes>
            <CampoModalDetalhes
              visivel={checkVisibilidade(ordemSelecionada.codigoAutorizacao)}
            >
              {dadosOrdemSelecionada.codigoAutorizacao && (
                <>
                  <h5>Cód. Autorização</h5>
                  {dadosOrdemSelecionada.codigoAutorizacao}
                </>
              )}
            </CampoModalDetalhes>
            <CampoModalDetalhes
              visivel={checkVisibilidade(ordemSelecionada.nsu)}
            >
              {dadosOrdemSelecionada.nsu && (
                <>
                  <h5>NSU</h5>
                  {dadosOrdemSelecionada.nsu}
                </>
              )}
            </CampoModalDetalhes>
            <CampoModalDetalhes
              visivel={checkVisibilidade(ordemSelecionada.tid)}
            >
              {dadosOrdemSelecionada.tid && (
                <>
                  <h5>TID</h5>
                  {dadosOrdemSelecionada.tid}
                </>
              )}
            </CampoModalDetalhes>
            <CampoModalDetalhes
              visivel={checkVisibilidade(ordemSelecionada.valor)}
            >
              {ordemSelecionada.valor && (
                <>
                  <h5>Valor</h5>
                  <strong>{formatarReal(ordemSelecionada.valor)}</strong>
                </>
              )}
            </CampoModalDetalhes>
          </LinhaInternaModalDetalhes>
          {(ordemSelecionada.quantidadeNotas ||
            ordemSelecionada.valorBilhetagem) && (
            <LinhaInternaModalDetalhes
              style={{ justifyContent: "flex-start", gap: 16 }}
            >
              <CampoModalDetalhes
                visivel={checkVisibilidade(ordemSelecionada.valor)}
              >
                {ordemSelecionada.quantidadeNotas && (
                  <>
                    <h5>Quantidade</h5>
                    <strong>{ordemSelecionada.quantidadeNotas}</strong>
                  </>
                )}
              </CampoModalDetalhes>
              <CampoModalDetalhes
                visivel={checkVisibilidade(ordemSelecionada.valor)}
              >
                {ordemSelecionada.valorBilhetagem && (
                  <>
                    <h5>Bilhetagem</h5>
                    <strong>
                      {formatarReal(ordemSelecionada.valorBilhetagem)}
                    </strong>
                  </>
                )}
              </CampoModalDetalhes>
            </LinhaInternaModalDetalhes>
          )}
          <Linha>
            {(!dadosOrdemSelecionada.idOrdemPagamento ||
              !dadosOrdemSelecionada.tid ||
              !dadosOrdemSelecionada.nsu) && (
              <AvisoPagamento>
                <IconeETextoAvisoPagamento>
                  <WarningAmber style={{ color: theme.cores.semanticAlert3 }} />
                  <TextoAvisoPagamento>
                    {dadosOrdemSelecionada.mensagemRetorno ||
                    dadosOrdemSelecionada.message
                      ? dadosOrdemSelecionada.mensagemRetorno ||
                        dadosOrdemSelecionada.message
                      : "Pagamento não realizado"}
                  </TextoAvisoPagamento>
                </IconeETextoAvisoPagamento>
                {/* <TextoAlterarCartao>Alterar Cartão</TextoAlterarCartao> */}
              </AvisoPagamento>
            )}
          </Linha>
          <Linha
            style={{
              gap: 24,
              justifyContent: "flex-end",
            }}
          >
            <BotaoVermelhoVazado
              texto={"Voltar"}
              onClick={() => {
                close();
                setOrdemSelecionada(null);
              }}
            />
            {/* {ordemSelecionada.tipoStatusPagamento.toLowerCase() !== "pago" && (
              <BotaoAzulPreenchido
                texto={"Fazer o pagamento"}
                onClick={() => {
                  close();
                  irParaPagamentoFatura();
                }}
              />
            )} */}
          </Linha>
        </Coluna>
      </ContainerModalDetalhes>
    )
  );
}
