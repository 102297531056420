import { Document, Font, PDFViewer, Page, Text, View, Image } from "@react-pdf/renderer";
import styles from "./stylesPdf";
import dayjs from "dayjs";
import { useAuth } from "../../../../../contexts/auth";
import { useSearchParams } from "react-router-dom";
import RobotoBold from "../../../../../assets/fonts/Roboto/Roboto-Bold.ttf";
import Comfortaa from '../../../../../assets/fonts/Comfortaa/Comfortaa_Regular.ttf'
import ComfortaaBold from '../../../../../assets/fonts/Comfortaa/Comfortaa_Bold.ttf'
import { useEffect, useState } from "react";
import Logo from '../../../../../assets/images/logo-pdf-relatorio.png'
import { useApi } from "../../../../../contexts/api";

export default function RelatorioPdf() {
    const [dadosFiltro, setDadosFiltro] = useState([]);
    const [loading, setLoading] = useState(true)

    const [searchParams] = useSearchParams();
    const { dadosAuth } = useAuth();
    const { post } = useApi();

    Font.register({
        family: "RobotoBold",
        src: RobotoBold,
    });

    Font.register({
        family: "ComfortaaBold",
        src: ComfortaaBold
    })

    Font.register({
        family: "Comfortaa",
        src: Comfortaa
    })


    Font.registerHyphenationCallback((word) => [word]);

    const [dados, setDados] = useState({})

    dayjs.locale("pt-br");

    const estiloBorda = "0.8px groove #888";


    const dataCompletaEscrita = () => {
        const data = new Date();

        const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            timeZoneName: "short",
        };

        const formatter = new Intl.DateTimeFormat("pt-BR", options);
        return formatter.format(data);
    };

    const funcaoFormatarValor = (valor) => {
        const formatter = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
        });

        return formatter.format(valor);
    };

    const funcaoSomarTotal = (referencia, dados) => {
        if (referencia === "totalPorcentagem") {
            return "100%"
        }


        let total = 0;
        dados.forEach((dado) => {
            total += dado[referencia] ? dado[referencia] : 0;
        });
        return referencia === "quantidadeItem" ? total
            : (referencia === "precoVenda" || referencia === "precoCusto")
                ? `${funcaoFormatarValor(total / dadosFiltro?.topProdutosDTO?.length)}`
                : `${funcaoFormatarValor(total)}`;
    };

    const titulosDetalhadoss = [
        { referencia: "apelidoPessoaEmpresa", titulo: "Loja", largura: "15%" },
        {
            referencia: "descricaoGrupo",
            titulo: "Grupo",
            largura: "18%",
        },
        { referencia: "descricaoNotaItem", titulo: "Descricao", largura: "18%" },
        {
            referencia: "quantidadeItem",
            titulo: "Qtd.vendidos",
            largura: "12%",
            funcaoTotalRodape: funcaoSomarTotal,
        },
        {
            referencia: "precoCusto",
            titulo: "P.Custo",
            largura: "10%",
            funcaoTextoCelula: funcaoFormatarValor,
            funcaoTotalRodape: funcaoSomarTotal,
        },
        {
            referencia: "margemLucro",
            titulo: "Margem de lucro (%)",
            largura: "12%",
        },
        {
            referencia: "precoVenda",
            titulo: "P.Venda médio",
            largura: "12%",
            funcaoTextoCelula: funcaoFormatarValor,
            funcaoTotalRodape: funcaoSomarTotal,
        },
        {
            referencia: "precoTotal",
            titulo: "Total (R$)",
            largura: "15%",
            funcaoTextoCelula: funcaoFormatarValor,
            funcaoTotalRodape: funcaoSomarTotal,
        },
        {
            referencia: "totalPorcentagem",
            titulo: "Total (%)",
            largura: "10%",
            funcaoTotalRodape: funcaoSomarTotal
        }
    ];

    function CelulasTitulo() {
        try {
            return (
                <View style={{ ...styles.linha, marginBottom: 5 }} wrap={false}>
                    {titulosDetalhadoss.map((titulo, index) => {
                        return (
                            <View
                                key={`titulo+${titulo}+${index}`}
                                style={{
                                    ...styles.celulaTabelaTitulo,
                                    width: titulo.largura ? titulo.largura : null,
                                    borderRight: estiloBorda,
                                    borderLeft: index === 0 ? estiloBorda : null,
                                    borderBottom: estiloBorda,
                                    borderTop: estiloBorda,
                                }}
                            >
                                <Text style={styles.celulaTabelaTextoTitulo}>
                                    {titulo.titulo}
                                </Text>
                            </View>
                        );
                    })}
                </View>
            )
        } catch (error) {

            window.location.href = "/relatorios/cashback";
        }

    }

    function gerarLinhas(dadosApi) {
        return (
            <View wrap={true}>
                {dadosApi.topProdutosDTO.map((dado, indexDado) => (
                    <View key={`linha+${indexDado}`} style={styles.linha} wrap={false}>
                        {titulosDetalhadoss.map((titulo, index) => {
                            return (
                                <View
                                    key={`celula+${titulo}+${index}`}
                                    style={{
                                        ...styles.celulaTabela,
                                        width: titulo.largura ? titulo.largura : null,
                                        backgroundColor: indexDado % 2 === 0 ? "#f2f2f2" : "#fff",
                                        borderRight: estiloBorda,
                                        borderLeft: index === 0 ? estiloBorda : null,
                                        borderTop: estiloBorda,
                                        borderBottom: estiloBorda,
                                        marginTop: -2,
                                    }}
                                >
                                    {(titulo.referencia !== "totalPorcentagem" && titulo.referencia !== "margemLucro") ? (
                                        <Text style={styles.celulaTabelaTexto}>
                                            {titulo.funcaoTextoCelula
                                                ? titulo.funcaoTextoCelula(dado[titulo.referencia])
                                                : dado[titulo.referencia]}
                                        </Text>
                                    ) : titulo.referencia === "margemLucro" ? (
                                        <Text style={styles.celulaTabelaTexto}>
                                            {dado.margemLucro + "%"}
                                        </Text>    
                                    ) : (
                                        <Text style={styles.celulaTabelaTexto}>
                                            {((dado.precoTotal / dados?.resumoTotal) * 100).toFixed(2) + "%"}
                                        </Text>
                                    )}
                                </View>
                            );
                        })}
                    </View>
                ))}
                <LinhaFinalComTotal dados={dadosApi.topProdutosDTO} />
            </View>
        )
    }

    function LinhaFinalComTotal({ dados }) {
        return (
            <View
                style={{
                    ...styles.linha,
                    backgroundColor: "transparent",
                    marginTop: 2,
                    marginBottom: 30,
                }}
                wrap={false}
            >
                {titulosDetalhadoss.map((titulo, index) => {
                    return titulo.funcaoTotalRodape ? (
                        <View
                            key={`celula+${titulo}+${index}`}
                            style={{
                                ...styles.celulaTabelaTotal,
                                width: titulo.largura ? titulo.largura : null,
                            }}
                        >
                            <Text style={styles.celulaTabelaTextoTitulo}>
                                {titulo.funcaoTotalRodape(titulo.referencia, dados)}
                            </Text>
                        </View>
                    ) : (
                        <View
                            key={`celula+total+${titulo}+${index}`}
                            style={{
                                width: titulo.largura ? titulo.largura : null,
                            }}
                        ></View>
                    );
                })}
                <Text style={styles.celulaTabelaTextoTotalFlutuante}>
                    Soma total dos Valores:
                </Text>
            </View>
        )
    }

    let resumoTotal = 0;


    for (let i = 0; i < dadosFiltro.length; i++) {
        let item = dadosFiltro[i]
        resumoTotal += item.precoTotal;
    }

    async function buscarDadosRelatorioTop10(dadosPDF) {
        try {
            const response = await post('/relatorios/relatorio/top-produtos', {
                listaEmpresas: Object.keys(dadosPDF.lojasSelecionadasEstado),
                dataInicio: dayjs(dadosPDF.dataInicio).format("YYYY-MM-DD"),
                dataFim: dayjs(dadosPDF.dataFim).format("YYYY-MM-DD"),
                tipoRelatorio: dadosPDF.tipoRelatorio
            })
            setDadosFiltro(response.data)
        } catch (error) {
            window.location.href = "/analise-relatorios/produtos-vendidos/analise-relatorios/produtos-vendidos/top10"
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        pegarDadosStorage()
    }, []);

    const pegarDadosStorage = async () => {
        try {
            const dadosPDF = JSON.parse(localStorage.getItem('relatoriotop10'));
            setDados(dadosPDF.dados);
            buscarDadosRelatorioTop10(dadosPDF.dados);

            //Limpar storage após recuperar dados
            localStorage.removeItem('relatoriotop10');

        } catch (error) {

        }
    }

    return (
        <>
            {loading ? (
                <></>
            ) : (
                <PDFViewer style={{ width: "100vw", height: "100vh" }}>
                    <Document title={`Relatório Top 10 ${dayjs().format('DD/MM/YYYY HH:mm:ss')}`}>
                        <Page size="A4" style={styles.page}></Page>
                        <View style={styles.coluna}>
                            <View style={styles.header}>
                                <Text style={styles.headerTitulo}>
                                    {dadosAuth.empresaDTO.apelido}
                                </Text>
                                <Text style={styles.headerRelatorio}>Relatório Top 10</Text>
                            </View>
                            <View style={styles.headerBottom} />
                            <View style={{ ...styles.section, minHeight: 150 }}>
                                <Text style={styles.filtrosTitulo}>Filtros aplicados</Text>
                                <br />
                                {(dados.dataInicio && dados.dataFim) ? (
                                    <Text style={styles.filtros}>
                                        Período: {dayjs(dados.dataInicio).format("DD/MM/YYYY HH:mm")} até
                                        {dayjs(dados.dataFim).format(" DD/MM/YYYY HH:mm")}
                                    </Text>
                                ) : null}
                                <br style={{ marginBottom: 6 }} />
                                {dados.lojasSelecionadasEstado && Object.keys(dados.lojasSelecionadasEstado).length > 0 && (
                                    <Text style={styles.filtros}>
                                        Lojas:{" "}
                                        {Object.values(dados.lojasSelecionadasEstado)
                                            .map((loja, index) => {
                                                return loja.apelido;
                                            })
                                            .join(", ")}
                                    </Text>
                                )}
                            </View>
                            <CelulasTitulo />
                            {gerarLinhas(dadosFiltro)}
                            <View style={styles.footer}>
                                <View style={styles.footerLinha}>
                                    <Text style={styles.footerTitulo}>ERP Waybe</Text>
                                    <Image source={Logo} style={styles.logo} />
                                </View>
                                <View style={styles.footerLinha}>
                                    <Text style={styles.footerTexto}>
                                        Nome do usuário: <Text style={styles.footerUsuario}>{dadosAuth.usuarioLogadoDTO.nome}</Text>
                                    </Text>
                                </View>
                                <View style={styles.footerLinha} fixed={true}>
                                    <Text style={styles.footerTexto} fixed={true}>
                                        {dataCompletaEscrita()}
                                    </Text>
                                    <Text
                                        style={styles.footerTexto}
                                        fixed={true}
                                    >Página 1 de 1</Text>
                                </View>
                            </View>
                        </View>
                    </Document>
                </PDFViewer>
            )}
        </>
    )
}
