import styled from "@emotion/styled";

export const ContainerFundoBranco = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.cores.surface2};
  border-radius: 16px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  margin-bottom: 146px;

  @media (max-width: 768px) {
    margin-bottom: 96px;
    padding: 8px;
  }
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.cores.surfaceBackground1};
  margin: 0px 40px 80px 40px;

  @media (max-width: 768px) {
    margin: 0px 20px 80px 20px;
  }
`;

export const CabecalhoMudarPagina = styled.header`
  background-color: ${(props) => props.theme.adaptativo("brand10", "brand70")};
  height: 72px;
  display: flex;
  align-items: center;
  gap: 48px;
  border-radius: 16px 16px 0px 0px;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 29px;
  padding: 16px 24px;
`;

export const ItemPreenchido = styled.div`
  background: ${(props) => props.theme.cores.actionActive};
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  padding: 4px 32px;

  p {
    color: #ffffff;
  }
`;

export const TextoCabecalhoMudarPagina = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.adaptativo("actionActive", "neutral100")};
`;

export const TextoCorLinkNovoPlano = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0.5px;

  p:hover {
    color: ${(props) => props.theme.adaptativo("actionHover2", "actionHover")};
  }

  p {
    color: ${(props) => props.theme.adaptativo("actionActive", "actionActive")};
    text-decoration: underline;
  }
`;
