import { StyleSheet } from "@react-pdf/renderer";

const alturaCelula = 20;

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
    display: "block",
    width: "210mm",
    // height: "297mm",
    paddingTop: 0,
  },
  section: {
    marginTop: 3,
    marginBottom: 5,
    marginHorizontal: 2,
    whiteSpace: "normal",
    borderCollapse: "collapse"
  },
  sectionTotal: {
    marginTop: 3,
    marginBottom: 5,
    // marginHorizontal: 2,
    whiteSpace: "normal",
    borderCollapse: "collapse"
  },
  sectionFiltros: {
    marginTop: 3,
    marginBottom: 20,
    marginHorizontal: 20,  overflow: "hidden", 
    // textOverflow: "ellipsis",
  },
  section2: {
    // marginTop: 3,
    // marginBottom: 5,
    marginHorizontal: 2,
    whiteSpace: "normal",
    borderCollapse: "collapse"
  },
  section3: {
    marginTop: 15,
    // marginBottom: 30,
    marginHorizontal: 2,
    whiteSpace: "normal",
    borderCollapse: "collapse"
  },
  section2: {
    // marginTop: 3,
    // marginBottom: 5,
    marginHorizontal: 2,
    whiteSpace: "normal",
    borderCollapse: "collapse"
  },
  sectionTitulos: {
    marginTop: 10,
    marginBottom: 3,
    marginHorizontal: 20,
  },

  sectionLinhas: {
    marginTop: 5,
    marginHorizontal: 20,
  },
  coluna: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  header: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    height: 97,
    paddingLeft: 24,
    paddingTop: 24,
    paddingBottom: 21,
    gap: 14,
    width: "100%",
    backgroundColor: "#D1EBFA",
    paddingHorizontal: 20,
    flexShrink: 0,
    marginTop: -11
  },
  headerTitulo: {
    fontSize: 19,
    color: "#000",
    fontWeight: 700,
    fontFamily: "Comfortaa",
    lineHeight: "normal",
    letterSpacing: 0.15
  },
  headerRelatorio: {
    fontSize: 16,
    color: "#000",
    fontWeight: 400,
    textAlign: "center",
    textOverflow: "ellipsis",
    whiteSpace: "pre-wrap",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    fontFamily: "Comfortaa",
  },
  headerBottom: {
    display: "flex",
    height: 5,
    width: "100%",
    backgroundColor: "#1E86D1",
  },
  filtrosTitulo: {
    fontSize: 16,
    fontFamily: "Comfortaa",
    fontWeight: "ultrabold",
    marginTop: 12,
    marginBottom: 8
  },
  filtros: {
    fontSize: 12,
    marginBottom: 4,
    fontFamily: "Comfortaa",
    fontWeight: 600,
    maxHeight: 50,
  },
  celulaTabelaTitulo: {
    padding: 5,
    // marginRight: -1,
    display: "flex",
    // fontSize: 12,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    border: "0.8px groove #888",
    borderCollapse: "collapse",
  },
  celulaFooter: {
    // padding: 5,
    // marginRight: -1,
    display: "flex",
    // fontSize: 12,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    // border: "0.8px groove #888",
    // borderCollapse: "collapse",
  },
  celulaTabelaTituloPlanoContas: {
    padding: 5,
    // marginRight: -1,
    display: "flex",
    // fontSize: 12,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    border: "0.8px groove #888",
    backgroundColor: "#d9d5d4",
    borderCollapse: "collapse",
  },
  linha: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignSelf: "center",
    paddingHorizontal: 20,
    // overflow: "hidden",
  },
  celulaTabelaTextoTitulo: {
    fontSize: 10,
    fontWeight: 700,
    textAlign: "center",
    textOverflow: "ellipsis",
    // whiteSpace: "pre-wrap",
    display: "flex",
    // flexDirection: "row",
    // flexWrap: "nowrap",
    fontFamily: "RobotoBold",
    justifyContent: "center",
    alignItems: "center"
  },
  textoTotalPlanoContas: {
    fontSize: 10,
    fontWeight: 700,
    textOverflow: "ellipsis",
    fontFamily: "RobotoBold",
  
  },
  textoTotalPlanoContasValor: {
    fontSize: 8,
    fontWeight: 700,
    textOverflow: "ellipsis",
    fontFamily: "RobotoBold",
  
  },
  textoTotalPlanoContasValorDireita: {
    fontSize: 8,
    fontWeight: 700,
    textOverflow: "ellipsis",
    fontFamily: "RobotoBold",
  
  },
  celulaTabela: {
    padding: 2,
    // marginRight: -1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: alturaCelula,
    // border: "0.8px groove #888",
    maxHeight: alturaCelula * 2,
  },
  celulaTabelaTotal: {
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: alturaCelula,
  },
  blockTextoTabela: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 2,
  },
  celulaTabelaTexto: {
    fontSize: 8,
    display: "flex",
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // display: "flex",
    // flexDirection: "row",
    flexDirection: "column",
    flexWrap: "wrap"
  },
  celulaTabelaTextoTotalFlutuante: {
    fontSize: 8,
    width: "100%",
    height: alturaCelula,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "left",
    top: 5,
    fontWeight: "bold",
    position: "absolute",
    backgroundColor: "transparent",
  },
  footer: {
    display: "flex",
    height: 97,
    backgroundColor: "#D1EBFA",
    position: "absolute",
    paddingTop: 15,
    paddingBottom: 24,
    paddingLeft: 22,
    paddingRight: 24,
    bottom: 0,
    left: 0,
    right: 0,
    marginTop: 20,
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: 6,
    zIndex: -1,
  },
  footerTitulo: {
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Comfortaa",
  },
  footerTexto: {
    fontSize: 12,
    color: "#051F2E",
    fontWeight: 400,
    fontFamily: "Comfortaa",
    lineHeight: "133.7%",
    letterSpacing: 0.5,
  },
  footerUsuario: {
    fontFamily: "ComfortaaBold",
    fontWeight: 700,
    lineHeight: "133.7%",
    letterSpacing: 0.5,
    fontSize: 12
  },
  logo : {
    width: 60,
    height: 12,
  },
  footerLinha: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
});

export default styles;