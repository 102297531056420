import styled from "@emotion/styled";
import "transition-style";

export const PopupCustomizado = styled.div`
  position: fixed;
  inset: 0px;
  display: flex;
  z-index: 999;
  pointer-events: auto;
  justify-content: center;
  align-items: center;    
  flex-direction: column;
  background: ${(props) => props.theme.temaDarkAtivo ? `rgba(0,0,0,0.4 )` : `rgba(255,255,255,0.4 )`};
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 5.5px );
  -webkit-backdrop-filter: blur( 5.5px );
`;

export const Carregando = styled.div`
  width: 254px;
  padding: 12px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: rgba(30, 134, 209, 1);
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
          -webkit-animation:spin 1s linear infinite;
    -moz-animation:spin 1s linear infinite;
    animation:spin 1s linear infinite;

    @-moz-keyframes spin { 
        100% { -moz-transform: rotate(360deg); } 
    }
    @-webkit-keyframes spin { 
        100% { -webkit-transform: rotate(360deg); } 
    }
    @keyframes spin { 
        100% { 
            -webkit-transform: rotate(360deg); 
            transform:rotate(360deg); 
        } 
    }

`;


export const Logo = styled.img`
  width: 135.44px;
  height: 93.47px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -46.73px 0 0 -67.72px;
`
