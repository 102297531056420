export const ResponseAplicacoesContratadas = {
    map(data) {
        return data.map(c => mapAplicacaoContratada(c));
    },
};


function mapAplicacaoContratada(plano) {
    return {
        nomeAplicacao: plano.nomeAplicacao,
        uri: plano.uri,
        tipoAplicacao: plano.tipoAplicacaoContrato,
        possuiExtras: plano.possuiExtras
    }
}



