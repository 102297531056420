import { useTheme } from "@emotion/react";
import {
  BotaoAzulVazado,
  BotaoVerdePreenchido,
  BotaoVermelhoPreenchido,
  BotaoVermelhoVazado,
} from "../../../../../components/Botao";
import { GerarModalConfirmacao } from "../../../../../components/PopUpConfirmacao";
import { TextoAdicionar, TextoCancelarRecorrencia } from "../styles";
import formatarReal from "../../../../../utils/formatarReal";
import { useEffect, useState } from "react";
import { CampoDeInputNumericoComSetas } from "../../../../../components/CampoInput";
import { useApi } from "../../../../../contexts/api";
import { toast } from "react-toastify";

export function ModalAdicionarQuantidade({
  item,
  adicionarExtra,
  buscarExtras,
  tipoItem,
}) {
  function ModalCancelar() {
    const { del } = useApi();
    const [quantidade, setQuantidade] = useState(0);

    const quantidadeContratadaAtual =
      item?.quantidadeContratada - item?.quantidadeRecorrenciaCancelada;

    const quantidadeTotal =
      quantidadeContratadaAtual + item.camposTela.adicionadoAoCarrinho;

    useEffect(() => {
      if (quantidade > quantidadeTotal) {
        setQuantidade(quantidadeTotal);
      }
    }, [quantidade]);

    function cancelar(fechar) {
      let valorRequest = 0;
      console.log(quantidade, quantidadeTotal, quantidadeContratadaAtual);

      if (quantidade >= quantidadeTotal && quantidadeContratadaAtual > 0) {
        valorRequest = quantidadeContratadaAtual;
      } else {
        if (item.camposTela.adicionadoAoCarrinho >= quantidade) {
          adicionarExtra(
            item.idItemConfiguracao,
            item.camposTela.adicionadoAoCarrinho - quantidade
          );

          fechar();
        } else {
          valorRequest = quantidade;
        }
      }

      if (valorRequest > 0) {
        del(
          `/contrato/consumidor/${tipoItem}/empresa-contrato/item/extra/${item.idItemConfiguracao}/${valorRequest}`
        )
          .then((response) => {
            if (response.status === 200) {
              toast.success("Recorrência cancelada com sucesso!");
              fechar();
              buscarExtras();
            } else {
              throw new Error(response);
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error("Desculpe! Houve um erro ao cancelar a recorrência");
            fechar();
            buscarExtras();
          });
      } else {
        fechar();
      }
    }

    return (
      <GerarModalConfirmacao
        trigger={
          <TextoCancelarRecorrencia
            onClick={cancelar}
            style={{
              cursor: "pointer",
              marginRight: 16,
            }}
          >
            Cancelar
          </TextoCancelarRecorrencia>
        }
        titulo={`Remover quantidade ${item.nomeExtra}`}
        subTitulo={
          <div style={{ marginLeft: 0, marginTop: "-26px" }}>
            <p style={{ fontSize: 18, lineHeight: "normal", marginBottom: 24 }}>
              A quantidade definida a seguir será removida da quantidade atual.
            </p>
            <p style={{ fontSize: 18, lineHeight: "normal", marginBottom: 24 }}>
              Valor Unitário:
              <span
                style={{
                  marginLeft: 4,
                }}
              >
                {formatarReal(item.valorUnitario)}
              </span>
            </p>
            <p style={{ fontSize: 18, lineHeight: "normal", marginBottom: 24 }}>
              Quantidade contratada:
              <span
                style={{
                  marginLeft: 4,
                }}
              >
                {item?.quantidadeContratada -
                  item?.quantidadeRecorrenciaCancelada +
                  item?.camposTela?.adicionadoAoCarrinho}
              </span>
            </p>
          </div>
        }
        botaoCancelar={(fechar) => (
          <BotaoVermelhoVazado
            texto="Cancelar"
            tamanho="MD"
            onClick={fechar}
            style={{
              minWidth: "150px",
              padding: "0px 0px",
              userSelect: "none",
            }}
          />
        )}
        botaoConfirmar={(fechar) => (
          <BotaoVermelhoPreenchido
            style={{
              minWidth: "150px",
              padding: "0px 0px",
              userSelect: "none",
            }}
            texto="Confirmar"
            tamanho="MD"
            onClick={() => {
              cancelar(fechar);
            }}
          />
        )}
        campoInput={
          <CampoDeInputNumericoComSetas
            width="100%"
            label="Quantidade"
            maxValue={quantidadeTotal}
            style={{ userSelect: "none" }}
            value={quantidade}
            onChange={(e) => {
              setQuantidade(e);
            }}
          />
        }
      />
    );
  }

  function ModalAdicionar() {
    const [quantidade, setQuantidade] = useState(0);

    return (
      <GerarModalConfirmacao
        trigger={
          <TextoAdicionar
            style={{
              cursor: "pointer",
            }}
          >
            Adicionar
          </TextoAdicionar>
        }
        titulo={`Adicionar quantidade ${item.nomeExtra}`}
        subTitulo={
          <div style={{ marginLeft: 0, marginTop: "-26px" }}>
            <p style={{ fontSize: 18, lineHeight: "normal", marginBottom: 24 }}>
              A quantidade definida a seguir será somada a quantidade atual.
            </p>
            <p style={{ fontSize: 18, lineHeight: "normal", marginBottom: 24 }}>
              Valor Unitário:
              <span
                style={{
                  marginLeft: 4,
                }}
              >
                {formatarReal(item.valorUnitario)}
              </span>
            </p>
            <p style={{ fontSize: 18, lineHeight: "normal", marginBottom: 24 }}>
              Quantidade já contratada:
              <span
                style={{
                  marginLeft: 4,
                }}
              >
                {item.quantidadeContratada -
                  item.quantidadeRecorrenciaCancelada}
              </span>
            </p>
          </div>
        }
        botaoCancelar={(fechar) => (
          <BotaoVermelhoVazado
            texto="Cancelar"
            tamanho="MD"
            onClick={fechar}
            style={{
              minWidth: "150px",
              padding: "0px 0px",
              userSelect: "none",
            }}
          />
        )}
        botaoConfirmar={(fechar) => (
          <BotaoVerdePreenchido
            style={{
              minWidth: "150px",
              padding: "0px 0px",
              userSelect: "none",
            }}
            texto="Adicionar"
            tamanho="MD"
            onClick={() => {
              adicionarExtra(item.idItemConfiguracao, quantidade);
            }}
          />
        )}
        campoInput={
          <CampoDeInputNumericoComSetas
            width="100%"
            label="Quantidade"
            style={{ userSelect: "none" }}
            value={quantidade}
            onChange={(e) => {
              setQuantidade(e);
            }}
          />
        }
      />
    );
  }

  return item?.camposTela?.adicionadoAoCarrinho ||
    item?.quantidadeContratada - item?.quantidadeRecorrenciaCancelada > 0 ? (
    <div style={{ display: `flex`, width: "180px" }}>
      <ModalCancelar />
      <div style={{ marginRight: 16, marginTop: 4 }}>/</div>
      <ModalAdicionar />
    </div>
  ) : (
    <ModalAdicionar />
  );
}
