import { Accordion, AccordionDetails, TextField } from "@mui/material";
import Popup from "reactjs-popup";
import styled from "@emotion/styled";
import Chip from "../../../../components/Chip";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.cores.surfaceBackground1};
  margin: 0px 40px 80px 40px;

  @media (max-width: 768px) {
    margin: 0px 16px 80px 16px;
  }

  .desabilitado {
    color: ${(props) => props.theme.cores.textActionDisable};
    background-color: ${(props) => props.theme.cores.actionDisable};
    fill: ${(props) => props.theme.cores.textActionDisable};
    border: 1px solid ${(props) => props.theme.cores.actionDisable};
  }
`;

export const CorpoCard = styled.div`
  height: 274px;
  min-width: 370px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.16);

  flex: 1 0 0;
  padding: 24px;
  color: ${(props) => props.theme.adaptativo("netral10", "neutral100")};

  @media (max-width: 412px) {
    padding: 12px 12px;
    min-width: 0px;
  }
`;

export const ContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  color: red;

  @media (max-width: 768px) {
    gap: 8px;
    margin-bottom: 48px;
  }
`;

export const ContainerCards = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;

  overflow-x: scroll;

  @media (max-width: 768px) {
    flex-direction: column;
    /* overflow-x: unset; */
  }
`;

export const ContainerItemCard = styled.div`
  display: flex;
  gap: 8px;

  p {
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: ${(props) => props.theme.adaptativo("grayScale600", "textoSimples")};

    @media (max-width: 378px) {
      font-size: 15px;
    }
  }
`;

export const Linha = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 25px;
`;

export const TituloCard = styled.h2`
  font-family: Comfortaa;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
`;

export const CardFooter = styled.h2`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const Valor = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: rgba(30, 168, 52, 1);

  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.25px;
  }
`;

export const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  margin: 0;
  margin-bottom: 0;
  background-color: ${(props) => props.theme.adaptativo("brand10", "brand70")};

  svg {
    color: ${(props) => props.theme.textoContrato};
  }
`;

export const PopupCustomizado = styled(Popup)`
  &-content {
    background-color: ${(props) => props.theme.cores.surface2};
    height: 430px;
    width: 70vw;
    border-radius: 16px;
    overflow-y: scroll;
    border: none;
    width: 868px;
  }

  &-overlay {
  }
`;

export const ContainerPopup = styled.div`
  padding-top: 34px;
  padding-left: 21px;
  padding-bottom: 39px;
  padding-right: 24px;
`;

export const Hr = styled.hr`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.cores.corHr};
  margin-bottom: 36px;
`;

export const FooterCard = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 375px) {
    width: 100%;
    flex-direction: column-reverse;
    gap: 12px;
    justify-content: center;
  }
`;

export const LinhaBotoesInformacoesGerais = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 16px;
  flex-direction: row;

  @media (max-width: 375px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const Coluna = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ContainerAviso = styled.div`
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  gap: 16px;
  margin-top: 44px;
  background: ${({ theme }) => (theme.temaDarkAtivo ? "#ECC300" : "#FFE17B")};

  @media (max-width: 924px) {
    margin-bottom: 32px;
  }
`;

export const TextoAviso = styled.p`
  font-size: 15px;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0.5px;
  color: black;
`;

export const NomeEmpresa = styled.h1`
  font-size: 26px;
  font-weight: 400;
  line-height: 37px;
  padding: 27px 0 32px;
`;
