import {
  faFile,
  faFileAlt,
  faMoneyCheckAlt,
  faPieChart,
  faSearch,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as IconeGarfoFaca } from "../../assets/icons/garfo_faca.svg";
import {
  faPlus,
  faChartPie,
  faTruck,
  faFileLines,
  faConciergeBell,
  faShield,
  faMagnifyingGlass,
  faCommentsDollar,
} from "@fortawesome/free-solid-svg-icons";
export const url = "http://homolog.erp.waybe.com.br";

export const dados = {
  menus: {
    "Cadastros": {
      "icone": faPlus,
      "filhos": {
        "Produtos": {
          "Produtos": () => {
            window.location.href = `${url}/cadastro/produto`;
          },
          "Grupos": () => {
            window.location.href = `${url}/cadastro/produto/grupo`;
          },
          "Locais de Produção": () => {
            window.location.href = `${url}/cadastro/produto/local-producao`;
          },
          "Menus": () => {
            window.location.href = `${url}/cadastro/produto/menus`;
          },
          "Observações": () => {
            window.location.href = `${url}/cadastro/produto/observacao`;
          },
          "Tabela de Preços": () => {
            window.location.href = `${url}/cadastro/produto/tabela-preco`;
          },
          "Variações": () => {
            window.location.href = `${url}/cadastro/telas_produto/tamanho.xhtml`;
          },
          "Cardápio": {
            "Autoatendimento": () => {
              window.location.href = `${url}/cadastro/waychef/cardapio-totem`;
            },
            "Waychef": () => {
              window.location.href = `${url}/cadastro/waychef/cardapio`;
            },
            "Ifood": () => {
              window.location.href = `${url}/cadastro/waychef/cardapio-ifood`;
            },
          },
        },
        "Clientes": {
          "Clientes": () => {
            window.location.href = `${url}/cadastro/cliente`;
          },
          "Classificações": () => {
            window.location.href = `${url}/cadastro/cliente/classificacao`;
          },
          "Ramo de atividade": () => {
            window.location.href = `${url}/cadastro/ramo-atividade`;
          },
          "Regiões": () => {
            window.location.href = `${url}/cadastro/cliente/regiao`;
          },
          "Status de Crédito": () => {
            window.location.href = `${url}/cadastro/status-credito`;
          },
          "Waywallet": {
            "Carteira": () => {
              window.location.href = `${url}/financeiro/bandeira-cartao?o=waywallet`;
            },
            "Lojistas": () => {
              window.location.href = `${url}/waywallet/lojistas`;
            },
          },
        },
        "Finanças": {
          "Fornecedores e Credores": () => {
            window.location.href = `${url}/cadastro/fornecedor`;
          },
          "Representantes": () => {
            window.location.href = `${url}/cadastro/representante`;
          },
          "Cadastro de bandeiras": () => {
            window.location.href = `${url}/cadastro/telas_financeiro/financeiro/bandeira-cartao`;
          },
          "Categoria de sangria": () => {
            window.location.href = `${url}/cadastro/telas_financeiro/sangria_categoria`;
          },
          "Centro de Custos": () => {
            window.location.href = `${url}/financeiro/centro-custos`;
          },
          "Contas Correntes": () => {
            window.location.href = `${url}/financeiro/conta-corrente`;
          },
          "Plano de contas": () => {
            window.location.href = `${url}/financeiro/plano-contas`;
          },
        },
        "Fiscal": {
          "CFOP": () => {
            window.location.href = `${url}/fiscal/cop`;
          },
          "Classficação Fiscal Contribuinte - ICMS": () => {
            window.location.href = `${url}/cadastro/produto/fiscal/classificacao-fiscal-contribuinte`;
          },
          "ICMS": () => {
            window.location.href = `${url}/cadastro/produto/fiscal/icms`;
          },
          "PIS/COFINS": () => {
            window.location.href = `${url}/cadastro/produto/fiscal/pis-cofins`;
          },
          "Tributação por UF de Destino": () => {
            window.location.href = `${url}/cadastro/produto/fiscal/tributacao-por-uf-destino`;
          },
        },
        "Estoque": {
          "Local de estocagem": () => {
            window.location.href = `${url}/cadastro/produto/local-estocagem`;
          },
          "Setores": () => {
            window.location.href = `${url}/cadastro/telas_empresa/setor`;
          },
        },
      },
    },
    "Operacional": {
      "icone": faTruck,
      "filhos": {
        "Produtos": {
          "Mix de produtos": () => {
            window.location.href = `${url}/cadastro/produto/mix`;
          },
          "Promoções": () => {
            window.location.href = `${url}/cadastro/produto/promocao`;
          },
          "Remarcação de Preços": () => {
            window.location.href = `${url}/cadastro/produto/remarcacao-preco`;
          },
        },
        "Estoque": {
          "Inventário": () => {
            window.location.href = `${url}/lancamento/inventario`;
          },
          "Lançamentos": () => {
            window.location.href = `${url}/consulta/ajustes-estoque`;
          },
          "Produção": () => {
            window.location.href = `${url}/lancamento/estoque-producao`;
          },
          "Ajustes": {
            "Entrada Avançada": () => {
              window.location.href = `${url}/estoques/dci-entrada`;
            },
            "Saída Avançada": () => {
              window.location.href = `${url}/estoques/dci-saida`;
            },
            "Simples": () => {
              window.location.href = `${url}/lancamento/ajuste-estoque`;
            },
            "Zerar estoque": () => {
              window.location.href = `${url}/funcionalidades/zerar-estoque`;
            },
          },
          "Transferência": {
            "Externa": () => {
              window.location.href = `${url}/estoques/dci-transferencia`;
            },
            "Interna": () => {
              window.location.href = `${url}/lancamento/estoque-transferencia`;
            },
          },
        },
        "Fiscal": {
          "Consultar NF-e/NFS-e": () => {
            window.location.href = `${url}/nfe/consulta`;
          },
          "Gerenciador de NF-e": "/",
          "Gerenciador de NFC-e": "/",
          "Emitir NF-e/NFS-e": {
            "Nota Fiscal Complementar": () => {
              window.location.href = `${url}/nfe/nf_complementar`;
            },
            "Nota Fiscal de Entrada": () => {
              window.location.href = `${url}/nfe/emissao-entrada`;
            },
            "Nota Fiscal de Saída": () => {
              window.location.href = `${url}/nfe/emissao-saida`;
            },
          },
          "Lançar NF-e": {
            "Nota Fiscal de Entrada": () => {
              window.location.href = `${url}/nfe/lancamento-entrada`;
            },
          },
        },
        "Finanças": {
          "Controle de Cartões": {
            "Manutenção dos créditos": () => {
              window.location.href = `${url}/controle-cartoes/manutencao-credito`;
            },
            "Transações Realizadas": () => {
              window.location.href = `${url}/controle-cartoes/transacao-cartao`;
            },
          },
          "Movimentações": {
            "Cobrança bancaria": () => {
              window.location.href = `${url}/financeiro/remessa-bancaria`;
            },
            "Conferência de caixa": () => {
              window.location.href = `${url}/financeiro/conferencia-caixa`;
            },
            "Contas Correntes": () => {
              window.location.href = `${url}/financeiro/movimentacao-caixa`;
            },
            "Contas a pagar": () => {
              window.location.href = `${url}/financeiro/movimentacao-caixa?o=pagar`;
            },
            "Contas a receber": () => {
              window.location.href = `${url}/financeiro/movimentacao-caixa?o=receber`;
            },
            "Pagamento em lote": () => {
              window.location.href = `${url}/financas/movimentacoes/pagamento-lote`;
            },
            "Recebimento em lote": () => {
              window.location.href = `${url}/financas/movimentacoes/recebimento-lote`;
            },
          },
        },
        "Clientes": {
          "Fatura": {
            "Extrato conta": () => {
              window.location.href = `${url}/cliente/fatura/extrato`;
            },
            "Faturamento de vendas": () => {
              window.location.href = `${url}/cliente/fatura/faturamento`;
            },
            "Saldo de créditos": () => {
              window.location.href = `${url}/cliente/fatura/saldo-de-creditos`;
            },
          },
          "Relacionamento": {
            "Waywallet": {
              "Transações": () => {
                window.location.href = `${url}/waywallet/transacoes`;
              },
            },
          },
        },
      },
    },
    "Relatórios": {
      "icone": faFileLines,
      "filhos": {
        "Cadastrais": {
          "Clientes": () => {
            window.location.href = `${url}/relatorios/cliente`;
          },
          "Ficha técnica": () => {
            window.location.href = `${url}/relatorios/relatorio_ficha_tecnica.xhtml`;
          },
          "Produtos": () => {
            window.location.href = `${url}/relatorios/produto`;
          },
          "Tributação": {
            "ICMS": () => {
              window.location.href = `${url}/relatorios/tributacao-icms`;
            },
            "PIS/COFINS": () => {
              window.location.href = `${url}/relatorios/tributacao-pis-cofins`;
            },
          },
        },
        "Estoque": {
          "Disponível": () => {
            window.location.href = `${url}/relatorios/estoque/disponivel`;
          },
          "Em falta": () => {
            window.location.href = `${url}/relatorios/estoque/em-falta`;
          },
          "Geral": () => {
            window.location.href = `${url}/relatorios/produto-estoque.xhtml`;
          },
          "Histórico de movimentação": () => {
            window.location.href = `${url}/relatorios/estoque/movimentacao-produto.xhtml`;
          },
          "Movimentação de insumos": () => {
            window.location.href = `${url}/relatorios/estoque/movimentacao-insumos`;
          },
        },
        "Faturamento": {
          "Cancelamentos": {
            "Itens cancelados": () => {
              window.location.href = `${url}/relatorios/itens-cancelados`;
            },
          },
        },
        "Fiscal": {
          "Listagem aux. Escrituração": () => {
            window.location.href = `${url}/relatorios/faturamento/tributacao/listagem-aux-escrituracao-fiscal`;
          },
        },
        "Monetário": {
          "Anual (mês a mês)": () => {
            window.location.href = `${url}/relatorios/faturamento-mensal`;
          },
          "Atendimentos": () => {
            window.location.href = `${url}/relatorios/atendimento`;
          },
          "Comissão do vendedor": () => {
            window.location.href = `${url}/relatorios/comissao-vendedor.xhtml`;
          },
          "Faturamento por período": () => {
            window.location.href = `${url}/relatorios/faturamento_periodo_dia.xhtml`;
          },
          "Forma de pagamento": () => {
            window.location.href = `${url}/relatorios/atendimento-finalizadora`;
          },
          "Por módulo": () => {
            window.location.href = `${url}/relatorios/faturamento/monetario/por-modulo`;
          },
          "Taxas de serviço": () => {
            window.location.href = `${url}/relatorios/faturamento/monetário/taxas-servico-vendedor`;
          },
        },
        "Módulos": {
          "Controle de tempo produção": () => {
            window.location.href = `${url}/relatorios/relatorio_controle_tempo_producao.xhtml`;
          },
          "Delivery": () => {
            window.location.href = `${url}/relatorios/atendimento-delivery`;
          },
        },
        "Produtos vendidos": {
          "Por grupo": () => {
            window.location.href = `${url}/relatorios/faturamento-por-grupo`;
          },
          "Por pacote": () => {
            window.location.href = `${url}/relatorios/pacotes-vendidos`;
          },
          "Por período": () => {
            window.location.href = `${url}/relatorios/produtos-vendidos`;
          },
          "Por tabela de preço": () => {
            window.location.href = `${url}/relatorios/produtos-vendidos-por-tabela`;
          },
          "Por vendedor": () => {
            window.location.href = `${url}/relatorios/produtos-vendidos-por-vendedor.xhtml`;
          },
        },
        "Análise Gerencial": {
          "DFC Mensal": () => {
            window.location.href = `${url}/financeiro/fluxo-caixa`;
          },
          "DRE Mensal": () => {
            window.location.href = `${url}/financeiro/dre-gerencial`;
          },
        },
      },
    },
    "Serviços": {
      "icone": faConciergeBell,
      "filhos": {
        "Serviços do sistema": () => {
          window.location.href = `${url}/configuracao/servicos`;
        },
        "Aplicações": () => {
          window.location.href = `${url}/configuracao/servicos`;
        },
      },
    },
    "Segurança": {
      "icone": faShield,
      "filhos": {
        "Configurações de usuário": {
          "Perfis de usuário": () => {
            window.location.href = `${url}/cadastro/telas_usuario/perfil_empresa.xhtml`;
          },
          "Usuário": () => {
            window.location.href = `${url}/cadastro/usuario`;
          },
        },
        "Dados da Empresa": {
          "Funcionários": () => {
            window.location.href = `${url}/cadastro/funcionario`;
          },
          "Minha empresa": () => {
            window.location.href = `${url}/configuracao/empresa`;
          },
          "Pagamento": () => {
            window.location.href = `${url}/empresa/gerenciar-assinatura`;
          },
        },
      },
    },
    "Consultas": {
      "icone": faMagnifyingGlass,
      "filhos": {
        "Atendimentos": () => {
          window.location.href = `${url}/consulta/atendimento`;
        },
        "Caixas": () => {
          window.location.href = `${url}/relatorios/operacao-caixa`;
        },
      },
    },
    "Fidelidade": {
      "icone": faCommentsDollar,
      "filhos": {
        "Cashback": () => {
          window.location.href = `/fidelidade/cashback`;
        },
      },
    },
  },
};
