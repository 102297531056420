import { faArrowLeft, faFloppyDisk, faPlus, faX } from "@fortawesome/free-solid-svg-icons"
import { BottomSheetCustomizada, ColunaAtalho, ContainerBottomSheet, ContainerColunas, ContainerTeclasAtalho, HeaderBottomSheet, IconeBotao, LinhaAtalho, StyledHr, TeclaAtalho, TextoAtalhos, TextoTeclaAtalho } from "./styles"
import { useTheme } from "@emotion/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { BotaoAzulVazado, BotaoVerdeVazado, BotaoVermelhoVazado } from "../Botao"
import useTamanhoTela from "../../utils/useTamanhoTela"

export const BottomSheetAtalhos = ({ bottomSheetAberto = false, atalhos, fecharBottomSheet = () => { } }) => {
    const theme = useTheme()

    const { largura } = useTamanhoTela()

    return (
        <BottomSheetCustomizada
            open={bottomSheetAberto}
            snapPoints={({ maxHeight }) => largura > 1100 ? [maxHeight * 0.6] : [maxHeight * 0.8]}
            onDismiss={fecharBottomSheet}
            blocking={false}
        >
            <ContainerBottomSheet>

                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                    <IconeBotao onClick={fecharBottomSheet}>
                        <FontAwesomeIcon
                            icon={faX}
                            style={{
                                width: 20,
                                height: 20,
                                color: theme.cores.semanticDanger2,
                            }}
                        />
                    </IconeBotao>

                </div>

                <HeaderBottomSheet>
                    <TextoAtalhos>Atalhos</TextoAtalhos>
                    <StyledHr />
                </HeaderBottomSheet>

                <ContainerColunas>
                    {(atalhos.includes("Pesquisar") || atalhos.includes("Limpar filtros") || atalhos.includes("Novo")) && (
                        <ColunaAtalho>
                            {atalhos.includes("Pesquisar") && (
                                <LinhaAtalho>
                                    <BotaoAzulVazado
                                        texto="Pesquisar"
                                        tamanho="MD"
                                    />
                                    <ContainerTeclasAtalho>
                                        <TeclaAtalho>
                                            <TextoTeclaAtalho>Alt</TextoTeclaAtalho>
                                        </TeclaAtalho>

                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            style={{
                                                width: 14,
                                                height: 14,
                                                color: theme.adaptativo("textActionDisable", "textActionSuccessAlternative")
                                            }}
                                        />

                                        <TeclaAtalho>
                                            <TextoTeclaAtalho>P</TextoTeclaAtalho>
                                        </TeclaAtalho>

                                    </ContainerTeclasAtalho>
                                </LinhaAtalho>
                            )}

                            {atalhos.includes("Limpar filtros") && (
                                <LinhaAtalho>
                                    <BotaoVermelhoVazado
                                        texto="Limpar filtros"
                                        tamanho="MD"

                                    />
                                    <ContainerTeclasAtalho>
                                        <TeclaAtalho>
                                            <TextoTeclaAtalho>Alt</TextoTeclaAtalho>
                                        </TeclaAtalho>

                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            style={{
                                                width: 14,
                                                height: 14,
                                                color: theme.adaptativo("textActionDisable", "textActionSuccessAlternative")
                                            }}
                                        />

                                        <TeclaAtalho>
                                            <TextoTeclaAtalho>L</TextoTeclaAtalho>
                                        </TeclaAtalho>

                                    </ContainerTeclasAtalho>
                                </LinhaAtalho>
                            )}

                            {atalhos.includes("Novo") && (
                                <LinhaAtalho>
                                    <BotaoAzulVazado
                                        texto="Novo"
                                        tamanho="MD"

                                        icone={
                                            <FontAwesomeIcon
                                                icon={faPlus}
                                                style={{
                                                    width: 18,
                                                    height: 18,
                                                    color: theme.cores.surfaceActionActivePrimary
                                                }}
                                            />
                                        }
                                    />
                                    <ContainerTeclasAtalho>
                                        <TeclaAtalho>
                                            <TextoTeclaAtalho>Alt</TextoTeclaAtalho>
                                        </TeclaAtalho>

                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            style={{
                                                width: 14,
                                                height: 14,
                                                color: theme.adaptativo("textActionDisable", "textActionSuccessAlternative")
                                            }}
                                        />

                                        <TeclaAtalho>
                                            <TextoTeclaAtalho>N</TextoTeclaAtalho>
                                        </TeclaAtalho>

                                    </ContainerTeclasAtalho>
                                </LinhaAtalho>
                            )}
                        </ColunaAtalho>
                    )}

                    {(atalhos.includes("Voltar") || atalhos.includes("Confirmar empresas") || atalhos.includes("Adicionar")) && (
                        <ColunaAtalho>
                            {atalhos.includes("Voltar") && (
                                <LinhaAtalho>
                                    <BotaoAzulVazado
                                        texto="Voltar"
                                        tamanho="MD"
                                        icone={
                                            <FontAwesomeIcon
                                                icon={faArrowLeft}
                                                style={{
                                                    width: 18,
                                                    height: 18,
                                                    color: theme.cores.surfaceActionActivePrimary
                                                }}
                                            />
                                        }
                                    />
                                    <ContainerTeclasAtalho>
                                        <TeclaAtalho>
                                            <TextoTeclaAtalho>Alt</TextoTeclaAtalho>
                                        </TeclaAtalho>

                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            style={{
                                                width: 14,
                                                height: 14,
                                                color: theme.adaptativo("textActionDisable", "textActionSuccessAlternative")
                                            }}
                                        />

                                        <TeclaAtalho>
                                            <TextoTeclaAtalho>V</TextoTeclaAtalho>
                                        </TeclaAtalho>

                                    </ContainerTeclasAtalho>
                                </LinhaAtalho>
                            )}

                            {atalhos.includes("Confirmar empresas") && (
                                <LinhaAtalho>
                                    <BotaoAzulVazado
                                        texto="Confirmar empresas"
                                        tamanho="MD"
                                    />
                                    <ContainerTeclasAtalho>
                                        <TeclaAtalho>
                                            <TextoTeclaAtalho>Alt</TextoTeclaAtalho>
                                        </TeclaAtalho>

                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            style={{
                                                width: 14,
                                                height: 14,
                                                color: theme.adaptativo("textActionDisable", "textActionSuccessAlternative")
                                            }}
                                        />

                                        <TeclaAtalho>
                                            <TextoTeclaAtalho>C</TextoTeclaAtalho>
                                        </TeclaAtalho>

                                    </ContainerTeclasAtalho>
                                </LinhaAtalho>
                            )}

                            {atalhos.includes("Adicionar") && (
                                <LinhaAtalho>
                                    <BotaoAzulVazado
                                        texto="Adicionar"
                                        tamanho="MD"
                                    />
                                    <ContainerTeclasAtalho>
                                        <TeclaAtalho>
                                            <TextoTeclaAtalho>Alt</TextoTeclaAtalho>
                                        </TeclaAtalho>

                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            style={{
                                                width: 14,
                                                height: 14,
                                                color: theme.adaptativo("textActionDisable", "textActionSuccessAlternative")
                                            }}
                                        />

                                        <TeclaAtalho>
                                            <TextoTeclaAtalho>A</TextoTeclaAtalho>
                                        </TeclaAtalho>

                                    </ContainerTeclasAtalho>
                                </LinhaAtalho>
                            )}
                        </ColunaAtalho>
                    )}
                    {atalhos.includes("Gravar") && (

                        <ColunaAtalho>
                            <LinhaAtalho>
                                <BotaoVerdeVazado
                                    texto="Gravar"
                                    tamanho="MD"
                                    icone={
                                        <FontAwesomeIcon
                                            icon={faFloppyDisk}
                                            style={{
                                                width: 18,
                                                height: 18,
                                                color: theme.cores.surfaceActionActiveSuccess
                                            }}
                                        />
                                    }
                                />

                                <ContainerTeclasAtalho>
                                    <TeclaAtalho>
                                        <TextoTeclaAtalho>Alt</TextoTeclaAtalho>
                                    </TeclaAtalho>

                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        style={{
                                            width: 14,
                                            height: 14,
                                            color: theme.adaptativo("textActionDisable", "textActionSuccessAlternative")
                                        }}
                                    />

                                    <TeclaAtalho>
                                        <TextoTeclaAtalho>G </TextoTeclaAtalho>
                                    </TeclaAtalho>

                                </ContainerTeclasAtalho>
                            </LinhaAtalho>
                        </ColunaAtalho>
                    )}

                </ContainerColunas>
            </ContainerBottomSheet>
        </BottomSheetCustomizada>
    )
}