import { Accordion, AccordionDetails, TextField } from "@mui/material";
import Popup from "reactjs-popup";
import styled from "@emotion/styled";

export const CabecalhoTituloPlano = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: unset;
    gap: 28px;
    margin-bottom: 36px;
  }
`;

export const ContainerTipoAplicacao = styled.div`
  .MuiFormControl-root {
    margin: 0px;
  }
`;

export const ContainerTituloPlano = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TituloPlano = styled.p`
  font-family: "Comfortaa", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 29px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.cores.textoSimples};

  @media (max-width: 768px) {
    font-size: 19px;
    line-height: 21px;
  }
`;

export const SubtituloVencimento = styled.p`
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.adaptativo("grayScale600", "neutral60")};

  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.25px;
  }
`;

export const ContainerSubtituloValor = styled.div`
  display: flex;
  gap: 2px;
`;

export const SubtituloValor = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.adaptativo("grayScale600", "neutral60")};

  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.25px;
  }
`;

export const Valor = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.cores.surfaceActionActiveSuccess};

  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.25px;
  }
`;

export const BotaoAlterarPlano = styled.button`
  width: 147px;
  height: 48px;
  gap: 8px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 8px 16px;
  border: 1px solid ${({ theme }) => theme.cores.actionActive};
  background: transparent;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: ${({ theme }) => theme.cores.actionHover2};
    opacity: 0.7;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TextoBotaoAlterarPlano = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: ${({ theme }) => theme.cores.actionActive};
`;

export const ContainerAccordions1 = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 62px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 10px;
    gap: 8px;
  }
`;

export const ContainerAccordionsEsquerda = styled.div`
  width: 49.5%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    gap: 8px;
    width: 100%;
  }
`;

export const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  margin: 0;
  margin-bottom: 0;
  background-color: ${(props) => props.theme.adaptativo("brand10", "brand70")};

  svg {
    color: ${(props) => props.theme.textoContrato};
  }
`;

export const ContainerAccordionsDireita = styled.div`
  width: 49.5%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    gap: 8px;
    width: 100%;
  }
`;
export const LinhaClaraExtras = styled(AccordionDetails)`
  background: ${(props) => props.theme.cores.surface2};
  display: flex;
  align-items: center;
  height: 86px;
  justify-content: space-between;
  padding-right: 40px;
  gap: 10px;

  @media (max-width: 768px) {
    padding-right: 12px;
  }
`;

export const LinhaEscuraExtras = styled(AccordionDetails)`
  background: ${(props) => props.theme.adaptativo("surface3", "neutral10")};
  display: flex;
  align-items: center;
  height: 86px;
  padding-right: 40px;
  gap: 10px;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding-right: 12px;
  }
`;

export const TituloLinhaExtras = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.cores.textoContrato};
  width: 400px;

  @media (max-width: 414px) {
    font-size: 14px;
  }
`;

export const ContainerValorTotalPlano = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999; /* Isso garante que a barra inferior esteja acima de tudo */

  width: 100%;
  height: 141px;
  border-radius: 16px 16px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 53px;
  background: ${({ theme }) => theme.adaptativo("actionDisable", "surface3")};

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    height: 273px;
    padding-right: 20px;
  }
`;

export const ContainerTextoValorTotalPlano = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ContainerTituloValorTotalPlano = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TituloValorTotalPlano = styled.p`
  font-family: "Comfortaa", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 26px;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.cores.textoSimples};
`;

export const ValorTotalPlano = styled.p`
  font-family: "Comfortaa", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 26px;
  letter-spacing: -0.5px;
  color: rgba(30, 168, 52, 1);
`;

export const SubtituloValorPlano = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.cores.textoSimples};

  @media (max-width: 768px) {
  }
`;

export const BotaoFazerPagamento = styled.button`
  cursor: pointer;
  transition: 0.3s;
  width: 184px;
  height: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 8px;
  background: ${({ theme }) => theme.cores.actionActive};
  border: none;

  &:hover {
    background: ${({ theme }) => theme.cores.actionHover2};
    opacity: 0.7;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TextoBotaoFazerPagamento = styled.p`
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.cores.neutral100};

  @media (max-width: 1000px) {
    font-size: 15px;
  }
`;

export const TextoAdicionar = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-decoration-line: underline;
  cursor: pointer;
  transition: 0.3s;
  color: ${({ theme }) => theme.cores.actionActive};

  &:hover {
    opacity: 0.7;
  }
`;

export const TextoReativarRecorrencia = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;

  color: orange;
`;

export const ContainerRemover = styled.div`
  display: flex;
  gap: 13px;
  align-items: center;
`;

export const TextoRemover = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-decoration-line: underline;
  cursor: pointer;
  transition: 0.3s;
  color: ${({ theme }) => theme.cores.semanticDanger2};

  &:hover {
    opacity: 0.7;
  }
`;

export const ContainerBoxCount = styled.div`
  width: 69px;
  height: 30px;
  display: flex;
  gap: 7px;
  padding: 0px;
  align-items: center;
`;

export const TextoBoxCount = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.adaptativo("neutral40", "neutral100")};
`;

export const TextoCancelarRecorrencia = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.cores.semanticDanger2};
`;

export const PopupCustomizado = styled(Popup)`
  &-content {
    background-color: ${(props) => props.theme.cores.surface2};
    height: 430px;
    width: 70vw;
    border-radius: 16px;
    overflow-y: scroll;
    border: none;
    width: 868px;
  }

  &-overlay {
  }
`;

export const ContainerPopup = styled.div`
  padding-top: 34px;
  padding-left: 21px;
  padding-bottom: 39px;
  padding-right: 24px;
`;

export const CabecalhoModalCancelarRecorrencia = styled.div`
  padding-left: 10px;
  box-sizing: border-box;
  margin-bottom: 14px;
`;

export const TextoCabecalhoModalCancelarRecorrencia = styled.p`
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 37px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.cores.textoSimples};
`;

export const LinhaCabecalhoModalCancelarRecorrencia = styled.hr`
  border: 1px solid ${({ theme }) => theme.cores.corHr};
  width: 100%;
  margin-bottom: 14px;
`;

export const TextoSubtituloModal = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.cores.textoSimples};
`;

export const ContainerInputBoxCountModal = styled.div`
  display: flex;
  gap: 87px;
  margin-bottom: 113px;
  align-items: center;
`;

export const NumberInputCustomizadoModal = styled(TextField)`
  width: 201px;
  background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? String(theme.cores.neutral100).replace("1)", "0.2)")
      : theme.cores.actionDisable};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  z-index: 50;

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0px;
  }
`;

export const BoxCountModal = styled.div`
  width: 69px;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 7px;
`;

export const TextoBoxCountModal = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.adaptativo("neutral40", "neutral100")};
`;

export const FooterModal = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
`;

export const BotaoDesistir = styled.button`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 8px;
  width: 99px;
  height: 48px;
  border: 1px solid ${({ theme }) => theme.cores.actionActive};
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
  background: transparent;

  &:hover {
    opacity: 0.7;
  }
`;

export const TextoBotaoDesistir = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.cores.actionActive};
  width: 67px;
  height: 32px;
`;

export const BotaoCancelarRecorrencia = styled.button`
  width: 214px;
  height: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 8px;
  background: ${({ theme }) => theme.cores.semanticDanger2};
  cursor: pointer;
  transition: 0.3s;
  border: none;

  &:hover {
    opacity: 0.7;
  }
`;
export const TextoBotaoCancelarRecorrencia = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.cores.semanticDanger3};
  width: 182px;
  height: 32px;
`;

export const PopupCustomizadoCartao = styled(Popup)`
  &-content {
    background-color: ${(props) => props.theme.cores.surface2};
    height: 670px;
    width: 70vw;
    border-radius: 16px;
    overflow-y: scroll;
    border: none;
  }

  &-overlay {
  }
`;

export const ContainerPopupCartao = styled.div`
  padding-top: 34px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 50px;
`;

export const TextoFinalizarCompra = styled.p`
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 37px;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.cores.textoSimples};
  margin-bottom: 10px;
`;

export const Hr = styled.hr`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.cores.corHr};
  margin-bottom: 36px;
`;

export const ContainerAlteracaoNovoPlano = styled.div`
  padding-top: 29px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  background: ${({ theme }) => theme.cores.surface3};
  margin-bottom: 24px;
  width: 100%;
  border-radius: 16px;
`;

export const TituloAlteracaoPlano = styled.p`
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 26px;

  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  margin-bottom: 36px;
`;

export const ContainerDescricaoAlteracaoPlano = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

export const DescricaoAlteracaoNovoPlano = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
`;

export const TextoCartaoCadastrado = styled.p`
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.cores.textoSimples};
  margin-bottom: 18px;
`;

export const ContainerCartoes = styled.div`
  display: flex;
  gap: 32px;
  margin-bottom: 80px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const ContainerBottomSheetCartao = styled.div`
  padding-top: 30px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 22px;
`;

export const TextoFinalizarCompraModal = styled.p`
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 37px;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.cores.textoSimples};
  margin-bottom: 10px;
`;

export const HrModal = styled.hr`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.cores.corHr};
  margin-bottom: 24px;
`;

export const ContainerAlteracaoNovoPlanoModal = styled.div`
  padding-top: 29px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  background: ${({ theme }) => theme.cores.surface3};
  margin-bottom: 36px;
  width: 100%;
`;

export const TituloAlteracaoPlanoModal = styled.p`
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 26px;

  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  margin-bottom: 36px;
`;

export const ContainerDescricaoAlteracaoPlanoModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

export const DescricaoAlteracaoNovoPlanoModal = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
`;

export const ContainerCartoesModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 36px;
  align-items: center;
`;

export const BotaoFazerPagamentoModal = styled.button`
  background: ${({ theme }) => theme.cores.actionActive};
  border: none;
  cursor: pointer;
  transition: 0.3s;
  width: 190px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.7;
  }
`;

export const TextoBotaoFazerPagamentoModal = styled.p`
  font-size: 16px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.cores.textAction};
`;

export const BotaoCancelarModal = styled.button`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.cores.semanticDanger2};
  cursor: pointer;
  transition: 0.3s;
  width: 190px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.7;
  }
`;

export const TextoBotaoCancelarModal = styled.p`
  font-size: 16px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.cores.semanticDanger2};
`;

export const LinhaClara1 = styled(AccordionDetails)`
  background: ${({ theme }) => theme.cores.surface2};
  display: flex;
  align-items: center;
  height: 69px;
  justify-content: space-between;
  padding-right: 16px;
`;

export const LinhaEscura1 = styled(AccordionDetails)`
  background: ${({ theme }) => theme.adaptativo("surface3", "neutral10")};
  display: flex;
  align-items: center;
  height: 69px;
  padding-right: 16px;
  justify-content: space-between;
`;

export const ContainerDetalhesDoPlano = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
