import { TextFieldMonetario, TextFieldMonetarioPadronizado } from "../CampoInput/styles";
import { InputAdornment, } from "@mui/material";
import { NumberFormatBase, NumericFormat } from "react-number-format";
import { useEffect, useState } from "react";

export function CampoDeInputMonetario({
    style = {},
    onChange = () => {
    },
    value,
    label,
    titulo,
    error,
    onBlur = () => {
    },
    disabled,
    valorMax
}) {


    const propriedadesMaterialUIStandard = {
        id: "standard-basic",
        variant: "standard",

        InputProps: {
            InputAdornment: <InputAdornment position="start">R$</InputAdornment>,
            placeholder: "R$ 0,00",
        },
    };

    function representacaoVisual() {


        if (value !== undefined) {
            let valor = value;
            valor = tratarLimitesValor(valor);
            return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        } else
            return 0;


    }

    function onChangeInterno(valorAtualizado) {
        let valor = parseFloat(
            valorAtualizado.target.value
                .replace("R$", "")
                .replaceAll(".", "")
                .replaceAll(",", ".")
        );

        valor = tratarLimitesValor(valor);

        onChange(valor);
    }

    function tratarLimitesValor(valor) {

        let novoValor = valor;
        novoValor = tratarValorMax(novoValor)
        return novoValor
    }

    function tratarValorMax(valor) {
        if (valorMax) {

            let max = valorMax * 100;
            if (valor > max) {
                return max;
            }
        }

        return valor
    }


    const formatCurrencyByEnd = (value) => {
        if (!Number(value)) return "";

        let valor = tratarLimitesValor(value);


        const amount = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
        }).format(parseFloat(valor) / 100);

        return `${amount}`;
    };


    return (
        <NumberFormatBase
            // {...rest}
            format={formatCurrencyByEnd}
            style={{ ...style }}
            label={label || titulo}
            //onValueChange={onChangeInterno}
            onChange={onChangeInterno}
            onBlur={onBlur}
            value={representacaoVisual()}
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
            decimalScale={2}
            fixedDecimalScale
            error={error}
            customInput={TextFieldMonetario}
            disabled={disabled}
            {...propriedadesMaterialUIStandard}
        />
    );
}


export function CampoDeInputNumerico({
    style = {},
    onChange,
    value,
    label,
    error,
    onBlur = () => {
    },
    disabled,
}) {
    // Aviso
    // Diferente dos outros campos de Input, por fazer o tratamento, este retorna diretamente o Valor, e não o
    // e.target.value como os outros.

    const propriedadesMaterialUIStandard = {
        id: "standard-basic",
        variant: "standard",
    };


    function onChangeInterno(valorAtualizado) {
        let valor = valorAtualizado.target.value;

        onChange(
            parseFloat(
                valor
                    .replace("+", "")
                    .replaceAll("-", "")
                    .replaceAll(",", "")
                    .replaceAll(".", "")
            )
        );

    }

    return (
        <NumericFormat
            // {...rest}
            style={{ ...style }}
            label={label}
            onChange={onChangeInterno}
            onBlur={onBlur}
            value={value}
            allowNegative={false}
            allowLeadingZeros={false}
            min={0}
            error={error}
            customInput={TextFieldMonetario}
            disabled={disabled}
            {...propriedadesMaterialUIStandard}
        />
    );
}
export function CampoDeInputNumericoPadronizado({
    style = {},
    onChange,
    value,
    label,
    error,
    onBlur = () => {
    },
    disabled,
}) {
    // Aviso
    // Diferente dos outros campos de Input, por fazer o tratamento, este retorna diretamente o Valor, e não o
    // e.target.value como os outros.

    const propriedadesMaterialUIStandard = {
        id: "standard-basic",
        variant: "standard",
    };


    function onChangeInterno(valorAtualizado) {
        let valor = valorAtualizado.target.value;

        onChange(
            parseFloat(
                valor
                    .replace("+", "")
                    .replaceAll("-", "")
                    .replaceAll(",", "")
                    .replaceAll(".", "")
            )
        );

    }

    return (
        <NumericFormat
            // {...rest}
            style={{ ...style }}
            label={label}
            onChange={onChangeInterno}
            onBlur={onBlur}
            value={value}
            allowNegative={false}
            allowLeadingZeros={false}
            min={0}
            error={error}
            customInput={TextFieldMonetarioPadronizado}
            disabled={disabled}
            {...propriedadesMaterialUIStandard}
        />
    );
}

