export default function formatarReal(valor, style = 'currency') {
  class RealFormatter {
    static format(value, style) {
      const formatter = new Intl.NumberFormat("pt-BR", {
        style,
        currency: "BRL",
        roundingMode: 'floor'
      });
      return formatter.format(value);
    }
  }

  if (!valor) {
    return "R$ 0,00";
  }

  return RealFormatter.format(valor, style);
}
