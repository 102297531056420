import { Global, css, useTheme } from '@emotion/react';
import ToastifyStyle from './toastify';

const GlobalStyle = () => {
  const theme = useTheme();

  return (
    <Global
      styles={css`
        :root {
          --toastify-toast-min-height: 70px;
          --toastify-toast-max-height: 800px;
          --toastify-z-index: 99999;

          // Fonte
          --toastify-font-family: 'Source Sans Pro', sans-serif;
          .Toastify__toast-body {
            font-size: 17px;
          }

          --toastify-color-info: ${theme.cores.toastifyInfoFundo};
          --toastify-color-success: ${theme.cores.toastifySucessoFundo};
          --toastify-color-warning: ${theme.cores.toastifyWarningFundo};
          --toastify-color-error: ${theme.cores.toastifyErrorFundo};
          --toastify-icon-color-info: ${theme.cores.toastifyInfoTexto};
          --toastify-icon-color-success: ${theme.cores.toastifySucessoTexto};
          --toastify-icon-color-warning: ${theme.cores.toastifyWarningTexto};
          --toastify-icon-color-error: ${theme.cores.toastifyErrorTexto};
          --toastify-toast-width: auto;
          --toastify-text-color-info: ${theme.cores.toastifyInfoTexto};
          --toastify-text-color-success: ${theme.cores.toastifySucessoTexto};
          --toastify-text-color-warning: ${theme.cores.toastifyWarningTexto};
          --toastify-text-color-error: ${theme.cores.toastifyErrorTexto};
          --toastify-color-progress-info: ${theme.cores.toastifyInfoTexto};
          --toastify-color-progress-success: ${theme.cores
          .toastifySucessoTexto};
          --toastify-color-progress-warning: ${theme.cores
          .toastifyWarningTexto};
          --toastify-color-progress-error: ${theme.cores.toastifyErrorTexto};
        }

        @keyframes Toastify__trackProgress {
          0% {
            transform: scaleX(1);
          }
          to {
            transform: scaleX(0);
          }
        }

        .Toastify__progress-bar {
          animation: Toastify__trackProgress linear 1 forwards;
        }

        // Cores da progress bar e do close icon
        .Toastify__toast-theme--colored.Toastify__toast--info {
          --toastify-color-transparent: var(--toastify-text-color-info);

          .Toastify__close-button {
            color: var(--toastify-text-color-info);
          }
        }
        .Toastify__toast-theme--colored.Toastify__toast--success {
          --toastify-color-transparent: var(--toastify-text-color-success);

          .Toastify__close-button {
            color: var(--toastify-text-color-success);
          }
        }
        .Toastify__toast-theme--colored.Toastify__toast--warning {
          --toastify-color-transparent: var(--toastify-text-color-warning);

          .Toastify__close-button {
            color: var(--toastify-text-color-warning);
          }
        }
        .Toastify__toast-theme--colored.Toastify__toast--error {
          --toastify-color-transparent: var(--toastify-text-color-error);

          .Toastify__close-button {
            color: var(--toastify-text-color-error);
          }
        }

        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }

        body {
          width: 100vw;
          height: 100vh;
          background-color: ${theme.cores.surfaceBackground1};
          font-family: Arial, Helvetica, sans-serif;
        }

        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        span {
          color: ${theme.cores.textoSimples};
        }

        h1,
        h2,
        h3,
        h4,
        h5 {
          font-family: 'Comfortaa', cursive;
        }

        p {
          font-family: 'Source Sans Pro', sans-serif;
        }

        ::-webkit-scrollbar {
          height: 0px;
          width: 8px;
          opacity: 0;
          position: relative;
          display: none;
          left: 8px;
        }

        ::-webkit-scrollbar-thumb:hover {
          display: block;
        }

        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 3px lightgrey;
          border-radius: 5px;
          display: none;
        }

        ::-webkit-scrollbar-thumb {
          background: lightgrey;
          border-radius: 5px;
          opacity: 0.1;
          left: 8px;
          height: 40px;
        }

        ::-webkit-scrollbar-thumb:hover {
          opacity: 0.1;
        }

        //MUI CLASSES de INPUT para adicionar o padding interno de forma geral
        .MuiFormLabel-root {
          padding: 0 10px;
          font-size: 0.9rem;
        }

        .MuiInput-root {
          margin-top: 0px !important;
        }

        .MuiAutocomplete-endAdornment {
          top: 0px !important;
        }

        .MuiInput-input {
          padding: 5px 5px;
          font-size: 1rem !important;
        }

        .MuiInputBase-readOnly {
          padding: 5px 5px;
          font-size: 0.75rem;
        }

        .MuiInputBase-root {
          padding: 5px 10px;
          font-size: 0.75rem;
        }

        //icons font-google
        .material-symbols-rounded {
          font-family: 'Material Icons Round';
        }

        //Classes de Overlay
        [data-rsbs-root]::after {
          background-color: ${theme.cores.surface2};
        }

        .popup-overlay {
          background: ${theme.cores.overlay};
        }

        :root {
          --rsbs-backdrop-bg: ${theme.cores.overlay};
        }

        .react-grid-item.react-grid-placeholder {
          background: white !important;
          border-radius: 2px !important;
        }

        input:-webkit-autofill {
          transition-delay: 9999s;
        }

        .MuiPaper-root:has(.MuiList-root):has(.cardapioAvancado) {
          left: 63px !important;
        }

        .Mui-disabled{          
          ${!theme.temaDarkAtivo && `
            opacity: 1;
            -webkit-text-fill-color: rgba(0, 0, 0, 0.5)!important;
            color: rgba(0, 0, 0, 0.5)!important;
            cursor: not-allowed!important;
          `}
        }

      `}
    />
  );
};

export { GlobalStyle };
