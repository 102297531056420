import { BotaoVermelhoPreenchido, BotaoVermelhoVazado } from "../Botao";
import {
  BotaoCancelarRecorrencia,
  BotaoDesistir,
  CabecalhoModalCancelarRecorrencia,
  FooterModal,
  TextoBotaoCancelarRecorrencia,
  TextoCabecalhoModalCancelarRecorrencia,
  PopupCustomizadoMin,
  ContainerPopup,
} from "./styles";
import { LinhaCabecalhoModalCancelarRecorrencia } from "../../pages/gerenciamentoAssinaturaPlanos/detalhesPlano/plano/styles";
import {
  TextoBotaoDesistir,
  TextoSubtituloModal,
} from "../Contrato/Ativo/styles";
import { useEffect, useRef, useState } from "react";

export function GerarModalConfirmacao({
  titulo,
  subTitulo,
  observacao,
  trigger,
  botaoCancelar,
  botaoConfirmar,
  ativo = false,
  onFechar = () => {},
  campoInput,
  style,
  disabled,
}) {
  const keyDownRef = useRef(null);

  const [aberto, setAberto] = useState(false);

  useEffect(() => {
    setAberto(ativo);
  }, [ativo]);

  useEffect(() => {
    function teclaPressionada(e) {
      if (e.key === "Enter") {
        setAberto(true);
      }
    }

    if (keyDownRef.current) {
      keyDownRef.current.addEventListener("keydown", teclaPressionada);
    }

    return () => {
      if (keyDownRef.current) {
        keyDownRef.current.removeEventListener("keydown", teclaPressionada);
      }
    };
  }, [keyDownRef]);

  return (
    <div tabIndex={0} ref={keyDownRef}>
      <PopupCustomizadoMin
        trigger={trigger}
        modal
        nested
        disabled={disabled}
        open={ativo || aberto ? ativo || aberto : null}
        onClose={() => {
          setAberto(false);
          onFechar();
        }}
      >
        {(close) => (
          <ContainerPopup>
            <CabecalhoModalCancelarRecorrencia>
              <TextoCabecalhoModalCancelarRecorrencia>
                {titulo}
              </TextoCabecalhoModalCancelarRecorrencia>
            </CabecalhoModalCancelarRecorrencia>

            <TextoSubtituloModal>{subTitulo}</TextoSubtituloModal>

            <div>{campoInput}</div>

            <p>
              <b>{observacao}</b>
            </p>

            <FooterModal>
              {botaoCancelar(close)}
              {botaoConfirmar(close)}
            </FooterModal>
          </ContainerPopup>
        )}
      </PopupCustomizadoMin>
    </div>
  );
}
