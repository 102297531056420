import { useEffect, useState } from "react";
import Header from "../../../components/Header";
import { Plano } from "./plano";
import { FormaPagamento } from "./formaPagamento";
import { Fatura } from "./fatura";
import Footer from "../../../components/Footer";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { InformacoesGerais } from "./informacoesGerais";
import { Extras } from "./extras";
import { TabBar } from "../../../components/TabBar";
import { Container, ContainerFundoBranco } from "./styles";
import useTamanhoTela from "../../../utils/useTamanhoTela";
import { useAuth } from "../../../contexts/auth";
import Breadcrumb from "../../../components/Breadcrumb";
import { useApi } from "../../../contexts/api";
import Carregando from "../../carregando";

export default function DetalhesPlano() {
  const { largura } = useTamanhoTela();
  const { pagamentoNecessario } = useAuth();
  const { get } = useApi();

  const [mostrarPagina, setMostrarPagina] = useState(0);
  const [tipoAplicacaoDefault, setTipoAplicacaoDefault] = useState(null);

  const [tipoFaturamentoBoleto, setTipoFaturamentoBoleto] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    buscarDados();
  }, []);

  function buscarDados() {
    get("/contrato/consumidor/waybe/empresa-contrato/informacoes-gerais")
      .then((response) => {
        if (response.status === 200) {
          let tipoFaturamento = response.data.tipoFaturamentoPlataforma;
          let tipoBoleto = tipoFaturamento === "BOLETO_BANCARIO";
          if (tipoBoleto) {
            setTipoFaturamentoBoleto("BOLETO_BANCARIO");
          }

          setMostrarPagina(pagamentoNecessario ? (tipoBoleto ? 0 : 3) : 0);
          setLoading(false);
        } else {
          throw new Error("Erro ao obter o contrato");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        navigate("/");
        toast.error(
          "Desculpe! Houve um erro ao obter informaçoes do Tipo Faturamento"
        );
      });
  }

  function tratativasAoTrocarAba(tipoAplicacao) {
    if (tipoAplicacao) {
      setTipoAplicacaoDefault(tipoAplicacao);
      setMostrarPagina(1);
    } else {
      setTipoAplicacaoDefault(null);
    }
  }

  // useEffect(() => {
  //   tratativasAoTrocarAba();
  // }, [mostrarPagina]);

  const opcoes = [
    "Informações gerais",
    "Contratos",
    "Forma de pagamento",
    "Fatura",
    "Extras",
  ];

  const opcoesTipoBoleto = [
    "Informações gerais",
    "Contratos",
    "Forma de pagamento",
  ];

  function getAjusteMargem() {
    if (largura < 768) {
      return " -8px -8px 8px -8px";
    } else {
      return " 0px -24px 24px -24px";
    }
  }

  const renderizarBreadCrumb = () => {
    return (
      <Breadcrumb
        caminho={[
          { titulo: "Gerenciamento de assinatura" },
          { titulo: "Pagamento" },
        ]}
      />
    );
  };

  return loading ? (
    <Carregando />
  ) : (
    <>
      <Container>
        {renderizarBreadCrumb()}
        <ContainerFundoBranco>
          <TabBar
            items={tipoFaturamentoBoleto ? opcoesTipoBoleto : opcoes}
            disabled={pagamentoNecessario}
            indexSelecionado={mostrarPagina}
            setIndexSelecionado={(index) => {
              tratativasAoTrocarAba();
              setMostrarPagina(index);
            }}
            style={{
              margin: getAjusteMargem(),
              width: "unset",
              marginBottom: 32,
            }}
          />

          {mostrarPagina === 0 ? (
            <InformacoesGerais detalharPlano={tratativasAoTrocarAba} />
          ) : mostrarPagina === 1 ? (
            <Plano tipoAplicacaoDefault={tipoAplicacaoDefault} />
          ) : mostrarPagina === 2 ? (
            <FormaPagamento />
          ) : mostrarPagina === 3 && !tipoFaturamentoBoleto ? (
            <Fatura />
          ) : mostrarPagina === 4 && !tipoFaturamentoBoleto ? (
            <Extras tipoAplicacaoDefault={tipoAplicacaoDefault} />
          ) : null}
        </ContainerFundoBranco>
        <Footer
          caminho={["Dados Da Empresa", "Minha Empresa"]}
          icon="gerarIcone"
          gerarIcone={faGear}
        />
      </Container>
    </>
  );
}
