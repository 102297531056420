import React from "react";
import {
  BotaoBaseAzulPreenchido,
  BotaoBaseAzulVazado,
  BotaoBaseAzulSemFundo,
  BotaoBaseVermelhoPreenchido,
  BotaoBaseVermelhoVazado,
  BotaoBaseVermelhoSemFundo,
  BotaoBaseVerdePreenchido,
  BotaoBaseVerdeVazado,
  BotaoBaseVerdeSemFundo,
  Icone,
  Linha,
  ItemVazio,
  TextoBotao,
  TextoBotaoVermelhoPreenchido
} from "./styles";
import { useTheme } from "@emotion/react";
import Save from "@mui/icons-material/Download";

const definirTamanho = (tamanho) => {
  switch (tamanho) {
    case "XSM":
      return { padding: " 8px 12px 8px 12px", fontSize: "15px" };
    case "SM":
      return {
        minWidth: 120,
        height: 32,
        padding: " 8px 24px 8px 24px",
        fontSize: "15px",
      };
    case "MD":
      return {
        minWidth: 140,
        height: 40,
        padding: " 8px 16px 8px 16px",
        fontSize: "17px",
      };
    case "LG":
      return {
        minWidth: 204,
        height: 48,
        padding: " 8px 16px 8px 16px",
        fontSize: "19px",
      };
    default:
      return {
        minWidth: "fit-content",
        height: 40,
        padding: " 8px 16px 8px 16px",
      };
  }
};

///// Botao Azul
export const BotaoAzulPreenchido = ({
  texto,
  icone,
  iconeFinal,
  tamanho,
  style = {},
  onClick,
  disabled,
  cor,
  tabindex,
  refs,
  className = '',
}) => {
  return texto != "" ? (
    <BotaoBaseAzulPreenchido
      className={`${className} btnGravarItem`}
      style={{ ...definirTamanho(tamanho), ...style }}
      onClick={onClick}
      disabled={disabled}
      texto={texto}
      cor={cor}
      tabIndex={tabindex}
      ref={refs}

    >
      <Linha>
        {icone && (
          <Icone style={{ marginRight: texto != "vazio" ? 10 : 0 }} margin={texto != "vazio" ? true : false}>{icone}</Icone>
        )}
        {texto != "vazio" ? <TextoBotao style={{ marginLeft: 5 }}>{texto}</TextoBotao> : ""}
      </Linha>
    </BotaoBaseAzulPreenchido>


  ) : (
    <ItemVazio style={{ ...definirTamanho(tamanho), ...style }} />
  );
};

export const BotaoAzulVazado = ({
  texto,
  icone,
  iconeFinal,
  tamanho,
  style = {},
  onClick,
  disabled,
  className,
}) => {
  return texto != "" ? (
    <BotaoBaseAzulVazado
      className={className}
      disabled={disabled}
      style={{ ...definirTamanho(tamanho), ...style }}
      onClick={onClick}
    >
      <Linha>
        {icone && (
          <Icone style={{ marginRight: texto != "vazio" ? 10 : 0 }}>
            {icone}
          </Icone>
        )}
        <TextoBotao>{texto}</TextoBotao>
        {iconeFinal && (
          <Icone style={{ marginLeft: texto != "vazio" ? 10 : 0 }}>
            {iconeFinal}
          </Icone>
        )}
      </Linha>
    </BotaoBaseAzulVazado>
  ) : (
    <ItemVazio style={{ ...definirTamanho(tamanho), ...style }} />
  );
};

export const BotaoAzulSemFundo = ({
  texto,
  icone,
  iconeFinal,
  tamanho,
  style = {},
  onClick,
  disabled = false
}) => {
  return (
    <BotaoBaseAzulSemFundo
      style={{
        ...definirTamanho(tamanho),
        ...style,
      }}
      disabled={disabled}
      onClick={onClick}
    >
      <Linha>
        {icone && (
          <Icone style={{ marginRight: texto != "vazio" ? 10 : 0 }}>
            {icone}
          </Icone>
        )}
        {texto}
        {iconeFinal && (
          <Icone style={{ marginLeft: texto != "vazio" ? 10 : 0 }}>
            {iconeFinal}
          </Icone>
        )}
      </Linha>
    </BotaoBaseAzulSemFundo>
  );
};

///// Botao Vermelho

export const BotaoVermelhoPreenchido = ({
  className,
  texto,
  icone,
  iconeFinal,
  disabled,
  tamanho,
  style = {},
  onClick,
}) => {
  return (
    <BotaoBaseVermelhoPreenchido
      className={className}
      style={{ ...definirTamanho(tamanho), ...style }}
      onClick={onClick}
      disabled={disabled}
    >
      <Linha>
        {icone && (
          <Icone style={{ marginRight: texto != "vazio" ? 10 : 0 }}>
            {icone}
          </Icone>
        )}
        <div style={{ fontSize: 15 }}>{texto}</div>
        {iconeFinal && (
          <Icone style={{ marginLeft: texto != "vazio" ? 10 : 0 }}>
            {iconeFinal}
          </Icone>
        )}
      </Linha>
    </BotaoBaseVermelhoPreenchido>
  );
};

export const BotaoVermelhoVazado = ({
  texto,
  icone,
  iconeFinal,
  tamanho,
  style = {},
  onClick,
  refs,
}) => {
  return (
    <BotaoBaseVermelhoVazado
      ref={refs}
      style={{ ...definirTamanho(tamanho), ...style }}
      onClick={onClick}
    >
      <Linha>
        {icone && (
          <Icone style={{ marginRight: texto != "vazio" ? 10 : 0 }}>
            {icone}
          </Icone>
        )}
        <TextoBotao>{texto}</TextoBotao>
        {iconeFinal && (
          <Icone style={{ marginLeft: texto != "vazio" ? 10 : 0 }}>
            {iconeFinal}
          </Icone>
        )}
      </Linha>
    </BotaoBaseVermelhoVazado>
  );
};

export const BotaoVermelhoSemFundo = ({
  texto,
  icone,
  iconeFinal,
  tamanho,
  style = {},
  onClick,
  refs,
}) => {
  return (
    <BotaoBaseVermelhoSemFundo
      style={{
        ...definirTamanho(tamanho),
        ...style,
      }}
      onClick={onClick}
      ref={refs}
    >
      <Linha>
        {icone && (
          <Icone style={{ marginRight: texto != "vazio" ? 10 : 0 }}>
            {icone}
          </Icone>
        )}
        {texto}
        {iconeFinal && (
          <Icone style={{ marginLeft: texto != "vazio" ? 10 : 0 }}>
            {iconeFinal}
          </Icone>
        )}
      </Linha>
    </BotaoBaseVermelhoSemFundo>
  );
};

///// Botao Verde

export const BotaoVerdePreenchido = ({
  texto,
  icone,
  iconeFinal,
  tamanho,
  style = {},
  onClick,
  disabled,
  tabindex,
  refs,
}) => {
  const theme = useTheme();

  return (
    <BotaoBaseVerdePreenchido
      className="btnGravarItem"
      ref={refs}
      style={{ ...definirTamanho(tamanho), ...style }}
      onClick={onClick}
      disabled={disabled}
      tabIndex={parseInt(tabindex) ? parseInt(tabindex) : 0}
    >
      <Linha>
        {icone && (
          <Icone style={{ marginRight: texto != "vazio" ? 10 : 0 }}>
            {icone}
          </Icone>
        )}
        {iconeFinal && (
          <Icone style={{ marginLeft: texto != "vazio" ? 10 : 0 }}>
            {iconeFinal}
          </Icone>
        )}
        <TextoBotao>{texto}</TextoBotao>
      </Linha>
    </BotaoBaseVerdePreenchido>
  );
};

export const BotaoVerdeVazado = ({
  texto,
  icone,
  iconeFinal,
  tamanho,
  style = {},
  onClick,
}) => {
  return (
    <BotaoBaseVerdeVazado
      style={{ ...definirTamanho(tamanho), ...style }}
      onClick={onClick}
    >
      <Linha>
        {icone && (
          <Icone style={{ marginRight: texto != "vazio" ? 10 : 0 }}>
            {icone}
          </Icone>
        )}
        {texto}
        {iconeFinal && (
          <Icone style={{ marginLeft: texto != "vazio" ? 10 : 0 }}>
            {iconeFinal}
          </Icone>
        )}
      </Linha>
    </BotaoBaseVerdeVazado>
  );
};

export const BotaoVerdeSemFundo = ({
  texto,
  icone,
  iconeFinal,
  tamanho,
  style = {},
  onClick,
}) => {
  return (
    <BotaoBaseVerdeSemFundo
      style={{
        ...definirTamanho(tamanho),
        ...style,
      }}
      onClick={onClick}
    >
      <Linha>
        {icone && (
          <Icone style={{ marginRight: texto != "vazio" ? 10 : 0 }}>
            {icone}
          </Icone>
        )}
        {texto}
        {iconeFinal && (
          <Icone style={{ marginLeft: texto != "vazio" ? 10 : 0 }}>
            {iconeFinal}
          </Icone>
        )}
      </Linha>
    </BotaoBaseVerdeSemFundo>
  );
};

export const BotaoGravar = ({
  texto,
  icone,
  tamanho,
  style = {},
  onClick,
  disabled,
  cor,
}) => {
  return texto != "" ? (
    <BotaoBaseAzulPreenchido
      className="btnGravarItem"
      style={{ ...definirTamanho(tamanho), ...style }}
      onClick={onClick}
      disabled={disabled}
      texto={texto}
      cor={cor}
    >
      <Linha>
        {icone && (
          <Icone style={{ marginRight: texto != "vazio" ? 10 : 0 }}>
            <Save />
          </Icone>
        )}
      </Linha>
    </BotaoBaseAzulPreenchido>
  ) : (
    <ItemVazio style={{ ...definirTamanho(tamanho), ...style }} />
  );
};
