import { createContext, useState, useContext } from "react";
import { ModalCarregando } from "../components/ModalCarregando";
import { toast } from "react-toastify";

export const LoaderContext = createContext({});


export const LoaderProvider = ({ children }) => {    
    const url = "http://homolog.erp.waybe.com.br";
        
    const [carregando, setCarregando] = useState(false);
    const [fechamento, setFechamento] = useState(false);
    const [carregandoTabela, setCarregandoTabela] = useState(false);
    const [titulo, setTitulo] = useState(false);
    const [atualizarMenu, setAtualizarMenu] = useState(false);

    function abrirCarregamentoTabela(){
      setCarregandoTabela(true) 
  }

  function fecharCarregamentoTabela(){      
    setTimeout(() => {
      setCarregandoTabela(false)  
    }, 500)
  }

  function mostrarAnimacaoMenu(){
    setAtualizarMenu(true);
    setTimeout(() => {
      setAtualizarMenu(false);
    }, 1000);
  }

    function abrirCarregamento(texto){
        setTitulo(texto);
        setCarregando(true);      
        setFechamento(false);  
          
    }

    function fecharCarregamento(atualizarMenu=false){      
      return new Promise((resolve, reject) => {
        setTimeout(() => {

          setFechamento(true);          

          setTimeout(() => {
            resolve();
            setCarregando(false);                        
            if(atualizarMenu){
              setTimeout(() => {
                mostrarAnimacaoMenu();                
              }, 700);
            }
          }, 1000);


        }, 900);  
        
      });  
    }

    function mostrarAnimacaoMenu(){
      setAtualizarMenu(true);
      setTimeout(() => {
        setAtualizarMenu(false);
      }, 1000);
    }

    return (
        <LoaderContext.Provider
          value={{
            abrirCarregamento,
            fecharCarregamento,
            atualizarMenu,
            carregando,
            carregandoTabela,
            abrirCarregamentoTabela,
            fecharCarregamentoTabela
          }}
        >
            {carregando && <ModalCarregando fechar={() => fecharCarregamento()} titulo={titulo} fechamento={fechamento} />}
            {children}
        </LoaderContext.Provider>
      );
    };
    
    export const useLoader = () => useContext(LoaderContext);