import { FormControlLabel } from "@mui/material";
import styled from "@emotion/styled";

export const FormControlLabelCustomizado = styled(FormControlLabel)`
  .MuiTypography-root {
    color: ${({ theme }) => theme.cores.textoSimples};
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-weight: 400;
  }
`;
