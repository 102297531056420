import styled from "@emotion/styled";

export const StyledTd = styled.td`
  min-width: 100%;
  font-size: 15px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;

  color: ${(props) => props.theme.adaptativo("neutral10", "neutral100")};

  padding: 24px 16px;
  text-align: left;
`;
