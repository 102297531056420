/*
    Esse arquivo é um objeto que contém a estrutura de navegação do sistema temporariamente,
    na integração com o backend, esse objeto será substituído por um objeto que será retornado de lá.

    Na hora de fazer a integração, temos duas opções:

    1 - Converter o objeto que vem de lá pra esse formato
    2 - Adaptar os componentes que usam esse objeto pra usar o formato que vem de lá, que seriam o header e o footer
*/

const objetoNavegacao = {
  navegacao: {
    "Dashboard": {
      "Visão Geral": "/",
    },
    "Produtos": () => {
    
    },
    "Clientes": {
      "Visão Geral": {
        "Visão Geral": "/",
      },
    },
    "Finanças": "/",
    "Fiscal": "/",
    "Consultas Rápidas": "/",
    "Analises e Relatórios": "/",
  },

  // navegacao: {
  //   "Home": {
  //     "Produtos": "www.google.com.br",
  //     "Faturamento": "/",
  //     "Pedidos": "/",
  //     "Financeiro": "/",
  //   },
  //   "Cadastros": {
  //     "Produtos": {
  //       "Produtos": "/cadastro/produto",
  //       "Grupos": "/cadastro/produto/grupo",
  //       "Locais de Produção": "/cadastro/produto/local-producao",
  //       "Menus": "/cadastro/produto/menus",
  //       "Observações": "/cadastro/produto/observacao",
  //       "Tabela de Preços": "/cadastro/produto/tabela-preco",
  //       "Variações": "/cadastro/telas_produto/tamanho.xhtml",
  //       "Cardápio": {
  //         "Autoatendimento": "/cadastro/waychef/cardapio-totem",
  //         "Waychef": "/cadastro/waychef/cardapio",
  //         "Ifood": "/cadastro/waychef/cardapio-ifood",
  //       },
  //     },
  //     "Clientes": {
  //       "Clientes": "/cadastro/cliente",
  //       "Classificações": "/cadastro/cliente/classificacao",
  //       "Ramo de atividade": "/cadastro/ramo-atividade",
  //       "Regiões": "/cadastro/cliente/regiao",
  //       "Status de Crédito": "/cadastro/status-credito",
  //       "Waywallet": {
  //         "Carteira": "/financeiro/bandeira-cartao?o=waywallet",
  //         "Lojistas": "/waywallet/lojistas",
  //       },
  //     },
  //     "Finanças": {
  //       "Fornecedores e Credores": "/cadastro/fornecedor",
  //       "Representantes": "/cadastro/representante",
  //       "Cadastro de bandeiras":
  //         "/cadastro/telas_financeiro/financeiro/bandeira-cartao",
  //       "Categoria de sangria": "/cadastro/telas_financeiro/sangria_categoria",
  //       "Centro de Custos": "/financeiro/centro-custos",
  //       "Contas Correntes": "/financeiro/conta-corrente",
  //       "Plano de contas": "/financeiro/plano-contas",
  //     },
  //     "Fiscal": {
  //       "CFOP": "/fiscal/cop",
  //       "Classficação Fiscal Contribuinte - ICMS":
  //         "/cadastro/produto/fiscal/classificacao-fiscal-contribuinte",
  //       "ICMS": "/cadastro/produto/fiscal/icms",
  //       "PIS/COFINS": "/cadastro/produto/fiscal/pis-cofins",
  //       "Tributação por UF de Destino":
  //         "/cadastro/produto/fiscal/tributacao-por-uf-destino",
  //     },
  //     "Estoque": {
  //       "Local de estocagem": "/cadastro/produto/local-estocagem",
  //       "Setores": "/cadastro/telas_empresa/setor",
  //     },
  //   },
  //   "Operacional": {
  //     "Produtos": {
  //       "Mix de produtos": "/cadastro/produto/mix",
  //       "Promoções": "/cadastro/produto/promocao",
  //       "Remarcação de Preços": "/cadastro/produto/remarcacao-preco",
  //     },
  //     "Estoque": {
  //       "Inventário": "/lancamento/inventario",
  //       "Lançamentos": "/consulta/ajustes-estoque",
  //       "Produção": "/lancamento/estoque-producao",
  //       "Ajustes": {
  //         "Entrada Avançada": "/estoques/dci-entrada",
  //         "Saída Avançada": "/estoques/dci-saida",
  //         "Simples": "/lancamento/ajuste-estoque",
  //         "Zerar estoque": "/funcionalidades/zerar-estoque",
  //       },
  //       "Transferência": {
  //         "Externa": "/estoques/dci-transferencia",
  //         "Interna": "/lancamento/estoque-transferencia",
  //       },
  //     },
  //     "Fiscal": {
  //       "Consultar NF-e/NFS-e": "/nfe/consulta",
  //       "Gerenciador de NF-e": "/",
  //       "Gerenciador de NFC-e": "/",
  //       "Emitir NF-e/NFS-e": {
  //         "Nota Fiscal Complementar": "/nfe/nf_complementar",
  //         "Nota Fiscal de Entrada": "/nfe/emissao-entrada",
  //         "Nota Fiscal de Saída": "/nfe/emissao-saida",
  //       },
  //       "Lançar NF-e": {
  //         "Nota Fiscal de Entrada": "/nfe/lancamento-entrada",
  //       },
  //     },
  //     "Finanças": {
  //       "Controle de Cartões": {
  //         "Manutenção dos créditos": "/controle-cartoes/manutencao-credito",
  //         "Transações Realizadas": "/controle-cartoes/transacao-cartao",
  //       },
  //       "Movimentações": {
  //         "Cobrança bancaria": "/financeiro/remessa-bancaria",
  //         "Conferência de caixa": "/financeiro/conferencia-caixa",
  //         "Contas Correntes": "/financeiro/movimentacao-caixa",
  //         "Contas a pagar": "/financeiro/movimentacao-caixa?o=pagar",
  //         "Contas a receber": "/financeiro/movimentacao-caixa?o=receber",
  //         "Pagamento em lote": "/financas/movimentacoes/pagamento-lote",
  //         "Recebimento em lote": "/financas/movimentacoes/recebimento-lote",
  //       },
  //     },
  //     "Clientes": {
  //       "Fatura": {
  //         "Extrato conta": "/cliente/fatura/extrato",
  //         "Faturamento de vendas": "/cliente/fatura/faturamento",
  //         "Saldo de créditos": "/cliente/fatura/saldo-de-creditos",
  //       },
  //       "Relacionamento": {
  //         "Waywallet": {
  //           "Transações": "/waywallet/transacoes ",
  //         },
  //       },
  //     },
  //   },
  //   "Relatórios": {
  //     "Cadastrais": {
  //       "Clientes": "/relatorios/cliente",
  //       "Ficha técnica": "/relatorios/relatorio_ficha_tecnica.xhtml",
  //       "Produtos": "/relatorios/produto",
  //       "Tributação": {
  //         "ICMS": "/relatorios/tributacao-icms",
  //         "PIS/COFINS": "/relatorios/tributacao-pis-cofins",
  //       },
  //     },
  //     "Estoque": {
  //       "Disponível": "/relatorios/estoque/disponivel",
  //       "Em falta": "/relatorios/estoque/em-falta",
  //       "Geral": "/relatorios/produto-estoque.xhtml",
  //       "Histórico de movimentação":
  //         "/relatorios/estoque/movimentacao-produto.xhtml",
  //       "Movimentação de insumos": "/relatorios/estoque/movimentacao-insumos",
  //     },
  //     "Faturamento": {
  //       "Cancelamentos": {
  //         "Itens cancelados": "/relatorios/itens-cancelados",
  //       },
  //     },
  //     "Fiscal": {
  //       "Listagem aux. Escrituração":
  //         "/relatorios/faturamento/tributacao/listagem-aux-escrituracao-fiscal",
  //     },
  //     "Monetário": {
  //       "Anual (mês a mês)": "/relatorios/faturamento-mensal",
  //       "Atendimentos": "/relatorios/atendimento",
  //       "Comissão do vendedor": "/relatorios/comissao-vendedor.xhtml",
  //       "Faturamento por período": "/relatorios/faturamento_periodo_dia.xhtml",
  //       "Forma de pagamento": "/relatorios/atendimento-finalizadora",
  //       "Por módulo": "/relatorios/faturamento/monetario/por-modulo",
  //       "Taxas de serviço":
  //         "/relatorios/faturamento/monetário/taxas-servico-vendedor",
  //     },

  //     "Módulos": {
  //       "Controle de tempo produção":
  //         "/relatorios/relatorio_controle_tempo_producao.xhtml",
  //       "Delivery": "/relatorios/atendimento-delivery",
  //     },
  //     "Produtos vendidos": {
  //       "Por grupo": "/relatorios/faturamento-por-grupo",
  //       "Por pacote": "/relatorios/pacotes-vendidos",
  //       "Por período": "/relatorios/produtos-vendidos",
  //       "Por tabela de preço": "/relatorios/produtos-vendidos-por-tabela",
  //       "Por vendedor": "/relatorios/produtos-vendidos-por-vendedor.xhtml",
  //     },
  //     "Análise Gerencial": {
  //       "DFC Mensal": "/financeiro/fluxo-caixa",
  //       "DRE Mensal": "/financeiro/dre-gerencial",
  //     },
  //   },
  //   "Serviços": {
  //     "Serviços do sistema": "/configuracao/servicos",
  //     "Aplicações": "/configuracao/servicos",
  //   },
  //   "Segurança": {
  //     "Configurações de usuário": {
  //       "Perfis de usuário": "/cadastro/telas_usuario/perfil_empresa.xhtml",
  //       "Usuário": "/cadastro/usuario",
  //     },
  //     "Dados da Empresa": {
  //       "Funcionários": "/cadastro/funcionario",
  //       "Minha empresa": "/configuracao/empresa",
  //       "Pagamento": "/empresa/gerenciar-assinatura",
  //     },
  //   },
  //   "Consultas Rápidas": {
  //     "Atendimentos": "/consulta/atendimento",
  //     "Caixas": "/relatorios/operacao-caixa",
  //   },
  // },
};

export default objetoNavegacao;
