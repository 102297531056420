export const ResponseCalculoExtras = {
  map(data) {
    return {
      total: data.total,
      listaItens: data.listaItens.map((i) => mapItemCalculoExtra(i)),
    };
  },
};

function mapItemCalculoExtra(i) {
  return {
    descricao: i.descricao,
    valor: i.valorTotal,
    idItemConfiguracao: i.idItemConfiguracao,
  };
}
