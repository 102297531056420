import React from "react";
import { useTheme } from "@emotion/react";
import { Container } from "./styles";

export const IndicadorStatus = ({ texto, cor, style }) => {
  const tema = useTheme();
  function definirCores() {
    switch (cor) {
      case "amarelo":
        return {
          color: tema.cores.toastifyWarningTexto,
          backgroundColor: tema.adaptativo(
            "semanticAlert2",
            "toastifyWarningFundo"
          ),
        };
      case "verde":
        return {
          color: tema.cores.semanticSuccess3,
          backgroundColor: tema.cores.semanticSuccess2,
        };
      case "vermelho":
        return {
          color: tema.adaptativo("semanticDanger2", "semanticDanger3"),
          backgroundColor: tema.adaptativo(
            "semanticDanger3",
            "semanticDanger2"
          ),
        };
      case "azul":
        return {
          color: tema.adaptativo("semanticInfo3", "semanticInfo1"),
          backgroundColor: tema.cores.semanticInfo2,
        };
      case "cinza":
        return {
          color: tema.adaptativo("textoInverso", "textoInverso"),
          backgroundColor: tema.cores.corStatusCinza,
        };
      default:
        return {
          color: tema.cores.textoSimples,
          backgroundColor: tema.adaptativo("neutral40", "neutral80"),
        };
    }
  }

  return <Container style={{ ...definirCores(), ...style }}>{texto}</Container>;
};
