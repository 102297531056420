import styled from "@emotion/styled";

export const Container = styled.div`
  height: 24px;
  width: 100%;
  max-width: 100px;
  display: flex;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 12px;
`;
