import { ReactComponent as IconeLupaLight } from "../../../assets/icons/lupa-light.svg";
import { ReactComponent as IconeLupaDark } from "../../../assets/icons/lupa-dark.svg";
import { IconeBotao } from "../../../pages/selecaoEmpresas/styles";
import { TemaCustomizadoContext } from "../../../contexts/theme";
import { useContext } from "react";
import { CampoDeTexto } from "../../CampoInput";
import { StyledTh, StyledTrHeader, TextFieldIcon } from "./styles";

export function HeaderTabela({ dados }) {
  const { getTemaDarkAtivo } = useContext(TemaCustomizadoContext);
  const booleanTema = getTemaDarkAtivo();


  return (
    <>
      <StyledTrHeader dados={dados}>
        {dados.colunasTabelaSecundaria &&
          dados.colunasTabelaSecundaria.map((coluna) => {
          
            return (
              <>
                <StyledTh
                key={`table-head-cell-${coluna} `}
                >
                  {coluna.titulo}
                </StyledTh>
              </>
            );
          })}
      </StyledTrHeader>
    </>
  );
}
