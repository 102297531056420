import styled from "@emotion/styled";
import { BottomSheet } from "react-spring-bottom-sheet";

export const BottomSheetCustomizada = styled(BottomSheet)`
    --rsbs-bg: ${({ theme }) => theme.cores.surface2};
`;

export const ContainerBottomSheet = styled.div`
    padding-right: 16px;
    padding-left: 42px;
    padding-bottom: 24px;
`

export const IconeBotao = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;

  &:hover {
    opacity: 0.7;
    transition: 0.2s;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`

export const HeaderBottomSheet = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    padding-left: 8px;
    margin-bottom: 48px;
    padding-right: 32px;
`

export const TextoAtalhos = styled.p`
    font-family: "Poppins", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.adaptativo("textActionDisable", "textActionSuccessAlternative")};
`


export const StyledHr = styled.hr`
    border: 1px solid ${({ theme }) => theme.adaptativo("textActionDisable", "textActionSuccessAlternative")};
    width: 100%;
`

export const ContainerColunas = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 50px;

    @media (max-width: 1100px) {
        flex-direction: column;
        gap: 24px;
    }
`

export const ColunaAtalho = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (max-width: 1100px) {
        width: 100%;
    }
`

export const LinhaAtalho = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;

    @media (max-width: 1100px) {
        gap: 20px;
    }

    @media (max-width: 360px) {
        gap: 10px
    }
`

export const ContainerTeclasAtalho = styled.div`
    display: flex;
    align-items: center;
    border-radius: 2px;
    gap: 8px;
`

export const TeclaAtalho = styled.div`
    width: 52px;
    height: 52px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.cores.surfaceActionHoverPrimary};
    display: flex;
    align-items: center;
    justify-content: center;
`

export const TextoTeclaAtalho = styled.p`
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.cores.textAction};
`