import { Columns } from "phosphor-react";
import Popup from "reactjs-popup";
import styled from "@emotion/styled";

export const ContainerTabelaGlobal = styled.div`
  overflow-x: auto;
  width: 100%;
`;

export const ContainerDownloadRelatorio = styled.div`
  display: flex;
  justify-content: end;
  padding: 16px;

  background-color: ${(props) => props.theme.cores.brand10brand80};

  /* border-bottom: 1px solid ${(props) => props.theme.cores.surface2}; */
`;

export const TextFieldIcon = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: -35px;
    margin-bottom: -10px;
  }
`;

export const BotoesIcone = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  svg {
    color: ${(props) => props.theme.cores.actionActive};
    height: 16px;
    width: 16px;
  }
`;

export const BotaoAzulRefatorado = styled.button`
  cursor: pointer;
  border: none;
  display: flex;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: 137px;
  height: 40px;
  border-radius: 8px;
  font-weight: 700;

  background-color: transparent;

  padding: 8px 24px;

  font-size: 16px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  line-height: 1.5;

  color: ${(props) => props.theme.cores.neutral100};
  fill: ${(props) => props.theme.cores.neutral100brand80};
  background-color: ${(props) => props.theme.cores.actionActive};
  border: ${(props) => `1px solid ${props.theme.cores.actionActive}`};

  &:hover {
    background-color: ${(props) =>
      props.theme.temaDarkAtivo
        ? props.theme.cores.actionHover
        : props.theme.cores.brand40Default};
    color: ${(props) => props.theme.cores.neutral10};
    fill: ${(props) => props.theme.cores.brand80};
    border: ${(props) => `1px solid ${props.theme.cores.brand40Default}`};
    transition: color 0.3s ease-in-out, border 0.3s ease-in-out,
      background-color 0.3s ease-in-out;
  }

  &:active {
    background-color: ${(props) => props.theme.cores.actionPressed};
    color: ${(props) => props.theme.cores.textoInverso};
    fill: ${(props) => props.theme.cores.textoInverso};
    border: ${(props) => `1px solid ${props.theme.cores.actionActive}`};
  }
`;

export const IconeColunas = styled(Columns)`
  display: flex;
  align-items: center;
`;

export const StyledTabela = styled.table`
  border-collapse: collapse;

  /* display: grid;
  grid-template-columns: 150px repeat(3, minmax(150px, 1fr)); */
  width: 100%;
`;

export const ContainerSemDados = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 400px;
  width: 100%;

  p {
    font-size: 22px;
    font-family: monospace;
  }
`;

export const StyledPopupDownload = styled(Popup)`
  &-overlay {
    background-color: ${(props) =>
      props.theme.adaptativo("actionDisable", "surface2")};
  }

  &-content {
    max-width: 60px;
    background-color: ${(props) =>
      props.theme.adaptativo("actionDisable", "surface2")};
    border: none;

    & .popup-arrow {
      color: ${(props) => props.theme.adaptativo("actionDisable", "surface2")};
      stroke: ${(props) => props.theme.adaptativo("actionDisable", "surface2")};
    }
  }
`;

export const ContainerBotoesPopup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button:nth-child(1) {
    border-bottom: 1px solid
      ${(props) => props.theme.adaptativo("bordaCheckbox", "textActionDisable")};
    padding-bottom: 5px;
  }

  button:nth-child(2) {
    padding-top: 5px;
  }
`;
export const BotaoPopup = styled.button`
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: 700;
  font-family: "Source Sans Pro", sans-serif;
  cursor: pointer;

  color: ${(props) => props.theme.cores.textoSimples};
  &:hover {
    opacity: 0.7;
  }
`;

export const StyledPopupColunas = styled(Popup)`
  &-content {
    padding: 8px;
    background-color: ${(props) =>
      props.theme.adaptativo("actionDisable", "surface2")};
    border: none;
    max-height: 220px;
    overflow-y: auto;

    & .popup-arrow {
      color: ${(props) => props.theme.adaptativo("actionDisable", "surface2")};
      stroke: ${(props) => props.theme.adaptativo("actionDisable", "surface2")};
    }
  }
`;

export const ContainerCheckbox = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    padding: 8px 0;
    font-size: 15px;
    font-weight: 400px;
  }
`;

export const StyledHr = styled.hr`
  border-color: ${(props) =>
    props.theme.adaptativo("actionDisable", "neutral40")};
`;

export const PaginacaoTabela = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: ${(props) => props.theme.cores.brand10brand80};
  padding: 6px 14px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const ContainerPaginaAtual = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 200px;
  height: 50px;
`;
export const PaginaAtual = styled.span`
  font-size: 17px;
  line-height: 21px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  min-width: 65px;
`;

export const ContainerIconeAvancarRetroceder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-width: 304px;
  min-height: 32px;

  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

export const NumeroPagina = styled.span`
  font-size: 17px;
  font-family: "Source Sans Pro", sans-serif;
  line-height: 21px;
  padding: 8px;

  cursor: pointer;

  color: ${(props) => props.theme.adaptativo("brand80", "neutral100")};
`;

export const ContainerNumeroPagina = styled.div`
  width: 97px;
  height: 33px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.cores.backgroundBoxPagina};
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid ${(props) => props.theme.cores.corBoxPagina};
`;

export const TextoNumeroPagina = styled.p`
  color: ${(props) => props.theme.cores.corBoxPagina};
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
`;
export const TextoFiltroSemResultado = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: ${(props) => props.theme.adaptativo("brand80", "neutral100")};
  font-family: "Comfortaa", sans-serif;
  font-size: 19px;
  font-weight: 600;
`;
