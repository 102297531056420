export const ResponseContratoWaychef = {
  mapContratoComItens(data) {
    return mapResponseToPlanoComItens(data);
  },
};

export const RequestContratoWaychef = {
  mapContratoComItens(data) {
    return mapRequestToPlanoComItens(data);
  },
};

function mapResponseToPlanoComItens(plano) {
  return {
    id: plano.idEmpresaContrato,
    dataCancelamento: plano.dataCancelamento,
    descricao: plano.descricao,
    idPlanoPadrao: plano.idPlanoPadrao,
    limiteContasPagar: plano.limiteContasPagar,
    limiteContasReceber: plano.limiteContasReceber,
    limiteFaturamento: plano.limiteFaturamento,
    listaItens: plano.listaItens.map((item) => mapResponseToItemPlano(item)),
    statusContrato: plano.tipoStatusContrato,
    valorTotal: plano.valorTotal,
    tipoRenovacaoContrato: plano.tipoRenovacaoContrato,
  };
}

function mapResponseToItemPlano(item) {
  return {
    descricaoModuloContrato: item.descricaoModuloContrato,
    modulo: item.modulo,
    possuiItem: item.possuiItem,
    descricaoItem: item.nomeItemDescricao,
    tipoItemWaychef: item.tipoItemPlanoWaychef,
    idItemConfiguracao: item.idItemConfiguracao,
  };
}

function mapRequestToPlanoComItens(plano) {
  return plano;
}

function mapRequestToItemPlano(item) {
  return item;
}
