import styled from "@emotion/styled";

export const Linha = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: inherit;
`;

export const Botao = styled.button`
  cursor: pointer;
  border: none;
  flex-direction: row;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  font-weight: 700;
`;

export const Icone = styled.div`
  fill: inherit;
  color: inherit;
  justify-content: center;
  align-items: center;
  display: flex;
  svg {
    font-size: 21px;
  }
`;

/// Botao Azul

export const BotaoBaseAzulPreenchido = styled(Botao)`
  color: ${(props) => props.theme.cores.textActionPrimary};
  fill: ${(props) => props.theme.cores.textActionPrimary};
  background-color: ${(props) => props.theme.cores.surfaceActionActivePrimary};
  transition: background-color 0.3s ease-in-out, border 0.2s ease-in-out,
    color 0.3s ease-in-out;
  &:hover {
    background-color: ${(props) => props.theme.cores.surfaceActionHoverPrimary};
    color: ${(props) => props.theme.cores.textActionPrimaryAlernative};
    fill: ${(props) => props.theme.cores.textActionPrimaryAlernative};
  }

  &:active {
    background-color: ${(props) =>
    props.theme.cores.surfaceActionPressedPrimary};
    background-color: ${(props) =>
    props.theme.cores.surfaceActionPressedPrimary};
    color: ${(props) => props.theme.cores.textActionPrimary};
    fill: ${(props) => props.theme.cores.textActionPrimary};
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    background-color: ${(props) => props.theme.cores.surfaceActionDisabled};
    color: ${(props) => props.theme.cores.textActionDisabled};
    fill: ${(props) => props.theme.cores.textActionDisabled};    
    font-family: Source Sans Pro;
  }

  font-size: 16px;
  font-weight: 600;  
`;

export const BotaoBaseAzulVazado = styled(Botao)`
  color: ${(props) => props.theme.cores.surfaceActionActivePrimary};
  fill: ${(props) => props.theme.cores.surfaceActionActivePrimary};
  border: 1px solid ${(props) => props.theme.cores.surfaceActionActivePrimary};
  background-color: transparent;
  transition: 0.3s;

  &:hover {
    color: ${(props) => props.theme.cores.textActionPrimaryAlernative};
    fill: ${(props) => props.theme.cores.textActionPrimaryAlernative};
    background-color: ${(props) => props.theme.cores.surfaceActionHoverPrimary};
    transition: background-color 0.3s ease-in-out, border 0.2s ease-in-out,
      color 0.3s ease-in-out;
    border: 1px solid transparent;
  }

  &:active {
    color: ${(props) =>
    props.theme.cores.textActionPrimaryAlernative}!important;
    color: ${(props) =>
    props.theme.cores.textActionPrimaryAlernative}!important;
    fill: ${(props) => props.theme.cores.textActionPrimaryAlernative}!important;
    background-color: ${(props) =>
    props.theme.cores.surfaceActionPressedPrimary};
    background-color: ${(props) =>
    props.theme.cores.surfaceActionPressedPrimary};
    border: 1px solid transparent;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  font-size: 16px;
  font-weight: 600;

  &:disabled {
    color: ${(props) => props.theme.cores.textActionDisable};
    background-color: ${(props) => props.theme.cores.actionDisable};
    fill: ${(props) => props.theme.cores.textActionDisable};
    border: 1px solid ${(props) => props.theme.cores.actionDisable};
    cursor: not-allowed;
  }
`;

export const BotaoBaseAzulSemFundo = styled(Botao)`
  color: ${(props) => props.theme.cores.actionActive};
  fill: ${(props) => props.theme.cores.actionActive};
  background-color: transparent;
  text-decoration: underline;
  border: none;

  &:hover {
    color: ${(props) => props.theme.cores.actionHover};
    fill: ${(props) => props.theme.cores.actionHover};
    transition: color 0.3s ease-in-out, border 0.2s ease-in-out;
  }

  &:active {
    color: ${(props) => props.theme.adaptativo("brand60", "actionPressed")};
    fill: ${(props) => props.theme.adaptativo("brand60", "actionPressed")};
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    color: ${(props) => props.theme.cores.textActionDisabled}; 
    font-family: Source Sans Pro;
  }
`;

/// Botao Vermelho

export const BotaoBaseVermelhoPreenchido = styled(Botao)`
  color: ${(props) => props.theme.adaptativo("neutral100", "semanticDanger3")};
  fill: ${(props) => props.theme.cores.neutral100};
  background-color: ${(props) =>
    props.theme.adaptativo("semanticDanger2", "semanticDanger2")};
  /* border: ${(props) =>
    `1px solid ${props.theme.adaptativo(
      "semanticDanger1",
      "semanticDanger3"
    )}`}; */
  &:hover {
    color: ${(props) =>
    props.theme.adaptativo("semanticDanger1", "neutral100")};
    fill: ${(props) => props.theme.adaptativo("semanticDanger1", "neutral100")};
    /* border: ${(props) =>
    `1px solid ${props.theme.adaptativo(
      "semanticDanger3",
      "semanticDanger3"
    )}`}; */
    background-color: ${(props) =>
    props.theme.adaptativo("semanticDanger3", "semanticDanger3")};
    transition: background-color 0.3s ease-in-out, border 0.2s ease-in-out,
      color 0.3s ease-in-out;
  }

  &:active {
    background-color: ${(props) => props.theme.cores.semanticDanger1};
    color: ${(props) => props.theme.cores.neutral100};
    fill: ${(props) => props.theme.cores.neutral100};
    border: ${(props) => `1px solid ${props.theme.cores.semanticDanger1}`};
  }
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    background-color: ${(props) => props.theme.cores.surfaceActionDisabled};
    color: ${(props) => props.theme.cores.textActionDisabled};
    fill: ${(props) => props.theme.cores.textActionDisabled};    
    font-family: Source Sans Pro;
  }

  font-size: 15px;
  font-weight: 600;
`;

export const BotaoBaseVermelhoVazado = styled(Botao)`
  color: ${(props) => props.theme.cores.semanticDanger2};
  fill: ${(props) => props.theme.cores.semanticDanger2};
  background-color: "transparent";
  border: ${(props) => `1px solid ${props.theme.cores.semanticDanger2}`};

  &:hover {
    background-color: ${(props) => props.theme.cores.semanticDanger3};
    color: ${(props) =>
    props.theme.temaDarkAtivo
      ? props.theme.cores.neutral100
      : props.theme.cores.semanticDanger2};
    fill: ${(props) =>
    props.theme.temaDarkAtivo
      ? props.theme.cores.neutral100
      : props.theme.cores.semanticDanger2};
    border: ${(props) => `1px solid ${props.theme.cores.semanticDanger3}`};
    transition: background-color 0.3s ease-in-out, border 0.2s ease-in-out;
  }

  &:active {
    background-color: ${(props) => props.theme.cores.semanticDanger1};
    color: ${(props) => props.theme.cores.neutral100};
    fill: ${(props) => props.theme.cores.neutral100};
    border: ${(props) => `1px solid ${props.theme.cores.semanticDanger1}`};
  }

  font-size: 16px;
  font-weight: 600;
`;

export const BotaoBaseVermelhoSemFundo = styled(Botao)`
  color: ${(props) => props.theme.cores.semanticDanger2};
  fill: ${(props) => props.theme.cores.semanticDanger2};
  background-color: transparent;
  transition: 0.3s;
  text-decoration: none;
  border: none;

  &:hover {
    color: ${(props) => props.theme.cores.semanticDanger3};
    fill: ${(props) => props.theme.cores.semanticDanger3};
    transition: background-color 0.3s ease-in-out, border 0.2s ease-in-out,
      color 0.3s ease-in-out;
  }

  &:active {
    color: ${(props) => props.theme.cores.semanticDanger1};
    fill: ${(props) => props.theme.cores.semanticDanger1};
  }
`;

/// Botao Verde

export const BotaoBaseVerdePreenchido = styled(Botao)`
  color: ${(props) => props.theme.cores.textActionSuccess};
  fill: ${(props) => props.theme.cores.textActionSuccess};
  background-color: ${(props) => props.theme.cores.surfaceActionActiveSuccess};

  &:hover {
    color: ${(props) => props.theme.cores.textActionSuccess};
    fill: ${(props) => props.theme.cores.textActionSuccess};
    background-color: ${(props) => props.theme.cores.surfaceActionHoverSuccess};
    transition: background-color 0.3s ease-in-out, border 0.2s ease-in-out,
      color 0.3s ease-in-out;
  }

  &:active {
    color: ${(props) =>
    props.theme.cores.textActionSuccessAlternative}!important;
    fill: ${(props) =>
    props.theme.cores.textActionSuccessAlternative}!important;
    background-color: ${(props) =>
    props.theme.cores.surfaceActionPressedSuccess};
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    background-color: ${(props) => props.theme.cores.surfaceActionDisabled};
    color: ${(props) => props.theme.cores.textActionDisabled};
    fill: ${(props) => props.theme.cores.textActionDisabled};    
  }
`;

export const TextoBotao = styled.div``;

export const BotaoBaseVerdeVazado = styled(Botao)`
  color: ${(props) =>
    props.theme.adaptativo("semanticSuccess3", "semanticSuccess2")};
  fill: ${(props) =>
    props.theme.adaptativo("semanticSuccess3", "semanticSuccess2")};
  background-color: "transparent";
  transition: 0.3s;
  border: ${(props) =>
    `1px solid ${props.theme.adaptativo(
      "semanticSuccess3",
      "semanticSuccess2"
    )}`};
  transition: 0.3s;
  &:hover {
    background-color: ${(props) =>
    props.theme.adaptativo("semanticSuccess2", "semanticSuccess1")};
    color: ${(props) =>
    props.theme.adaptativo("semanticSuccess1", "neutral100")};
    fill: ${(props) =>
    props.theme.adaptativo("semanticSuccess1", "neutral100")};
    border: ${(props) =>
    `1px solid ${props.theme.adaptativo(
      "semanticSuccess2",
      "semanticSuccess1"
    )}`};
    transition: color 0.3s ease-in-out, border 0.3s ease-in-out,
      background-color 0.3s ease-in-out;
  }

  &:active {
    background-color: ${(props) =>
    props.theme.adaptativo("semanticSuccess1", "semanticSuccess3")};
    color: ${(props) => props.theme.cores.neutral100};
    fill: ${(props) => props.theme.cores.neutral100};
    border: ${(props) => `1px solid ${props.theme.cores.semanticSuccess2}`};
  }

  font-size: 16px;
  font-weight: 600;
`;

export const BotaoBaseVerdeSemFundo = styled(Botao)`
  color: ${(props) =>
    props.theme.adaptativo("semanticSuccess3", "semanticSuccess2")};
  fill: ${(props) =>
    props.theme.adaptativo("semanticSuccess3", "semanticSuccess2")};
  background-color: transparent;
  text-decoration: underline;
  border: none;

  &:hover {
    color: ${(props) =>
    props.theme.adaptativo("semanticSuccess1", "semanticSuccess3")};
    fill: ${(props) =>
    props.theme.adaptativo("semanticSuccess1", "semanticSuccess3")};
  }

  &:active {
    color: ${(props) =>
    props.theme.adaptativo("semanticSuccess1", "semanticSuccess3")};
    fill: ${(props) =>
    props.theme.adaptativo("semanticSuccess1", "semanticSuccess3")};
  }
`;

export const ItemVazio = styled.p``;
