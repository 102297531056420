import {
  ContainerAccordions1,
  ContainerAccordionsDireita,
  ContainerAccordionsEsquerda,
} from "./styles";
import { useEffect, useState } from "react";
import {
  agruparItensPorModulo,
  gerarItemExpansao,
} from "./componentes/ModularizadorItens";
import { useTheme } from "@emotion/react";
import useTamanhoTela from "../../../../utils/useTamanhoTela";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../../../contexts/api";

export function ItensWaymenu({ contrato, aplicacao }) {
  const { largura } = useTamanhoTela();
  const tema = useTheme();
  const navigate = useNavigate();
  const { get, put } = useApi();

  const [itensContratoModularizados, setItensContratoModularizados] = useState(
    []
  );

  useEffect(() => {
    setItensContratoModularizados(agruparItensPorModulo(contrato.listaItens));
  }, []);

  return (
    <div>
      {/*itens plano*/}
      <ContainerAccordions1>
        <ContainerAccordionsEsquerda>
          {Object.keys(itensContratoModularizados).map((modulo, index) => {
            if (itensContratoModularizados[modulo]) {
              return gerarItemExpansao(itensContratoModularizados[modulo]);
            }
          })}
        </ContainerAccordionsEsquerda>

        <ContainerAccordionsDireita></ContainerAccordionsDireita>
      </ContainerAccordions1>
    </div>
  );
}
