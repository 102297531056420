import { get } from "lodash";
import { StyledTd } from "./styles";
import { useLoader } from "../../../contexts/loader";
import { Skeleton } from '@mui/material'

export function CelulaLinhaTabela({ item }) {
  const { carregandoTabela } = useLoader()

  return (
    <StyledTd>
      {carregandoTabela ? (
        <Skeleton
          sx={{
            height: "18.67px",
            transform: "none",
          }}
          animation="wave"
        />
      ) : (
        item
      )}
    </StyledTd>
  );
}
