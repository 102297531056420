import { TextField } from "@mui/material";
import { BottomSheet } from "react-spring-bottom-sheet";
import Popup from "reactjs-popup";
import styled from "@emotion/styled";

export const ContainerCartoes = styled.div`
  display: flex;
  gap: 28px;

  @media (max-width: 920px) {
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 290px;
  }
`;

export const AdicionarCartao = styled.div`
  width: 316px;
  height: 175px;
  border: 1px solid ${({ theme }) => theme.adaptativo("neutral10", "neutral60")};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  > button > svg {
    color: ${({ theme }) => theme.adaptativo("botaoModal", "neutral60")};
  }

  @media (max-width: 400px) {
    width: 100%;
  }
`;
