import {
  BotaoPopup,
  BotoesIcone,
  ContainerBotoesPopup,
  ContainerDownloadRelatorio,
  ContainerIconeAvancarRetroceder,
  ContainerNumeroPagina,
  ContainerPaginaAtual,
  ContainerSemDados,
  ContainerTabelaGlobal,
  NumeroPagina,
  PaginaAtual,
  PaginacaoTabela,
  StyledPopupDownload,
  StyledTabela,
  TextoFiltroSemResultado,
  TextoNumeroPagina,
} from "./styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

import { useTheme } from "@emotion/react";
import tornarSlider from "../../utils/tornarSlider";
import { HeaderTabela } from "./HeaderTabela";
import { useEffect, useRef } from "react";

import { LinhaTabela } from "./LinhaTabela";
import useTamanhoTela from "../../utils/useTamanhoTela";
import Carregando from "../../pages/carregando";

export function TabelaSimples({ dados = undefined, item }) {
  const { largura } = useTamanhoTela();
  const theme = useTheme();
  const refTabelaScroll = useRef();

  useEffect(() => {
    tornarSlider(refTabelaScroll.current);
  }, [refTabelaScroll]);
  return item ? (
    <>
      <ContainerTabelaGlobal ref={refTabelaScroll}>
        <StyledTabela dados={dados.gridColunaTabelaSecundaria}>
          <thead>
            <HeaderTabela dados={dados} />
          </thead>
          <tbody>
            <LinhaTabela dados={dados} itensTabela={item} />
            {item?.itensTabelaSecundaria &&
              item?.itensTabelaSecundaria?.length === 0 && (
                <TextoFiltroSemResultado>
                  Sem dados para exibir
                </TextoFiltroSemResultado>
              )}
          </tbody>
        </StyledTabela>
      </ContainerTabelaGlobal>
    </>
  ) : (
    <Carregando adaptavel={true} />
  );
}
