import { useEffect, useState } from "react";

export default function useTamanhoTela() {
  function getTamanhoTela() {
    return {
      largura: window.innerWidth,
      altura: window.innerHeight,
    };
  }

  const [tamanhoTela, setTamanhoTela] = useState(getTamanhoTela());

  useEffect(() => {
    const updateDimension = () => {
      setTamanhoTela(getTamanhoTela());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [tamanhoTela]);

  return tamanhoTela;
}
