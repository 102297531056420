import { Accordion, AccordionDetails, TextField } from "@mui/material";
import Popup from "reactjs-popup";
import styled from "@emotion/styled";

export const Valor = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: rgba(30, 168, 52, 1);

  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.25px;
  }
`;

export const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  margin: 0;
  margin-bottom: 0;
  background-color: ${(props) => props.theme.adaptativo("brand10", "brand70")};

  svg {
    color: ${(props) => props.theme.textoContrato};
  }
`;

export const PopupCustomizado = styled(Popup)`
  &-content {
    background-color: ${(props) => props.theme.cores.surface2};
    height: 430px;
    width: 70vw;
    border-radius: 16px;
    overflow-y: scroll;
    border: none;
    width: 868px;
  }

  &-overlay {
  }
`;

export const ContainerPopup = styled.div`
  padding-top: 34px;
  padding-left: 21px;
  padding-bottom: 39px;
  padding-right: 24px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 26px;
`;

export const CabecalhoModalCancelarRecorrencia = styled.div`
  /* padding-left: 10px; */
  box-sizing: border-box;
  margin-bottom: 14px;
`;

export const TextoCabecalhoModalCancelarRecorrencia = styled.p`
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 37px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.cores.textoSimples};
`;

export const FooterModal = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
`;

export const BotaoDesistir = styled.button`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 8px;
  width: 99px;
  height: 48px;
  border: 1px solid ${({ theme }) => theme.cores.actionActive};
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
  background: transparent;

  &:hover {
    opacity: 0.7;
  }
`;

export const BotaoCancelarRecorrencia = styled.button`
  width: 214px;
  height: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 8px;
  background: ${({ theme }) => theme.cores.semanticDanger2};
  cursor: pointer;
  transition: 0.3s;
  border: none;

  &:hover {
    opacity: 0.7;
  }
`;
export const TextoBotaoCancelarRecorrencia = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.cores.semanticDanger3};
  width: 182px;
  height: 32px;
`;

export const Hr = styled.hr`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.cores.corHr};
  margin-bottom: 36px;
`;

export const PopupCustomizadoMin = styled(Popup)`
  &-content {
    background-color: ${(props) => props.theme.cores.surface2};
    height: auto;
    width: 70vw;
    border-radius: 16px;
    overflow-y: scroll;
    border: none;
    width: 500px;
  }

  &-overlay {
  }
`;
