import { BottomSheet } from "react-spring-bottom-sheet";
import Popup from "reactjs-popup";
import styled from "@emotion/styled";

export const BottomSheetCustomizada = styled(BottomSheet)`
  --rsbs-bg: ${({ theme }) => theme.cores.surface2};
`;

export const ContainerBottomSheet = styled.div`
  padding: 0px 40px 40px 40px;
`;
export const TituloModal = styled.div`
  position: fixed;
  height: 67px;
  background-color: ${({ theme }) => theme.cores.surface2};
  z-index: 10;
  top: 0;
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 96%;
  padding-top: 20px;
  justify-content: space-between;
  h3 {
    font-size: 26px;
  }

  @media (max-width: 768px) {
    width: 85%;
  }
`;

export const BotaoFechar = styled.div`
  cursor: pointer;
  padding: 0px 15px;
`;

export const CardLojaImage = styled.img`
  object-fit: contain;
  width: 60px;
  height: 60px;
`;

export const SelecionarEmpresa = styled.h3`
  cursor: pointer;
  padding: 10px;
  text-decoration: underline;
  font-size: 15px;
  color: ${({ theme }) => theme.cores.visualizarEmpresa};
`;

export const Tabela = styled.div`
  margin-top: 50px;
`;
