import styled from "@emotion/styled";

export const StyledTh = styled.th`
  font-family: "Comfortaa", sans-serif;
  font-size: 14px;
  font-weight: 500;

  padding: 16px;

  text-align: left;

  background-color: ${(props) => props.theme.cores.brand10brand80};

  color: ${(props) => props.theme.adaptativo("neutral10", "neutral100")};
`;

export const TextFieldIcon = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: -35px;
    margin-bottom: -10px;
  }
`;

export const StyledTrHeader = styled.tr`
  display: grid;
    grid-template-columns: ${({ dados }) => dados.gridColunaTabelaSecundaria ? dados.gridColunaTabelaSecundaria : `repeat(${dados?.colunasTabelaSecundaria?.length}, minmax(200px, 1fr))`};
`