export const ResponseInformacoesGerais = {
  map(data) {
    return mapResponseInformacoesGerais(data);
  },
};

export const RequestInformacoesGerais = {
  map(data) {
    return mapRequestInformacoesGerais(data);
  },
};

function mapResponseInformacoesGerais(plano) {
  return {
    dataInicioContrato: plano.dataInicioContrato,
    dataVencimentoContrato: plano.dataVencimentoContrato,
    recorrencia: plano.tipoRecorrenciaPlano,
    statusPagamento: mapStatusPagamento(plano.tipoStatusPagamento),
    valorExtras: parseFloat(plano.valorExtras).toFixed(2),
    valorTotal: parseFloat(plano.valorTotal).toFixed(2),
    sessoesExtras: mapSessoesExtras(plano),
    idEmpresaContratoWaychef: plano.idEmpresaContratoWaychef,
    idEmpresaContratoWaymenu: plano.idEmpresaContratoWaymenu,
    listaContratos: plano.empresaContratoResumidoList.map((item) =>
      mapContrato(item)
    ),
    ...plano,
  };
}

function mapContrato(contrato) {
  return {
    nomeAplicacao: contrato.nomeAplicacao,
    tipoPlano: contrato.tipoPlano,
    quantidadeSessoes: contrato.numeroUsuarios,
    status: mapStatusContrato(contrato.status),
    tipoAplicacaoContrato: contrato.tipoAplicacaoContrato,
  };
}

function mapStatusContrato(status) {
  switch (status) {
    case "Ativo":
      return { status: "PAGO", descricao: "Ativo", cor: "verde" };
    case "Não contratado":
      return {
        status: "NAO_CONTRATADO",
        descricao: "Não Contratado",
        cor: "azul",
      };
    case "Pendente de pagamento":
      return {
        status: "PENDENTE",
        descricao: "Pendente",
        cor: "amarelo",
      };
    case "Cancelado":
      return { status: "CANCELADO", descricao: "Cancelado", cor: "vermelho" };

    default:
      return { status: status, descricao: "", cor: "" };
  }
}

function mapStatusPagamento(status) {
  switch (status) {
    case "PAGO":
      return { status: status, descricao: "Pagamento em dia", cor: "verde" };
    case "PENDENTE":
      return { status: status, descricao: "Pendente", cor: "amarelo" };
    case "CANCELADO":
      return { status: status, descricao: "Cancelado", cor: "vermelho" };

    default:
      return { status: status, descricao: "", cor: "" };
  }
}

function mapSessoesExtras({
  numeroUsuariosContratos,
  numeroUsuariosExtras,
  numeroUsuariosExtrasCancelados,
}) {
  return {
    quantidadeSessoesExtrasCanceladas: numeroUsuariosExtrasCancelados,
    quantidadeSessoesPlano: numeroUsuariosContratos,
    quantidadeSessoesExtras: numeroUsuariosExtras,
    total: numeroUsuariosContratos + numeroUsuariosExtras,
  };
}

function mapRequestInformacoesGerais(plano) {
  return plano;
}
