import { css } from "@emotion/css";

import { ReactComponent as IconeInfo } from "../assets/icons/icone-info-vazado.svg";
import { ReactComponent as IconeSucesso } from "../assets/icons/icone-sucesso-vazado.svg";
import { ReactComponent as IconeAviso } from "../assets/icons/icone-aviso-vazado.svg";
import { ReactComponent as IconeErro } from "../assets/icons/icone-erro-vazado.svg";

const ToastifyStyle = ({ theme }) => css`
  :root {
    --toastify-toast-min-height: 70px;
    --toastify-toast-max-height: 800px;
    --toastify-z-index: 99999;

    // Fonte
    --toastify-font-family: "Source Sans Pro", sans-serif;
    .Toastify__toast-body {
      font-size: 17px;
    }

    --toastify-color-info: ${theme.cores.semanticInfo2};
    --toastify-color-success: #b91d20 !important;
    --toastify-color-warning: ${theme.cores.semanticAlert2};
    --toastify-color-error: ${theme.cores.semanticDanger3};

    --toastify-text-color-info: #d2ffef;
    --toastify-text-color-success: #b91d20 !important;
    --toastify-text-color-warning: ${theme.cores
      .semanticAlert3}; // light3 => dark1
    --toastify-text-color-error: ${theme.cores.semanticDanger2};

    // Cores da progress bar e do close icon
    .Toastify__toast-theme--colored.Toastify__toast--info {
      --toastify-color-transparent: var(--toastify-text-color-info);

      .Toastify__close-button {
        color: var(--toastify-text-color-info);
      }
    }
    .Toastify__toast-theme--colored.Toastify__toast--success {
      --toastify-color-transparent: var(--toastify-text-color-success);

      .Toastify__close-button {
        color: var(--toastify-text-color-success);
      }
    }
    .Toastify__toast-theme--colored.Toastify__toast--warning {
      --toastify-color-transparent: var(--toastify-text-color-warning);

      .Toastify__close-button {
        color: var(--toastify-text-color-warning);
      }
    }
    .Toastify__toast-theme--colored.Toastify__toast--error {
      --toastify-color-transparent: var(--toastify-text-color-error);

      .Toastify__close-button {
        color: var(--toastify-text-color-error);
      }
    }
    .Toastify__toast-theme--colored.Toastify__toast--default {
      .Toastify__progress-bar {
        background-color: #0000007f;
        /* background: linear-gradient(
                    to right,
                    #4cd964,
                    #5ac8fa,
                    #007aff,
                    #34aadc,
                    #5856d6,
                    #ff2d55
                ); */
      }

      .Toastify__close-button {
        color: var(--toastify-text-color-light);
      }
    }

    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;

    .Toastify__toast-body > div:last-child {
      word-break: keep-all;
    }

    .Toastify__toast-container {
      width: var(--toastify-toast-min-width);
    }

    .Toastify__toast-icon {
      width: unset;
    }
  }
`;

const ToastifyIcon = ({ type }) => {
  const icons = {
    "info": <IconeInfo />,
    "success": <IconeSucesso />,
    "warning": <IconeAviso />,
    "error": <IconeErro />,
  };

  return icons[type];
};

export { ToastifyIcon };
export default ToastifyStyle;
