import {
  Document,
  Font,
  Image,
  PDFViewer,
  Page,
  Text,
  View,
} from "@react-pdf/renderer";
import styles from "./stylesPdf";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import RobotoBold from "../../../../assets/fonts/Roboto/Roboto-Bold.ttf";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../contexts/auth";
import Comfortaa from "../../../../assets/fonts/Comfortaa/Comfortaa_Regular.ttf";
import ComfortaaBold from "../../../../assets/fonts/Comfortaa/Comfortaa_Bold.ttf";
import { useApi } from "../../../../contexts/api";
import { useLoader } from "../../../../contexts/loader";
import Logo from "../../../../assets/images/logo-pdf-relatorio.png";
import formatarReal from "../../../../utils/formatarReal";

export default function RelatorioPdfContasAReceber() {
  const [dadosFiltro, setDadosFiltro] = useState([]);
  const { abrirCarregamento, fecharCarregamento } = useLoader();
  const [searchParams] = useSearchParams();
  const { dadosAuth } = useAuth();
  const { post, get } = useApi();
  const [dadosRequest, setDadosRequest] = useState([]);
  const [filtros, setFiltros] = useState();

  Font.register({
    family: "RobotoBold",
    src: RobotoBold,
  });

  Font.register({
    family: "ComfortaaBold",
    src: ComfortaaBold,
  });

  Font.register({
    family: "Comfortaa",
    src: Comfortaa,
  });

  Font.registerHyphenationCallback((word) => [word]);

  dayjs.locale("pt-br");

  const [dadosCampos, setDadosCampos] = useState([]);
  async function buscarDadosCampoSelecao() {
    try {
      const response = await get("/relatorios/financas/dados/contas/receber");
      setDadosCampos(response.data);
    } catch (e) {
      console.error(e);
    } finally {
    }
  }

  function encontrarItens(item, idItem, listaItem) {
    if (!item) {
      return;
    }
    const arr = [];
    item[idItem].find((id) => {
      dadosCampos[listaItem]?.map((idDadosCampos) => {
        if (id === idDadosCampos.id) {
          return arr.push(idDadosCampos.descricao);
        }
      });
    });
    return arr;
  }

  function encontrarContaCorrente(array, idConta) {
    const conta = array?.listaContaCorrente?.find((item) => {
      return item.id === idConta;
    });

    return conta ? conta.descricao : "";
  }

  useEffect(() => {
    pegarDadosStorage();
    buscarDadosCampoSelecao();
  }, []);

  const dataCompletaEscrita = () => {
    const data = new Date();

    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZoneName: "short",
    };

    const formatter = new Intl.DateTimeFormat("pt-BR", options);
    return formatter.format(data);
  };

  const pegarDadosStorage = async () => {
    try {
      const dados = JSON.parse(localStorage.getItem("relatorioContasReceber"));
      setFiltros(dados.filtros);

      gerarRelatorio(dados.filtros);

      //Limpar storage após recuperar dados
      // localStorage.removeItem('relatorioContasReceber');
    } catch (error) {
      // window.location.href = "/analise-relatorios/financas/contas-a-receber";
    }
  };

  function agrupamentoItens(array, key) {
    if (!array) {
      return;
    }
    return Object.values(
      array?.reduce((acc, item) => {
        const chave = item[key];

        if (!acc[chave]) {
          acc[chave] = {
            idPlanoConta: chave,
            descricaoPlanoConta: item.descricaoPlanoConta,
            itens: [],
          };
        }

        acc[chave].itens.push(item);

        return acc;
      }, {})
    );
  }

  const itensPorPagina = 10;
  const [dadosAgrupadosPorPagina, setDadosAgrupadosPorPagina] = useState([]);
  const resultado = agrupamentoItens(dadosRequest, "idPlanoConta");

  function dividirItens(itens, limite) {
    const arraysDivididos = [];
    let arrayAtual = [];

    for (const item of itens) {
      arrayAtual.push(item);

      if (arrayAtual.length === limite) {
        arraysDivididos.push(arrayAtual);
        arrayAtual = [];
      }
    }

    if (arrayAtual.length > 0) {
      arraysDivididos.push(arrayAtual);
    }

    return arraysDivididos;
  }

  // Processamento do array original
  for (let i = 0; i < resultado?.length; i++) {
    const objeto = resultado[i];

    if (i === 0) {
      const primeiroArrayItens = objeto.itens.slice(0, 14);
      objeto.itens = [
        primeiroArrayItens,
        ...dividirItens(objeto.itens.slice(14), 20),
      ];
    } else {
      objeto.itens = dividirItens(objeto.itens, 20);
    }
  }

  function calcularSomaValores(dados) {
    if (!dados) {
      return;
    }
    return dados.map((item) => {
      const somaItens = item.itens.flat().reduce(
        (subtotal, subItem) => {
          subtotal.valorRateio += subItem.valorRateio || 0;
          subtotal.valorPago += subItem.valorPago || 0;
          return subtotal;
        },
        { valorRateio: 0, valorPago: 0 }
      );

      const ultimoArray = item.itens[item.itens.length - 1];
      if (ultimoArray) {
        ultimoArray.push({
          total: somaItens,
        });
      }

      return item;
    });
  }

  const resultadoFinal = calcularSomaValores(resultado);

  function gerarPaginas(dados) {
    return (
      <>
        {dados &&
          dados.map((item, index) => (
            <>
              {item &&
                item.itens.map((subItens, page) => (
                  <Page size="A4" style={styles.page}>
                    <View key={page} style={styles.coluna}>
                      {index === 0 && page === 0 && (
                        <>
                          <View style={styles.header}>
                            <Text style={styles.headerTitulo}>
                              {dadosAuth.empresaDTO.apelido}
                            </Text>
                            <Text style={styles.headerRelatorio}>
                              Relatório de Contas a Receber
                            </Text>
                          </View>
                          <View style={styles.headerBottom} />
                          <View
                            style={{ ...styles.sectionFiltros, minHeight: 100 }}
                          >
                            <Text style={styles.filtrosTitulo}>
                              Filtros aplicados
                            </Text>
                            <br />
                            {filtros?.periodoVencimentoDe &&
                              filtros?.periodoVencimentoAte && (
                                <Text style={styles.filtros}>
                                  Periodo de Vencimento de{" "}
                                  {dayjs(filtros?.periodoVencimentoDe).format(
                                    "DD/MM/YYYY"
                                  )}{" "}
                                  até{" "}
                                  {dayjs(filtros?.periodoVencimentoAte).format(
                                    "DD/MM/YYYY"
                                  )}
                                </Text>
                              )}
                            {filtros?.periodoEmissaoDe &&
                              filtros?.periodoEmissaoAte && (
                                <Text style={styles.filtros}>
                                  Periodo de Emissão de{" "}
                                  {dayjs(filtros?.periodoEmissaoDe).format(
                                    "DD/MM/YYYY"
                                  )}{" "}
                                  até{" "}
                                  {dayjs(filtros?.periodoEmissaoAte).format(
                                    "DD/MM/YYYY"
                                  )}
                                </Text>
                              )}

                            {filtros?.periodoPagamentoDe &&
                              filtros?.periodoPagamentoAte && (
                                <Text style={styles.filtros}>
                                  Periodo de Pagamento de{" "}
                                  {dayjs(filtros?.periodoPagamentoDe).format(
                                    "DD/MM/YYYY"
                                  )}{" "}
                                  até{" "}
                                  {dayjs(filtros?.periodoPagamentoAte).format(
                                    "DD/MM/YYYY"
                                  )}
                                </Text>
                              )}
                            {filtros.statusTitulo && (
                              <Text style={styles.filtros}>
                                Status Título: {filtros?.statusTitulo}
                              </Text>
                            )}
                            {filtros.valor && (
                              <Text style={styles.filtros}>
                                Valor: {filtros?.valor}
                              </Text>
                            )}

                            {filtros.idContaCorrente && (
                              <Text style={styles.filtros}>
                                Conta Corrente:{" "}
                                {encontrarContaCorrente(
                                  dadosCampos,
                                  filtros.idContaCorrente
                                )}
                              </Text>
                            )}
                            {filtros.idCentroCusto.length && (
                              <Text style={styles.filtros}>
                                Centro Custo:{" "}
                                {filtros.idCentroCusto &&
                                  encontrarItens(
                                    filtros,
                                    "idCentroCusto",
                                    "listaCentroCustos"
                                  )
                                    .map((item) => {
                                      return item;
                                    })
                                    .join(", ")}
                              </Text>
                            )}

                            {filtros.idPlanoConta.length && (
                              <Text style={styles.filtros}>
                                Plano Conta:{" "}
                                {encontrarItens(
                                  filtros,
                                  "idPlanoConta",
                                  "listaPlanoContas"
                                )
                                  .map((item) => {
                                    return item;
                                  })
                                  .join(", ")}
                              </Text>
                            )}

                            {filtros?.operacoesControleCartao && (
                              <Text style={styles.filtros}>
                                Incluir Controle Cartão:{" "}
                                {filtros?.operacoesControleCartao
                                  ? "Sim"
                                  : "Não"}
                              </Text>
                            )}
                          </View>
                        </>
                      )}

                      {subItens &&
                        subItens.map((itensPagina, i) => {

                          return (
                            <>
                              {i === 0 && page === 0 && (
                                <>
                                  <View
                                    style={{
                                      ...styles.section,
                                      display: "flex",
                                      flexDirection: "row",
                                      width: "100%",
                                    }}
                                  >
                                    <View
                                      style={{
                                        ...styles.celulaTabelaTitulo,
                                        width: "17%",
                                      }}
                                    >
                                      <Text
                                        style={styles.celulaTabelaTextoTitulo}
                                      >
                                        Num. Documento
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        ...styles.celulaTabelaTitulo,
                                        width: "9%",
                                      }}
                                    >
                                      <Text
                                        style={styles.celulaTabelaTextoTitulo}
                                      >
                                        Emissão
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        ...styles.celulaTabelaTitulo,
                                        width: "9%",
                                      }}
                                    >
                                      <Text
                                        style={styles.celulaTabelaTextoTitulo}
                                      >
                                        Valor
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        ...styles.celulaTabelaTitulo,
                                        width: "8%",
                                      }}
                                    >
                                      <Text
                                        style={styles.celulaTabelaTextoTitulo}
                                      >
                                        % Rateio
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        ...styles.celulaTabelaTitulo,
                                        width: "9%",
                                      }}
                                    >
                                      <Text
                                        style={styles.celulaTabelaTextoTitulo}
                                      >
                                        Dt. Venc.
                                      </Text>
                                    </View>

                                    <View
                                      style={{
                                        ...styles.celulaTabelaTitulo,
                                        width: "8%",
                                      }}
                                    >
                                      <Text
                                        style={styles.celulaTabelaTextoTitulo}
                                      >
                                        Parcela
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        ...styles.celulaTabelaTitulo,
                                        width: "17%",
                                      }}
                                    >
                                      <Text
                                        style={styles.celulaTabelaTextoTitulo}
                                      >
                                        Cliente
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        ...styles.celulaTabelaTitulo,
                                        width: "9%",
                                      }}
                                    >
                                      <Text
                                        style={styles.celulaTabelaTextoTitulo}
                                      >
                                        Valor pago
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        ...styles.celulaTabelaTitulo,
                                        width: "9%",
                                      }}
                                    >
                                      <Text
                                        style={styles.celulaTabelaTextoTitulo}
                                      >
                                        Dt. Pgto.
                                      </Text>
                                    </View>
                                  </View>
                                  <View
                                    style={{
                                      ...styles.section3,
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "100%",
                                    }}
                                  >
                                    <View
                                      key={item.idPlanoConta}
                                      style={{
                                        ...styles.section2,
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                      }}
                                    >
                                      <View
                                        style={{
                                          ...styles.celulaTabelaTituloPlanoContas,
                                          width: "23%",
                                        }}
                                      >
                                        <Text
                                          style={styles.celulaTabelaTextoTitulo}
                                        >
                                          Plano de contas
                                        </Text>
                                      </View>

                                      <View
                                        style={{
                                          ...styles.celulaTabelaTitulo,
                                          width: "76%",
                                        }}
                                      >
                                        <Text
                                          style={styles.celulaTabelaTextoTitulo}
                                        >
                                          {item.descricaoPlanoConta}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                </>
                              )}
                              {itensPagina.idPlanoConta ||
                                (itensPagina.idsPlanoConta && (
                                  <View
                                    style={{
                                      ...styles.section2,
                                      display: "flex",
                                      flexDirection: "row",
                                      backgroundColor:
                                        i % 2 ? "#d9d5d4" : "transparent",
                                    }}
                                  >
                                    <View
                                      style={{
                                        ...styles.celulaTabelaTitulo,
                                        width: "19%",
                                      }}
                                    >
                                      <Text style={styles.celulaTabelaTexto}>
                                        {itensPagina.numeroDocumento}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        ...styles.celulaTabelaTitulo,
                                        width: "9%",
                                      }}
                                    >
                                      <Text style={styles.celulaTabelaTexto}>
                                        {itensPagina.dataEmissao !== null &&
                                          dayjs(itensPagina.dataEmissao).format(
                                            "DD/MM/YYYY"
                                          )}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        ...styles.celulaTabelaTitulo,
                                        width: "10%",
                                      }}
                                    >
                                      <Text style={styles.celulaTabelaTexto}>
                                        {formatarReal(itensPagina.valorPago)}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        ...styles.celulaTabelaTitulo,
                                        width: "6%",
                                      }}
                                    >
                                      <Text style={styles.celulaTabelaTexto}>
                                        {itensPagina.percentualRateio}%
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        ...styles.celulaTabelaTitulo,
                                        width: "9%",
                                      }}
                                    >
                                      <Text style={styles.celulaTabelaTexto}>
                                        {itensPagina.dataVencimento !== null &&
                                          dayjs(
                                            itensPagina.dataVencimento
                                          ).format("DD/MM/YYYY")}
                                      </Text>
                                    </View>

                                    <View
                                      style={{
                                        ...styles.celulaTabelaTitulo,
                                        width: "6%",
                                      }}
                                    >
                                      <Text style={styles.celulaTabelaTexto}>
                                        {itensPagina.parcela}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        ...styles.celulaTabelaTitulo,
                                        width: "19%",
                                      }}
                                    >
                                      <Text
                                        style={{ ...styles.celulaTabelaTexto }}
                                      >
                                        {itensPagina.nomeBeneficiario}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        ...styles.celulaTabelaTitulo,
                                        width: "14%",
                                      }}
                                    >
                                      <Text style={styles.celulaTabelaTexto}>
                                        {formatarReal(itensPagina.valorRateio)}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        ...styles.celulaTabelaTitulo,
                                        width: "9%",
                                      }}
                                    >
                                      <Text style={styles.celulaTabelaTexto}>
                                        {itensPagina.dataPagamento !== null &&
                                          dayjs(
                                            itensPagina.dataPagamento
                                          ).format("DD/MM/YYYY")}
                                      </Text>
                                    </View>
                                  </View>
                                ))}

                              {itensPagina.total && (
                                <>
                                  <view style={styles.blockTextoTabela}>
                                    <view></view>
                                    <view></view>
                                  </view>
                                  <View
                                    style={{
                                      ...styles.sectionTotal,
                                      display: "flex",
                                      flexDirection: "row",
                                      width: "100%",
                                    }}
                                  >
                                    <View
                                      style={{
                                        ...styles.celulaFooter,
                                        width: "19%",
                                      }}
                                    >
                                      <view>
                                        <Text
                                          style={styles.textoTotalPlanoContas}
                                        >
                                          Total Plano de Contas:{" "}
                                        </Text>
                                      </view>
                                    </View>
                                    <View
                                      style={{
                                        ...styles.celulaFooter,
                                        width: "9%",
                                      }}
                                    >
                                      <Text
                                        style={styles.celulaTabelaTextoTitulo}
                                      ></Text>
                                    </View>
                                    <View
                                      style={{
                                        ...styles.celulaFooter,
                                        width: "10%",
                                      }}
                                    >
                                      <Text
                                        style={
                                          styles.textoTotalPlanoContasValorDireita
                                        }
                                      >
                                        {formatarReal(
                                          itensPagina.total.valorPago
                                        )}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        ...styles.celulaFooter,
                                        width: "6%",
                                      }}
                                    >
                                      <Text
                                        style={styles.celulaTabelaTextoTitulo}
                                      ></Text>
                                    </View>
                                    <View
                                      style={{
                                        ...styles.celulaFooter,
                                        width: "9%",
                                      }}
                                    >
                                      <Text
                                        style={styles.celulaTabelaTextoTitulo}
                                      >
                                        <Text
                                          style={
                                            styles.textoTotalPlanoContasValor
                                          }
                                        >
                                          {" "}
                                        </Text>
                                      </Text>
                                    </View>

                                    <View
                                      style={{
                                        ...styles.celulaFooter,
                                        width: "6%",
                                      }}
                                    ></View>
                                    <View
                                      style={{
                                        ...styles.celulaFooter,
                                        width: "19%",
                                      }}
                                    >
                                      <Text
                                        style={styles.celulaTabelaTextoTitulo}
                                      ></Text>
                                    </View>
                                    <View
                                      style={{
                                        ...styles.celulaFooter,
                                        width: "14%",
                                      }}
                                    >
                                      <Text
                                        style={
                                          styles.textoTotalPlanoContasValorDireita
                                        }
                                      >
                                        {formatarReal(
                                          itensPagina.total.valorRateio
                                        )}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        ...styles.celulaFooter,
                                        width: "9%",
                                      }}
                                    >
                                      <Text
                                        style={styles.celulaTabelaTextoTitulo}
                                      ></Text>
                                    </View>
                                  </View>
                                </>
                              )}
                            </>
                          );
                        })}

                      <View fixed={true} style={styles.footer}>
                        <View style={styles.footerLinha}>
                          <Text style={styles.footerTitulo}>ERP Waybe</Text>
                          <Image source={Logo} style={styles.logo} />
                        </View>
                        <View style={styles.footerLinha}>
                          <Text style={styles.footerTexto}>
                            Nome do usuário:{" "}
                            <Text style={styles.footerUsuario}>
                              {dadosAuth.usuarioLogadoDTO.nome}
                            </Text>
                          </Text>
                        </View>
                        <View style={styles.footerLinha}>
                          <Text style={styles.footerTexto}>
                            {dataCompletaEscrita()}
                          </Text>
                          <Text
                            render={({ pageNumber, totalPages }) =>
                              `Página ${pageNumber} de ${totalPages}`
                            }
                            style={styles.footerTexto}
                          />
                        </View>
                      </View>
                    </View>
                  </Page>
                ))}
            </>
          ))}
      </>
    );
  }

  async function gerarRelatorio(body) {
    if (!body) {
      return;
    }
    abrirCarregamento("Carregando dados do relatório...");
    try {
      const response = await post(
        `/relatorios/financas/contas/receber?size=9999`,
        body
      );
      setDadosRequest(response.data.content);
    } catch (e) {
      console.error(e);
    } finally {
      fecharCarregamento();
    }
  }

  return (
    <PDFViewer style={{ width: "100vw", height: "100vh" }}>
      <Document
        title={`Contas a receber por plano de contas ${dayjs().format(
          "DD/MM/YYYY HH:mm:ss"
        )}`}
      >
        {gerarPaginas(resultadoFinal)}
      </Document>
    </PDFViewer>
  );
}
