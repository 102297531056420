import {
  CabecalhoPopup,
  ContainerAccordion,
  ContainerBotoesPopup,
  ContainerItemAccordion,
  ContainerLinhaAccordion,
  ContainerOverflow,
  ContainerPopup,
  LinhaClara,
  LinhaEscura,
  PopupCustomizado,
  TextoCabecalhoAccordion,
  TituloPopup,
} from "../../../components/Contrato/Padrao/styles";
import { IconeBotao } from "../../selecaoEmpresas/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { TextoLinha } from "../../contratoCustomizado/cadastro/styles";
import { AccordionSummary, CircularProgress } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Check } from "phosphor-react";
import {
  BotaoCancelarModal,
  BotaoFazerPagamentoModal,
  TextoBotaoCancelarModal,
  TextoBotaoFazerPagamentoModal,
} from "../detalhesPlano/plano/styles";
import useTamanhoTela from "../../../utils/useTamanhoTela";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useApi } from "../../../contexts/api";
import { toast } from "react-toastify";
import {
  CabecalhoAccordion,
  ContainerCarregamento,
  ContainerDados,
  LinhaComparacao,
  LinhaItem,
  StyledAccordion,
  TextoCabecalhoAccordionDescricao,
  TypographyTitulo,
} from "./style";
import GerarModalResponsivo from "../../../components/GerarModalResponsivo";
import Carregando from "../../carregando";
import { BotaoAzulPreenchido } from "../../../components/Botao";

export default function ModalDetalharPlano({
  componenteTrigger,
  idPlanoParaDetalhar,
  planosObjeto,
  descricaoNovoPlano,
  fazerPagamento,
  comparacao,
  loadingCalculo = false,
}) {
  const { get } = useApi();
  const navigate = useNavigate();
  const { largura } = useTamanhoTela();
  const tema = useTheme();

  function ModuloContrato() {
    return planosObjeto?.length === 0 ? (
      <ContainerCarregamento>
        <Carregando />
      </ContainerCarregamento>
    ) : (
      <div>
        {comparacao ? (
          <CabecalhoAccordion>
            <div></div>
            <TextoCabecalhoAccordionDescricao>
              Plano atual
            </TextoCabecalhoAccordionDescricao>
            <TextoCabecalhoAccordionDescricao>
              Plano {descricaoNovoPlano}
            </TextoCabecalhoAccordionDescricao>
          </CabecalhoAccordion>
        ) : (
          <CabecalhoAccordion>
            <div></div>
            <TextoCabecalhoAccordionDescricao>
              <div></div>
            </TextoCabecalhoAccordionDescricao>
            <TextoCabecalhoAccordionDescricao>
              Disponibilidade
            </TextoCabecalhoAccordionDescricao>
          </CabecalhoAccordion>
        )}
        <StyledAccordion>
          <ContainerLinhaAccordion>
            <ContainerItemAccordion>
              {Object.keys(planosObjeto).map((modulo, index) => (
                <StyledAccordion
                  defaultExpanded={true}
                  key={"itemsContrato" + modulo}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <TypographyTitulo>{modulo}</TypographyTitulo>
                  </AccordionSummary>

                  {planosObjeto[modulo] &&
                    planosObjeto[modulo].map((item, index) => (
                      <LinhaComparacao
                        key={"comparacaoItem" + index + item.nome}
                      >
                        {comparacao ? (
                          <ItemLinha
                            descricao={item.nome}
                            antigo={item.planoAntigoPossui}
                            novo={item.planoNovoPossui}
                            fundoEscuro={index % 2 === 0}
                          />
                        ) : (
                          <ItemLinha
                            descricao={item.descricaoItem}
                            novo={item.inclusoNoPlano}
                            fundoEscuro={index % 2 === 0}
                          />
                        )}
                      </LinhaComparacao>
                    ))}
                </StyledAccordion>
              ))}
            </ContainerItemAccordion>
          </ContainerLinhaAccordion>
        </StyledAccordion>
      </div>
    );
  }
  function IconeStatusPossuiItem({ possuiItem }) {
    return (
      <div style={{ flex: 1, justifyContent: "center", display: "flex" }}>
        {possuiItem ? (
          <Check
            size={22}
            style={{ flex: 1 }}
            weight="bold"
            color="rgba(22, 156, 52, 1)"
          />
        ) : (
          <FontAwesomeIcon
            icon={faX}
            style={{
              flex: 1,
              // marginLeft: 9,
              width: 15,
              height: 15,
              color: `${tema.cores.semanticDanger2}`,
            }}
          />
        )}
      </div>
    );
  }
  function ItemLinha({ descricao, antigo, novo, fundoEscuro }) {
    let corFundo = fundoEscuro
      ? tema.adaptativo("surface3", "neutral10")
      : tema.cores.surface2;

    return (
      <LinhaItem
        style={{
          background: corFundo,
        }}
      >
        <TextoLinha>{descricao}</TextoLinha>
        {comparacao ? <IconeStatusPossuiItem possuiItem={antigo} /> : <div />}
        <IconeStatusPossuiItem possuiItem={novo} />
      </LinhaItem>
    );
  }
  return (
    <GerarModalResponsivo trigger={componenteTrigger} width={750}>
      {(close) => (
        <ContainerPopup>
          <CabecalhoPopup>
            <TituloPopup>
              {comparacao ? "Comparação de contrato" : "Detalhes do contrato"}
            </TituloPopup>
            <IconeBotao onClick={close}>
              <FontAwesomeIcon
                icon={faX}
                style={{
                  width: 20,
                  height: 20,
                  color: tema.cores.semanticDanger2,
                }}
              />
            </IconeBotao>
          </CabecalhoPopup>
          <ContainerOverflow>
            <ContainerDados>
              <ModuloContrato />
            </ContainerDados>
          </ContainerOverflow>
          <ContainerBotoesPopup>
            <BotaoCancelarModal onClick={close}>
              <TextoBotaoCancelarModal>Fechar</TextoBotaoCancelarModal>
            </BotaoCancelarModal>
            <BotaoAzulPreenchido
              texto={"Fazer o pagamento"}
              onClick={fazerPagamento}
              icone={
                loadingCalculo ? (
                  <CircularProgress
                    style={{
                      color: "white",
                      fill: "white",
                    }}
                    size={15}
                  />
                ) : null
              }
            />
          </ContainerBotoesPopup>
        </ContainerPopup>
      )}
    </GerarModalResponsivo>
  );
}
