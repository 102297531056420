import { ChipCustomizado } from "./styles";
import { ReactComponent as Icone_DeleteClaro } from "../../assets/icons/close_chip_claro.svg";
import { ReactComponent as Icone_DeleteEscuro } from "../../assets/icons/close_chip_escuro.svg";
import { useTheme } from "@emotion/react";

export default function Chip({
  label,
  color = "primary",
  variant = "outlined",
  imagem,
  ...rest
}) {
  const tema = useTheme();
  return (
    <ChipCustomizado
      {...rest}
      label={label}
      color={color}
      variant={variant}
      avatar={
        imagem && (
          <img
            src={imagem}
            alt="Chip Imagem"
            style={{ height: "60%", marginLeft: 8 }}
          />
        )
      }
      deleteIcon={
        tema.temaDarkAtivo ? <Icone_DeleteEscuro /> : <Icone_DeleteClaro />
      }
    />
  );
}
