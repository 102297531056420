import { useTheme } from "@emotion/react";
import useTamanhoTela from "../../../../utils/useTamanhoTela";
import { Container } from "../../../produtos/promocoes/styles";
import { IconeBotao } from "../../../selecaoEmpresas/styles";
import { ItemPreenchido, TextoCabecalhoMudarPagina } from "../styles";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CabecalhoMudarPagina,
  ContainerFundoBranco,
  TextoMeusCartoes,
} from "./styles";
import ListagemCartao from "../../cartoes/listagemCartao";

export function FormaPagamento() {
  const { largura } = useTamanhoTela();
  const theme = useTheme();

  return (
    <>
      <TextoMeusCartoes>Meus cartões</TextoMeusCartoes>
      <ListagemCartao />
    </>
  );
}
