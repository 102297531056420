import styled from "@emotion/styled";
import { CampoDeSelecao } from "../../../../components/CampoInput";

export const ContainerInputs = styled.div`
  display: flex;
  gap: 48px;
  margin-bottom: 32px;
  align-items: center;

  @media (max-width: 1013px) {
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
    justify-content: center;
    align-items: center;
  }

  label {
    padding-top: 0px !important;
  }
`;

export const ContainerCardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  margin-bottom: 24px;
  width: 100%;

  @media (max-width: 1330px) {
    grid-template-columns: auto auto;
  }

  @media (max-width: 915px) {
    grid-template-columns: auto;
  }
`;

export const ContainerBotoes = styled.div`
  display: flex;
  gap: 32px;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 32px;

  @media (max-width: 1013px) {
    justify-content: center;
  }
`;

export const BotaoLimparFiltros = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 129px;
  height: 40px;
  cursor: pointer;
  transition: 0.3s;
  background: transparent;
  border: 1px solid ${({ theme }) => theme.cores.semanticDanger2};
  border-radius: 8px;

  @media (max-width: 550px) {
    width: 50%;
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const TextoBotaoLimparFiltros = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.cores.semanticDanger2};

  @media (max-width: 375px) {
    font-size: 12px;
  }
`;

export const BotaoPesquisar = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 102px;
  height: 40px;
  background: ${({ theme }) => theme.cores.actionActive};
  cursor: pointer;
  transition: 0.3s;
  border-radius: 8px;
  border: none;

  @media (max-width: 550px) {
    width: 50%;
  }

  &:hover {
    opacity: 0.7;
  }
`;
export const BotaoPagar = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 102px;
  height: 40px;
  background: ${({ theme }) => theme.cores.surfaceActionActiveSuccess};
  cursor: pointer;
  transition: 0.3s;
  border-radius: 8px;
  border: none;

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.cores.textAction};

  @media (max-width: 375px) {
    font-size: 12px;
  }
  @media (max-width: 550px) {
    width: 50%;
  }

  &:hover {
    opacity: 0.7;
  }
`;
export const TextoBotaoPesquisar = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.cores.textAction};

  @media (max-width: 375px) {
    font-size: 12px;
  }
`;

export const Hr = styled.hr`
  border: 1px solid ${({ theme }) => theme.cores.corHr};
  width: 100%;
  margin-bottom: 42px;

  @media (max-width: 1013px) {
    margin-bottom: 16px;
  }
`;

export const TextoPendencias = styled.p`
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.adaptativo("neutralVariant10", "neutral100")};
  margin-bottom: 24px;
  @media (max-width: 1013px) {
    margin-bottom: 16px;
  }
`;

export const ContainerCardPendencias = styled(ContainerCardGrid)``;

export const ColunaCardPendencias = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
`;

export const ColunaCardFaturas = styled(ColunaCardPendencias)``;

export const CardPedencias = styled.div`
  width: 508px;
  min-width: 330px;
  height: 92px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.cores.corHr};
  padding-left: 24px;
  padding-top: 10px;
  padding-right: 12px;
  padding-bottom: 11px;

  @media (max-width: 1013px) {
    width: 100%;
    height: min-content;
    padding-left: 12px;
    padding-top: 10px;
    padding-right: 12px;
    padding-bottom: 11px;
  }

  @media (max-width: 450px) {
    min-width: unset;
  }
`;

export const TituloCardPendencias = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.cores.textoSimples};
  margin-bottom: 24px;

  @media (max-width: 1013px) {
    margin-bottom: 50px;
  }

  @media (max-width: 550px) {
    font-size: 17px;
  }
`;

export const LinhaInternaCardPendencias = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;

  @media (max-width: 430px) {
    flex-direction: column;
  }
`;

export const DataFooter = styled.p`
  width: 100%;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.cores.textoSimples};

  @media (max-width: 550px) {
    font-size: 15px;
  }
`;

export const ValorFooter = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  display: flex;
  flex-direction: column;
  font-size: 19px;
  line-height: 24px;
  display: flex;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.cores.textoSimples};

  @media (max-width: 550px) {
    font-size: 15px;
  }
`;

export const Bilhetagem = styled(ValorFooter)``;

export const AlinhamentoTexto = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  gap: 16px;
  margin-top: 32px;
  margin-bottom: 32px;
  p {
    font-size: 19px;
  }

  /* @media (max-width: 1550px) and (min-width: 1013px) {
    p {
      font-size: 13px;
    }
  } */
`;

export const TextoFaturas = styled.p`
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 26px;
  color: ${({ theme }) => theme.adaptativo("neutralVariant10", "neutral100")};
  margin-bottom: 42px;

  @media (max-width: 1013px) {
    margin-bottom: 16px;
  }
`;

export const ContainerCardFaturas = styled(ContainerCardGrid)``;

export const CabecalhoContainerContratosFooter = styled.div`
  height: 69px;
  display: flex;
  gap: 48px;
  justify-content: center;
  background-color: ${({ theme }) => theme.adaptativo("brand10", "brand70")};
  align-items: center;
  margin-right: -30px;
  margin-left: -30px;
  margin-bottom: 12px;
  margin-top: 12px;

  @media (max-width: 1013px) {
    gap: 24px;
    padding-left: 8px;
  }
`;

export const TextoContainerContratosFooter = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.adaptativo("actionActive", "neutral100")};
  @media (max-width: 880px) {
    font-size: 15px;
  }
`;

export const ContainerBodyContratosFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-right: -12px;
  padding-right: 8px;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 42px;
    display: block;
    background-color: ${({ theme }) => theme.adaptativo("brand20", "brand60")};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.cores.surfaceActionActivePrimary};
    border-radius: 5px;
  }

  @media (max-width: 1013px) {
    margin-right: 0px;
    padding-right: 0px;
    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  min-width: 380px;
  border-radius: 16px;
  padding-left: 12px;
  padding-top: 10px;
  padding-right: 12px;
  padding-bottom: 11px;
  background: ${({ theme }) => theme.adaptativo("neutral100", "surface4")};
  border: 1px solid ${({ theme }) => theme.cores.corHr};

  @media (max-width: 380px) {
    min-width: unset;
  }
`;

export const CabecalhoTituloContratosFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const ContainerPagamento = styled.div`
  width: 49%;
  height: 500px;
  border-radius: 16px;
  background: ${({ theme }) => theme.cores.surface4};
  padding-left: 16px;
  padding-top: 16px;
  padding-right: 14px;
  padding-bottom: 32px;
`;

export const CabecalhoContainerPagamento = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 60px;
`;

export const ContainerNumeroPagamento = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TituloNumeroPagamento = styled.p`
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 27px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.adaptativo("actionPressed", "neutral100")};
`;

export const NumeroPagamento = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.adaptativo("actionPressed", "neutral100")};
`;

export const AvisoPagamento = styled.div`
  width: 100%;
  height: 60px;
  padding-left: 22px;
  padding-right: 28px;
  padding-bottom: 10px;
  padding-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.cores.toastifyWarningFundo};

  @media (max-width: 768px) {
    height: unset;
  }
`;

export const AvisoPagamentoBilhetagem = styled(AvisoPagamento)`
  height: min-content;
  margin-bottom: 24px;
`;

export const IconeETextoAvisoPagamento = styled.div`
  display: flex;
  gap: 16px;
`;

export const TextoAvisoPagamento = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.adaptativo("semanticAlert3", "semanticAlert1")};
`;

export const TextoAlterarCartao = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.cores.neutral10};
  letter-spacing: 0.25px;
  text-decoration-line: underline;
`;

export const ContainerBoleto = styled.div`
  width: 420px;
  display: flex;
  justify-content: space-between;
  padding: 24px;
  background: ${({ theme }) => theme.cores.semanticInfo2};
  align-items: center;

  @media (max-width: 1013px) {
    width: 100%;
    padding-left: 24px;
    padding-top: 19px;
    padding-right: 9px;
    padding-bottom: 9px;
    flex-direction: column;
    align-items: normal;
  }
`;

export const IconeETextoBoleto = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const TextoBoleto = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.cores.semanticInfo1};
`;

export const TextoCopiarCodigo = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.cores.neutral10};
`;

export const InformacoesPagamento = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 72px;
  gap: 12px;
  flex-wrap: wrap;

  @media (max-width: 1200px) {
    justify-content: space-between;
  }
`;

export const ColunaInformacoesPagamento = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TituloColunaInformacoesPagamento = styled.p`
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.adaptativo("actionPressed", "neutral100")};
`;

export const DescricaoColunaInformacoesPagamento = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.adaptativo("actionPressed", "neutral100")};
  margin-bottom: 10px;
`;

export const FooterPagamento = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerDescricaoFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TituloDescricaoFooter = styled.p`
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.adaptativo("actionPressed", "neutral100")};
`;

export const TextoDescricaoFooter = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.adaptativo("actionPressed", "neutral100")};
  width: 288px;
  height: 42px;
`;

export const ContainerValorFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TituloValorFooter = styled.p`
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.adaptativo("actionPressed", "neutral100")};
`;

export const TextoValorFooter = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.adaptativo("actionPressed", "neutral100")};
`;

export const ContainerFooterMobile = styled.div`
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 96px;
  border-radius: 16px 16px 0px 0px;
  background: ${({ theme }) => theme.adaptativo("brand10", "surface3")};
`;

export const ContainerDescricaoPagamento = styled.div`
  padding-top: 20px;
  padding-left: 8px;
  padding-bottom: 16px;
  background: ${({ theme }) => theme.cores.surface4};
  padding-right: 12px;
  border-radius: 0px 0px 16px 16px;
  margin-bottom: 40px;
`;

export const CabecalhoContainerDescricaoPagamento = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const ColunaInformacoesPagamentoMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const ContainerDescricaoFooterMobile = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardContainerContratosFooterEspecial = styled.div`
  width: 100%;
  height: 92px;
  border-radius: 16px 16px 0px 0px;
  padding-left: 24px;
  padding-top: 10px;
  padding-right: 12px;
  padding-bottom: 11px;
  background: ${({ theme }) => theme.adaptativo("neutral100", "surface4")};

  @media (max-width: 1013px) {
    width: 100%;
    height: 143px;
    padding-left: 12px;
    padding-top: 10px;
    padding-right: 12px;
    padding-bottom: 11px;
  }
`;

export const ContainerCardsMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const ContratoItemPreenchido = styled.div`
  width: 144px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 32px;
  gap: 8px;
  border-radius: 16px;
  background: ${({ theme }) => theme.cores.actionActive};
`;

export const Coluna = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerPaginacao = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 6px 14px;

  background-color: ${(props) => props.theme.cores.brand10brand80};

  @media (max-width: 768px) {
    justify-content: center;
  }
`;
export const ContainerPaginas = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 0px;
  }
`;

export const BotaoPaginacao = styled.button`
  font-size: 17px;
  font-family: "Source Sans Pro", sans-serif;
  line-height: 21px;
  padding: 8px;

  cursor: pointer;

  color: ${(props) => props.theme.adaptativo("brand80", "neutral100")};

  border: none;
  background-color: transparent;
`;

export const ContainerPaginaAtualTextfield = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TextFieldIcon = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: -35px;
    margin-bottom: -10px;
  }
`;
export const StyledCampoDeSelecao = styled(CampoDeSelecao)`
  &.MuiInputBase-root {
    height: 0;
  }
`;

export const IconeBotaoPage = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;

  &:disabled {
    opacity: 0.7;
    cursor: auto;
    span,
    svg {
      color: ${(props) => props.theme.cores.neutral70};
    }
  }
`;

export const IconsFirstAndLastPage = styled.span`
  font-size: 24px;

  display: flex;
  align-items: center;

  color: ${(props) => props.theme.cores.actionActive};
`;

export const BotaoPrimario = styled.button`
  color: ${({ accent = "#FFFFFF" }) => accent};
  background-color: ${({ color = "#000000" }) => color};
  box-shadow: none;
  border: none;
  cursor: pointer;
  border-radius: 4px;
`;

export const BotaoPagina = styled(BotaoPrimario)`
  font-size: ${({ selected }) => (selected ? "19px" : "17px")};
  font-weight: ${({ selected }) => (selected ? "600" : "400")};
  width: 32px;
  height: 32px;
  color: ${({ theme }) => theme.cores.textoSimples};
  font-family: "Source Sans Pro", sans-serif;

  /* @media (max-width: 768px) {
    width: 25px;
    height: 25px;
  } */
`;

export const ContainerModalDetalhes = styled.div`
  width: 800px;

  @media (max-width: 800px) {
    width: 100%;
    padding-bottom: 32px;
  }
`;

export const Linha = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CampoModalDetalhes = styled(ValorFooter)`
  font-size: 16px;
  margin-top: 12px;

  @media (max-width: 800px) {
    display: ${({ visivel }) => {
      return visivel ? "flex" : "none";
    }};
  }
`;

export const LinhaInternaModalDetalhes = styled(Linha)`
  @media (max-width: 800px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const TextoNenhumItemEncontrado = styled.h2`
  @media (max-width: 800px) {
    font-size: 16px;
  }
`;

export const ContainerNenhumaFaturaEncontrada = styled(Linha)`
  display: flex;
  justify-content: center;
  align-items: start;
  height: 20vh;
`;
