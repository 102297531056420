import { Fragment, useEffect } from "react";
import { useAuth } from "../../contexts/auth";
import Carregando from "../../pages/carregando";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../contexts/api";

export function RedirectWaymenu() {
  const { getDadosEmpresa } = useAuth();
  const navigate = useNavigate();
  let { tipo } = useParams();

  useEffect(() => {
    irParaCardapio();
  }, []);

  async function irParaCardapio() {
    let dadosEmpresaDTO = await getDadosEmpresa();
    let urlAmigavel = dadosEmpresaDTO?.urlAmigavel;
    if (urlAmigavel) {
      let url = "192.168.5.213:3000";

      if (baseURL) {
        if (baseURL.includes("192.168.5.237:")) {
          url = "192.168.5.213:3000";
        } else if (baseURL.includes("192.168.5.20:")) {
          url = "192.168.5.53:3000";
        } else if (baseURL.includes("192.168.5.207:")) {
          url = "192.168.5.65:3000";
        }
      }

      window.location.replace(`http://${url}/menu/${urlAmigavel}/${tipo}/`);
    } else {
      toast.error("url_amigavel não configurada");
      navigate("/");
    }
  }

  return (
    <Fragment>
      <Carregando />
    </Fragment>
  );
}
