import React, { useState, useEffect, useContext } from "react";
import { ThemeProvider, useTheme } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { NavegacaoContext } from "../../contexts/navegacao";

import { ReactComponent as IconeSeta } from "../../assets/icons/expand_more.svg";
import { ReactComponent as IconeMenu } from "../../assets/icons/icone_menu.svg";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as IconePredio } from "../../assets/icons/domain-icon.svg";
import useTamanhoTela from "../../utils/useTamanhoTela";
import {
  CaminhoLinha,
  Container,
  ContainerBottomSheetNavegacao,
  ContainerCaminho,
  ContainerIcone,
  ContainerRecortarCirculo,
  RecorteCirculo,
  Separador,
  BotaoNavegacao,
  OverlayFechar,
} from "./styles";
import ComponentePaginado from "../ComponentePaginado";
import { AuthContext } from "../../contexts/auth";
import { useMenu } from "../../contexts/menu";
import verificarPermissaoContrato from "../../utils/verificarPermissaoContrato";

const Footer = ({
  caminho = ["exemplo", "exemplo", "exemplo"],
  icon = "iconMenu",
  gerarIcone,
}) => {
  //////////////////////////////////////////// Constantes ////////////////////////////////////////////

  const LARGURA_QUEBRA = 1150;
  const ALTURA_FOOTER = 90;

  //////////////////////////////////////////// Hooks e getters ////////////////////////////////////////////
  const { largura, altura } = useTamanhoTela();
  let visivel = true; // Isso daqui poderia virar um getter

  const theme = useTheme();
  const [aberto, setAberto] = useState(false);
  const [navegacaoPadrao] = useContext(NavegacaoContext);
  const [navegacao, setNavegacao] = useState(navegacaoPadrao);
  const [listaMenuUtilizavelCopia, setListaMenuUtilizavelCopia] = useState();
  const { listaMenu } = useMenu();
  const [primeiraListagem, setPrimeiraListagem] = useState();
  useEffect(() => {
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    if (!primeiraListagem) {
      setListaMenuUtilizavelCopia();
      setTimeout(() => {
        setListaMenuUtilizavelCopia(listaMenu.menus);
      }, 1000);
    } else {
      setPrimeiraListagem(false);
    }
  }, [listaMenu]);

  useEffect(() => {
    if (listaMenuUtilizavelCopia == undefined) {
      setListaMenuUtilizavelCopia(listaMenu.menus);
    }
  }, [listaMenuUtilizavelCopia]);

  useEffect(() => {
    if (aberto) {
      setNavegacao(navegacaoPadrao);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [aberto]);

  useEffect(() => {
    if (!visivel) setAberto(true);
  }, [visivel]);

  const verificarMenusAtivos = (lista) => {
    try {
      const listaAtiva = {};
      for (let i = 0; i < Object.keys(lista).length; i++) {
        //Verificar se está ativo
        if (lista[Object.keys(lista)[i]].ativo) {
          const { larguraMinima, ...rest } = lista[Object.keys(lista)[i]];
          listaAtiva[Object.keys(lista)[i]] = { ...rest };
        }
      }

      return listaAtiva;
    } catch (erro) {
      return lista;
    }
  };
  //////////////////////////////////////////// Layout da Página ////////////////////////////////////////////
  function Corpo() {
    return (
      listaMenuUtilizavelCopia != undefined && (
        <>
          <OverlayFechar
            onClick={() => setAberto(!aberto)}
            style={{
              opacity: aberto ? 1 : 0,
              visibility: aberto ? "visible" : "hidden",
            }}
          />
          <ContainerBottomSheetNavegacao
            style={{
              paddingBottom: ALTURA_FOOTER + "px",
              overflow: "scroll",
              height: `${altura - 120}px`,
              bottom: aberto
                ? 0
                : -1 *
                (170 * Object.keys(navegacao).length - 1 + ALTURA_FOOTER) +
                "px",
              left: "0",
              transition: "bottom 0.45s ease-out",
            }}
          >
            <ComponentePaginado
              Filho={BotaoNavegacao}
              Separador={Separador}
              navegacaoInicial={listaMenuUtilizavelCopia}
              mobile={true}
            />
          </ContainerBottomSheetNavegacao>
          <ConteudoFooter />
        </>
      )
    );
  }

  //////////////////////////////////////////// COMPONENTES  ////////////////////////////////////////////
  function Caminho() {
    let caminhoGerado = [];
    for (let i = 0; i < caminho.length; i++) {
      caminhoGerado.push(
        <CaminhoLinha key={caminho[i] + i}>
          <p>{caminho[i]}</p>{" "}
          {i !== caminho.length - 1 && (
            <IconeSeta
              fill={theme.cores.textoSimples}
              style={{
                transform: "rotate(270deg)",
                marginLeft: "6px",
                marginRight: "6px",
              }}
            />
          )}
        </CaminhoLinha>
      );
    }
    return <ContainerCaminho>{caminhoGerado}</ContainerCaminho>;
  }

  function ConteudoFooter({ style }) {
    return (
      <Container
        style={{
          ...style,
        }}
        icon={"iconMenu"}
        onClick={() => setAberto(!aberto)}
      >
        <ContainerRecortarCirculo>
          <RecorteCirculo />
        </ContainerRecortarCirculo>
        <ContainerIcone>
          {!aberto ? (
            <FontAwesomeIcon
              icon={faBars}
              style={{ width: 27, height: 27, color: theme.cores.actionActive }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faXmark}
              style={{ width: 30, height: 30, color: theme.cores.actionActive }}
            />
          )}
        </ContainerIcone>
        <Caminho />
      </Container>
    );
  }

  ///////////////////////////////////////////////////////////

  return Corpo();
};

export default Footer;
