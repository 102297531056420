export const ResponsePagamentoCartao = {
    mapLista(data) {
        return data.map(c => mapCartaoReduzido(c));
    },
};


export const RequestPagamentoCartao = {
    mapCartao(data) {
        return mapCartao(data);
    },
};



function mapCartao(cartao) {
    return {
        id: cartao.id,
        numeroCartao: cartao.numeroCartao,
        validade: cartao.validade,
        codigoSeguranca: cartao.codigoSeguranca,
        nomeDonoCartao: cartao.nomeDonoCartao,
        cartaoPadrao: cartao.cartaoPadrao,
        cpf: cartao.cpf,
        tipoBandeiraCartao: cartao.tipoBandeiraCartao,
    };
}


function mapCartaoReduzido(cartao) {
    return {
        id: cartao.id,
        finalCartao: cartao.finalCartao,
        nomeDonoCartao: cartao.nomeDonoCartao,
        cartaoPadrao: cartao.cartaoPadrao
    };
}




