import styled from "@emotion/styled";

export const Container = styled.div`
  background: rgba(13, 13, 13, 1);
  width: 320px;
  height: 169px;
  padding-left: 19px;
  padding-right: 20px;
  padding-top: 18px;
  padding-bottom: 15px;
  border-radius: 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

  @media (max-width: 400px) {
    width: 100%;
  }
`;

export const Cabecalho = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

  > button > svg {
    font-size: 16px;
  }
`;

export const TextoCabecalho = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #ffffff;
`;

export const TextoNumero = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #ffffff;
  margin-bottom: 12px;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    
`;

export const BotaoCartaoPadrao = styled.button`
  width: 115px;
  height: 24px;
  background: rgba(209, 255, 238, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  gap: 10px;
  border-radius: 99px;
  border: none;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }
`;

export const TextoBotaoCartaoPadrao = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #006d44;
`;

export const BotaoDefinirCartaoPadrao = styled.button`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  gap: 10px;
  border: 1px solid #ffdbd1;
  border-radius: 99px;
  cursor: pointer;
  transition: 0.3s;
  background: transparent;

  &:hover {
    opacity: 0.7;
  }
`;

export const TextoBotaoDefinirCartaoPadrao = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #ffdbd1;
`;
