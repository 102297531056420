export const mesmaCor = (cor) => (index, { corDoTema }) => {
    return cor || corDoTema || "transparent";
}

export const mesmaCorComControleDestacado = (corPadrao, corControle, total) => (index, { corDoTema, corControleDoTema }) => {
    if (index === total - 1) return corControle || corControleDoTema || "transparent";

    return corPadrao || corDoTema || "transparent";
}

export const alternado = (cor1, cor2) => (index, { corDoTema1, corDoTema2 }) => {
    if (index % 2 === 0) return cor1 || corDoTema1 || "transparent";

    return cor2 || corDoTema2 || "transparent";
}

export const alternadoComControleDestacado = (cor1, cor2, corControle, total) => (index, { corDoTema1, corDoTema2, corControleDoTema }) => {
    if (index === total - 1) return corControle || corControleDoTema || "transparent";
    if (index % 2 === 0) return cor1 || corDoTema1 || "transparent";

    return cor2 || corDoTema2 || "transparent";
}