import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35px;
`;

export const CarregandoAnimacao = styled.div`
  margin-left: -26px;
  width: 130px;
  padding: 6px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: rgba(30, 134, 209, 1);
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: animacao 1s infinite linear;
  @keyframes animacao {
    to {
      transform: rotate(1turn);
    }
  }
`;

export const Logo = styled.img`
  width: ${(props) => props.larguraImagem}px;
  object-fit: contain;
  margin: 0 0 0 -99px;
`;
