import {
  BotaoCancelarRecorrencia,
  BotaoDesistir,
  BotaoFazerPagamento,
  BoxCountModal,
  CabecalhoModalCancelarRecorrencia,
  ContainerAccordions1,
  ContainerAccordionsDireita,
  ContainerAccordionsEsquerda,
  ContainerInputBoxCountModal,
  ContainerRemover,
  ContainerTextoValorTotalPlano,
  ContainerTituloValorTotalPlano,
  ContainerValorTotalPlano,
  FooterModal,
  LinhaCabecalhoModalCancelarRecorrencia,
  LinhaClaraExtras,
  LinhaEscuraExtras,
  NumberInputCustomizadoModal,
  SubtituloValorPlano,
  TextoAdicionar,
  TextoBotaoCancelarRecorrencia,
  TextoBotaoDesistir,
  TextoBotaoFazerPagamento,
  TextoBoxCountModal,
  TextoCabecalhoModalCancelarRecorrencia,
  TextoCancelarRecorrencia,
  TextoReativarRecorrencia,
  TextoRemover,
  TextoSubtituloModal,
  TituloLinhaExtras,
  TituloValorTotalPlano,
  ValorTotalPlano,
} from "./styles";

import { PopupCustomizadoMin } from "../../../../components/Contrato/Ativo/styles";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  StyledAccordion,
  TextoLinha,
  TypographyTitulo,
} from "../../../contratoCustomizado/cadastro/styles";
import { AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconeBotao } from "../../../selecaoEmpresas/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContainerPopup } from "../../../../components/Contrato/Padrao/styles";
import { useEffect, useState } from "react";
import {
  agruparItensPorModulo,
  gerarItemExpansao,
} from "./componentes/ModularizadorItens";
import { ResponseExtrasWaychef } from "../../../../services/contrato/waychef/MapperExtrasWaychef";
import { useTheme } from "@emotion/react";
import useTamanhoTela from "../../../../utils/useTamanhoTela";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../../../contexts/api";
import formatarReal from "../../../../utils/formatarReal";
import { toast } from "react-toastify";
import ModalDetalharPlano from "../../comparacaoPlanos";
import { BotaoAzulVazado } from "../../../../components/Botao";
import { ItensRealizarPagamentoFactory } from "../../../realizarPagamento/itensRealizarPagamentoFactory";
import { ResponseCalculoExtras } from "../../../../services/contrato/calculo/MapperCalculoExtras";
import { getUrlEmpresa } from "../../../../enums/TipoEmpresa";

export function ItensWaychef({ contrato, aplicacao, irParaPagamento }) {
  const [itensContratoModularizados, setItensContratoModularizados] = useState(
    []
  );

  useEffect(() => {
    setItensContratoModularizados(agruparItensPorModulo(contrato.listaItens));
  }, []);

  return (
    <div>
      <ContainerAccordions1>
        <ContainerAccordionsEsquerda>
          {Object.keys(itensContratoModularizados).map((modulo, index) => {
            if (itensContratoModularizados[modulo] && index % 2 === 0) {
              return gerarItemExpansao(itensContratoModularizados[modulo]);
            }
          })}
        </ContainerAccordionsEsquerda>

        <ContainerAccordionsDireita>
          {Object.keys(itensContratoModularizados).map((modulo, index) => {
            if (itensContratoModularizados[modulo] && index % 2 !== 0) {
              return gerarItemExpansao(itensContratoModularizados[modulo]);
            }
          })}
        </ContainerAccordionsDireita>
      </ContainerAccordions1>
    </div>
  );
}
