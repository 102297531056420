import { useTheme } from "@emotion/react";
import { CelulaLinhaTabela } from "../CelulaLinhaTabela";
import { StyledTr } from "./styles";

export function LinhaTabela({ dados, itensTabela }) {
const theme = useTheme()
  return (
    <>

    {itensTabela.itensTabelaSecundaria?.map(obj => {

          return (
            <>
                <StyledTr dados={dados} key={`table-body-${obj}`} style={{backgroundColor: obj.linhaAzul && theme.cores.linhaAzulTabela}}>
                  {dados.colunasTabelaSecundaria?.map((coluna, colunaIndex) => {
                    return (
                      <CelulaLinhaTabela
                        key={`table-row-cell-${colunaIndex}`}
                        item={
                          obj.linhaAzul && colunaIndex == 0 ? obj.linhaAzul : obj[coluna.key]
                        }
                        coluna={obj.linhaAzul ? dados?.colunasTabelaSecundaria[0] : coluna}
                        linhaAzul={obj.linhaAzul ? true : false}
                      />
                    );
                  
                  })}
                </StyledTr>
             
            </>
          );
        })
      }
    </>
  );
}
