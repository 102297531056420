import styled from "@emotion/styled";
import Popup from "reactjs-popup";

export const Container = styled.footer`
  display: none;
  height: 70px;
  width: 100%;
  position: fixed;

  background-color: ${(props) =>
    props.theme.adaptativo("neutralBackground99", "surfaceBackground1")};
  z-index: 2;
  left: 0;
  bottom: 0;
  left: 0;
  /* border-top: 1px solid #ccc; */
  -webkit-box-shadow: 0px -4px 12px -8px rgba(0, 0, 0, 0.5);
  box-shadow: 0px -4px 12px -8px rgba(0, 0, 0, 0.5);
  border-radius: 8px 8px 0px 0px;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const RecorteCirculo = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: relative;
  left: calc(50% - 35px);
  top: -35px;
  background: radial-gradient(
    circle at 50%,
    rgba(0, 0, 0, 0.15),
    ${(props) => props.theme.cores.surfaceBackground1} 100%
  );
`;

export const ContainerRecortarCirculo = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ContainerIcone = styled.div`
  border: 3px solid ${(props) => props.theme.cores.actionActive};
  width: 56px;
  height: 56px;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 28px);
  top: -28px;

  background-color: ${(props) =>
    props.theme.temaDarkAtivo
      ? props.theme.cores.surface2
      : props.theme.cores.neutral100};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerCaminho = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 15%;
`;

export const CaminhoLinha = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerBottomSheetNavegacao = styled.div`
  position: fixed;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  background-color: ${(props) =>
    props.theme.adaptativo("neutralBackground99", "surfaceBackground1")};
  border-radius: 8px 8px 0px 0px;
  transition: bottom 0.4s ease-in-out;
`;

export const Separador = styled.div`
  height: 1px;
  width: calc(100% - 48px);
  background-color: ${(props) =>
    props.theme.adaptativo("neutral95", "neutral60")};
`;

export const BotaoNavegacao = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 0px 24px;
  width: 100%;
  height: 72px;
  cursor: pointer;
`;

export const OverlayFechar = styled.div`  
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.cores.overlay};
  /* opacity: ${({ $aberto }) => ($aberto ? 1 : 0)};
  visibility: ${({ $aberto }) => ($aberto ? "visible" : "hidden")}; */
  transition: all 0.45s ease-out;
`;

export const PainelMenu = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
`;

export const LinhaMenusContainer = styled.nav`
  ${(props) => props.menuFixo && `position: fixed`};
  ${(props) => props.menuFixo && ` background-color: rgba(243, 243, 243, 1)`};
  top: 0;
  height: 60px;
  width: 100%;
  padding: 0 40px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 3px 4px -2px gray;
  transform-origin: top;
  overflow-y: auto;
  ${(props) => props.menuFixo && `animation: expandirMenu 0.3s ease`};

  @keyframes expandirMenu {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
`;

export const PopupCustomizado = styled(Popup)`
  &-content {
    width: 230px !important;
    height: auto;
    padding: 12px 16px;
    animation: delayPopup 0.5s ease-in-out forwards;
    background-color: ${(props) => props.theme.cores.surface2};
  }

  @keyframes delayPopup {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const ContainerMenuItem = styled.div`
  min-width: 150px;
  flex-direction: row;
  //flex: 1;
  //display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
  padding: 10px 10px 10px 10px;
  border-radius: 4px;
  ${(props) =>
    props.selecionado && `background-color: rgba(35, 160, 232, 0.15);`}
  ${(props) =>
    props.atualizar && `animation: vibrar 0.3s ease-in-out forwards;`}
  

  .menu-item-selecionado {
    background-color: red;
  }

  .icone-menu-painel-transicao {
    color: ${(props) => props.theme.cores.textoSimples};
    fill: ${(props) => props.theme.cores.textoSimples};
    ${(props) =>
    props.aberto && `animation: trocarCor 0.5s ease-in-out forwards`};
  }

  .icone-menu-painel-transicao-inverter {
    ${(props) =>
    props.$aberto && `animation: girarIcone 0.5s ease-in-out forwards`};
  }

  @keyframes vibrar {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(10px);
    }
    50% {
      transform: translateX(-10px);
    }
    75% {
      transform: translateX(10px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes girarIcone {
    0% {
      transform: rotate(0deg);
      fill: ${(props) => props.theme.cores.textoSimples};
    }
    100% {
      transform: rotate(180deg);
      fill: ${(props) => props.theme.cores.brand40Default};
    }
  }

  @keyframes trocarCor {
    0% {
      color: ${(props) => props.theme.cores.textoSimples};
      fill: ${(props) => props.theme.cores.textoSimples};
    }
    100% {
      color: ${(props) => props.theme.cores.brand40Default};
      fill: ${(props) => props.theme.cores.brand40Default};
    }
  }
`;

export const TextoMenuItem = styled.span`
  margin: 0 10px;
  font-size: 16px;
  font-weight: 400;
`;

export const MenuItemFilhoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 4px;
  border-bottom: 0.2px solid #ccc3;

  p {
    text-overflow: ellipsis;
    width: 100%;
    padding: 8px 0;
  }

  &:has(p):hover {
    background-color: ${(props) => props.theme.cores.brand10neutral20};
    border-radius: 5px;
  }
`;
