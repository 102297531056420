import { StyleSheet } from "@react-pdf/renderer";

const alturaCelula = 20;

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
    display: "block",
    width: "210mm",
    // height: "297mm",
    paddingTop: 10,
    paddingBottom: 50,
  },
  section: {
    marginTop: 3,
    marginBottom: 50,
    marginHorizontal: 20,
  },
  sectionTitulos: {
    paddingBottom: 35,
    paddingTop: 35,
    marginHorizontal: 20,
    borderBottom: "1px groove #888",
  },

  sectionLinhas: {
    marginTop: 5,
    marginHorizontal: 20,
  },
  coluna: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 97,
    width: "100%",
    backgroundColor: "#D1EBFA",
    paddingHorizontal: 20,
    marginTop: "-20px"
  },
  headerTitulo: {
    fontSize: "19px",
    color: "#000",
    fontWeight: 700,
    fontFamily: "Comfortaa",
    textAlign: "center",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    marginBottom: 10,
  },
  headerSubtitulo: {
    fontSize: "16px",
    color: "#000",
    fontWeight: 400,
    fontFamily: "Comfortaa",
    textAlign: "center",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },
  headerRelatorio: {
    fontSize: 16,
    color: "#000",
    fontWeight: 400,
    textAlign: "center",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    fontFamily: "Comfortaa"
  },
  headerListagem: {
    fontWeight: "bold",
    display: "flex",
    height: 7,
    width: "100%",
    backgroundColor: "#1E86D1",
  },
  tituloProduto: {

  },
  filtrosTitulo: {
    fontSize: 16,
    fontWeight: 700,
    marginVertical: 5,
  },
  filtrosSubtitulo: {
    fontSize: 12,
    fontWeight: 600,
    marginVertical: 5,
  },
  containerIngredientesModoPreparo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  containerPontilhado: {
    border: "1px dotted #babcbf ",
    width: '250px',
    minHeight: 30,
    paddingHorizontal: 5,
    paddingBottom: 3,
  },
  celulaTabelaTitulo: {
    padding: 2,
    // marginRight: -1,
    display: "flex",
    fontSize: 8,
    justifyContent: "center",
    alignItems: "center",
    maxHeight: alturaCelula * 3,
    // border: "0.8px groove #888",
  },
  containerNomeProduto: {
    border: "1px groove #888",
  },
  celulaTabelaTextoTitulo: {
    fontSize: 10,
    fontWeight: "bold",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "nowrap",
    paddingLeft: 60,
    paddingRight: 60,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: "#dee1e6",
    borderRight: "1px groove #babcbf ",
  },
  textoNomeProduto: {
    fontSize: 12,
    marginLeft: 10,
  },
  headerTabela: {
    backgroundColor: "#dee1e6",
    marginTop: 20,

  },
  textHeaderTabela: {
    fontSize: 12,
    fontWeight: 700,
    fontFamily: "ComfortaaBold",
    textAlign: "center",
    border: "1px groove #babcbf ",
    paddingBottom: 5,
    paddingTop: 10
  },
  containerColunasTabela: {
    display: "flex",
    flexDirection: "row",
  },
  textColunaTabela: {
    fontSize: 12,
    fontWeight: 700,
    fontFamily: "ComfortaaBold",
  },
  containerColunaTabela: {
    backgroundColor: "#dee1e6",
    border: "1px groove #babcbf ",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
    borderCollapse: "collapse"

  },
  containerCelulaTabela: {
    border: "1px groove #babcbf ",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 4,
    paddingBottom: 4
  },
  textCelulaTabela: {
    textAlign: 'center',
    fontSize: 10,
    fontWeight: 700,
  },
  containerCustoTotalTabela: {
    // border: "1px groove #babcbf ",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
    marginTop: 10,
    marginRight: 20,
  },
  containerPreçoTotalTabela: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  footer: {
    width: "100%",
    backgroundColor: "#D1EBFA",
    paddingTop: 10,
    paddingHorizontal: 22,
    paddingBottom: 20,
    position: "absolute",
    bottom: 0,
    marginBottom: "-50px"
  },
  containerERPLogo: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  textERP: {
    fontFamily: "Comfortaa",
    fontSize: "16px",
    fontWeight: 600,
  },
  textPequenoFooter: {
    fontFamily: "Comfortaa",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: 1.3,
    paddingTop: 10,

  }
});

export default styles;
