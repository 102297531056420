import {
  ThemeProvider as ThemeMaterial,
  createTheme,
} from "@mui/material/styles";
import { createContext, useEffect, useState, useContext } from "react";
import { ThemeProvider } from "@emotion/react";
import dark from "../styles/temaDark";
import light from "../styles/temaLight";
import { AuthContext } from "./auth";

import { ptBR } from '@mui/x-date-pickers/locales';

export const TemaCustomizadoContext = createContext({
  mudarTema: () => {},
  getTemaDarkAtivo: () => {},
});

export const TemaProvider = ({ children }) => {
  const [temaAtual, setTemaAtual] = useState("light");

  useEffect(() => {
    let tema;
    tema = localStorage.getItem("_tema");

    setTemaAtual(tema ? tema : "light");
  }, []);

  const mudarTema = () => {
    setTemaAtual(temaAtual === "light" ? "dark" : "light");
    localStorage.setItem("_tema", temaAtual === "light" ? "dark" : "light");
  };

  const getTemaDarkAtivo = () => {
    return temaAtual === "light" ? false : true;
  };

  const temaMaterial = createTheme({
    palette: {
      mode: temaAtual,
      ...(temaAtual === "light" ? light : dark),
    },
    ptBR
  });

  return (
    <ThemeProvider theme={temaAtual === "light" ? light : dark}>
      <TemaCustomizadoContext.Provider value={{ mudarTema, getTemaDarkAtivo }}>
        <ThemeMaterial theme={temaMaterial}>{children}</ThemeMaterial>
      </TemaCustomizadoContext.Provider>
    </ThemeProvider>
  );
};
