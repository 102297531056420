export function formatarCPF(cpf) {

  console.log(cpf, "testess")
  if (cpf == null) {
    return cpf
  }

  if (cpf.includes("*")) {
    return cpf
  }
  const numerosCPF = cpf.replace(/\D/g, '');

  // Verificar se há 11 dígitos numéricos
  if (numerosCPF.length >= 10) {
    return `${numerosCPF.slice(0, 3)}.${numerosCPF.slice(3, 6)}.${numerosCPF.slice(6, 9)}-${numerosCPF.slice(9)}`;
  }
  else if (numerosCPF.length > 6) {
    return `${numerosCPF.slice(0, 3)}.${numerosCPF.slice(3, 6)}.${numerosCPF.slice(6)}`;
  }
  else if (numerosCPF.length > 3) {
    return `${numerosCPF.slice(0, 3)}.${numerosCPF.slice(3)}`;
  }
  else {
    return cpf;
  }



}

