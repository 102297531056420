export function formatarDataParaPadraoBrasileiro(data) {
  if (!data) {
    return;
  }

  const partes = data.split("-");
  return `${partes[2]}/${partes[1]}/${partes[0]}`;
}

export function formatarDataEHorarioParaPadraoBrasileiro(data) {
  if (!data) {
    return;
  }

  const dataObj = new Date(data);

  const dia = String(dataObj.getDate()).padStart(2, "0");
  const mes = String(dataObj.getMonth() + 1).padStart(2, "0");
  const ano = dataObj.getFullYear();

  const hora = String(dataObj.getHours()).padStart(2, "0");
  const minutos = String(dataObj.getMinutes()).padStart(2, "0");

  return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
}
