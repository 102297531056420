import styled from "@emotion/styled";
import { alternado } from "./padraoCores";

export const BotaoPrimario = styled.button`
  color: ${({ accent = "#FFFFFF" }) => accent};
  background-color: ${({ color = "#000000" }) => color};
  box-shadow: none;
  border: none;
  cursor: pointer;
  border-radius: 4px;
`;

export const BotaoSecundario = styled.button`
  color: ${({ color = "#000000" }) => color};
  background-color: transparent;
  box-shadow: none;
  border: 1px solid ${({ color = "#000000" }) => color};
  cursor: pointer;
  border-radius: 4px;
`;

export const BotaoEstilizado = styled(BotaoSecundario)`
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
`;

export const BotaoTerciario = styled.button`
  padding: 0;
  color: ${({ color = "#000000" }) => color};
  background-color: transparent;
  box-shadow: none;
  border: none;
  cursor: pointer;
  border-radius: 4px;

  :focus {
    offset: none;
  }
`;

export const Linha = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Coluna = styled.div`
  display: flex;
  flex-direction: Column;

  width: 100%;
`;

function gerarColuna(coluna, larguraTela, tamanhoMinimo) {
  let { expandivel, largura, larguraPixel } = coluna;

  if (larguraPixel) {
    return `${larguraPixel}px`;
  }

  if (largura) {
    return larguraTela < tamanhoMinimo
      ? `${tamanhoMinimo / largura}px`
      : `${largura}vw`;
  }

  if (expandivel || expandivel === undefined) {
    return "1fr";
  } else {
    return "min-content";
  }
}

export const Tabela = styled.div`
  display: grid;
  grid-template-columns: ${({ $colunas, $larguraTela, $tamanhoMinimo }) =>
    $colunas.reduce((acumulado, coluna) => {
      return `${acumulado} ${gerarColuna(
        coluna,
        $larguraTela,
        $tamanhoMinimo
      )}`;
    }, "")};
  color: ${(props) => props.theme.cores.textoSimples};
  width: 100%;
`;

export const Cabecalho = styled.div`
  background-color: ${(props) => props.theme.adaptativo("brand10", "brand80")};
  cursor: pointer;
  padding: ${({ $paddingHorizontal }) =>
    $paddingHorizontal ? $paddingHorizontal : "16"}px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  min-width: 100px;
  text-overflow: ellipsis;
  /* text-wrap: nowrap; */
  font-size: 14px;

  h1 {
    font-weight: 500;
    white-space: nowrap;
    font-size: 14px;
  }

  svg {
    color: ${(props) => props.theme.textoSimples};
    font-size: 8px;
  }

  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
`;

// Acho que isso não é usado
export const DefinirCores = styled(Linha)`
  background-color: ${({ $index, theme, $func = alternado() }) => {
    if ($func)
      return $func($index, {
        corDoTema1: "transparent",
        corDoTema2: theme.cores.surface3,
      });

    return $index % 2 === 0 ? "transparent" : theme.cores.surface3;
  }};
`;

export const Celula = styled(DefinirCores)`
  padding: 24px
    ${({ $paddingHorizontal }) =>
      $paddingHorizontal ? $paddingHorizontal : 16}px;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 15px;
  font-family: "Source Sans Pro", sans-serif;

  p {
    text-overflow: ellipsis;
    width: 100%;
  }
`;

export const Controle = styled(DefinirCores)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 6px 15px;
  background-color: ${({ $alternarEmpresa }) =>
    ({ theme }) =>
      $alternarEmpresa ? theme.cores.brand85 : "inherit"};

  p {
    font-size: 19px;
  }
`;

export const ControleModal = styled(DefinirCores)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 6px 15px;
  background-color: p {
    font-size: 19px;
  }
`;

export const BotaoAvancarRetroceder = styled(BotaoTerciario)`
  font-size: 24px;
`;

export const ContainerTamanho = styled(Coluna)`
  width: 100%;
  min-width: ${({ $tamanhoMinimo }) =>
    $tamanhoMinimo ? $tamanhoMinimo : 1200}px;
`;

export const BotaoPagina = styled(BotaoPrimario)`
  font-size: ${({ selected }) => (selected ? "19px" : "17px")};
  font-weight: ${({ selected }) => (selected ? "600" : "400")};
  width: 32px;
  height: 32px;
  color: ${({ theme }) => theme.cores.textoSimples};
  font-family: "Source Sans Pro", sans-serif;
`;

export const Slider = styled.div`
  overflow-x: scroll;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const MostrarPagina = styled(Linha)`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const BotoesPagina = styled(Linha)`
  gap: 16px;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ContainerMostrarPagina = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ContainerNumeroPagina = styled.div`
  width: 97px;
  height: 33px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.cores.backgroundBoxPagina};
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid ${(props) => props.theme.cores.corBoxPagina};
`;

export const TextoNumeroPagina = styled.p`
  color: ${(props) => props.theme.cores.corBoxPagina};
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
`;

export const CabecalhoSVGContainer = styled.div`
  margin: 0px 4px;
  opacity: 0.7;
  width: 6px;
`;
