import { TextField } from "@mui/material";
import { BottomSheet } from "react-spring-bottom-sheet";
import Popup from "reactjs-popup";
import styled from "@emotion/styled";

export const ContainerFundoBranco = styled.div`
  margin-top: 94px;
  width: 100%;
  background-color: ${(props) => props.theme.cores.surface2};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  margin-bottom: 146px;

  @media (max-width: 920px) {
    margin-bottom: 50px;
    margin-top: 24px;
    border-radius: 16px;
  }
`;

export const TextoMeusCartoes = styled.p`
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 26px;
  letter-spacing: -0.5px;
  color: #ffffff;
  margin-bottom: 24px;
  display: flex;

  @media (max-width: 920px) {
    display: none;
  }
`;

export const CabecalhoMudarPagina = styled.header`
  background-color: ${(props) => props.theme.adaptativo("brand10", "brand70")};
  height: 72px;
  display: flex;
  align-items: center;
  gap: 48px;
  border-radius: 16px 16px 0px 0px;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 29px;
  padding: 16px 24px;
`;

export const ContainerCartoes = styled.div`
  display: flex;
  gap: 28px;

  @media (max-width: 920px) {
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    align-items: center;
  }
`;

export const AdicionarCartao = styled.div`
  width: 316px;
  height: 175px;
  border: 1px solid ${({ theme }) => theme.adaptativo("neutral10", "neutral60")};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  > button > svg {
    color: ${({ theme }) => theme.adaptativo("botaoModal", "neutral60")};
  }

  @media (max-width: 400px) {
    width: 100%;
  }
`;

export const PopupCustomizado = styled(Popup)`
  &-content {
    background-color: ${(props) => props.theme.cores.surface2};
    height: 500px;
    width: 70vw;
    border-radius: 16px;
    overflow-y: scroll;
    border: none;
    width: 898px;
  }

  &-overlay {
  }
`;

export const ContainerPopup = styled.div`
  padding-top: 34px;
  padding-left: 21px;
  padding-bottom: 39px;
  padding-right: 24px;
`;

export const BotoesModal = styled.div`
  display: flex;
  gap: 24px;

  @media (max-width: 768px) {
    gap: 16px;
  }
`;

export const BotaoCancelar = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 8px;
  width: 109px;
  height: 48px;
  background: transparent;
  border: 1px solid ${({ theme }) => theme.cores.semanticDanger2};
  cursor: pointer;
  transition: 0.3s;
  border-radius: 8px;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 768px) {
    width: 50%;
  }
`;

export const TextoBotaoCancelar = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.cores.semanticDanger2};

  @media (max-width: 920px) {
    font-size: 16px;
  }
`;

export const BotaoCadastrarCartaoModal = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 8px;
  width: 179px;
  height: 48px;
  border-radius: 8px;
  background: ${({ theme }) =>
    theme.adaptativo("semanticSuccess3", "semanticSuccess2")};
  cursor: pointer;
  transition: 0.3s;
  border: none;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 768px) {
    width: 50%;
  }
`;

export const TextoBotaoCadastrarCartaoModal = styled.p`
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: #ffffff;

  @media (max-width: 920px) {
    font-size: 16px;
  }
`;

export const BottomSheetCustomizada = styled(BottomSheet)`
  --rsbs-bg: ${({ theme }) => theme.cores.surface2};
`;

export const ContainerBottomSheet = styled.div`
  padding-top: 43px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 10px;
`;
