import styled from "@emotion/styled";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.cores.surfaceBackground1};
  margin: 0px 40px 80px 40px;

  @media (max-width: 768px) {
    margin: 0px 20px 80px 20px;
  }
`;

export const ContainerFundoBranco = styled.div`
  margin-top: 53px;
  width: 100%;
  background-color: ${(props) => props.theme.cores.surface2};
  border-radius: 16px;
  display: flex;
  margin-bottom: 72px;
  gap: 133px;

  @media (max-width: 768px) {
    margin-bottom: 50px;
    margin-top: 24px;
  }
`;

export const ContainerEsquerda = styled.div`
  padding-top: 26px;
  padding-left: 20px;
  padding-bottom: 21px;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  width: 40%;
  background: ${({ theme }) => theme.cores.surface2};
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;

  @media (max-width: 924px) {
    width: 100%;
  }
`;

export const ContainerLayoutEsquerda = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const ContainerDireita = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.adaptativo("neutral99", "surface3")};
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;

  @media (max-width: 924px) {
    width: 100%;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
`;

export const TextoAlteracaoPlano = styled.p`
  font-size: 33px;
  font-family: "Comfortaa", sans-serif;
  font-weight: 700;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.cores.textoSimples};
  margin-bottom: 30px;

  @media (max-width: 924px) {
    font-size: 23px;
    font-family: "Comfortaa", sans-serif;
    font-weight: 500;
    letter-spacing: -0.5px;
    margin-bottom: 32px;
  }
`;

export const ContainerAvisoSuperiorEsquerda = styled.div`
  padding-top: 19px;
  padding-left: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.adaptativo("brand10", "surface4")};
  background: ${({ theme }) => theme.adaptativo("brand10", "surface4")};
  width: 100%;
  margin-bottom: 111px;

  @media (max-width: 924px) {
    margin-bottom: 48px;
  }
`;

export const CabecalhoAvisoSuperior = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const TituloAvisoSuperior = styled.p`
  font-size: 16px;
  font-family: "Comfortaa", sans-serif;
  font-weight: 600;
  color: ${({ theme }) => theme.cores.textoSimples};
`;

export const ValorAvisoSuperior = styled.p`
  font-size: 16px;
  font-family: "Comfortaa", sans-serif;
  font-weight: 600;
  font-weight: bolder;
  color: ${({ theme }) => theme.cores.textoSimples};
`;

export const DescricaoAvisoSuperior = styled.p`
  font-size: 17px;
  font-family: "Source Sans Pro";
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.cores.textoSimples};
  flex-shrink: 1;
`;

export const ContainerDescricaoPagamento = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;

  @media (max-width: 924px) {
    margin-bottom: 32px;
  }
`;

export const Total = styled.p`
  font-size: 20px;
  font-family: "Comfortaa", sans-serif;
  font-weight: 600;
  color: ${({ theme }) => theme.cores.textoSimples};
  margin-bottom: 18px;
`;

export const ContainerResumo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const TituloResumo = styled.p`
  font-size: 16px;
  font-family: "Comfortaa";
  font-weight: 600;
  color: ${({ theme }) => theme.cores.textoSimples};
`;

export const ValorResumo = styled.p`
  font-size: 19px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.cores.textoSimples};
`;

export const Hr = styled.hr`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.cores.corHr};
  margin-bottom: 8px;
`;

export const ContainerAviso = styled.div`
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  gap: 16px;
  margin-bottom: 44px;
  background: ${({ theme }) => (theme.temaDarkAtivo ? "#ECC300" : "#FFE17B")};

  @media (max-width: 924px) {
    margin-bottom: 32px;
  }
`;

export const TextoAviso = styled.p`
  font-size: 17px;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0.5px;
  color: black;
`;

export const ContainerBotoes = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  width: 100%;

  @media (max-width: 924px) {
    justify-content: space-around;
    gap: 16px;
  }
`;

export const BotaoAdicionarExtra = styled.button`
  background: transparent;
  cursor: pointer;
  transition: 0.3s;
  border: 1px solid ${({ theme }) => theme.cores.actionActive};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 8px 16px;
  gap: 8px;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 924px) {
    width: 50%;
  }
`;

export const TextoAdicionarExtra = styled.p`
  font-size: 16px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.cores.actionActive};

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

export const BotaoFazerPagamento = styled.button`
  background: ${({ theme }) => theme.cores.actionActive};
  cursor: pointer;
  transition: 0.3s;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 8px 16px;
  gap: 8px;

  &:hover {
    opacity: 0.7;
  }
`;

export const TextoFazerPagamento = styled.p`
  font-size: 16px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.cores.textAction};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 5px;

  .MuiCircularProgress-svg {
    color: ${({ theme }) => theme.cores.textAction};
  }

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

export const ContainerResumoPagamento = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;
  width: 60%;

  @media (max-width: 924px) {
    margin-bottom: 50px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
  }
`;

export const TextoPlanoResumo = styled.p`
  font-size: 20px;
  font-family: "Comfortaa", sans-serif;
  font-weight: 600;
  color: ${({ theme }) => theme.cores.textoSimples};
  margin-bottom: 19px;
`;

export const LinhaResumo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
`;

export const TituloResumoPagamento = styled.p`
  font-size: 16px;
  font-family: "Comfortaa", sans-serif;
  font-weight: 600;
  color: ${({ theme }) => theme.cores.textoSimples};
`;
export const ValorResumoPagamento = styled.p`
  font-size: 19px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.cores.textoSimples};
`;

export const HrMenor = styled.hr`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.cores.corHr};
  margin-bottom: 11px;
`;

export const ContainerMobile = styled.div`
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
`;

export const LayoutResponsivo = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 924px) {
    flex-direction: column-reverse;
  }
`;
