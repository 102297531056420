import styled from "@emotion/styled";
export const StyledTr = styled.tr`

  &:nth-child(even) {
    background-color: ${(props) =>
      props.theme.adaptativo("surface3", "surfaceBackground1")};
  }

    display: grid;
    grid-template-columns: ${({ dados }) => dados.gridColunaTabelaSecundaria ? dados.gridColunaTabelaSecundaria : `repeat(${dados.colunasTabelaSecundaria.length}, minmax(200px, 1fr))`};
`;
export const StyledTd = styled.td`
  min-width: 260px;
  font-size: 15px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;

  color: ${(props) => props.theme.adaptativo("neutral10", "neutral100")};

  padding: 24px 16px;
  text-align: left;
`;
