import {
  StyledAccordion,
  TextoLinha,
  TypographyTitulo,
} from "../../../../contratoCustomizado/cadastro/styles";
import { AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LinhaClara1, LinhaEscura1 } from "../styles";
import { Check } from "phosphor-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export function gerarItemExpansao(modulo) {
  return (
    <StyledAccordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1d-content"
        id="panel1d-header"
      >
        <TypographyTitulo>{modulo?.descricao}</TypographyTitulo>
      </AccordionSummary>
      {modulo?.listaItens.map((item, index) => criarItemContrato(item, index))}
    </StyledAccordion>
  );
}

export function agruparItensPorModulo(lista) {
  const grupos = {};

  if (lista) {
    lista.forEach((item) => {
      const modulo = item.modulo;

      if (!grupos[modulo]) {
        grupos[modulo] = {
          descricao: item.descricaoModuloContrato,
          listaItens: [],
        };
      }

      grupos[modulo].listaItens.push(item);
    });
  }

  return grupos;
}

function criarItemContrato(item, index) {
  if (index % 2 === 0) return <ItemCheckBoxLinhaClara item={item} />;
  else return <ItemCheckBoxLinhaEscura item={item} />;
}

function ItemCheckBoxLinhaClara({ item, temaDarkAtivo, largura }) {
  return (
    <LinhaClara1 style={{ paddingRight: largura > 768 ? 175 : 60 }}>
      <TextoLinha>{item.descricaoItem}</TextoLinha>
      {item.possuiItem ? (
        <Check
          size={20}
          weight="bold"
          color={corVerdeIconeCheck(temaDarkAtivo)}
        />
      ) : (
        <FontAwesomeIcon
          icon={faTimes}
          style={{
            width: 20,
            height: 20,
            color: corVermelhoTimes(temaDarkAtivo),
          }}
        />
      )}
    </LinhaClara1>
  );
}

function ItemCheckBoxLinhaEscura({ item, temaDarkAtivo, largura }) {
  return (
    <LinhaEscura1 style={{ paddingRight: largura > 768 ? 175 : 60 }}>
      <TextoLinha>{item.descricaoItem}</TextoLinha>
      {item.possuiItem ? (
        <Check
          size={20}
          weight="bold"
          color={corVerdeIconeCheck(temaDarkAtivo)}
        />
      ) : (
        <FontAwesomeIcon
          icon={faTimes}
          style={{
            width: 20,
            height: 20,
            color: corVermelhoTimes(temaDarkAtivo),
          }}
        />
      )}
    </LinhaEscura1>
  );
}

function corVerdeIconeCheck(temaDarkAtivo) {
  return temaDarkAtivo ? "#41E098" : "#006D44";
}

function corVermelhoTimes(temaDarkAtivo) {
  return temaDarkAtivo ? "red" : "#BA1A1A";
}
