export default function useIsDev() {
  var isDev = false;

  if (process.env.NODE_ENV === "development") {
    isDev = true;
  }

  if (process.env.NODE_ENV === "dev") {
    isDev = true;
  }

  if (window.location.hostname === "localhost") {
    isDev = true;
  }

  if (window.location.href.includes("localhost")) {
    isDev = true;
  }

  if (window.location.href.includes("192.168.5.213:3001")) {
    isDev = true;
  }

  return { isDev: isDev };
}
