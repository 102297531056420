import { BottomSheet } from "react-spring-bottom-sheet";
import Popup from "reactjs-popup";
import styled from "@emotion/styled";

export const BottomSheetCustomizado = styled(BottomSheet)`
  [data-rsbs-overlay] {
    background: ${(props) => props.theme.cores.surface2};
  }

  [data-rsbs-content] {
    padding: 4px 24px 0 24px;
  }
`;

export const PopupCustomizado = styled(Popup)`
  &-content {
    width: ${({ $width }) => $width || "auto"};
    border-radius: 24px;
    padding: 24px;
    background-color: ${({ theme }) => theme.cores.surface2};
    border: none;
    /* max-width: ${({ $maxWidth }) => $maxWidth || "500px"}; */
    max-width: ${({ $maxWidth }) => $maxWidth};
  }
`;

export const OverlayManual = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100vw;
  height: 150vh;
  background-color: rgba(0, 0, 0, 0.6);
  background-color: var(--rsbs-backdrop-bg, rgba(0, 0, 0, 0.6));
  will-change: opacity;
  cursor: pointer;
  animation: fadeIn 400ms ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
