import {
  BotaoAdicionarExtra,
  BotaoFazerPagamento,
  CabecalhoAvisoSuperior,
  Container,
  ContainerAviso,
  ContainerBotoes,
  ContainerDescricaoPagamento,
  ContainerDireita,
  ContainerEsquerda,
  ContainerFundoBranco,
  ContainerLayoutEsquerda,
  ContainerMobile,
  ContainerAvisoSuperiorEsquerda,
  ContainerResumo,
  ContainerResumoPagamento,
  DescricaoAvisoSuperior,
  Hr,
  HrMenor,
  LinhaResumo,
  TextoAdicionarExtra,
  TextoAlteracaoPlano,
  TextoAviso,
  TextoFazerPagamento,
  TextoPlanoResumo,
  TituloAvisoSuperior,
  TituloResumo,
  TituloResumoPagamento,
  Total,
  ValorAvisoSuperior,
  ValorResumo,
  ValorResumoPagamento,
  LayoutResponsivo,
} from "./styles";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { Fragment, useState } from "react";
import useTamanhoTela from "../../utils/useTamanhoTela";
import {
  ContainerCartoes,
  ContainerPopupCartao,
  PopupCustomizadoCartao,
  TextoCartaoCadastrado,
  TextoFinalizarCompra,
} from "../gerenciamentoAssinaturaPlanos/detalhesPlano/plano/styles";
import {
  BotaoCadastrarCartaoModal,
  BotaoCancelar,
  BotoesModal,
  TextoBotaoCadastrarCartaoModal,
  TextoBotaoCancelar,
} from "../gerenciamentoAssinaturaPlanos/detalhesPlano/formaPagamento/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faWarning } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../components/Footer";
import formatarReal from "../../utils/formatarReal";
import ModalPagamento from "../gerenciamentoAssinaturaPlanos/modalPagamento";
import {
  BotaoAzulPreenchido,
  BotaoAzulVazado,
  BotaoVerdePreenchido,
} from "../../components/Botao";
import { CircularProgress } from "@mui/material";
import { useLoader } from "../../contexts/loader";

export default function RealizarPagamento({
  titulo,
  avisoAntesPagamento,
  avisoSuperiorEsquerda,
  listaTotal = [],
  listaDetalhes = [],
  valorTotal,
  voltarParaTelaAnterior,
  realizarPagamentoFuncao,
}) {
  const { largura } = useTamanhoTela();
  const theme = useTheme();
  const navigate = useNavigate();
  const { abrirCarregamento, fecharCarregamento } = useLoader();
  const [bloqueioBotao, setBloqueioBotao] = useState(false);

  function pagar() {
    setBloqueioBotao(true);
    abrirCarregamento("Realizando pagamento");

    try {
      if (realizarPagamentoFuncao && !bloqueioBotao) {
        realizarPagamentoFuncao()
          .then(() => {
            setBloqueioBotao(false);
            fecharCarregamento();
          })
          .catch(() => {
            setBloqueioBotao(false);
            fecharCarregamento();
          });
      }
    } catch (error) {
      console.error(error);
      realizarPagamentoFuncao();
      // setTimeout(() => {
      setBloqueioBotao(false);
      fecharCarregamento();
      // }, 5000);
    }
  }

  function totalParaSerPago() {
    if (valorTotal) {
      return valorTotal;
    } else {
      let total = 0;
      for (let item of listaTotal) {
        total = total + item.valor;
      }

      return total;
    }
  }
  function ItensTotal() {
    return (
      <>
        {listaTotal.map((item, index) => {
          return (
            <ContainerResumo key={"total" + item.descricao + index}>
              <TituloResumo>{item.descricao}</TituloResumo>
              <ValorResumo>{formatarReal(item.valor)}</ValorResumo>
            </ContainerResumo>
          );
        })}
        <Hr />
        <ContainerResumo>
          <TituloResumo>Total a ser pago</TituloResumo>
          <ValorResumo>{formatarReal(totalParaSerPago())}</ValorResumo>
        </ContainerResumo>
      </>
    );
  }

  function ItensDetalhe() {
    return (
      <>
        {listaDetalhes.map((item, index) => {
          return (
            <Fragment key={item.titulo + index}>
              <TextoPlanoResumo>{item.titulo}</TextoPlanoResumo>
              <SubItensDetalhe listaSubItens={item.listaSubItens} />
            </Fragment>
          );
        })}
      </>
    );
  }

  function SubItensDetalhe({ listaSubItens }) {
    function exibicaoValor(valor) {
      if (valor === 0) {
        return "Gratuito";
      } else if (valor) {
        return formatarReal(valor);
      }
    }

    return (
      <div style={{ marginBottom: 28 }}>
        {listaSubItens &&
          listaSubItens.map((item, index) => {
            return (
              <div key={item.descricao + index}>
                <LinhaResumo>
                  <TituloResumoPagamento>
                    {item.descricao}
                  </TituloResumoPagamento>
                  <ValorResumoPagamento>
                    {exibicaoValor(item.valor)}
                  </ValorResumoPagamento>
                </LinhaResumo>
                <HrMenor />
              </div>
            );
          })}
      </div>
    );
  }

  function AvisoSuperiorEsquerda() {
    return avisoSuperiorEsquerda ? (
      <ContainerAvisoSuperiorEsquerda>
        <CabecalhoAvisoSuperior>
          <TituloAvisoSuperior>
            {avisoSuperiorEsquerda.titulo}
          </TituloAvisoSuperior>
          <ValorAvisoSuperior>
            {formatarReal(avisoSuperiorEsquerda.valor)}
          </ValorAvisoSuperior>
        </CabecalhoAvisoSuperior>
        <DescricaoAvisoSuperior>
          {avisoSuperiorEsquerda.descricao}
        </DescricaoAvisoSuperior>
      </ContainerAvisoSuperiorEsquerda>
    ) : (
      <div></div>
    );
  }

  return (
    <>
      <LayoutResponsivo>
        <ContainerEsquerda>
          <TextoAlteracaoPlano>{titulo}</TextoAlteracaoPlano>

          <ContainerLayoutEsquerda>
            <AvisoSuperiorEsquerda />

            <div>
              <ContainerDescricaoPagamento>
                <Total>Total</Total>
                <ItensTotal />
              </ContainerDescricaoPagamento>

              {avisoAntesPagamento && (
                <ContainerAviso>
                  <FontAwesomeIcon
                    icon={faWarning}
                    style={{
                      width: 20,
                      height: 20,
                      color: theme.cores.semanticAlert3,
                    }}
                  />
                  <TextoAviso>{avisoAntesPagamento}</TextoAviso>
                </ContainerAviso>
              )}

              <ContainerBotoes>
                <BotaoAzulVazado
                  onClick={voltarParaTelaAnterior}
                  texto={"Voltar"}
                />
                <ModalPagamento
                  realizarPagamentoFuncao={pagar}
                  textoAviso={`Valor total das pendências: ${formatarReal(
                    totalParaSerPago()
                  )}`}
                  componenteTrigger={
                    <BotaoFazerPagamento disabled={bloqueioBotao}>
                      <TextoFazerPagamento>
                        Fazer o pagamento
                        {bloqueioBotao && <CircularProgress size={13} />}
                      </TextoFazerPagamento>
                    </BotaoFazerPagamento>
                  }
                />
              </ContainerBotoes>
            </div>
          </ContainerLayoutEsquerda>
        </ContainerEsquerda>
        <ContainerDireita>
          <ContainerResumoPagamento>
            <ItensDetalhe />
          </ContainerResumoPagamento>
        </ContainerDireita>

        <Footer caminho={["Dados Da Empresa", "Minha Empresa"]} icon="" />
      </LayoutResponsivo>
    </>
  );
}
