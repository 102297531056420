import { Document, Font, G, Image, PDFViewer, Page, Svg, Text, View } from "@react-pdf/renderer";
import styles from "./stylesPdf";
import Comfortaa from "../../../../assets/fonts/Comfortaa/Comfortaa_Regular.ttf";
import ComfortaaBold from "../../../../assets/fonts/Comfortaa/Comfortaa_Bold.ttf";
import Logo from "../../../../assets/images/logo-pdf.svg"
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useApi } from "../../../../contexts/api";
import formatarReal from "../../../../utils/formatarReal";
import dayjs from "dayjs";
import { useAuth } from "../../../../contexts/auth";

export default function RelatorioPdf() {
  const [searchParams] = useSearchParams();
  const [dadosRequest, setDadosRequest] = useState()
  const { post } = useApi();
  const { dadosAuth } = useAuth();

  Font.register({
    family: "Comfortaa",
    src: Comfortaa,
  },
  );
  Font.register({
    family: "ComfortaaBold",
    src: ComfortaaBold,
  });

  const dados = JSON.parse(atob(searchParams.get("dados")));
  async function buscarDados() {

    try {
      const response = await post(`/relatorios/cadastrais/buscar-relatorio-ficha-tecnica?size=99999`,
        dados
      )

      setDadosRequest(response.data.content)

    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => { buscarDados() }, [])

  const dataCompletaEscrita = () => {
    const data = new Date();

    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZoneName: "short",
    };

    const formatter = new Intl.DateTimeFormat("pt-BR", options);
    return formatter.format(data);
  };

  let itensMostrar = 0;

  function calcularItensPorPagina(item) {
    if (item.insumos.length > 4) {
      return itensMostrar = 6
    }
    itensMostrar += item.insumos.length;

    if (item.insumos && itensMostrar < 5) {
      itensMostrar = 0;
      return 1
    } else {
      itensMostrar = 0;
      return 2;
    }
  }


  function dividirEmPaginas(dados) {
    const itensPorPagina = [];
    let paginaAtual = [];

    dados?.forEach((item, index) => {
      const itensParaExibir = calcularItensPorPagina(item);

      if (index === 0) {
        itensPorPagina.push([item]);
      } else if (paginaAtual.length + itensParaExibir > 2 || item.insumos.length > 6) {

        itensPorPagina.push(paginaAtual);
        paginaAtual = [];
      }
      paginaAtual.push(item);


      if (index === dados.length - 1) {
        itensPorPagina.push(paginaAtual);
      }
    });

    return itensPorPagina;
  }

  const paginas = dividirEmPaginas(dadosRequest)



  return (
    <>
      <PDFViewer style={{ width: "100vw", height: "100vh" }}>
        <Document title={`Relatório Ficha Técnica ${dayjs().format('DD/MM/YYYY HH:mm:ss')}`}>
          {paginas?.map((itens, index) => {
            return (<Page key={index} size="A4" style={styles.page} >
              <View style={styles.coluna}>
                {index || (<> <View style={styles.header}>
                  <View>

                    <Text style={styles.headerTitulo}>
                      RESTAURANTE WAYBURGUER
                    </Text>
                    <Text style={styles.headerSubtitulo}>
                      Relatório Ficha Técnica
                    </Text>
                  </View>
                </View>
                  <View style={styles.headerListagem} />
                  <View style={{ ...styles.section }}>
                    <Text style={styles.filtrosTitulo}>
                      Filtros aplicados
                    </Text>
                    <View>

                      <Text style={styles.filtrosSubtitulo}>
                        Tipo do produto: Ficha técnica.
                      </Text>
                    </View>
                  </View>
                </>
                )}



                {itens?.map((item) => {
                  return (<View style={{ ...styles.sectionTitulos, border: "none" }}>
                    <View style={styles.containerNomeProduto}>
                      <View style={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                        <Text style={styles.celulaTabelaTextoTitulo}>
                          Produto
                        </Text>
                        <Text style={styles.textoNomeProduto}>
                          {item.produto}
                        </Text>
                      </View>

                    </View>
                    <View>
                      <Text style={styles.filtrosTitulo}>
                        Ficha técnica: Padrão
                      </Text>
                    </View>
                    <View style={styles.containerIngredientesModoPreparo}>

                      <View>

                        <Text style={styles.filtrosSubtitulo}>
                          Ingredientes:
                        </Text>
                        <View style={styles.containerPontilhado}>
                          <Text style={styles.textPequenoFooter}>
                            {item.ingredientes}
                          </Text>
                        </View>

                      </View>
                      <View>

                        <Text style={styles.filtrosSubtitulo}>
                          Modo de preparo:
                        </Text>
                        <View style={styles.containerPontilhado}>
                          <Text style={styles.textPequenoFooter}>
                            {item.modoPreparo}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View>
                      <View style={styles.headerTabela}>
                        <Text style={styles.textHeaderTabela}>
                          Listagem de insumos
                        </Text>
                      </View>
                    </View>

                    <View style={styles.containerColunasTabela}>

                      <View style={styles.containerColunaTabela}>
                        <Text style={styles.textColunaTabela}>
                          Ativo
                        </Text>
                      </View>
                      <View style={styles.containerColunaTabela}>
                        <Text style={styles.textColunaTabela}>
                          Código <br /> interno
                        </Text>
                      </View>
                      <View style={styles.containerColunaTabela}>
                        <Text style={styles.textColunaTabela}>
                          Produto
                        </Text>
                      </View>
                      <View style={styles.containerColunaTabela}>
                        <Text style={styles.textColunaTabela}>
                          Tipo
                        </Text>
                      </View>
                      <View style={styles.containerColunaTabela}>
                        <Text style={styles.textColunaTabela}>
                          Unidade de medida
                        </Text>
                      </View>
                      <View style={styles.containerColunaTabela}>
                        <Text style={styles.textColunaTabela}>
                          Quantidade
                        </Text>
                      </View>
                      <View style={styles.containerColunaTabela}>
                        <Text style={styles.textColunaTabela}>
                          Preço de custo (R$)
                        </Text>
                      </View>



                    </View>

                    {item.insumos.map((insumo) => {
                      return (
                        <View style={styles.containerColunasTabela}>

                          <View style={styles.containerCelulaTabela}>
                            <Text style={styles.textCelulaTabela}>
                              {insumo.ativo ? "Ativo" : "Inativo"}
                            </Text>
                          </View>
                          <View style={styles.containerCelulaTabela}>
                            <Text style={styles.textCelulaTabela}>
                              {insumo.codigoInterno}
                            </Text>
                          </View>
                          <View style={styles.containerCelulaTabela}>
                            <Text style={styles.textCelulaTabela}>
                              {insumo.insumo}
                            </Text>
                          </View>
                          <View style={styles.containerCelulaTabela}>
                            <Text style={styles.textCelulaTabela}>
                              UNIDADE({insumo.unidadeMedida})
                            </Text>
                          </View>
                          <View style={styles.containerCelulaTabela}>
                            <Text style={styles.textCelulaTabela}>
                              {insumo.unidadeMedida}
                            </Text>
                          </View>
                          <View style={styles.containerCelulaTabela}>
                            <Text style={styles.textCelulaTabela}>
                              {insumo.quantidade}
                            </Text>
                          </View>
                          <View style={styles.containerCelulaTabela}>
                            <Text style={styles.textCelulaTabela}>
                              {formatarReal(insumo.custoInsumo)}
                            </Text>
                          </View>



                        </View>
                      )
                    })}



                    <View style={styles.containerPreçoTotalTabela}>

                      <View style={styles.containerCustoTotalTabela}>
                        <Text style={styles.textColunaTabela}>
                          Custo total (R$)
                        </Text>
                      </View>
                      <View style={styles.containerCustoTotalTabela}>
                        <Text style={styles.textColunaTabela}>
                          {formatarReal(item.precoCustoTotal)}
                        </Text>
                      </View>



                    </View>

                  </View>)
                })}




                <View style={styles.footer}>

                  <View style={styles.containerERPLogo} >

                    <Text style={styles.textERP}>
                      ERP Waybe
                    </Text>
                    <Image src={Logo} style={{ width: "60px", height: "12px" }} />
                  </View>

                  <Text style={styles.textPequenoFooter}>
                    Nome do usuário: {dadosAuth.usuarioLogadoDTO.nome}
                  </Text>

                  <View style={styles.containerERPLogo}>

                    <Text style={styles.textPequenoFooter}>
                      {dataCompletaEscrita()}
                    </Text>
                    <Text style={styles.textPequenoFooter}>
                      {/* {`Página ${index + 1} de ${item.totalPages} `} */}
                    </Text>
                  </View>
                </View>
              </View>
            </Page>)


          })}

        </Document>
      </PDFViewer>
    </>
  )
}