import {
  StyledAccordion,
  TextoLinha,
  TypographyTitulo,
} from "../../../../contratoCustomizado/cadastro/styles";
import { AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LinhaClara1, LinhaEscura1 } from "../styles";
import { Check } from "phosphor-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@emotion/react";

export function gerarItemExpansao(modulo, index) {
  if (modulo && modulo?.listaItens?.length > 0) {
    return (
      <StyledAccordion key={"itemsContrato" + modulo?.descricao}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <TypographyTitulo>{modulo?.descricao}</TypographyTitulo>
        </AccordionSummary>
        {modulo?.listaItens.map(
          (item, index) =>
            item.idItemConfiguracao && criarItemContrato(item, index)
        )}
      </StyledAccordion>
    );
  }
}

export function agruparItensPorModulo(lista) {
  const grupos = {};

  if (lista) {
    lista.forEach((item) => {
      const modulo = item.modulo;
      if (!grupos[modulo]) {
        grupos[modulo] = {
          id: item.idItemConfiguracao,
          descricao: item.descricaoModuloContrato,
          listaItens: [],
        };
      }

      grupos[modulo].listaItens.push(item);
    });
  }

  return grupos;
}

function criarItemContrato(item, index) {
  if (index % 2 === 0)
    return <ItemCheckBoxLinhaClara item={item} key={item.idItemConfiguracao} />;
  else
    return (
      <ItemCheckBoxLinhaEscura item={item} key={item.idItemConfiguracao} />
    );
}

function ItemCheckBoxLinhaClara({ item, temaDarkAtivo, largura }) {
  const theme = useTheme();
  return (
    <LinhaClara1 style={{ paddingRight: largura > 768 ? 175 : 60 }}>
      <TextoLinha>{item.descricaoItem}</TextoLinha>
      {item.possuiItem ? (
        <Check
          size={20}
          weight="bold"
          color={theme.cores.surfaceActionActiveSuccess}
        />
      ) : (
        <FontAwesomeIcon
          icon={faTimes}
          style={{
            width: 20,
            height: 20,
            color: theme.adaptativo("semanticDanger2", "semanticDanger2"),
          }}
        />
      )}
    </LinhaClara1>
  );
}

function ItemCheckBoxLinhaEscura({ item, temaDarkAtivo, largura }) {
  const theme = useTheme();
  return (
    <LinhaEscura1 style={{ paddingRight: largura > 768 ? 175 : 60 }}>
      <TextoLinha>{item.descricaoItem}</TextoLinha>
      {item.possuiItem ? (
        <Check
          size={20}
          weight="bold"
          color={theme.cores.surfaceActionActiveSuccess}
        />
      ) : (
        <FontAwesomeIcon
          icon={faTimes}
          style={{
            width: 20,
            height: 20,
            color: theme.adaptativo("semanticDanger2", "semanticDanger2"),
          }}
        />
      )}
    </LinhaEscura1>
  );
}
