export const ItensRealizarPagamentoFactory = {
  criarItemTotal(descricao, valor) {
    return { descricao: descricao, valor: valor };
  },
  criarItemDescricao(descricao, listaSubItens) {
    return { titulo: descricao, listaSubItens: listaSubItens };
  },
  criarSubItemDescricao(descricao, valor) {
    return { descricao: descricao, valor: valor };
  },
  criarAvisoSuperiorEsquerda(titulo, valor, descricao) {
    return { titulo: titulo, valor: valor, descricao: descricao };
  },
};
