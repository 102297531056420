import { useTheme } from "@emotion/react";
import {
  CheckBoxDesmarcado,
  CheckBoxRefItem,
  CheckBoxMarcado,
  Linha,
  LinhaCheckboxRef,
} from "./styles";
import useTamanhoTela from "../../utils/useTamanhoTela";

const definirTamanho = (tamanho, largura) => {
  switch (tamanho) {
    case "SM":
      return 0.7;
    case "MD":
      return 1;
    case "LG":
      return 1.3;
    default:
      return largura < 768 ? 1.3 : 1;
  }
};

export const CheckBox = ({
  ref,
  texto,
  titulo,
  valor,
  value,
  onClick,
  cor,
  corTexto,
  style,
  styleCheckbox,
  tamanho,
  desabilitado,
  id,
  tabIndex = 0,
}) => {
  const theme = useTheme();
  const { largura } = useTamanhoTela();
  return (
    <Linha
      onClick={onClick}
      style={{
        ...style,
      }}
      desabilitado={desabilitado}
      ref={ref}
    >
      {valor || value ? (
        <CheckBoxMarcado
          tabIndex={tabIndex}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClick();
            }
          }}
          cor={cor}
          style={{
            ...styleCheckbox,
            minWidth: 24,
            marginRight: 10,
            transform: `scale(${definirTamanho(tamanho, largura)})`,
          }}
          desabilitado={desabilitado}
        />
      ) : (
        <CheckBoxDesmarcado
          tabIndex={tabIndex}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClick();
            }
          }}
          cor={cor}
          style={{
            ...styleCheckbox,
            minWidth: 24,
            marginRight: 10,
            transform: `scale(${definirTamanho(tamanho, largura)})`,
          }}
          desabilitado={desabilitado}
        />
      )}
      {(texto || titulo) && (
        <p
          style={{
            margin: 0,
            color: corTexto ? corTexto : theme.cores.textoSimples,
          }}
        >
          {texto || titulo}
        </p>
      )}
    </Linha>
  );
};

export const CheckBoxRef = ({ refs, style, className }) => {
  const theme = useTheme();
  const { largura } = useTamanhoTela();
  return (
    <LinhaCheckboxRef
      className={className}
      style={{
        ...style,
      }}
      ref={refs}
    >
      <CheckBoxMarcado />
      <CheckBoxDesmarcado />
    </LinhaCheckboxRef>
  );
};
