import { createContext, useState } from "react";
import objetoNavegacao from "../routes/objetoPadraoNavegacao";

export const NavegacaoContext = createContext(null);

export const NavegacaoProvider = ({ children }) => {
  const { navegacao: padraoNavegacao } = objetoNavegacao;
  const [navegacaoObject, setNavegacaoObject] = useState(padraoNavegacao);
  return (
    <NavegacaoContext.Provider value={[navegacaoObject, setNavegacaoObject]}>
      {children}
    </NavegacaoContext.Provider>
  );
};
