import styled from "@emotion/styled";

export const ItemAdicionarDashboard = styled.div`
  color: ${({ theme }) => theme.cores.neutral100};
  padding: 8px;
  font-weight: bold;
  flex: 1;

  background-color: ${({ theme }) => theme.cores.brand40Default};
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  margin: 5px 0px 5px 0px;
  cursor: pointer;

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 18px;
  }

  &:hover {
    animation: animacaoBotaoAdicionar 0.9s linear reverse infinite;
  }

  @keyframes animacaoBotaoAdicionar {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const ItemMenu = styled.p`
  color: ${({ theme }) => theme.cores.textoSimples};
  font-weight: bold;
  margin: 5px 0px 5px 8px;
  font-size: 18px;
  cursor: pointer;
`;
